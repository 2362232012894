import React, { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../../../common/translation';
import { Title, Link } from '../../../../common/text';
import { SIZE } from '../../../../../constant/text';
import Line from '../../../../common/line';
import { warningMessage } from '../../../../../api/util';
import TextField from '../../../../common/textField';
import Button from '../../../../common/button';
import ConfirmBox from '../../../../common/confirmBox';
import { PRIMARY, SECONDARY } from '../../../../../constant/color';
import { SET_MESSAGE, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../../../constant/action';
import { saveEss } from '../../../../../api/ess';
import { useStore } from '../../../../../store';
import { EditIcon, DeleteIcon, UpArrowIcon, DownArrowIcon } from '../../../../common/icon';
import Loader from '../../../../common/loader';
import { useMount } from '../../../../common/useMount';

const LocalLinks = props => {
    const { t, ess, setEss } = props;
    const { id: essId, packageId, externalLinks = [] } = ess;
    const [link, setLink] = useState({});
    const [loader, setLoader] = useState(false);
    const [addDisabled, setAddDisabled] = useState(true);
    const mounted = useMount();
    const { id, url, description } = link;
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    const editLink = async lnk => {
        setAddDisabled(true);
        setLink(lnk);
    };

    const saveLink = async (lnk, essId) => {
        const { description, url } = lnk;
        if (isEmpty(description) || isEmpty(url)) {
            setMessage(warningMessage(['REQUIRED_FIELDS_FOR_LOCAL_LINKS']));
        } else {
            const payload = {
                action: 'save',
                essId,
                externalLink: lnk
            };
            clearMessage();
            setLoader(true);
            const savedEss = await saveEss(payload);
            if (mounted.current) {
                setLoader(false);
                if (savedEss.error) {
                    setMessage(savedEss.error);
                } else {
                    onCancel();
                    setEss(packageId, essId);
                }
            }
        }
    };

    const onCancel = () => {
        setAddDisabled(true);
        setLink({});
    };

    const removeLink = async lnk => {
        const payload = {
            action: 'delete',
            essId,
            externalLink: lnk
        };
        clearMessage();
        setLoader(true);
        const savedEss = await saveEss(payload);
        if (mounted.current) {
            setLoader(false);
            if (savedEss.error) {
                setMessage(savedEss.error);
            } else {
                setEss(packageId, essId);
            }
        }
    };

    const deleteLink = lnk => {
        openPopUp({ component: <ConfirmBox value="REMOVE_LINK_CONFIRM" onConfirm={() => removeLink(lnk)} /> });
    };

    const sortDown = async lnk => {
        if (lnk.sortNumber < externalLinks.length) {
            lnk.sortNumber += 1;
            await saveLink(lnk, essId);
        }
    };

    const sortUp = async lnk => {
        if (lnk.sortNumber > 1) {
            lnk.sortNumber -= 1;
            await saveLink(lnk, essId);
        }
    };

    const openUrl = url => {
        window.open(url);
    };

    const setDescription = description => {
        if (isEmpty(description)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setLink({ ...link, description });
    };

    const setUrl = url => {
        setLink({ ...link, url });
        setAddDisabled(false);
    };

    return (
        <div className="card loadable">
            {loader && <Loader />}
            <div className="essCardTitle">
                <Title value={`${t('LOCAL_LINKS')}:`} size={SIZE.M} className="essTitle" />
            </div>
            <Line />
            <div className="section inline">
                <TextField
                    placeholder="DESCRIPTION"
                    value={description}
                    onChange={setDescription}
                />
                <TextField
                    placeholder="URL"
                    value={url}
                    onChange={setUrl}
                />
                <Button
                    btnType={PRIMARY}
                    value={t(id ? 'UPDATE' : 'ADD')}
                    onClick={() => saveLink(link, essId)}
                    disabled={addDisabled}
                />
                {id && (
                    <Button
                        btnType={SECONDARY}
                        value={t('CANCEL')}
                        onClick={onCancel}
                    />
                )}
            </div>
            <Line />
            <div className="section separateLines">
                {
                    !isEmpty(externalLinks) && externalLinks
                        .map(lnk => {
                        const { id, description, url } = lnk;

                        return (
                            <div key={id} className="inline">
                                <div className="essCardTitle inline">
                                    <Link
                                        className="leftSpacingInMiddleOfLine"
                                        value={description}
                                        onClick={() => openUrl(url)}
                                    />
                                </div>
                                <div className="buttonField-right">
                                    <EditIcon className="clickable" onClick={() => editLink(lnk)} />
                                    <DeleteIcon className="clickable" onClick={() => deleteLink(lnk)} />
                                    <UpArrowIcon className="clickable" onClick={() => sortUp(lnk)}/>
                                    <DownArrowIcon className="clickable" onClick={() => sortDown(lnk)}/>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default withTranslation()(LocalLinks);
