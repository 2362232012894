import React, { useState, useCallback, useEffect } from 'react';
import { defer } from 'rxjs';
import { withTranslation } from '../../common/translation';
import ProductSelector from '../../common/productSelector';
import { Title } from '../../common/text';
import Dropdown from '../../common/dropdown';
import Checkbox from '../../common/checkbox';
import TextField from '../../common/textField';
import {
    EXTENTS, SIZE, PDF, XML, XSD, INTERVAL_EXTENT, SINGLE_ESS_EXTENT, EMPTY_STRING, WARNING
} from '../../../constant/text';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import { getAssignedProduct, isPredicare } from '../../util';
import { downloadEssAsPdf, downloadEssAsXml, downloadSchemaAsXsd, downloadEssIntervalAsPdf } from '../../../api/ess';
import ExportDownload from './exportDownload';
import { canDownloadPdf, canExportXml } from '../../../api/util';
import { useStore } from '../../../store';
import { useMount } from '../../common/useMount';
import RadioButtons from '../../common/radioButtons';

const Ess = props => {
    const { t } = props;
    const { dispatch } = useStore();
    const mounted = useMount();
    const [essPackage, setEssPackage] = useState(getAssignedProduct());
    const [extent, setExtent] = useState(EXTENTS[0].value);
    const [localAdjustments, setLocalAdjustments] = useState(true);
    const [landscape, setLandscape] = useState(false);
    const [compressed, setCompressed] = useState(false);
    const [alphaIndex, setAlphaIndex] = useState(false);
    const [numIndex, setNumIndex] = useState(true);
    const [firstEss, setFirstEss] = useState(1);
    const [lastEss, setLastEss] = useState(1);
    const [pdfLoader, setPdfLoader] = useState(false);
    const [sortOrder, setSortOrder] = useState('NUMERICAL_INDEX');
    const [xmlLoader, setXmlLoader] = useState(false);
    const [schemaLoader, setSchemaLoader] = useState(false);
    const [firstEssFillMessage, setFirstEssFillMessage] = useState(EMPTY_STRING);
    const [lastEssFillMessage, setLastEssFillMessage] = useState(EMPTY_STRING);
    const { type, typeId, version, id: essPackageId, language: { languageCode, languageId } } = essPackage;

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const subscription = defer(() => { }).subscribe(() => setEssPackage(getAssignedProduct()));

        return () => subscription.unsubscribe();
    }, [setEssPackage]);

    const clearFieldErrorMessages = () => {
        setFirstEssFillMessage(EMPTY_STRING);
        setLastEssFillMessage(EMPTY_STRING);
    };

    const downloadPdf = async () => {
        clearFieldErrorMessages();
        setPdfLoader(true);
        clearMessage();
        const obj = {
            packageId: essPackageId,
            firstEss,
            lastEss,
            localAdjustments,
            languageCode,
            landscape,
            compressed,
            alphaIndex,
            numIndex
        };
        let response;
        if (extent === INTERVAL_EXTENT) {
            response = await downloadEssIntervalAsPdf(obj, `ESS${firstEss}-ESS${lastEss}.pdf`);
        } else if (extent === SINGLE_ESS_EXTENT) {
            obj.lastEss = firstEss;
            response = await downloadEssIntervalAsPdf(obj, `ESS ${firstEss}.pdf`);
        } else {
            response = await downloadEssAsPdf(obj, 'ess.pdf');
        }
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setPdfLoader(false);
        }
    };

    const downloadXml = async () => {
        setXmlLoader(true);
        clearMessage();
        const obj = {
            packageId: essPackageId,
            localAdjustments
        };
        const response = await downloadEssAsXml(obj, 'ess.xml');
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setXmlLoader(false);
        }
    };

    const downloadSchema = async () => {
        setSchemaLoader(true);
        clearMessage();
        const response = await downloadSchemaAsXsd('packageSchema.xsd');
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setSchemaLoader(false);
        }
    };

    const sortOrderOptions = [
        {
            label: t('ALPHABETICAL_INDEX'),
            checked: alphaIndex,
            value: 'ALPHABETICAL_INDEX'
        },
        {
            label: t('NUMERICAL_INDEX'),
            checked: numIndex,
            value: 'NUMERICAL_INDEX'
        },
    ];

    const onSortOrderChange = newSortOrder => {
        setNumIndex(newSortOrder === 'NUMERICAL_INDEX');
        setAlphaIndex(newSortOrder !== 'NUMERICAL_INDEX');

        setSortOrder(newSortOrder);
    };

    const enablePdfDownload = (canDownloadPdf() || isPredicare());
    const enableXmlExport = (canExportXml() || isPredicare());

    return (
        <div className="essExport">
            <div>
                <Dropdown
                    options={EXTENTS}
                    value={extent}
                    onChange={setExtent}
                    keyPrefix="extent"
                    className="extentDropdown"
                />
                <ProductSelector
                    showPublicationStatus={true}
                    setEssPackage={setEssPackage}
                    essTypeId={typeId}
                    version={version}
                    languageId={languageId}
                />
            </div>
            {(extent === INTERVAL_EXTENT || extent === SINGLE_ESS_EXTENT) && (
                <div className="essRangeFields">
                    <TextField
                        placeholder={extent === SINGLE_ESS_EXTENT ? 'ESS_NUMBER' : 'FIRST_ESS'}
                        value={firstEss}
                        onChange={setFirstEss}
                        errorType={WARNING}
                        errorMessage={firstEssFillMessage}
                        label={extent === SINGLE_ESS_EXTENT ? 'ESS_NUMBER' : 'FIRST_ESS'}
                        required={true}
                    />
                    {extent === INTERVAL_EXTENT && (
                        <TextField
                            placeholder="LAST_ESS"
                            value={lastEss}
                            onChange={setLastEss}
                            errorType={WARNING}
                            errorMessage={lastEssFillMessage}
                            label="LAST_ESS"
                            required={true}
                        />
                    )}
                </div>
            )}
            {!isPredicare() && (
                <Checkbox
                    checked={localAdjustments}
                    onChange={setLocalAdjustments}
                    value={'INCLUDE_LOCAL_ADJUSTMENTS'}
                />
            )}
            <div className="checkOptions">
                <div className="layoutOptions">
                    <Title size={SIZE.M} value={t('LAYOUT_OPTIONS')} />
                    <Checkbox
                        checked={landscape}
                        onChange={setLandscape}
                        value={'PDF_IN_LANDSCAPE'}
                    />
                    <Checkbox
                        checked={compressed}
                        onChange={setCompressed}
                        value={'COMPRESS'}
                    />
                </div>
                <div className="tablesOfContent">
                    <Title size={SIZE.M} value={t('INDEX_OPTIONS')} />

                    <RadioButtons options={sortOrderOptions} name={t('SORT_ORDER')} onItemClick={onSortOrderChange} value={sortOrder} />
                </div>
            </div>
            <div className="exportDownloads">
                <ExportDownload iconType={PDF} desc1={type} desc2={version} note={'DOWNLOAD_AS_PDF'} onClick={downloadPdf} loader={pdfLoader} disabled={!enablePdfDownload} />
                <ExportDownload iconType={XML} desc1={type} desc2={version} note={'DOWNLOAD_AS_XML'} onClick={downloadXml} loader={xmlLoader} disabled={!enableXmlExport} />
                {isPredicare() && <ExportDownload iconType={XSD} note={'DOWNLOAD_XML_SCHEMA_ESS'} onClick={downloadSchema} loader={schemaLoader} />}
            </div>
        </div>
    );
};

export default withTranslation()(Ess);
