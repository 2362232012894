import React, { useState, useEffect, useCallback } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../common/translation';
import { Title, Label } from '../../common/text';
import TextField from '../../common/textField';
import Dropdown from '../../common/dropdown';
import { SIZE, EMPTY_STRING, ALL_ACCOUNT_TYPE_ID } from '../../../constant/text';
import Loader from '../../common/loader';
import PopUpCloseIcon from '../../common/popUpCloseIcon';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import { useStore } from '../../../store';
import { getAccountTypes, getCategoryTypes } from '../../../api/app';
import { saveAccount, getAccounts, getTreeChildAccounts, getAccount } from '../../../api/user';
import { SET_MESSAGE, CLOSE_POP_UP, SET_ACCOUNT_TYPES, SET_CATEGORY_TYPES, SET_LANGUAGES, SET_ACCOUNTS, CLEAR_MESSAGE } from '../../../constant/action';
import { getAllLanguages, convertLanguagesToDropdownFormat, getLanguageIdByCode, persistOpenNodes, isPredicare } from '../../util';
import { successMessage } from '../../../api/util';
import Checkbox from '../../common/checkbox';
import { useMount } from '../../common/useMount';

const SaveAccount = props => {
    const { t, parent = {}, node = {}, title = EMPTY_STRING, onClose } = props;
    const { id: parentId = null, accountType: parentAccountType = {} } = parent;
    const { id: parentAccountTypeId = null } = parentAccountType;
    const { id: accountId = null } = node;
    const { dispatch, state: { languages = [], accountTypes = [], accounts = {}, categoryTypes = [] } } = useStore();
    const { rootId, nodes = {} } = accounts;
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const mounted = useMount();
    const acc = {
        parentId,
        accountType: { id: ALL_ACCOUNT_TYPE_ID },
        categoryType: { id: 1 },
        languageId: getLanguageIdByCode(),
        visitingAddress: null,
        invoiceAddress: null,
        hasLicense: false,
        hasAdmin: false,
        isLicenseExpiring: false,
        hasLicenseExpired: false,
        canExportXml: false
    };
    const [account, setAccount] = useState();
    const { name, languageId, canExportXml = false } = account || {};
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const setAccountTypes = useCallback(payload => dispatch({ type: SET_ACCOUNT_TYPES, payload }), [dispatch]);
    const setCategoryTypes = useCallback(payload => dispatch({ type: SET_CATEGORY_TYPES, payload }), [dispatch]);
    const setLanguages = useCallback(payload => dispatch({ type: SET_LANGUAGES, payload }), [dispatch]);
    const setAccounts = useCallback(payload => dispatch({ type: SET_ACCOUNTS, payload }), [dispatch]);

    useEffect(() => {
        const loadAccount = async () => {
            if (accountId) {
                clearMessage();
                const editAcc = await getAccount(accountId);
                if (mounted.current) {
                    if (editAcc.error) {
                        setMessage(editAcc.error);
                    } else {
                        setAccount(editAcc);
                    }
                }
            } else {
                if (parentId && parentAccountTypeId) {
                    acc.accountType.id = parentAccountTypeId;
                }
                setAccount(acc);
            }
        };

        const fetchAccountTypes = async () => {
            if (isEmpty(accountTypes)) {
                setLoader(true);
                clearMessage();
                const accTypes = await getAccountTypes();
                if (mounted.current) {
                    setLoader(false);
                    if (accTypes.error) {
                        setMessage(accTypes.error);
                    } else {
                        setAccountTypes(accTypes);
                    }
                }
            }
        };

        const fetchCategoryTypes = async () => {
            setLoader(true);
            clearMessage();
            const catTypes = await getCategoryTypes();
            if (mounted.current) {
                setLoader(false);
                if (catTypes.error) {
                    setMessage(catTypes.error);
                } else {
                    setCategoryTypes(catTypes);
                }
            }
        };
        const fetchData = () => {
            loadAccount();
            fetchAccountTypes();
            if (isEmpty(categoryTypes)) {
                fetchCategoryTypes();
            }
            if (isEmpty(languages)) {
                setLanguages(getAllLanguages());
            }
        };

        const subscription = defer(() => fetchData()).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const onNameChange = val => {
        setAccount({ ...account, name: val });
    };

    const onLanguageChange = val => {
        setAccount({ ...account, languageId: val });
    };

    const onExportXmlChecked = val => {
        setAccount({ ...account, canExportXml: val });
    };

    const onsubmit = async () => {
        setDisabled(true);
        setLoader(true);
        clearMessage();
        delete account.children;
        const saved = await saveAccount(account);
        if (mounted.current) {
            setLoader(false);
            if (saved.error) {
                setMessage(saved.error);
                setDisabled(false);
            } else if (parentId) {
                setLoader(true);
                const childAccs = await getTreeChildAccounts(rootId);
                if (mounted.current) {
                    setLoader(false);
                    if (childAccs.error) {
                        setMessage(childAccs.error);
                    } else {
                        setAccounts({ nodes: persistOpenNodes(nodes, childAccs) });
                        if (accountId) {
                            setMessage(successMessage(['ACCOUNT_UPDATED']));
                        } else {
                            setMessage(successMessage(['ACCOUNT_ADDED']));
                        }
                    }
                }
                onClose(name);
                closePopUp();
            } else {
                setLoader(true);
                const regs = await getAccounts();
                if (mounted.current) {
                    setLoader(false);
                    if (regs.error) {
                        setMessage(regs.error);
                    } else {
                        setAccounts({ regions: regs });
                        if (accountId) {
                            setMessage(successMessage(['CUSTOMER_UPDATED']));
                        } else {
                            setMessage(successMessage(['CUSTOMER_ADDED']));
                        }
                    }
                }
                onClose(name);
                closePopUp();
            }
        }
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader} />
            <div className="popUpBody">
                <Title value={t(title)} size={SIZE.L} />
                {loader && <Loader />}
                <TextField
                    placeholder={'NAME'}
                    value={name}
                    onChange={onNameChange}
                    label={'NAME'}
                />
                <div className="labelAndField">
                    <Label value={`${t('LANGUAGE')}:`} />
                    <Dropdown
                        options={convertLanguagesToDropdownFormat('languageId')}
                        value={languageId}
                        keyPrefix="addCustomer"
                        onChange={onLanguageChange}
                    />
                </div>
                {isPredicare() && <Checkbox checked={canExportXml} onChange={onExportXmlChecked} value="ACCOUNT_SETTINGS_EXPORT_XML" />}
                <div className="buttonField-right">
                    <Button btnType={PRIMARY} value={accountId ? t('SAVE') : t(title)} onClick={onsubmit} disabled={disabled} />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(SaveAccount);
