import React, { useState } from 'react';
import { withTranslation } from './translation';
import { Title } from './text';
import { DownArrowIcon, RightArrowIcon } from './icon';
import { SIZE } from '../../constant/text';

const Accordion = props => {
    const { t, title, expandedByDefault, component: Component } = props;
    const [expand, setExpand] = useState(expandedByDefault || false);

    const getArrowIcon = isExpanded => {
        if (isExpanded) {
            return <DownArrowIcon />;
        }

        return <RightArrowIcon />;
    };

    return (
        <div className="accordion card">
            <div className="accordionTitle clickable" onClick={() => setExpand(!expand)}>
                <Title value={t(title)} size={SIZE.M} />
                {getArrowIcon(expand)}
            </div>
            {expand && (
                <div className="accordionBody">
                    <Component />
                </div>
            )}
        </div>
    );
};

export default withTranslation()(Accordion);
