import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from './button';
import ToolTip from './toolTip';

const CharacterButtons = ({ t, returnCallback, returnCursorTo }) => {
    const el = document.getElementById(returnCursorTo);

    return (
        <div>
            <ToolTip
                toolTipText={t('SPECIAL_CHAR_WARNING')}
                value={(
                    <>
                        <Button
                            onClick={() => {
                                el.focus();
                                returnCallback(String.fromCharCode(176));
                            }}
                            value={`X${String.fromCharCode(176)}`} />
                        <Button
                            onClick={() => {
                                el.focus();
                                returnCallback(String.fromCharCode(8322));
                            }}
                            value={`X${String.fromCharCode(8322)}`} />
                        <Button
                            onClick={() => {
                                el.focus();
                                returnCallback(String.fromCharCode(178));
                            }}
                            value={`X${String.fromCharCode(178)}`} />
                    </>
                )} />
        </div>
    );
};

export default withTranslation()(CharacterButtons);
