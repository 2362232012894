import React from 'react';
import reverse from 'lodash/reverse';
import { withTranslation } from './translation';
import { SIZE, DROPDOWN_SELECT_TEXT_VALUE, EMPTY_STRING } from '../../constant/text';

const Dropdown = props => {
    const { dataAttribute, t, options, optionsTitle, value, size = SIZE.M, className = EMPTY_STRING, keyPrefix = EMPTY_STRING, selectLabel, onChange, sortReverse = false, disabled = false } = props;
    const selectObject = { label: t(selectLabel), value: DROPDOWN_SELECT_TEXT_VALUE };
    if (selectLabel && !options.some(option => option.label === selectLabel)) {
        options.sort((a, b) => a.value - b.value);
        if (sortReverse) {
            reverse(options);
        }
        options.unshift(selectObject);
    }

    return (
        <select data-test={dataAttribute} disabled={disabled} className={`${size} ${className} field`} onChange={e => onChange(e.target.value)} value={value}>
            {optionsTitle
                && <option value="">{optionsTitle}</option>
            }
            {
                options.map(obj => <option className="option" key={`${keyPrefix}-${obj.value}`} value={obj.value}>{t(obj.label)}</option>)
            }
        </select>
    );
};

export default withTranslation()(Dropdown);
