import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { defer } from 'rxjs';
import { Title } from '../../../../common/text';
import TextField from '../../../../common/textField';
import Button from '../../../../common/button';
import { withTranslation } from '../../../../common/translation';
import InfoBox from '../../../../common/infoBox';
import { getVpSearchPhrases, saveVpSearchPhrase, deleteVpSearchPhrase } from '../../../../../api/vp';
import { EditIcon, DeleteIcon } from '../../../../common/icon';
import { PRIMARY, SECONDARY } from '../../../../../constant/color';
import { EMPTY_STRING } from '../../../../../constant/text';
import ConfirmBox from '../../../../common/confirmBox';
import { warningMessage } from '../../../../../api/util';
import { useStore } from '../../../../../store';
import { SET_MESSAGE, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../../../constant/action';
import Loader from '../../../../common/loader';
import { useMount } from '../../../../common/useMount';

const SearchPhrase = props => {
    const { t, vpPackageId } = props;
    const mounted = useMount();
    const [searchPhrases, setSearchPhrases] = useState([]);
    const [searchPhrase, setSearchPhrase] = useState({});
    const { id, searchphrase: description = EMPTY_STRING } = searchPhrase;
    const [addDisabled, setAddDisabled] = useState(true);
    const [loader, setLoader] = useState(false);
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    const fetchSearchPhrases = async () => {
        setLoader(true);
        const spList = await getVpSearchPhrases(vpPackageId);
        setLoader(false);
        if (spList.error) {
            setMessage(spList.error);
        } else {
            setSearchPhrases(spList);
        }
    };

    useEffect(() => {
        const subscription = defer(() => fetchSearchPhrases()).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const setSearchPhraseDescription = description => {
        if (isEmpty(description)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setSearchPhrase({ ...searchPhrase, searchphrase: description });
    };

    const editSearchPhrase = async sp => {
        setAddDisabled(true);
        setSearchPhrase(sp);
    };

    const deleteSearchPhrase = spId => {
        openPopUp({ component: <ConfirmBox value="REMOVE_SEARCHPHRASE_CONFIRM" onConfirm={() => removeSearchPhrase(spId)} /> });
    };

    const removeSearchPhrase = async spId => {
        clearMessage();
        setLoader(true);
        const savedEss = await deleteVpSearchPhrase(spId);
        if (mounted.current) {
            setLoader(false);
            if (savedEss.error) {
                setMessage(savedEss.error);
            } else {
                fetchSearchPhrases();
            }
        }
    };

    const saveSearchPhrase = async (sp, vpPackageId) => {
        const { searchphrase: description } = sp;
        if (isEmpty(description)) {
            setMessage(warningMessage(['REQUIRED_FIELD']));
        } else {
            const payload = sp;
            sp.vppackagesId = vpPackageId;
            clearMessage();
            setLoader(true);
            const savedEss = await saveVpSearchPhrase(payload);
            if (mounted.current) {
                setLoader(false);
                if (savedEss.error) {
                    setMessage(savedEss.error);
                } else {
                    onCancel();
                    fetchSearchPhrases();
                }
            }
        }
    };

    const onCancel = () => {
        setAddDisabled(true);
        setSearchPhrase({});
    };

    return (
        <div className="section separateLines">
            <Title value={t('EDIT_SEARCH_PHRASE')} />
            <InfoBox>{t('VP_SEARCHPHRASES_INFO')}</InfoBox>
            {loader && <Loader />}
            <div className="section inline">
                <TextField
                    placeholder="SEARCH_PHRASE"
                    value={description}
                    onChange={setSearchPhraseDescription}
                />
                <Button
                    btnType={PRIMARY}
                    value={t(id ? 'UPDATE' : 'ADD')}
                    onClick={() => saveSearchPhrase(searchPhrase, vpPackageId)}
                    disabled={addDisabled}
                />
                {id && (
                    <Button
                        btnType={SECONDARY}
                        value={t('CANCEL')}
                        onClick={onCancel}
                    />
                )}
            </div>
            {
                searchPhrases.map(item => {
                    const { id, searchphrase: description } = item;

                    return (
                        <div key={id} className="inline">
                            <div className="essCardTitle inline">
                                <div className="leftSpacingInMiddleOfLine">{description}</div>
                            </div>
                            <div className="buttonField-right">
                                <EditIcon className="clickable" onClick={() => editSearchPhrase(item)} />
                                <DeleteIcon className="clickable" onClick={() => deleteSearchPhrase(id)} />
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default withTranslation()(SearchPhrase);
