import React from 'react';
import { withTranslation } from '../../../common/translation';

const StatusField = ({ essStatus, isFullSticky }) => {
    const className = isFullSticky ? 'statusFieldContainer' : '';

return (
    <div className={className}>
        <div
            className="statusFieldContent section card warning noBorder textCenter"
    >
            {essStatus}
        </div>
    </div>
);
};

export default withTranslation()(StatusField);
