import React, { useCallback, useState } from 'react';
import map from 'lodash/map';
import Button from './button';
import { withTranslation } from './translation';
import { PRIMARY, SECONDARY } from '../../constant/color';
import PopUpCloseIcon from './popUpCloseIcon';
import { useStore } from '../../store';
import { CLOSE_POP_UP, SAVE_CARDS } from '../../constant/action';

const ConfirmBox = props => {
    const { t, value, onConfirm, component: Component, chooseCardToCloseButton = false, data = {} } = props;
    const { dispatch, state: { card: { searchCards = [] } } } = useStore();
    const [disabled, setDisabled] = useState(false);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const saveCards = useCallback(payload => dispatch({ type: SAVE_CARDS, payload }), [dispatch]);

    const onCancel = () => {
        setDisabled(true);
        closePopUp();
    };

    const onConfirmClick = () => {
        setDisabled(true);
        onConfirm();
        closePopUp();
    };

    const onCloseCard = cardToClose => {
        setDisabled(true);
        saveCards({ data, replace: cardToClose.id });
        closePopUp();
    };

    const composeButtonTextForCard = card => {
        let res = { type: '', value: '' };

        switch (card.cardType) {
            case 'ESS':
                res.type = `${t(card.cardType)}:`;
                res.value = card.number.toString();
                break;
            case 'VP':
                res.type = `${t(card.cardType)}:`;
                res.value = t(card.vptypeName);
                break;
            case 'DEFINITION':
                res.type = `${t(card.cardType).toLowerCase()}:`;
                res.value = t(card.key);
                break;
            default:
                res = { type: '', value: '' };
        }

        return t('CLOSE_CARD', res);
    };

    return (
        <div className="popUpComponent">
            <PopUpCloseIcon />
            <div className="popUpBody">
                {value && (
                    <div className="confirmBoxText">
                        {t(value)}
                    </div>
                )}
                {Component && Component}
                <div className="buttonField-right">
                    <Button value={t('CANCEL')} btnType={SECONDARY} onClick={() => onCancel()} disabled={disabled} />
                    {!chooseCardToCloseButton && <Button value={t('CONFIRM')} btnType={PRIMARY} onClick={() => onConfirmClick()} disabled={disabled} />}
                    {chooseCardToCloseButton && map(searchCards, card => (
                        <Button
                            key={card.id}
                            value={composeButtonTextForCard(card)}
                            btnType={PRIMARY}
                            onClick={() => onCloseCard(card)}
                            disabled={disabled}
                                />
                            ))
                    }
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(ConfirmBox);
