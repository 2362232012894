import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import Accounts from './accounts';
import { useStore } from '../../../store';
import Users from './users';
import Licenses from './licenses';
import AccountInfo from './accountInfo';
import Products from './products';
import { withTranslation } from '../../common/translation';
import { isPredicareRegion, isPredicare } from '../../util';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import ListDownloader from './listDownloader';
import SearchUser from './searchUser';
import { OPEN_POP_UP } from '../../../constant/action';

const ManageUsers = props => {
    const { t } = props;
    const { dispatch, state: { accounts = {} } } = useStore();
    const { selectedAccountNode = {} } = accounts || {};
    const predicareRegion = isPredicareRegion(selectedAccountNode);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    return (
        <div className="manageUsers">
            {isPredicare() && (
                <div className="manageUsersButtons">
                    <div className="buttonField-right">
                        <Button btnType={PRIMARY} value={t('SEARCH_FOR_USER')} onClick={() => openPopUp({ component: <SearchUser/> })}/>
                        <Button btnType={PRIMARY} value={t('GET_ADDRESS_LIST')} onClick={() => openPopUp({ component: <ListDownloader/> })}/>
                    </div>
                </div>
            )}
            <Accounts/>
            {!isEmpty(selectedAccountNode) && (
                <div className="accountDetails">
                    <Users node={selectedAccountNode}/>
                    <Licenses node={selectedAccountNode}/>
                    {isPredicare() && !predicareRegion && (
                        <div>
                            <Products node={selectedAccountNode}/>
                            <AccountInfo node={selectedAccountNode}/>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default withTranslation()(ManageUsers);
