import React from 'react';
import LocalProcessActions from './localProcessActions';
import LocalFactBox from './localFactBox';
import LocalLinks from './localLinks';

const EssLocal = props => {
    const { ess, setEss, defaultProcessActions } = props;

    return (
        <div className="essLocal ie11-hack">
            <LocalProcessActions
                ess={ess}
                setEss={setEss}
                defaultProcessActions={defaultProcessActions}
                />
            <LocalFactBox ess={ess} setEss={setEss} />
            <LocalLinks ess={ess} setEss={setEss} />
        </div>
    );
};

export default EssLocal;
