import React from 'react';
import map from 'lodash/map';
import { withTranslation } from '../../../common/translation';
import { Link, Title } from '../../../common/text';
import { SIZE } from '../../../../constant/text';

const EssSearchPhrase = ({ data, addEssIdToSearchCard, t, isFullSticky }) => {
    const id = isFullSticky ? 'searcPhrasesContainerSticky' : 'searcPhrasesContainer';

    return (
        <div className="section infoLayerBorder" id={id}>
            <div className="searchPhrases">
                <div className="cardRow">
                    <div className="leftDiv infoLayer">
                        <Title value={`${t('SEARCH_PHRASE')}`} size={SIZE.L} align="center"/>
                        <ul>
                            {map(data.searchPhrases, item => <li key={item.id}>{item.searchPhrase}</li>)}
                        </ul>
                    </div>
                    <div className="rightDiv infoLayer">
                        <Title value={`${t('RELATED_LINKS')}`} size={SIZE.L} align="center"/>
                        <div className="content">
                            {map(data.links, item => (
                                <div className="relatedLink" key={item.id}>
                                    <span key={item.id}>{item.description}</span>
                                    <span>&nbsp;</span>
                                    <span>
                                        <Link
                                            onClick={() => addEssIdToSearchCard(item.essTargetId)}
                                            value={`${t('ESS')}.${item.essTargetNumber}`}
                                                />
                                    </span>
                                </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(EssSearchPhrase);
