import React from 'react';
import { isEmpty } from 'lodash';
import EssSpList from './essSpList';
import VpList from './vpList';
import { withTranslation } from '../../common/translation';
import EssClassifyList from './essClassifyList';
import { getAssignedProduct } from '../../util';
import InfoBox from '../../common/infoBox';

const List = props => {
    const assignedProduct = getAssignedProduct();
    const { id: essPackageId, vppackageId: vpPackageId } = assignedProduct;
    const { t } = props;

    return (
        <div className="essList">
            {
                isEmpty(assignedProduct) && (
                    <div className="grid searchCardsGrid">
                        <InfoBox title={t('REFRESH_PAGE')} />
                    </div>
)
            }
            {
                assignedProduct.noProduct && (
                    <div className="grid searchCardsGrid">
                        <InfoBox title={t('NO_AVAILABLE_VERSIONS')} />
                    </div>
                )
            }
            {
                !assignedProduct.noProduct && !isEmpty(assignedProduct) && (
                    <div className="listContent">
                        <div className="left">
                            <EssClassifyList essPackageId={essPackageId}/>
                            <VpList vpPackageId={vpPackageId}/>
                        </div>
                        <div className="right">
                            <EssSpList essPackageId={essPackageId}/>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default React.memo(withTranslation()(List));
