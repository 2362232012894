import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { ENGLISH_CODE, LOCAL_LANGUAGE_CODE } from './constant/text';
import { getFromLocal } from './api/localStorageUtil';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['sv'],
    lng: getFromLocal(LOCAL_LANGUAGE_CODE)
      ? getFromLocal(LOCAL_LANGUAGE_CODE)
      : ENGLISH_CODE,
    // have a retts namespace used around the full app
    ns: ['common'],
    defaultNS: ['common'],
    fallbackNS: ['common'],
    debug: false,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?${process.env.REACT_APP_VERSION}`,
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
  });

export default i18n;
