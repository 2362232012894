/* eslint-disable no-unused-vars */
import {
    SET_REGISTER_DATA,
    SET_OVERVIEW,
    SET_USERS,
    SET_LICENSES,
    SET_USERNAME_AND_PASSWORD
} from '../constant/action';

export const userAction = {
    [SET_REGISTER_DATA]: (state, payload) => ({
            register: payload
        }),

    [SET_OVERVIEW]: (state, payload) => ({
            overview: payload
        }),
    [SET_USERS]: (state, payload) => ({
            users: payload
        }),
    [SET_LICENSES]: (state, payload) => ({
            licenses: payload
        }),
    [SET_USERNAME_AND_PASSWORD]: (state, payload) => ({
            loginId: payload
        }),
};
