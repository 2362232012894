import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../common/translation';
import { Title } from '../../common/text';
import { SIZE, EMPTY_STRING } from '../../../constant/text';
import { SET_MESSAGE, OPEN_POP_UP, SET_LICENSES, CLEAR_MESSAGE } from '../../../constant/action';
import { Table, Thead, Tbody, Th, Tr, Td } from '../../common/table';
import { getLicensesForAccount, deleteLicense } from '../../../api/user';
import { useStore } from '../../../store';
import { ReadIcon, CloseIcon, EditIcon, DeleteIcon } from '../../common/icon';
import Loader from '../../common/loader';
import EditLicense from './editLicense';
import AssignLicense from './assignLicense';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import { errorMessage } from '../../../api/util';
import { isPredicare } from '../../util';
import { useMount } from '../../common/useMount';

const Licenses = props => {
    const { t, node = {} } = props;
    const { id: accountId, parentId } = node;
    const mounted = useMount();
    const [loader, setLoader] = useState(false);
    const { dispatch, state: { licenses = [] } } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setLicenses = useCallback(payload => dispatch({ type: SET_LICENSES, payload }), [dispatch]);

    const fetchLicenses = async () => {
        setLoader(true);
        clearMessage();
        const licenses = await getLicensesForAccount(accountId);
        if (mounted.current) {
            setLoader(false);
            if (licenses.error) {
                setMessage(licenses.error);
            } else {
                setLicenses(licenses);
            }
        }
    };

    useEffect(() => {
        const subscription = defer(() => fetchLicenses()).subscribe();

        return () => subscription.unsubscribe();
    }, [accountId]);

    const addLicense = () => {
        openPopUp({ component: <AssignLicense node={node} /> });
    };

    const editLicense = license => {
        openPopUp({ component: <EditLicense data={license} /> });
    };

    const removeLicense = async license => {
        setLoader(true);
        clearMessage();
        const deleted = await deleteLicense(license.id);
        if (mounted.current) {
            setLoader(false);
            if (deleted.error) {
                setMessage(errorMessage(['BAD_REQUEST']));
            } else {
                fetchLicenses();
            }
        }
    };

    return (
        <div className="licenses loadable">
            <Title value={t('LICENSES')} size={SIZE.L} />
            {loader && <Loader />}
            {parentId === null && isPredicare() && <Button btnType={PRIMARY} value={t('ASSIGN_LICENSE')} onClick={addLicense} />}
            {isEmpty(licenses) && <div className="notFound">{t('NO_LICENSES_FOUND_FOR_ACCOUNT')}{!isPredicare() ? `. ${t('CONTACT_PREDICARE')}.` : EMPTY_STRING}</div>}
            {!isEmpty(licenses) && (
                <Table className={'striped-horizontally'}>
                    <Thead>
                        <Tr>
                            <Th>{t('TYPE_OF_LICENSE')}</Th>
                            <Th>{t('TYPE_OF_ACCOUNT')}</Th>
                            <Th>{t('OWNED_BY')}</Th>
                            <Th>{t('USED_BY')}</Th>
                            <Th>{t('PAID')}</Th>
                            <Th>{t('VALID_THRU')}</Th>
                            {isPredicare() && <Th>{EMPTY_STRING}</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            licenses.map(license => {
                                const { id, licenseType: { type: licenseTypeName }, accountType: { type: accountTypeName }, boughtByAccountName, usedByAccountName, paid = false, validTo } = license;

                                return (
                                    <Tr key={`${id}-${licenseTypeName}-${accountTypeName}-${boughtByAccountName}-${validTo}`}>
                                        <Td>{t(licenseTypeName)}</Td>
                                        <Td>{t(accountTypeName)}</Td>
                                        <Td>{t(boughtByAccountName)}</Td>
                                        <Td>{t(usedByAccountName)}</Td>
                                        <Td>{paid ? <ReadIcon toolTip={t('PAID')} /> : <CloseIcon toolTip={t('NOT_PAID')} />}</Td>
                                        <Td>{validTo !== null ? validTo : EMPTY_STRING}</Td>
                                        {isPredicare() && (
                                            <Td>
                                                <div className="icons">
                                                    <EditIcon toolTip={t('EDIT')} onClick={() => editLicense(license)} />
                                                    <DeleteIcon toolTip={t('DELETE')} onClick={() => removeLicense(license)} />
                                                </div>
                                            </Td>
                                        )}
                                    </Tr>
                                );
                            })
                        }
                    </Tbody>
                </Table>
            )}
        </div>
    );
};

export default withTranslation()(Licenses);
