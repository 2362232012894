import React from 'react';
import map from 'lodash/map';
import keys from 'lodash/keys';
import { RISK_LEVEL_VALUE, RISK_LEVEL_SYMPTOMS_TRANSLATION_KEYS } from '../../../../constant/text';
import { withTranslation } from '../../../common/translation';
import { WithAnnotations } from '../../../common/withAnnotations';

const EssTriage = ({ symptoms, t }) => map(keys(RISK_LEVEL_VALUE), riskLevel => (
    <div className={`section ie11-hack riskLevel-border-${riskLevel}`} key={riskLevel}>
        <div className={`sectionTitle riskLevel-bg-${riskLevel}`}>
            {t(RISK_LEVEL_SYMPTOMS_TRANSLATION_KEYS[RISK_LEVEL_VALUE[Number(riskLevel)]])}
        </div>
        <div className={symptoms[riskLevel] ? 'sectionContent' : `sectionContent riskLevel-bg-empty-${riskLevel}`}>
            <div className="sectionContent-text-wrapper">
                <ul>
                    {
                        map(symptoms[riskLevel], (item, j) => (
                            <li key={j}> <WithAnnotations>{item.description}</WithAnnotations> </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </div>
    ));

export default withTranslation()(EssTriage);
