import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import ToolTip from './toolTip';
import { getAssignedProduct, getUserLanguageId } from '../util';
import { getDefinitionListById } from '../../api/definitionList';
import { useMount } from './useMount';

const reactStringReplace = require('react-string-replace');

const urlRegex = /(https?:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_+.~#?&/=]*)/ig;

const prefixWebAddressWithProtocol = address => {
    if (address.startsWith('http://') || address.startsWith('https://')) {
        return address;
    }

    return `https://${address}`;
};

export const WithAnnotations = props => {
    const [definitionList, setDefinitionList] = useState([]);
    const mounted = useMount();

    useEffect(() => {
        const assignedProduct = getAssignedProduct();

        const languageId = !isEmpty(assignedProduct) && !assignedProduct.noProduct ? assignedProduct.language.languageId : getUserLanguageId();
        // prevents multiple requests to server
        if (definitionList.length === 0) {
            getDefinitionListById(languageId)
                .then(definitionList => {
                    if (mounted.current) {
                        setDefinitionList(definitionList);
                    }
                });
        }
    }, []);

    const children = Array.isArray(props.children) ? props.children : [props.children];

    return (
        children.map(child => {
            definitionList.forEach(def => {
                child = reactStringReplace(child, def.key, match => (
                    <ToolTip
                        className={'definition'}
                        key={`${uuidv4()}`}
                        toolTipText={def.definition}
                        value={match}
                    />
                ));
            });
            child = reactStringReplace(child, urlRegex, (match, i) => (
                <a
                    key={i}
                    className="link"
                    target="_blank"
                    href={prefixWebAddressWithProtocol(match)}
                    rel="noreferrer">{match}
                </a>
            ));

            return child;
        })
    );
};
