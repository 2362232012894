import React, { useState, useCallback, useEffect } from 'react';
import { defer } from 'rxjs';
import PopUpCloseIcon from '../../common/popUpCloseIcon';
import Loader from '../../common/loader';
import { withTranslation } from '../../common/translation';
import { Title } from '../../common/text';
import TextField from '../../common/textField';
import { useStore } from '../../../store';
import Button from '../../common/button';
import { saveUser, getUsersForAccount, getDepartmentUser } from '../../../api/user';
import { PRIMARY } from '../../../constant/color';
import { CLOSE_POP_UP, SET_USERS, SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import { successMessage, errorMessage } from '../../../api/util';
import Checkbox from '../../common/checkbox';
import { useMount } from '../../common/useMount';
import { DEPARTMENT_USER_ROLE_ID } from '../../../constant/route';
import { QUERY_SPLITTER } from '../../../constant/text';

const EditUser = props => {
    const { t, data } = props;
    const [loader, setLoader] = useState();
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [autoLoginLink, setAutoLoginLink] = useState('');
    const [user, setUser] = useState(data);
    const mounted = useMount();
    const { name, role: { id: roleId, description: userRole }, active, accountId } = user;
    const { dispatch, state: { accounts: { selectedAccountNode: { name: accountName } } } } = useStore();
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const setUsers = useCallback(payload => dispatch({ type: SET_USERS, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        setUser(data);
        const fetchDeptUserDetail = async () => {
            if (roleId === DEPARTMENT_USER_ROLE_ID) {
                setLoader(true);
                clearMessage();
                const deptUserDetail = await getDepartmentUser(accountId);
                if (mounted.current) {
                    setLoader(false);
                    if (deptUserDetail.error) {
                        setMessage(deptUserDetail.error);
                    } else {
                        setAutoLoginLink(replaceOriginForAutologinLink(deptUserDetail.autoLoginLink));
                    }
                }
            }
        };
        const subscription = defer(() => fetchDeptUserDetail()).subscribe();

        return () => subscription.unsubscribe();
    }, [data]);

    const onNameChange = name => {
        setUser({ ...user, name });
        setSaveButtonDisabled(false);
    };

    const onActiveChecked = checked => {
        setUser({ ...user, active: checked });
        setSaveButtonDisabled(false);
    };

    const replaceOriginForAutologinLink = autoLoginLink => {
        const queryParams = autoLoginLink.split(QUERY_SPLITTER)[1];
        const newLink = `${window.location.origin}${QUERY_SPLITTER}${queryParams}`;

        return encodeURI(newLink);
    };

    const onsubmit = async () => {
        setSaveButtonDisabled(true);
        setLoader(true);
        clearMessage();
        const url = window.location.href;
        const response = await saveUser({ ...user, url }, false);
        if (mounted.current) {
            setLoader(false);
            if (response.error) {
                setMessage(response.error);
                setSaveButtonDisabled(false);
            } else if (typeof response === 'string') {
                setMessage(errorMessage([response]));
                setSaveButtonDisabled(false);
            } else {
                setLoader(true);
                const usersResponse = await getUsersForAccount(accountId);
                if (mounted.current) {
                    setLoader(false);
                    if (usersResponse.error) {
                        setMessage(usersResponse.error);
                        setSaveButtonDisabled(false);
                    } else {
                        setUsers(usersResponse);
                        if (response === true) {
                            setMessage(successMessage(['USER_UPDATED']));
                            closePopUp();
                        } else {
                            setMessage(errorMessage(['USER_ROLE_ACCOUNT_EXISTS']));
                            setSaveButtonDisabled(false);
                        }
                    }
                }
            }
        }
    };

    const copyLink = () => {
        document.getElementById('link-input').select();
        document.execCommand('copy');
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader} />
            <div className="popUpBody">
                <Title value={t(accountName)} />
                {loader && <Loader />}
                <TextField
                    placeholder={'NAME'}
                    value={name}
                    onChange={onNameChange}
                    label={'NAME'}
                />
                <TextField
                    placeholder={'USER_ROLE'}
                    type="text"
                    value={t(userRole)}
                    label={'USER_ROLE'}
                    disabled={true}
                />
                <Checkbox checked={active} onChange={onActiveChecked} value="USER_SHOULD_BE_ACTIVE" additionalStyle={{ width: 'unset' }}/>
                { autoLoginLink && (
                    <div className="autologinlinkwrapper">
                        <TextField id={'link-input'} label={t('AUTOLOGIN_LINK')} value={autoLoginLink} />
                        <Button btnType={PRIMARY} value={t('COPY_LINK')} onClick={copyLink}/>
                    </div>
                ) }
                <div className="buttonField-center">
                    <Button btnType={PRIMARY} value={t('SAVE')} onClick={onsubmit} disabled={saveButtonDisabled} />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(EditUser);
