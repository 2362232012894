import React from 'react';
import map from 'lodash/map';
import { withTranslation } from '../../../common/translation';
import Loader from '../../../common/loader';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../../common/table';

const JournalList = props => {
    const { t, loader, list } = props;

    return (
        <div className="card loadable">
            <div className="title">{t('JOURNALS')}</div>
            <Table className={'striped-horizontally'}>
                <Thead>
                    <Tr>
                        <Th>{t('VERSION_NAME')}</Th>
                        <Th>{t('RETTS_TYPE')}</Th>
                        <Th>{t('LANGUAGE')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {map(list, (item, i) => (
                        <Tr key={i}>
                            <Td>{item.version}</Td>
                            <Td>{item.type}</Td>
                            <Td>{item.languageName}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            {loader && <Loader />}
        </div>
    );
};

export default withTranslation()(JournalList);
