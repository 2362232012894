import { apiGet, apiPost, unauthorizedApiGet } from './request';
import { GET_LANGUAGES, GET_ACCOUNT_TYPES, GET_CATEGORY_TYPES, GET_NOTIFICATIONS, NOTIFICATION_READ } from '../constant/api';
import { getRoleId } from './util';

export const getLanguages = async () => {
    const data = await unauthorizedApiGet(GET_LANGUAGES);

    return data;
};

export const getAccountTypes = async () => {
    const data = await apiGet(GET_ACCOUNT_TYPES);

    return data;
};

export const getCategoryTypes = async () => {
    const data = await apiGet(GET_CATEGORY_TYPES);

    return data;
};

export const getNotifications = async () => {
    let data = await apiGet(GET_NOTIFICATIONS);
    if (!data.error) {
        data = data.filter(notification => {
            const validRole = notification.roles.filter(role => role.id === getRoleId()).length > 0;

            return validRole;
        });
    }

    return data;
};

export const notificationRead = async (notifications, notificationId) => {
    const data = await apiPost(NOTIFICATION_READ, { id: notificationId });
    if (!data.error) {
        if (data.read) {
            return getNotifications();
        }

        return notifications;
    }

    return data;
};
