import React from 'react';

const Sticky = ({
  sometimesStickyComponents,
  alwaysStickyComponents,
  isFullSticky = false,
}) => {
  if (isFullSticky) {
    return (
        <div className="essCardSticky">
            {alwaysStickyComponents}
            {sometimesStickyComponents}
        </div>
    );
  }

  return (
      <>
          <div className="essCardSticky">{alwaysStickyComponents}</div>
          {sometimesStickyComponents}
      </>
  );
};

export default Sticky;
