import React, { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import SearchForm from '../../../search/searchForm';
import VpCard from '../../../search/vpCard';
import { useStore } from '../../../../../store';
import { SET_MESSAGE, CLEAR_MESSAGE, SET_VP_EDIT } from '../../../../../constant/action';
import { LOCAL_VP_EDIT } from '../../../../../constant/text';
import { SECONDARY } from '../../../../../constant/color';
import Loader from '../../../../common/loader';
import { getFromLocal } from '../../../../../api/localStorageUtil';
import PrevNextButtons from '../prevNextButtons';
import { getAdjacentVpTypeIdsByPackageAndVpTypeDetailId, getVpTypeDetailById, getVpByVpTypeId } from '../../../../../api/vp';
import { useMount } from '../../../../common/useMount';

const SearchVp = () => {
    // eslint-disable-next-line no-unused-vars
    const { dispatch, state: { manageVersions: { vpEdit = {} } } } = useStore();
    const { searchBy, publicationStatus, allVersions, version, languageId, editType } = getFromLocal(LOCAL_VP_EDIT, true);
    const [loader, setLoader] = useState(false);
    const mounted = useMount();
    const [adjacentDetail, setAdjacentDetail] = useState({ previousId: null, nextId: null });
    const { previousId, nextId, currentDetail = {} } = adjacentDetail;
    const { vppackageId: packageId } = currentDetail;

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setVpEdit = useCallback(payload => dispatch({ type: SET_VP_EDIT, payload }), [dispatch]);

    const onSearchData = data => {
        const { vppackageId: packageId, id } = data;
        getAdjacentDetail(packageId, id, data);
    };

    const onEdit = data => {
        const { vppackageId: packageId, cardType, selectedPublicationStatus,
            selectedAllVersions, selectedVersion, selectedLanguageId,
            vptypes: vpTypeId } = data;
        setVpEdit({
            searchBy: cardType,
            publicationStatus: selectedPublicationStatus,
            allVersions: selectedAllVersions,
            version: selectedVersion,
            languageId: selectedLanguageId,
            vpPackageId: packageId,
            vpTypeId,
            editType
        });
        const el = document.getElementById('editSection');
        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const getVpTypeDetail = async (vpPackageId, vpTypeId, vpTypeDetailId) => {
        if (vpTypeDetailId || vpTypeId) {
            setLoader(true);
            clearMessage();
            let vpTypeDetail;
            if (vpTypeDetailId) {
                vpTypeDetail = await getVpTypeDetailById(vpTypeDetailId);
            } else {
                vpTypeDetail = await getVpByVpTypeId(vpPackageId, vpTypeId);
            }
            if (mounted.current) {
                setLoader(false);
                if (vpTypeDetail.error) {
                    setMessage(vpTypeDetail.error);
                } else {
                    const { id: vpTypeDetId } = vpTypeDetail;
                    getAdjacentDetail(vpPackageId, vpTypeDetId, vpTypeDetail);
                }
            }
        }
    };

    const getAdjacentDetail = async (vpPackageId, vpTypeDetailId, vpTypeDetail) => {
        if (vpPackageId && vpTypeDetailId) {
            setLoader(true);
            const adjacentVpTypeIds = await getAdjacentVpTypeIdsByPackageAndVpTypeDetailId(vpPackageId, vpTypeDetailId);
            if (mounted.current) {
                const { previousHeaderId: previousId, nextHeaderId: nextId } = adjacentVpTypeIds;
                setAdjacentDetail({ ...adjacentDetail, previousId, nextId, currentDetail: vpTypeDetail });
                setLoader(false);
            }
        }
    };

    return (
        <div className="searchFormInEssEdit loadable">
            <SearchForm
                onData={onSearchData}
                showSearchBy={false}
                showProductSelector={true}
                showPublicationStatus={true}
                publicationStatus={publicationStatus}
                searchBy={searchBy}
                allVersions={allVersions}
                version={version}
                languageId={languageId}
            />
            <div className="prevNextButtonContainer">
                <div className="buttonField-left">
                    <PrevNextButtons btnType={SECONDARY} previousId={previousId} packageId={packageId} nextId={nextId} getDetailById={getVpTypeDetail} />
                </div>
            </div>
            {loader && <Loader />}
            {!isEmpty(currentDetail) && currentDetail.id && (
                <VpCard
                    data={currentDetail}
                    onClose={() => setAdjacentDetail({ ...adjacentDetail, currentDetail: {}, previousId: null, nextId: null })}
                    onEdit={() => onEdit(currentDetail)}
                    editable={true}
                />
            )}
        </div>
    );
};

export default SearchVp;
