import React from 'react';
import { CloseIcon } from '../../common/icon';
import { Title } from '../../common/text';
import { SIZE } from '../../../constant/text';

const DefinitionCard = props => {
    const { data, onClose } = props;
    const { key, id, definition = [] } = data;

    return (
        <div className="essCard scrollable">
            <div className="essCardTitle">
                <Title value={key} size={SIZE.M} className="essTitle" />
                <CloseIcon className="closeIcon-card" onClick={() => onClose(id)} />
            </div>
            <div className="definitionBlock">{definition}</div>
        </div>
    );
};

export default DefinitionCard;
