import React, { useCallback, useEffect } from 'react';
import { withTranslation } from '../../../../../common/translation';
import { Title } from '../../../../../common/text';
import { SIZE } from '../../../../../../constant/text';
import LocalSpecificProcessActions from './localSpecificProcessActions';
import LocalGeneralProcessActions from './localGeneralProcessActions';
import { CLEAR_MESSAGE, OPEN_POP_UP, SET_MESSAGE, SET_PROCESS_ACTION_TYPE } from '../../../../../../constant/action';
import { useStore } from '../../../../../../store';

const LocalProcessActions = ({ t, ess, setEss, defaultProcessActions }) => {
    const { isLocalProcessActions = false } = ess;
    const { dispatch, state: { manageVersions: { isRETTSProcessActionType } } } = useStore();
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setProcessActionTypeIsRETTS = useCallback(payload => dispatch({ type: SET_PROCESS_ACTION_TYPE, payload }), [dispatch]);

    useEffect(() => {
        if (isLocalProcessActions) {
            setProcessActionTypeIsRETTS(false);
        } else {
            setProcessActionTypeIsRETTS(true);
        }
    }, [ess]);

    return (
        <>
            <div className="card loadable">
                <div className="essCardTitle">
                    <Title value={t('PROCESS_ACTIONS')} size={SIZE.L} className="essTitle" />
                </div>
                <LocalSpecificProcessActions
                    isLocalProcessActions
                    ess={ess}
                    setEss={setEss}
                    clearMessage={clearMessage}
                    setMessage={setMessage}
                    openPopUp={openPopUp}
                    defaultProcessActions={defaultProcessActions}
                    isRETTSProcessActionType={isRETTSProcessActionType}
                    setProcessActionTypeIsRETTS={setProcessActionTypeIsRETTS}/>
            </div>
            <div className="card loadable generalProcessActionCard">
                <LocalGeneralProcessActions
                    ess={ess}
                    setEss={setEss}
                    clearMessage={clearMessage}
                    setMessage={setMessage}
                    openPopUp={openPopUp}
                    isRETTSProcessActionType={isRETTSProcessActionType} />
            </div>
        </>
    );
};

export default withTranslation()(LocalProcessActions);
