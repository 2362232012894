import isEmpty from 'lodash/isEmpty';
import LogRocket from 'logrocket';
import i18n from '../i18n';

import {
    EMPTY_STRING, LOCAL_ACCESS_TOKEN, USER, API_HEADER_JWT, ERROR, LOCAL_USER_TYPE,
    LOCAL_LANGUAGE_CODE, ERROR_RESPONSE_MESSAGE_PREFIX, UNAUTHORIZED, WARNING, SUCCESS, ISE, LOCAL_SELECTED_PRODUCT,
    NOT_FOUND, PREDICARE, PREDICARE_ACCOUNT_TYPE_ID, CARE_UNIT_ACCOUNT_TYPE_ID,
    LOCAL_ACCOUNT_USERS,
    LOCAL_ESS_EDIT,
    LOCAL_VP_EDIT
} from '../constant/text';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_USER_ROLE_ID, DEPARTMENT_USER_ROLE_ID, LOGIN_PAGE, ROLES } from '../constant/route';
import { setLocal, getFromLocal, removeFromLocal, parseJwt } from './localStorageUtil';
import { debugLog } from '../util';

export const isLoggedIn = () => isJwtValid();

export const hasSessionExpired = () => !isEmpty(getFromLocal(LOCAL_ACCESS_TOKEN)) && !isJwtValid(); // Checks if the user is a returning user (LOCAL_ACCESS_TOKEN will exists) and if the token is invalid, the session for the token will be expired.

const isJwtValid = () => {
    const { exp } = parseJwt();
    const now = new Date().getTime() / 1000;
    return exp - now >= 0;
};

export const redirectToLogin = () => {
    if (hasSessionExpired()) {
        setSessionHasExpiredMessage();
    }

    window.location.href = window.location.origin + LOGIN_PAGE;
};

export const setSessionHasExpiredMessage = () => {
    localStorage.setItem('wasRedirectedToLogin', true);
};

export const checkJwtValidation = () => {
    if (!isJwtValid()) {
        debugLog('JWT invalid, redirect to Login page');
        redirectToLogin();
    }
};

export const logout = () => {
    initializeThirdPartyAppsWithoutUserInfo();
    removeFromLocal([
        LOCAL_ACCESS_TOKEN,
        LOCAL_ACCOUNT_USERS,
        LOCAL_SELECTED_PRODUCT,
        LOCAL_USER_TYPE,
        LOCAL_ESS_EDIT,
        LOCAL_VP_EDIT,
    ]);
};

const setSpecificUserDetails = jwt => {
    setLocal(LOCAL_ACCESS_TOKEN, jwt);
    setLocal(LOCAL_USER_TYPE, USER, true);
};

export const initializeThirdPartyApps = accountUser => {
    try {
        debugLog('initializing accountUser', accountUser);
        if (process.env.REACT_APP_USE_LOGROCKET === 'true') {
            LogRocket.init('qvjsv3/rettsonline');
            LogRocket.identify(accountUser.accountUserId, {
                name: accountUser.name,
                email: accountUser.username,
                roleName: accountUser.roleName,
                userId: accountUser.userId,
            });
        }

        const intercomOptions = {
            app_id: process.env.REACT_APP_INTERCOM_ID,
            company: {
                company_id: accountUser.accountId,
                name: accountUser.accountName
            },
            source_site: window.location.href,
            company_id: accountUser.accountId,
            company_name: accountUser.accountName,
            role_name: accountUser.roleName
        };

        if (accountUser.roleId !== DEPARTMENT_USER_ROLE_ID) {
            intercomOptions.name = accountUser.name;
            intercomOptions.email = accountUser.username;
        }
        debugLog('IntercomOptions:', intercomOptions);
        window.Intercom('shutdown');
        window.Intercom('boot', intercomOptions);
    } catch (e) {
        console.log('Failed to initialize third-party apps:', e);
    }
};

export const initializeThirdPartyAppsWithoutUserInfo = () => {
    try {
        if (process.env.REACT_APP_USE_LOGROCKET === 'true') {
            LogRocket.init('qvjsv3/rettsonline');
            LogRocket.identify();
        }

        const intercomOptions = {
            app_id: process.env.REACT_APP_INTERCOM_ID
        };
        window.Intercom('shutdown');
        window.Intercom('boot', intercomOptions);
    } catch (e) {
        console.log('Failed to initialize third-party apps:', e);
    }
};

export const configureAppWithUserInfo = accountUser => {
    debugLog('configureAppWithUserInfo: ', accountUser);
    const { jwt, languageCode, accountusers } = accountUser;
    setLocal(LOCAL_ACCOUNT_USERS, accountusers, true);
    setLocal(LOCAL_LANGUAGE_CODE, languageCode);
    // delaying language change due to a console error when Store.Provider is rendering at the same time state is updating
    setTimeout(() => {
        i18n.changeLanguage(languageCode);
    }, 50);
    setSpecificUserDetails(jwt);

    initializeThirdPartyApps(accountUser);
};

export const getUser = () => {
    const { userDetail = {} } = parseJwt();

    return userDetail;
};

export const getUserId = () => {
    const { userId } = parseJwt();

    return userId;
};

export const getAccountUserId = () => {
    const { userDetail = {} } = parseJwt();
    const { accountUserId } = userDetail;

    return accountUserId;
};

export const getUserEmail = () => {
    const jwtDecode = parseJwt();
    if (Object.keys(jwtDecode).length === 0) {
        return null;
    }
    const { userDetail: { username: email } } = jwtDecode;

    return email;
};

export const getUserName = () => {
    const jwtDecode = parseJwt();
    if (Object.keys(jwtDecode).length === 0) {
        return null;
    }
    const { userDetail: { name: userName } } = jwtDecode;

    return userName;
};

export const getRoleName = () => {
    const jwtDecode = parseJwt();
    if (Object.keys(jwtDecode).length === 0) {
        return null;
    }
    const { userDetail: { roleName } } = jwtDecode;

    return roleName;
};

export const getRoleId = () => {
    const jwt = parseJwt();
    if (!jwt.roleId || !ROLES[jwt.roleId]) {
        return DEFAULT_USER_ROLE_ID;
    }

    return jwt.roleId;
};

export const getAccountName = () => {
    const jwtDecode = parseJwt();
    if (Object.keys(jwtDecode).length === 0) {
        return null;
    }
    const { userDetail: { accountName } } = jwtDecode;

    return accountName;
};

export const getUserSettings = () => {
    const { userDetail } = parseJwt();
    const { appParams = {} } = userDetail;
    const { settings = {} } = appParams;

    return settings;
};

export const canExportXml = () => {
    const { canexportxml } = getUserSettings();

    return (canexportxml === 'true' || canexportxml === true);
};

export const canDownloadPdf = () => {
    const { canDownloadPdf } = getUserSettings();

    return (canDownloadPdf === 'true' || canDownloadPdf === true);
};

export const canPublish = () => {
    const { canpublish = false } = getUserSettings();

    return (canpublish === 'true' || canpublish === true) ? true : false;
};

export const getStatusCode = message => {
    if (message && !isEmpty(message) && message.indexOf(ERROR_RESPONSE_MESSAGE_PREFIX) !== -1) {
        return message.split(ERROR_RESPONSE_MESSAGE_PREFIX)[1].trim();
    }

    return EMPTY_STRING;
};

export const apiError = (error, isFileType = false, customMessage = EMPTY_STRING) => {
    if (error.response && error.response.data) {
        return isFileType ? { error: errorMessage([customMessage]) } : { error: errorMessage([error.response.data]) };
    }
    const { message = EMPTY_STRING } = error;
    const statusCode = getStatusCode(message);
    switch (statusCode) {
        case UNAUTHORIZED:
            logout();

            return ({ error: errorMessage(['UNAUTHORIZED_USER']), statusCode });

        case ISE:
            return ({ error: errorMessage(['BAD_REQUEST']), statusCode });

        case EMPTY_STRING:
            return ({ error: errorMessage(['NETWORK_ERROR']), statusCode: NOT_FOUND });

        default:
            return ({ error: errorMessage(['GENERIC_ERROR']), statusCode });
    }
};

export const errorMessage = (message = [EMPTY_STRING]) => ({
        show: true,
        value: message,
        status: ERROR
    });

export const warningMessage = (message = [EMPTY_STRING]) => ({
        show: true,
        value: message,
        status: WARNING
    });

export const successMessage = (message = [EMPTY_STRING]) => ({
        show: true,
        value: message,
        status: SUCCESS
    });

export const clearMessage = () => ({
        show: false,
        value: EMPTY_STRING,
        status: WARNING
    });

export const getApiBaseUrl = () => process.env.REACT_APP_API_BASEURL;

export const getUiBaseUrl = () => {
    const uiUrl = process.env.REACT_APP_UI_URL;

    return uiUrl;
};

export const getHeaders = () => {
    const header = {
        'Cache-control': 'no-store',
        Pragma: 'no-cache'
    };

    if (isLoggedIn()) {
        header[API_HEADER_JWT] = getFromLocal(LOCAL_ACCESS_TOKEN);
    }

    return header;
};

export const getFileHeaders = () => {
    const header = getHeaders();
    header['content-type'] = 'multipart/form-data';

    return header;
};

export const isPredicareRegionExists = (regions = []) => {
    if (!isEmpty(regions)) {
        return regions.filter(region => region.name === PREDICARE).length > 0;
    }

    return false;
};

export const getFilteredAccountTypes = (predRegionExists, accountTypes, currentAccountTypeId) => {
    let filteredAccountTypes = [];
    if (isEmpty(accountTypes)) {
        return filteredAccountTypes;
    }
    filteredAccountTypes = accountTypes.filter(accountType => {
        if (accountType.id <= currentAccountTypeId) {
            return false;
        }
        if (predRegionExists && accountType.id === PREDICARE_ACCOUNT_TYPE_ID) {
            return false;
        }

        return true;
    });

    if (isEmpty(filteredAccountTypes) && !isEmpty(accountTypes)) {
        filteredAccountTypes = accountTypes.filter(accountType => accountType.id === CARE_UNIT_ACCOUNT_TYPE_ID);
    }

    return filteredAccountTypes;
};

export const download = (byteArray, fileName) => {
    const blob = new Blob([byteArray], { type: '*/*' });
    blob.name = fileName;
    blob.lastModified = Date.now();

    const userIsDownloadingWithIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);
    if (userIsDownloadingWithIE11) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);

        return;
    }

    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    link.click();

    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 1000);
};

export const getUnreadMessagesCount = notifications => {
    notifications = notifications.filter(notification => {
        const msgRead = notification.read;

        return !msgRead;
    });

    return notifications.length;
};

export const getDataInTreeStructure = (data, accountId) => {
    data.map(obj => {
        if (obj.id === accountId) {
            obj.isRoot = true;
        }
        obj.children = getChildren(data, obj.id);

        return obj;
    });

    const treeData = {};
    data.forEach(obj => {
        treeData[obj.id] = obj;
    });

    return treeData;
};

const getChildren = (data, id) => {
    const children = [];
    data.forEach(obj => {
        if (obj.parentId === id) {
            children.push(obj.id);
        }
    });

    return children;
};
