import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import List from './list';
import Search from './search/search';
import Login from './login';
import Register from './login/register';
import ManageUsers from './manageusers';
import { LIST_PAGE, SEARCH_PAGE, MANAGE_USERS_PAGE, MANAGE_VERSIONS_PAGE, MANAGE_VERSIONS_VPEDIT_PAGE,
    REGISTER_PAGE, IMPORT_PAGE, EXPORT_PAGE, MANAGE_VERSIONS_ESSEDIT_PAGE, PUBLISH_ESS, LOGIN_PAGE,
} from '../../constant/route';
import ManageVersions from './manageversions';
import VpEdit from './manageversions/edit/vp';
import EssEdit from './manageversions/edit/ess';
import EssPublish from './manageversions/esspublish';
import Import from './import';
import Export from './export';
import Message from '../common/message';
import PopUp from '../common/popUp';
import PrivateRoute from './privateRoute';
import SetLanguageAndRedirect from '../common/SetLanguageAndRedirect';
import AdminRoute from './AdminRoute';
import NoSuchPage from './NoSuchPage';
import { initializeThirdPartyApps, isLoggedIn } from '../../api/util';
import { useLoginManager } from '../../UseLoginManager';
import { debugLog } from '../../util';
import AutoLoginPage from './AutoLoginPage';
import { getAssignedProduct } from '../util';
import { useStore } from '../../store';
import { getDefaultProductsForUser } from '../../api/ess';
import { SET_PRODUCT } from '../../constant/action';

const Routes = () => {
    const { user, refreshExistingLogin, getTokenTimeLeft, logout } = useLoginManager();
    const location = useLocation();
    const { dispatch } = useStore();
    const setProduct = useCallback(payload => dispatch({ type: SET_PRODUCT, payload }), [dispatch]);

    const init = () => {
        debugLog('Router Init');
        if (isLoggedIn()) { // We have a token from earlier which is valid.
            const tokenExpiresIn = getTokenTimeLeft();
            debugLog('isLoggedIn and token expires in: ', tokenExpiresIn, ' seconds.');
            if (tokenExpiresIn < 6 * 60 * 60) { // less than 6 hours
                refreshExistingLogin();
            } else {
                initializeThirdPartyApps(user);
            }
        } else {
            debugLog('Router: No user was logged in, logging out.');
            logout();
        }
    };

    useEffect(() => {
        const getProduct = async () => {
            if (isLoggedIn()) {
                const product = getAssignedProduct();
                if (isEmpty(product)) {
                    const [product] = await getDefaultProductsForUser();
                    setProduct(product ?? { noProduct: true });
                }
            }
        };
        init();
        getProduct();
    }, [location]);

    return (
        <div className="routes">
            <Message/>
            <PopUp/>
            <Switch>
                <Route path={LOGIN_PAGE} component={Login}/>
                <Route path={REGISTER_PAGE} component={Register}/>
                <Route path={'/en'} component={() => <SetLanguageAndRedirect path={'/'} language={'en'}/>}/>
                <Route path={'/sv'} component={() => <SetLanguageAndRedirect path={'/'} language={'sv'}/>}/>
                <Route path={'/no'} component={() => <SetLanguageAndRedirect path={'/'} language={'no'}/>}/>
                <Route path={'/de'} component={() => <SetLanguageAndRedirect path={'/'} language={'de'}/>}/>
                <Route path={'/nl'} component={() => <SetLanguageAndRedirect path={'/'} language={'nl'}/>}/>
                <Route path={'/zh'} component={() => <SetLanguageAndRedirect path={'/'} language={'zh'}/>}/>
                <PrivateRoute exact path={LIST_PAGE} component={List}/>
                <PrivateRoute exact path={SEARCH_PAGE} component={Search}/>
                <AdminRoute exact path={MANAGE_USERS_PAGE} component={ManageUsers}/>
                <AdminRoute exact path={MANAGE_VERSIONS_PAGE} component={ManageVersions}/>
                <AdminRoute exact path={MANAGE_VERSIONS_ESSEDIT_PAGE} component={EssEdit}/>
                <AdminRoute exact path={MANAGE_VERSIONS_VPEDIT_PAGE} component={VpEdit}/>
                <AdminRoute exact path={IMPORT_PAGE} component={Import}/>
                <AdminRoute exact path={EXPORT_PAGE} component={Export}/>
                <AdminRoute exact path={PUBLISH_ESS} component={EssPublish}/>
                <Route exact path={'/'} component={AutoLoginPage}/>
                <Route path={'*'} component={NoSuchPage}/>
            </Switch>
        </div>
    );
};

export default Routes;
