import React from 'react';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { withTranslation } from '../../common/translation';
import { CloseIcon, EditIcon } from '../../common/icon';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../common/table';
import { Title } from '../../common/text';
import { SIZE, EMPTY_STRING } from '../../../constant/text';
import { WithAnnotations } from '../../common/withAnnotations';
import InfoBox from '../../common/infoBox';

const VpCard = props => {
    const { t, data, onClose, editable = false, onEdit = () => { } } = props;
    const vpOrdered = orderBy(data.vpList, 'vpCategory');
    const vp = groupBy(vpOrdered, 'vpCategory');

    const addEmptyRiskLevels = riskLevels => {
        for (let i = 1; i <= 4; i += 1) {
            riskLevels[i] = riskLevels[i] || [{ noValue: true }];
        }

        return riskLevels;
    };

    const isChildVP = vpType => vpType < 8;

    const isInfantVP = vpType => vpType === 1;

    return (
        <div className="essCard vpCard scrollable">
            <div className="essCardTitle">
                <Title value={t(data.vptypeName)} size={SIZE.M} className="essTitle" />
                {editable && <EditIcon className="editIcon-card" toolTip={t('EDIT_VP')} onClick={onEdit} />}
                <CloseIcon className="closeIcon-card" onClick={() => onClose(data.id)} />
            </div>
            <div className={'vpBlock'}>
                {
                    isChildVP(data.vptypes) && (
                        <InfoBox title={t('OTHER_INFO')}>
                            {
                                isInfantVP(data.vptypes) && (
                                    <WithAnnotations>{data.texts.VP_INFANT_INFO_1}</WithAnnotations>
                                )
                            }
                            {
                                (
                                    <div>
                                        <br />
                                        <WithAnnotations>{data.texts.PULSE_CORRECTION}</WithAnnotations>
                                        <ul>
                                            <li><WithAnnotations>{data.texts.VP_CHILD_TEXT_1}</WithAnnotations></li>
                                            <li><WithAnnotations>{data.texts.VP_CHILD_TEXT_2}</WithAnnotations></li>
                                        </ul>
                                    </div>
                                )
                            }
                        </InfoBox>
                    )
                }
            </div>
            <div className={'vpBlock'}>
                <Table className={'striped-vertically'}>
                    <Thead>
                        <Tr>
                            <Th>{t('VITAL_PARAMETERS_SHORT')}</Th>
                            <Th className="riskLevel-bg-1">{t('RED')}</Th>
                            <Th className="riskLevel-bg-2">{t('ORANGE')}</Th>
                            <Th className="riskLevel-bg-3">{t('YELLOW')}</Th>
                            <Th className="riskLevel-bg-4">{t('GREEN')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            Object.keys(vp).map(category => {
                                let riskLevels = groupBy(vp[category], 'essRisklevel');

                                riskLevels = addEmptyRiskLevels(riskLevels);

                                return (
                                    <Tr key={category}>
                                        <Td>{category}</Td>
                                        {
                                            Object.keys(riskLevels).map(riskLevel => (
                                                <Td key={`${category}-${riskLevel}`}>
                                                    {
                                                            riskLevels[riskLevel].map(obj => {
                                                                const { description = EMPTY_STRING, measurelimit, interval, noValue } = obj;

                                                                let operator;
                                                                let value;
                                                                let limitUnit;
                                                                let low;
                                                                let high;
                                                                let intervalUnit;

                                                                if (measurelimit) {
                                                                    operator = typeof measurelimit.operator === 'undefined' ? EMPTY_STRING : measurelimit.operator;
                                                                    value = typeof measurelimit.value === 'undefined' ? EMPTY_STRING : measurelimit.value;
                                                                    if (typeof measurelimit.unit === 'undefined' || measurelimit.unit === null) {
                                                                        limitUnit = EMPTY_STRING;
                                                                    } else {
                                                                        limitUnit = measurelimit.unit;
                                                                    }
                                                                }

                                                                if (interval) {
                                                                    low = typeof interval.low === 'undefined' ? EMPTY_STRING : interval.low;
                                                                    high = typeof interval.high === 'undefined' ? EMPTY_STRING : interval.high;
                                                                    if (typeof interval.unit === 'undefined' || interval.unit === null) {
                                                                        intervalUnit = EMPTY_STRING;
                                                                    } else {
                                                                        intervalUnit = interval.unit;
                                                                    }
                                                                }

                                                                return (
                                                                    <div key={`${description}-${operator}-${low}`}>
                                                                        {
                                                                            noValue && (
                                                                                <>-</>
                                                                            )
                                                                        }
                                                                        {
                                                                            !measurelimit && !interval && description
                                                                        }
                                                                        {
                                                                            measurelimit && (
                                                                                <div className="vp-description">
                                                                                    {`${description} ${operator} ${value} ${limitUnit}`}
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            interval && (
                                                                                <div className="vp-description">
                                                                                    {`${description} ${low}-${high} ${intervalUnit}`}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                </Td>
                                                ))
                                        }
                                    </Tr>
                                );
                            })
                        }
                    </Tbody>
                </Table>
            </div>
        </div>
    );
};

export default withTranslation()(VpCard);
