import React, { useState, useEffect } from 'react';
import { defer } from 'rxjs';
import values from 'lodash/values';
import TreeNode from './treeNode';
import { TREE_NODE_TYPE_NORMAL } from '../../../constant/text';

const Tree = props => {
    const { nodes = {}, onNodeSelect, type = TREE_NODE_TYPE_NORMAL, setSelectedYear } = props;
    const [treeNodes, setTreeNodes] = useState(nodes);

    useEffect(() => {
        const subscription = defer(() => setTreeNodes(nodes)).subscribe();

        return () => subscription.unsubscribe();
    }, [nodes]);

    const getChildNodes = node => {
        if (!node.children) {
            return [];
        }

        return node.children.map(id => treeNodes[id]);
    };

    const onToggle = (node, open) => {
        treeNodes[node.id].isOpen = open;
        setTreeNodes({ ...treeNodes });
    };

    return (
        <div className="tree">
            {
                values(treeNodes).filter(node => node.isRoot === true).map((node, i) => (
                    <TreeNode
                        key={i}
                        node={node}
                        getChildNodes={getChildNodes}
                        onToggle={onToggle}
                        onNodeSelect={onNodeSelect}
                        type={type}
                        setSelectedYear={setSelectedYear} />
                ))
            }
        </div>
    );
};

export default Tree;
