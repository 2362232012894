// eslint-disable-next-line import/no-cycle
import {
    ListIcon,
    SearchIcon,
    ManageVersionIcon,
    UsersIcon,
    ExportIcon,
    TranslateIcon,
    EditIcon
} from '../component/common/icon';

export const ROOT_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const REGISTER_PAGE = '/register';
export const LIST_PAGE = '/list';
export const SEARCH_PAGE = '/search';
export const ADMIN_PAGES_PREFIX = '/admin';
export const MANAGE_VERSIONS_PAGE = '/admin/manageversions';
export const MANAGE_VERSIONS_ESSEDIT_PAGE = '/admin/manageversions/edit/ess';
export const MANAGE_VERSIONS_VPEDIT_PAGE = '/admin/manageversions/edit/vp';
export const PUBLISH_ESS = '/admin/manageversions/esspublish';
export const IMPORT_PAGE = '/admin/import';
export const EXPORT_PAGE = '/admin/export';
export const MANAGE_USERS_PAGE = '/admin/manageusers';
export const TRANSLATE_PAGE = '/translate';

export const MANAGE_VERSION_SUB_MENU_LIST = [
    { toolTip: 'HANDLE_EXISTING_VERSIONS_TEXT', icon: 'PUBLISHED' },
    { toolTip: 'HANDLE_NEW_VERSION_TEXT', icon: 'HANDLE_NEW_VERSION' },
    { toolTip: 'VERSION_AUDIT_INFO', icon: 'GET_HISTORY' },
];

const MANAGE_VERSIONS_PAGES = [MANAGE_VERSIONS_PAGE, MANAGE_VERSIONS_ESSEDIT_PAGE, MANAGE_VERSIONS_VPEDIT_PAGE, PUBLISH_ESS];

export const NORMAL_USER_MENU_LIST = [
    { toolTip: 'SEARCH_FOR_ESS', paths: [SEARCH_PAGE], icon: SearchIcon },
    { toolTip: 'LIST_ESS', paths: [LIST_PAGE], icon: ListIcon }
];

export const LOCAL_ADMIN_MENU_LIST = [
    { toolTip: 'HANDLE_VERSIONS', paths: MANAGE_VERSIONS_PAGES, icon: ManageVersionIcon },
    { toolTip: 'HANDLE_USERS', paths: [MANAGE_USERS_PAGE], icon: UsersIcon },
    { toolTip: 'EXPORT', paths: [EXPORT_PAGE], icon: ExportIcon }
];

export const TRANSLATOR_MENU_LIST = [
    { toolTip: 'TRANSLATE', paths: [TRANSLATE_PAGE], icon: TranslateIcon }
];

export const CENTRAL_ADMIN_MENU_LIST = [
    { toolTip: 'HANDLE_USERS', paths: [MANAGE_USERS_PAGE], icon: UsersIcon }
];

export const DEFAULT_USER_ROLE_ID = 40;
export const DEPARTMENT_USER_ROLE_ID = 35;

export const PREDICAREUSER_PREDICAREREGION_ROLES = [10, 15];
export const PREDICAREUSER_OTHERREGION_ROLES = [20];
export const PREDICAREUSER_OTHERORGANIZATION_ROLES = [20];
export const PREDICAREUSER_OTHERDEPARTMENT_ROLES = [20, 30, DEPARTMENT_USER_ROLE_ID, DEFAULT_USER_ROLE_ID];
export const LOCALADMINUSER_ORGANIZATION_ROLES = [20, 30, DEFAULT_USER_ROLE_ID];
export const LOCALADMINUSER_DEPARTMENT_ROLES = [20, 30, DEPARTMENT_USER_ROLE_ID, DEFAULT_USER_ROLE_ID];

export const ROLES = {
    10: {
        translationCode: 'PREDICARE',
        adminView: false
    },
    15: {
        translationCode: 'TRANSLATOR',
        homePage: TRANSLATE_PAGE,
        menu: TRANSLATOR_MENU_LIST,
        adminView: false
    },
    20: {
        translationCode: 'CENTRAL_ADMINISTRATOR',
        homePage: MANAGE_USERS_PAGE,
        menu: CENTRAL_ADMIN_MENU_LIST,
        adminView: true
    },
    30: {
        translationCode: 'LOCAL_ADMINISTRATOR',
        homePage: MANAGE_VERSIONS_PAGE,
        menu: LOCAL_ADMIN_MENU_LIST,
        adminView: true
    },
    35: {
        translationCode: 'DEPARTMENT_USER',
        homePage: SEARCH_PAGE,
        menu: NORMAL_USER_MENU_LIST,
        adminView: false
    },
    40: {
        translationCode: 'USER',
        homePage: SEARCH_PAGE,
        menu: NORMAL_USER_MENU_LIST,
        adminView: false
    }
};
