import React, { useEffect, useCallback, useState } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { withTranslation } from '../../../../common/translation';
import { Title } from '../../../../common/text';
import { LOCAL_ESS_EDIT, REVISE, EDIT, EMPTY_STRING, SIZE, ERROR } from '../../../../../constant/text';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../../../constant/action';
import { MANAGE_VERSIONS_PAGE } from '../../../../../constant/route';
import {
    getAdjacentEssNumberIdsByPackageAndEssNumberId,
    getEssSearchById,
    getEssNumberDetailByPackageAndNumber,
    getFirstEssNumberDetail,
    getDefaultProcessActions,
} from '../../../../../api/ess';
import Loader from '../../../../common/loader';
import PrevNextButtons from '../prevNextButtons';
import EssLocal from './essLocal';
import InfoBox from '../../../../common/infoBox';
import { useStore } from '../../../../../store';
import { getFromLocal } from '../../../../../api/localStorageUtil';
import { PRIMARY, DEFAULT } from '../../../../../constant/color';
import { LeftArrowIcon } from '../../../../common/icon';
import SearchEss from './searchEss';
import { useMount } from '../../../../common/useMount';
import { warningMessage } from '../../../../../api/util';
import TextField from '../../../../common/textField';
import Line from '../../../../common/line';
import EssCard from '../../../search/Ess/EssCard';
import Button from '../../../../common/button';

const EssEdit = props => {
    const { t } = props;
    const mounted = useMount();
    const { dispatch, state: { manageVersions: { essEdit = {} } } } = useStore();
    const { editType = EDIT, essPackageId, essNumber = 0 } = getFromLocal(LOCAL_ESS_EDIT, true);
    const [jumpToNumber, setJumpToNumber] = useState();
    const [loader, setLoader] = useState(false);
    const [adjacentDetail, setAdjacentDetail] = useState({ previousId: null, nextId: null });
    const { previousId, nextId, currentDetail = {} } = adjacentDetail;
    const {
        packageId,
        packageType = EMPTY_STRING,
        packageVersion = EMPTY_STRING,
        packageLanguage = EMPTY_STRING,
    } = currentDetail;
    const [defaultProcessActions, setDefaultProcessActions] = useState(null);

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const fetchDetail = () => {
            if (essPackageId) {
                if (essNumber > 0) {
                    getEssNumberDetail(essPackageId, essNumber, null);
                } else {
                    fetchFirstEssNumberDetail(essPackageId);
                }
            }
        };

        const subscription = defer(() => fetchDetail()).subscribe();

        return () => subscription.unsubscribe();
    }, [essEdit]);

    useEffect(() => {
        if (currentDetail.id) {
            try {
                fetchDefaultProcessActions(currentDetail.id).then(processActionsResult => {
                    setDefaultProcessActions(processActionsResult);
                });
            } catch (e) {
                throw new Error('error in ess/search.tsx when trying to fetch default process actions', e.message);
            }
        }
    }, [currentDetail]);

    const fetchDefaultProcessActions = async essHeaderId => getDefaultProcessActions(essHeaderId);

    const getAdjacentDetail = async (packageId, essId, essDetail) => {
        if (packageId && essId) {
            setLoader(true);
            const adjacentEssNumIds = await getAdjacentEssNumberIdsByPackageAndEssNumberId(packageId, essId);
            if (mounted.current) {
                const { previousEssId: previousId, nextEssId: nextId } = adjacentEssNumIds;
                setAdjacentDetail({ ...adjacentDetail, previousId, nextId, currentDetail: essDetail });
                setLoader(false);
            }
        }
    };

    const getEssNumberDetail = async (packageId, essNumber, essId) => {
        if (essId || essNumber) {
            setLoader(true);
            clearMessage();
            let essNumberDetail;
            if (essId) {
                essNumberDetail = await getEssSearchById(essId);
            } else {
                essNumberDetail = await getEssNumberDetailByPackageAndNumber(packageId, essNumber);
            }
            if (mounted.current) {
                setLoader(false);
                if (essNumberDetail.error) {
                    setMessage(essNumberDetail.error);
                } else {
                    const { id: essNumberId } = essNumberDetail;
                    if (essNumberId) {
                        getAdjacentDetail(packageId, essNumberId, essNumberDetail);
                    } else {
                        setMessage(warningMessage(['ESS_MISSING']));
                    }
                }
            }
        }
    };

    const fetchFirstEssNumberDetail = async packId => {
        setLoader(true);
        clearMessage();
        const essNumberDetail = await getFirstEssNumberDetail(packId);
        if (mounted.current) {
            setLoader(false);
            if (essNumberDetail.error) {
                setMessage(essNumberDetail.error);
            } else {
                const { id: essNumberId, packageId } = essNumberDetail;
                getAdjacentDetail(packageId, essNumberId, essNumberDetail);
            }
        }
    };

    const setEss = (packageId, essId) => {
        getEssNumberDetail(packageId, null, essId);
    };

    const onEnterShowESS = e => {
        if (e.key === 'Enter') {
            getEssNumberDetail(packageId, jumpToNumber, null);
            setJumpToNumber(null);
        }
    };

    const onChangeSetJumpToNumber = number => {
        setJumpToNumber(number);
    };

    const onClickShowESS = () => {
        getEssNumberDetail(packageId, jumpToNumber, null);
        setJumpToNumber(null);
    };

    return (
        <div className="editContainer">
            <Link to={MANAGE_VERSIONS_PAGE} className={'button btn-primary'}>
                <LeftArrowIcon className="prevPageClickIcon"/>
            </Link>
            {isEmpty(currentDetail) && <div>{t('LOADING')}</div>}
            {
                !isEmpty(currentDetail) && (
                    <div className="editor">
                        <div className="editHeader">
                            <Title value={editType === REVISE ? t('REVISE_ESS') : t('EDIT_ESS')} size={SIZE.L} />
                            <Title value={`${t('VERSION')}: ${packageType}${packageVersion ? ` ${packageVersion}` : EMPTY_STRING}${packageLanguage ? `, ${packageLanguage}` : EMPTY_STRING}`} size={SIZE.M} />
                            <Title value={t('COMPARE_INFO')} size={SIZE.S} />
                            {editType === REVISE && (
                                <InfoBox type={ERROR}>{t('REVISION_WARNING_TEXT')}</InfoBox>
                            )}
                            <div className="prevNextButtonContainer">
                                <TextField
                                    type="text"
                                    placeholder={'JUMP_TO_NUMBER'}
                                    onChange={onChangeSetJumpToNumber}
                                    onKeyPress={e => onEnterShowESS(e)}
                                    value={jumpToNumber}
                                />
                                <Button
                                    value={t('VIEW')}
                                    onClick={onClickShowESS}
                                    btnType={PRIMARY}
                                />
                            </div>
                            <PrevNextButtons btnType={DEFAULT} previousId={previousId} packageId={packageId} nextId={nextId} getDetailById={getEssNumberDetail} />
                        </div>
                        <div id="editSection" className="editSection loadable">
                            {loader && <Loader />}
                            <div className={'card preview ie11-hack'}>
                                <EssCard
                                    data={currentDetail}
                                    closable={false}
                                />
                            </div>
                            <EssLocal
                                ess={currentDetail}
                                setEss={setEss}
                                defaultProcessActions={defaultProcessActions}/>
                        </div>
                    </div>
                )
            }
            <Line />
            <Title value={`${t('COMPARE_WITH_OTHER_VERSIONS')}:`} size={SIZE.L} />
            <SearchEss />
        </div>
    );
};

export default withTranslation()(EssEdit);
