import React, { useState, useCallback } from 'react';
import { Title } from '../../common/text';
import { withTranslation } from '../../common/translation';
import TextField from '../../common/textField';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import { errorMessage, successMessage } from '../../../api/util';
import { useStore } from '../../../store';
import { sendOtc } from '../../../api/user';
import { SET_MESSAGE, CLOSE_POP_UP, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../constant/action';
import { EMPTY_STRING } from '../../../constant/text';
import PasswordChanger from '../../header/passwordChanger';
import Loader from '../../common/loader';
import PopUpCloseIcon from '../../common/popUpCloseIcon';
import { notValidEmail } from '../../util';
import { useMount } from '../../common/useMount';

const PasswordResetter = props => {
    const { t } = props;
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState();
    const [loader, setLoader] = useState(false);
    const mounted = useMount();
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    const onEmailChange = val => {
        setEmail(val);
    };

    const isValid = () => {
        setEmailError(EMPTY_STRING);
        const mess = notValidEmail(email);
        if (mess) {
            setEmailError(t(mess));

            return false;
        }

        return true;
    };

    const onResetPassword = () => {
        if (isValid()) {
            setLoader(true);
            clearMessage();
            sendOtc(email).then(response => {
                if (mounted.current) {
                    setLoader(false);
                    if (response.error) {
                        setMessage(response.error);
                    } else if (response) {
                        setMessage(successMessage(['EMAIL_HAS_BEEN_SENT']));
                        closePopUp();
                        openPopUp({ component: <PasswordChanger otc={true} email={email} /> });
                    } else {
                        setMessage(errorMessage(['ACCOUNT_DOES_NOT_EXIST']));
                    }
                }
            });
        }
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader} />
            <div className="popUpBody">
                <Title value={t('RESET_PASSWORD')} />
                <TextField
                    placeholder={'EMAIL_ADDRESS'}
                    value={email}
                    onChange={onEmailChange}
                    label={'EMAIL_ADDRESS'}
                    required={true}
                    errorMessage={emailError}
                />
                {loader && <Loader />}
                <div className="buttonField-center">
                    <Button
                        value={t('RESET_PASSWORD')}
                        onClick={onResetPassword}
                        btnType={PRIMARY}
                        disabled={loader}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(PasswordResetter);
