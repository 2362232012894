import React, { useState, useEffect } from 'react';
import remove from 'lodash/remove';
import { CloseIcon, AddIcon } from '../../../../common/icon';
import { withTranslation } from '../../../../common/translation';
import TextField from '../../../../common/textField';
import Dropdown from '../../../../common/dropdown';
import { getCategoryTypes } from '../../../../../api/app';
import Button from '../../../../common/button';
import Checkbox from '../../../../common/checkbox';
import { addNewPackageType } from '../../../../../api/ess';

const AddNewProduct = props => {
    const { t, handleCreateVersionPopup } = props;
    const [data, setData] = useState({ checked: false, productName: '', category: [], selectedCategory: [], currentSelected: '' });

    const handleData = (key, val) => {
        setData({ ...data, [key]: val });
    };
    const fetchData = async () => {
        const category = await getCategoryTypes();
        setData({ ...data,
            currentSelected: category[0].id,
            category: category.map(item => ({ label: item.type, value: item.id })) });
      };

    useEffect(() => {
          fetchData();
    }, []);

    const handleCategoryData = () => {
        const cat = data.category;
        const currentSelected = data.currentSelected ? data.currentSelected : data.category[0] && data.category[0].value;
        const selectedCategory = remove(data.category, item => item.value === Number(currentSelected));
        setData({ ...data, currentSelected: data.category[0] && data.category[0].value, category: cat, selectedCategory: [...data.selectedCategory, selectedCategory[0]] });
    };

    const removeSelectedCategory = item => {
        remove(data.selectedCategory, cat => cat === item);
        setData({ ...data, selectedCategory: data.selectedCategory, category: [...data.category, item] });
    };

    const setChecked = e => {
        setData({ ...data, checked: e, selectedCategory: [], category: [...data.category, ...data.selectedCategory] });
    };

    const addProductType = async() => {
        await addNewPackageType({ categoryTypes: data.selectedCategory.map(item => ({ id: item.value, type: item.label })), type: data.productName });
        handleCreateVersionPopup();
    };

    return (
        <div className="popUpComponent">
            <CloseIcon className="popUpCloseIcon" onClick={handleCreateVersionPopup}/>
            <div className="title">AddNewProduct</div>
            <div className="popUpBody">
                {t('SPECIFY_TYPE')}
                <div className="horizontalDivider"/>
                <div className="grid">
                    <div className="col_2">{t('ADD_TYPE')}</div>
                    <div className="col_2">
                        <TextField value={data.productName} onChange={e => handleData('productName', e)} />
                    </div>
                </div>
                <div className="grid">
                    <div className="col_2">{t('AVAILABLE_TO_CATEGORY')}</div>
                    <div className="col_2">
                        <Dropdown
                            options={data.category}
                            keyPrefix="productList"
                            disabled={!data.category.length}
                            onChange={e => handleData('currentSelected', e)}
                            />
                        {(data.category.length && !data.checked) ? <AddIcon onClick={handleCategoryData}/> : null}
                    </div>
                </div>
                <div className="grid">
                    {data.selectedCategory && data.selectedCategory.map((item, i) => <div key={i} className="col_2 card">{item.label} <CloseIcon onClick={() => removeSelectedCategory(item)}/></div>) }
                </div>
                <Checkbox
                    checked={data.checked}
                    onChange={setChecked}
                    value={'INCLUDE_LOCAL_ADJUSTMENTS'}
            />
                <div className="horizontalDivider"/>
                <div className="grid">
                    <div className="col_2" ><Button onClick={handleCreateVersionPopup} value={t('CANCEL')} /></div>
                    <div className="col_2" ><Button onClick={addProductType} disabled={!(data.productName !== '' && (data.checked || data.selectedCategory.length))} value={t('CREATE_VERSION')} /></div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(AddNewProduct);
