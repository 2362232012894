import React, { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Title } from '../common/text';
import TextField from '../common/textField';
import Button from '../common/button';
import { EMPTY_STRING, SIZE } from '../../constant/text';
import { SET_MESSAGE, CLEAR_MESSAGE, CLOSE_POP_UP } from '../../constant/action';
import { useStore } from '../../store';
import { changePassword } from '../../api/user';
import { withTranslation } from '../common/translation';
import { PRIMARY } from '../../constant/color';
import { errorMessage, successMessage } from '../../api/util';
import Loader from '../common/loader';
import PopUpCloseIcon from '../common/popUpCloseIcon';
import { useMount } from '../common/useMount';

const PasswordChanger = props => {
    const { t, otc = false, email = EMPTY_STRING } = props;
    const [oldPassword, setOldPassword] = useState(EMPTY_STRING);
    const [newPassword, setNewPassword] = useState(EMPTY_STRING);
    const [confirmNewPassword, setConfirmNewPassword] = useState(EMPTY_STRING);
    const [oldPasswordError, setOldPasswordError] = useState(EMPTY_STRING);
    const [newPasswordError, setNewPasswordError] = useState(EMPTY_STRING);
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(EMPTY_STRING);
    const [otcError, setOtcError] = useState(EMPTY_STRING);
    const [loader, setLoader] = useState(false);
    const mounted = useMount();
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);

    const isValid = () => {
        setOldPasswordError(EMPTY_STRING);
        setOtcError(EMPTY_STRING);
        setNewPasswordError(EMPTY_STRING);
        setConfirmNewPasswordError(EMPTY_STRING);
        if (!otc && oldPassword.length < 6) {
            setOldPasswordError(t('PASSWORD_MINLENGTH'));

            return false;
        }

        if (otc && isEmpty(oldPassword)) {
            setOtcError(t('REQUIRED_FIELD'));

            return false;
        }

        if (newPassword.length < 6) {
            setNewPasswordError(t('PASSWORD_MINLENGTH'));

            return false;
        }

        if (isEmpty(confirmNewPassword)) {
            setConfirmNewPasswordError(t('REQUIRED_FIELD'));

            return false;
        }

        if (confirmNewPassword !== newPassword) {
            setConfirmNewPasswordError(t('PASSWORDS_DONT_MATCH'));

            return false;
        }

        return true;
    };

    const onSubmitPassword = () => {
        if (isValid()) {
            setLoader(true);
            clearMessage();
            changePassword(oldPassword, newPassword, email).then(response => {
                if (mounted.current) {
                    setLoader(false);
                    if (response.error) {
                        setMessage(errorMessage(['GENERIC_ERROR']));
                    } else if (response) {
                        setMessage(successMessage(['PASSWORD_CHANGED']));
                        closePopUp();
                    } else {
                        setMessage(errorMessage(isEmpty(email) ? ['OLD_PASSWORD_INVALID'] : ['UNABLE_CHANGE_PASSWORD']));
                    }
                }
            }).catch(() => {
                if (mounted.current) {
                    setLoader(false);
                    setMessage(errorMessage(['GENERIC_ERROR']));
                }
            });
        }
    };

    const oldPasswordChange = oldPwd => {
        setOldPassword(oldPwd);
    };

    const newPasswordChange = newPwd => {
        setNewPassword(newPwd);
    };

    const confirmNewPasswordChange = confirmNewPwd => {
        setConfirmNewPassword(confirmNewPwd);
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader} />
            <div className="popUpBody">
                <Title value={t('CHANGE_PASSWORD')} size={SIZE.L} />
                {!otc && (
                    <TextField
                        placeholder={'OLD_PASSWORD'}
                        type="password"
                        value={oldPassword}
                        onChange={oldPasswordChange}
                        label={'OLD_PASSWORD'}
                        required={true}
                        errorMessage={oldPasswordError}
                    />
                )}
                {otc && (
                    <TextField
                        placeholder={'ONE_TIME_CODE'}
                        value={oldPassword}
                        onChange={oldPasswordChange}
                        label={'ONE_TIME_CODE'}
                        required={true}
                        errorMessage={otcError}
                    />
                )}
                <TextField
                    placeholder={'NEW_PASSWORD'}
                    type="password"
                    value={newPassword}
                    onChange={newPasswordChange}
                    label={'NEW_PASSWORD'}
                    required={true}
                    errorMessage={newPasswordError}
                />
                <TextField
                    placeholder={'CONFIRM_NEW_PASSWORD'}
                    type="password"
                    value={confirmNewPassword}
                    onChange={confirmNewPasswordChange}
                    label={'CONFIRM_NEW_PASSWORD'}
                    required={true}
                    errorMessage={confirmNewPasswordError}
                />
                {loader && <Loader />}
                <div className="buttonField-center">
                    <Button
                        value={t('CHANGE_PASSWORD')}
                        onClick={onSubmitPassword}
                        btnType={PRIMARY}
                        disabled={loader}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(PasswordChanger);
