import React from 'react';
import { withTranslation } from '../../common/translation';

const Import = props => {
    const { t } = props;

    return (
        <div className="export">
            {t('IMPORT')}
        </div>
    );
};

export default withTranslation()(Import);
