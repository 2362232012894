import React, { useEffect } from 'react';

export const useMount = () => {
    const isMounted = React.useRef(true);

    useEffect(() => () => {
            isMounted.current = false;
        }, []);

    return isMounted;
};
