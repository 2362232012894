import React, { useCallback, useState } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import moment from 'moment-timezone';
import uuid from 'react-uuid';
import { useHistory } from 'react-router-dom';
import { withTranslation } from '../../../common/translation';
import RadioButtons from '../../../common/radioButtons';
import Dropdown from '../../../common/dropdown';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../../common/table';
import { getDefaultProductsForUser, getEssPdfCompPack, setDefaultLocalPackage } from '../../../../api/ess';
import { PUBLISHED, REVISE, ESS } from '../../../../constant/text';
import { useStore } from '../../../../store';
import { OPEN_POP_UP, SET_ESS_EDIT, SET_MESSAGE, SET_PRODUCT } from '../../../../constant/action';
import { MANAGE_VERSIONS_ESSEDIT_PAGE } from '../../../../constant/route';
import { getLocalDefualtPublishedPackage, isPredicare, getDateFormat } from '../../../util';
import { useMount } from '../../../common/useMount';
import { successMessage } from '../../../../api/util';
import ChangeEssVersionPopUp from './ChangeEssVersionPopUp';
import { PRIMARY } from '../../../../constant/color';
import Button from '../../../common/button';

const EssList = props => {
    const { t, list, updateEssListCallback } = props;
    const { dispatch } = useStore();
    const mounted = useMount();
    const history = useHistory();

    const setEssEdit = useCallback(payload => dispatch({ type: SET_ESS_EDIT, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const setProduct = useCallback(payload => dispatch({ type: SET_PRODUCT, payload }), [dispatch]);

    const currentVersionId = getLocalDefualtPublishedPackage(list);
    const [activeEss, setActiveEss] = useState(currentVersionId ? currentVersionId : '');

    const onChangeLog = async (newPkgId, id, language) => {
        const data = await getEssPdfCompPack(newPkgId, id, language);
        if (mounted.current && data) {
            window.open(data);
        }
    };

    const editEss = ess => {
        const { typeId, version, language: { languageId }, id: essPackageId } = ess;
        setEssEdit({
            searchBy: ESS,
            publicationStatus: PUBLISHED,
            typeId,
            version,
            languageId,
            essPackageId,
            editType: REVISE
        });
        history.push(MANAGE_VERSIONS_ESSEDIT_PAGE);
    };

    const updateActiveEssVersion = id => {
        setDefaultLocalPackage(id)
            .then(response => {
                if (mounted.current) {
                    if (response.error) {
                        setMessage(response.error);
                    } else {
                        setMessage(successMessage(['SUCCESS_DEFAULT_PKG_ACCESS_SETUP_MSG']));
                        setActiveEss(id);
                        updateEssListCallback();
                        getDefaultProductsForUser().then(defaultProducts => {
                            if (defaultProducts.error) {
                                throw new Error();
                            } else {
                                setProduct(defaultProducts[0] ?? { noProduct: true });
                            }
                        });
                    }
                }
            });
    };

    const openPopUpToChangeEssVersion = item => {
        openPopUp({ component: <ChangeEssVersionPopUp essitem={item} updateVersion={id => updateActiveEssVersion(id)}/> });
    };

    const publishedContent = (isPublished, item) => (isPublished ? (
        <RadioButtons
            options={[
                { label: '', value: activeEss }
            ]}
            name="replaceActiveVersion"
            onItemClick={() => openPopUpToChangeEssVersion(item)}
            value={item.id}
        />
) : (
    <div className="displayFlex column">
        <span>{t('AVAILABLE_FROM')}</span>
        <span>{getDateFormat(item.releaseDate)}</span>
    </div>
));

    return (
        <div className="card">
            <div className="title">{t('PUBLISHED_ESS_VERSIONS')}</div>
            <div className="blurredText">{t('PUBLISHED_VERSIONS_SUBTITLE')}</div>
            <Table className={'striped-horizontally'}>
                <Thead>
                    <Tr>
                        <Th>{t('VERSION_NAME')}</Th>
                        <Th>{t('LANGUAGE')}</Th>
                        <Th>{t('PUBLISHED')}</Th>
                        {!isPredicare() && <Th>{t('AVAILABLE_TO_USERS')}</Th>}
                        <Th />
                        {isPredicare() && <Th />}
                    </Tr>
                </Thead>
                <Tbody>
                    {map(list, item => {
                        const changeLogOptions = map(remove(filter(list, { type: item.type, languageName: item.languageName }), data => data.version !== item.version), mapItem => ({ label: mapItem.version, value: mapItem.id }));
                        const isPublished = moment(item.releaseDate).utc().isBefore(moment().utc(), 'second');

                        return (
                            <Tr key={uuid()}>
                                <Td>{item.version}</Td>
                                <Td>{item.languageName}</Td>
                                <Td>{item.releaseDate}</Td>
                                {!isPredicare() && (
                                    <Td className={'tabledata-centered'}>
                                        {publishedContent(isPublished, item)}
                                    </Td>
                                )}
                                <Td><Button btnType={PRIMARY} value={t('EDIT')} onClick={() => editEss(item)}/></Td>
                                {isPredicare() && (
                                    <Td>
                                        {t('CHANGELOG')}
                                        <Dropdown
                                            options={[...changeLogOptions, { label: 'None', value: '' }]}
                                            value={item.basedOn ? item.basedOn.id : 'None'}
                                            className="changelog"
                                            keyPrefix="changelog"
                                            onChange={e => onChangeLog(e, item.id, item.language.languageCode)}
                                        />
                                    </Td>
                                )}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </div>
    );
};

export default withTranslation()(EssList);
