import React, { useState, useEffect } from 'react';
import { defer } from 'rxjs';
import { LeftArrowIcon, RightArrowIcon } from '../../common/icon';
import { LICENSE_EXTENDER_YEAR_RANGE_DISPLAY, LICENSE_EXTENDER_START_YEAR_LENGTH_DISPLAY, LICENSE_EXPIRY_DATE_FORMAT } from '../../../constant/text';

const LicenseExtender = props => {
    const { selectedYear, onYearSelect } = props;
    const [startYear, setStartYear] = useState();
    const [endYear, setEndYear] = useState();
    const [yearRange, setYearRange] = useState([]);

    const onYearClick = year => {
        onYearSelect(`${year}${LICENSE_EXPIRY_DATE_FORMAT}`);
    };

    const calculateYearRange = startYr => {
        setStartYear(startYr);
        const yearArr = [];
        for (let i = 0; i < LICENSE_EXTENDER_YEAR_RANGE_DISPLAY; i += 1) {
            yearArr[i] = startYr + i;
        }
        setEndYear(yearArr[LICENSE_EXTENDER_YEAR_RANGE_DISPLAY - 1]);
        setYearRange(yearArr);
    };

    useEffect(() => {
        const subscription = defer(() => {
        }).subscribe(() => {
            const year = new Date().getFullYear();
            calculateYearRange(year - LICENSE_EXTENDER_START_YEAR_LENGTH_DISPLAY);
        });

        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className="licenseExtender">
            <div className="firstRow">
                <LeftArrowIcon className="leftArrow clickable" onClick={() => calculateYearRange(startYear - LICENSE_EXTENDER_YEAR_RANGE_DISPLAY)} />
                <div className="yearRange">{`${startYear} - ${endYear}`}</div>
                <RightArrowIcon className="rightArrow clickable" onClick={() => calculateYearRange(startYear + LICENSE_EXTENDER_YEAR_RANGE_DISPLAY)} />
            </div>
            <div className="secondRow">
                {
                    yearRange.map(year => <div key={year} className={`year ${selectedYear && Number(selectedYear.substring(0, 4)) === year ? 'activeOutline' : 'clickable'}`} onClick={() => onYearClick(year)}>{year}</div>)
                }
            </div>
        </div>
    );
};

export default LicenseExtender;
