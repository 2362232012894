import React from 'react';
import { withTranslation } from '../../../common/translation';
import PublishForm from './publishform';

const EssPublish = () => (
    <div className="card">
        <PublishForm />
    </div>
    );

export default withTranslation()(EssPublish);
