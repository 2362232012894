import React from 'react';
import { HORIZONTAL } from '../../constant/text';

const Line = props => {
    const { direction = HORIZONTAL } = props;

    return (
        <div className={direction} />
    );
};

export default Line;
