import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { ROLES } from '../../constant/route';
import { getRoleId } from '../../api/util';
import { getUserStartPage } from '../util';

const AdminRoute = ({ component: Component, path, ...rest }) => {
    const history = useHistory();

    const isPathAllowed = () => {
        const role = ROLES[getRoleId()];
        const allowedPaths = role.menu.flatMap(menuDefinition => menuDefinition.paths);
        return allowedPaths.includes(path);
    };

    return (
        <Route
            path={path}
            {...rest}
            render={props => (
                isPathAllowed() ? <Component {...props} /> : history.push(getUserStartPage())
            )}
        />
    );
};
export default AdminRoute;
