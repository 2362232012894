import React from 'react';
import { EMPTY_STRING } from '../../constant/text';

const Card = props => {
    const { count, component: Component, className = EMPTY_STRING } = props;

    return (
        <div className={`card cardess col_${count} ${className}`}>
            <Component {...props} />
        </div>
    );
};

export default Card;
