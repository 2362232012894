import React, { useState, useCallback } from 'react';
import PopUpCloseIcon from '../../common/popUpCloseIcon';
import Loader from '../../common/loader';
import { withTranslation } from '../../common/translation';
import { Title } from '../../common/text';
import RadioButtons from '../../common/radioButtons';
import { downloadUserEmails, downloadAdminEmails, downloadAllAddresses } from '../../../api/user';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import { useStore } from '../../../store';
import { useMount } from '../../common/useMount';

const GET_USER_EMAILS = 'GET_USER_EMAILS';
const GET_ADMIN_EMAILS = 'GET_ADMIN_EMAILS';
const GET_ALL_ADDRESSES = 'GET_ALL_ADDRESSES';

const ListDownloader = props => {
    const { t } = props;
    const [loader, setLoader] = useState();
    const mounted = useMount();
    const [selected, setSelected] = useState();
    const [disabled, setDisabled] = useState(true);
    const { dispatch } = useStore();
    const EMAILS_NOT_PREDICARE = `${t('EMAIL_FOR_ALL')} (${t('NOT_PREDICARE')})`;
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    const onItemClick = val => {
        setDisabled(false);
        setSelected(val);
    };

    const downloadList = async () => {
        setDisabled(true);
        setLoader(true);
        clearMessage();
        let data;
        switch (selected) {
            case GET_USER_EMAILS:
                data = await downloadUserEmails(`${t(EMAILS_NOT_PREDICARE)}.xls`);
                break;

            case GET_ADMIN_EMAILS:
                data = await downloadAdminEmails(`${t('EMAIL_FOR_ADMIN')}.xls`);
                break;

            case GET_ALL_ADDRESSES:
                data = await downloadAllAddresses(`${t('ADDRESS_LIST_FOR_ALL')}.xls`);
                break;

            default:
                data = false;
                break;
        }
        if (mounted.current) {
            if (data && data.error) {
                setMessage(data.error);
            }
            setLoader(false);
            setDisabled(false);
        }
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader} />
            <div className="popUpBody">
                <Title value={t('GET_ADDRESS_LIST')} />
                {loader && <Loader />}
                <RadioButtons
                    options={[
                        { label: EMAILS_NOT_PREDICARE, value: GET_USER_EMAILS },
                        { label: t('EMAIL_FOR_ADMIN'), value: GET_ADMIN_EMAILS },
                        { label: t('ADDRESS_LIST_FOR_ALL'), value: GET_ALL_ADDRESSES }
                    ]}
                    value={selected}
                    className="listDownloader"
                    onItemClick={onItemClick}
                />
                <div className="buttonField-right">
                    <Button btnType={PRIMARY} value={t('DOWNLOAD')} onClick={downloadList} disabled={disabled} />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(ListDownloader);
