import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { hasSessionExpired, setSessionHasExpiredMessage } from '../../api/util';
import { useLoginManager } from '../../UseLoginManager';
import { LOGIN_PAGE } from '../../constant/route';

const RedirectToLogin = () => {
    const history = useHistory();
    const { logout } = useLoginManager();

    useEffect(() => {
        if (hasSessionExpired()) {
            logout();
            setSessionHasExpiredMessage();
        }
        history.push(LOGIN_PAGE);
    }, []);

    return null;
};
export default RedirectToLogin;
