import React, { useEffect, useState, useRef } from 'react';
import { defer } from 'rxjs';
import map from 'lodash/map';
import { withTranslation } from './translation';
import { SIZE, EMPTY_STRING } from '../../constant/text';

const ARROW_DOWN_KEY_KEYCODE = 40;
const ARROW_UP_KEY_KEYCODE = 38;
const ENTER_KEY_KEYCODE = 13;

const AutoComplete = props => {
    const { dataAttribute, t, data, onChange, onAutocomplete, label, id, size = SIZE.M, value, className = EMPTY_STRING } = props;
    const [position, setPosition] = useState(0);
    const inputRef = useRef(null);

    useEffect(() => {
        const scrollPosition = () => {
            if (position >= data.length) {
                setPosition(0);
            }
            if (position < 0) {
                setPosition(data.length - 1);
            }
            if (data.length && value !== '') {
                document.onkeydown = e => checkKey(e);
            } else {
                document.onkeydown = null;
            }
        };
        const subscription = defer(() => scrollPosition()).subscribe();

        return subscription.unsubscribe();
    }, [position, data]);

    const checkKey = e => {
        if (inputRef.current !== document.activeElement) {
            return;
        }
        if (e.keyCode === ENTER_KEY_KEYCODE) {
            onAutocomplete(data[position].value);
            e.preventDefault();
        }
        if (e.keyCode === ARROW_DOWN_KEY_KEYCODE) {
            const pn = position + 1;
            setPosition(pn);
            e.preventDefault();
        }
        if (e.keyCode === ARROW_UP_KEY_KEYCODE) {
            const pn = position - 1;
            setPosition(pn);
            e.preventDefault();
        }
    };

    return (
        <div className="autoCompleteContainer field">
            <input
                data-test={dataAttribute}
                ref={inputRef}
                type="text"
                id={id}
                placeholder={t(label)}
                data-lpignore="true"
                value={value}
                className={`autoComplete ${size} ${className}`}
                onChange={e => onChange(e.target.value)}
                autoComplete="off"
            />
            {value && data.length ? (
                <ul className="autoComplete-list" data-test="autoComplete_ul_searchResults">
                    {map(data, (list, i) => (
                        <li
                            data-test="autoComplete_li_searchResult"
                            id={`auto_complete_${i}`}
                            onClick={() => onAutocomplete(list.value)}
                            key={i}
                            className={i === position ? 'active' : EMPTY_STRING}
                        >
                            {list.label}
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
};

export default withTranslation()(AutoComplete);
