import React, { useCallback } from 'react';
import Button from './button';
import { withTranslation } from './translation';
import { PRIMARY } from '../../constant/color';
import PopUpCloseIcon from './popUpCloseIcon';
import { useStore } from '../../store';
import { CLOSE_POP_UP } from '../../constant/action';

const InfoPopupBox = props => {
    const { t, value, component: Component } = props;
    const { dispatch } = useStore();
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);

    const onOKClick = () => {
        closePopUp();
    };

    return (
        <div className="popUpComponent">
            <PopUpCloseIcon />
            <div className="popUpBody">
                {value && (
                    <div className="confirmBoxText">
                        {t(value)}
                    </div>
                )}
                {Component}
                <div className="buttonField-right">
                    <Button
                        value={t('OK')}
                        btnType={PRIMARY}
                        onClick={onOKClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(InfoPopupBox);
