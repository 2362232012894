import React from 'react';

const UserDetails = ({ username, rolename, accountname }) => (
    <ul className="userDetails">
        <li data-test="fullName">{username}</li>
        <li>{rolename}</li>
        <li>{accountname}</li>
    </ul>
    );

export default React.memo(UserDetails);
