import React, { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../common/translation';
import { DownArrowIcon, RightArrowIcon, DeleteIcon, AddNodeIcon, EditIcon, FlagIcon, UserProfileIcon, LicenseIcon } from '../../common/icon';
import { maxWordLength, persistOpenNodes } from '../../util';
import { successMessage, errorMessage } from '../../../api/util';
import {
    TREENODE_NAME_MAX_LENGTH, EMPTY_STRING, TREE_NODE_TYPE_NORMAL, TREE_NODE_TYPE_LICENSE, TREE_NODE_TYPE_DELETE, RED_ICON, GREEN_ICON, YELLOW_ICON,
    RED_FLAG_TOOLTIP, GREEN_FLAG_TOOLTIP, YELLOW_FLAG_TOOLTIP, SUCCESS_ADMIN_MSG, FAILURE_ADMIN_MSG, SUCCESS_LICENSE_MSG, FAILURE_LICENSE_MSG, WARNING_LICENSE_MSG, DROPDOWN_SELECT_TEXT_VALUE
} from '../../../constant/text';
import { StyledTreeNode } from '../../../style/theme';
import { useStore } from '../../../store';
import Checkbox from '../../common/checkbox';
import LicenseExtender from './licenseExtender';
import ToolTip from '../../common/toolTip';
import { OPEN_POP_UP, SET_MESSAGE, CLOSE_POP_UP, SET_ACCOUNTS, CLEAR_MESSAGE } from '../../../constant/action';
import SaveAccount from './saveAccount';
import { deleteAccount, getTreeChildAccounts, getAccounts } from '../../../api/user';
import ConfirmBox from '../../common/confirmBox';
import Loader from '../../common/loader';
import { useMount } from '../../common/useMount';

const TreeNode = props => {
    const { t, node, getChildNodes, level = 0, onToggle, onNodeSelect, type = TREE_NODE_TYPE_NORMAL, setSelectedYear } = props;
    const { dispatch, state: { accounts = {} } } = useStore();
    const { regions = [], rootId, nodes = {}, selectedAccountNode = {} } = accounts;
    const [showLicenseExtender, setShowLicenseExtender] = useState(false);
    const mounted = useMount();
    const [loader, setLoader] = useState(false);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setAccounts = useCallback(payload => dispatch({ type: SET_ACCOUNTS, payload }), [dispatch]);

    let deleteNodeId;
    const onCheckboxClick = checked => {
        if (checked) {
            setShowLicenseExtender(true);
        } else {
            setShowLicenseExtender(false);
        }
        onNodeSelect(node);
    };

    const onYearSelect = year => {
        setSelectedYear(node, year);
    };

    const addSubLevel = () => {
        openPopUp({ component: <SaveAccount parent={node} title="ADD_CHILD" /> });
    };

    const updateAccountName = newName => {
        node.name = newName;
    };

    const editAccount = node => {
        openPopUp({ component: <SaveAccount node={node} title="EDIT_ACCOUNT" onClose={updateAccountName} /> });
    };

    const onDeleteConfirm = async decision => {
        if (decision) {
            setLoader(true);
            clearMessage();
            const deleted = await deleteAccount(deleteNodeId);
            if (mounted.current) {
                setLoader(false);
                if (deleted.error) {
                    setMessage(deleted.error);
                } else if (deleted) {
                    setLoader(true);
                    const childAccs = await getTreeChildAccounts(rootId);
                    if (mounted.current) {
                        setLoader(false);
                        if (childAccs.error) {
                            setMessage(childAccs.error);
                        } else {
                            const accs = {};
                            if (regions.filter(reg => reg.id === deleteNodeId).length > 0) {
                                setLoader(true);
                                const regs = await getAccounts();
                                if (mounted.current) {
                                    setLoader(false);
                                    accs.regions = regs;
                                    accs.rootId = DROPDOWN_SELECT_TEXT_VALUE;
                                    accs.selectedAccountNode = {};
                                }
                            }
                            accs.nodes = persistOpenNodes(nodes, childAccs);
                            setAccounts(accs);
                            setMessage(successMessage(['ACCOUNT_DELETED']));
                            closePopUp();
                        }
                    }
                } else {
                    setMessage(errorMessage(['ERROR_CHILDREN_OR_LICENSE']));
                    closePopUp();
                }
            }
        }
    };

    const removeAccount = nodeId => {
        deleteNodeId = nodeId;
        openPopUp({ component: <ConfirmBox value={'REMOVE_ACCOUNT_CONFIRM'} onConfirm={onDeleteConfirm} /> });
    };

    const getFlagIcon = node => {
        const { hasAdmin = false, hasLicense = false, hasLicenseExpired = false, isLicenseExpiring = false } = node;
        let icon;
        let toolTip;
        if (isLicenseExpiring) {
            toolTip = YELLOW_FLAG_TOOLTIP;
            icon = YELLOW_ICON;
        } else if (hasAdmin && hasLicense && !hasLicenseExpired) {
            toolTip = GREEN_FLAG_TOOLTIP;
            icon = GREEN_ICON;
        } else {
            toolTip = RED_FLAG_TOOLTIP;
            icon = RED_ICON;
        }

        return <FlagIcon fillClassName={icon} toolTip={t(toolTip)} />;
    };

    const getAdminIcon = node => {
        const { hasAdmin = false } = node;
        let icon = RED_ICON;
        let toolTip = FAILURE_ADMIN_MSG;
        if (hasAdmin) {
            icon = GREEN_ICON;
            toolTip = SUCCESS_ADMIN_MSG;
        }

        return <UserProfileIcon fillClassName={icon} toolTip={t(toolTip)} />;
    };

    const getLicenseIcon = node => {
        const { hasLicense = false, hasLicenseExpired = false, isLicenseExpiring = false } = node;
        let icon;
        let toolTip;
        if (isLicenseExpiring) {
            icon = YELLOW_ICON;
            toolTip = WARNING_LICENSE_MSG;
        } else if (hasLicense && !hasLicenseExpired && !isLicenseExpiring) {
            icon = GREEN_ICON;
            toolTip = SUCCESS_LICENSE_MSG;
        } else {
            icon = RED_ICON;
            toolTip = FAILURE_LICENSE_MSG;
        }

        return <LicenseIcon fillClassName={icon} toolTip={t(toolTip)} />;
    };

    return (
        <>
            {loader && <Loader />}
            <StyledTreeNode level={level}>
                {!isEmpty(node.children) ? (
                    <div className="nodeIcon">
                        {(node.isOpen ? <DownArrowIcon className="arrowIcon" onClick={() => onToggle(node, false)}/>
                                      : <RightArrowIcon className="arrowIcon" onClick={() => onToggle(node, true)}/>)}
                    </div>
                ) : <div className="emptyNodeIcon" /> }
                {
                    (type === TREE_NODE_TYPE_DELETE || type === TREE_NODE_TYPE_NORMAL) && (
                        <div className={`nodeName ${node.id === selectedAccountNode.id ? 'activeNode' : EMPTY_STRING}`}>
                            <div className={`nodeText ${node.id === selectedAccountNode.id ? EMPTY_STRING : 'clickable'}`} onClick={() => onNodeSelect(node)}><ToolTip value={node.name} maxLength={TREENODE_NAME_MAX_LENGTH} /></div>
                            <div className="icons">
                                {node.accountType.id === 1 && getFlagIcon(node)}
                                {node.accountType.id > 1 && getAdminIcon(node)}
                                {node.accountType.id > 1 && getLicenseIcon(node)}
                                <EditIcon toolTip={t('EDIT')} onClick={() => editAccount(node)} />
                                {type === TREE_NODE_TYPE_DELETE && (
                                    <>
                                        <DeleteIcon toolTip={t('DELETE')} onClick={() => removeAccount(node.id)} />
                                        <AddNodeIcon toolTip={t('ADD_CHILD')} onClick={addSubLevel} />
                                    </>
                                )}
                            </div>
                        </div>
                    )
                }
                {
                    (type === TREE_NODE_TYPE_LICENSE) && (
                        <div className="nodeName">
                            <Checkbox className="nodeText" checked={node.hasLicense} onChange={onCheckboxClick} value={maxWordLength(node.name, TREENODE_NAME_MAX_LENGTH)} />
                            {showLicenseExtender && <LicenseExtender selectedYear={node.validTo} onYearSelect={onYearSelect} />}
                        </div>
                    )
                }
            </StyledTreeNode>
            {
                node.isOpen && getChildNodes(node).map((childNode, i) => <TreeNode key={i} {...props} node={childNode} level={level + 1} onNodeSelect={onNodeSelect} type={type} setSelectedYear={setSelectedYear} />)
            }
        </>
    );
};

export default withTranslation()(TreeNode);
