import React from 'react';
import { withTranslation } from '../common/translation';
import { FooterContainer, CopyrightText } from '../../style/theme';
import { PredicareLogo } from '../common/icon';

const Footer = props => {
    const { t } = props;    
    return (
        <FooterContainer className="footer">
            <PredicareLogo />
            <CopyrightText>{t('WEB_COPYRIGHT_NOTICE')}</CopyrightText>
        </FooterContainer>
    );
}

export default withTranslation()(Footer);
