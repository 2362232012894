import axios from 'axios';
import { getHeaders, apiError, download, checkJwtValidation } from './util';
import { CANNOT_DOWNLOAD_FOR_THIS_INPUT } from '../constant/text';

export const apiGet = url => {
    checkJwtValidation();

    return getRequest(url);
};

export const unauthorizedApiGet = url => getRequest(url);

const getRequest = url => axios.get(url, { headers: getHeaders() })
        .then(response => response.data).catch(error => apiError(error));

export const apiDelete = (url, data) => {
    checkJwtValidation();

    return axios.delete(url, { data, headers: getHeaders() })
        .then(response => response.data).catch(error => apiError(error));
};

export const apiPost = (url, data) => {
    checkJwtValidation();

    return postRequest(url, data);
};

export const unauthorizedApiPost = (url, data) => postRequest(url, data);

const postRequest = (url, data) => axios.post(url, data, { headers: getHeaders() })
        .then(response => response.data).catch(error => apiError(error));

const saveFileUsingContentDisposition = (defaultFilename, response) => {
        let filename = defaultFilename;
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        download(response.data, filename);
};

export const apiFileDownloadPost = (url, data, fileNameWithExtension, errorMessage = CANNOT_DOWNLOAD_FOR_THIS_INPUT) => {
    checkJwtValidation();

    return axios.post(url, data, { headers: getHeaders(), responseType: 'blob' })
        .then(response => {
            saveFileUsingContentDisposition(fileNameWithExtension, response);

            return true;
        }).catch(error => apiError(error, true, errorMessage));
};

export const apiFileDownload = (url, fileNameWithExtension, errorMessage = CANNOT_DOWNLOAD_FOR_THIS_INPUT) => {
    checkJwtValidation();

    return axios.get(url, { headers: getHeaders(), responseType: 'blob' })
        .then(response => {
            saveFileUsingContentDisposition(fileNameWithExtension, response);

            return true;
        }).catch(error => apiError(error, true, errorMessage));
};
