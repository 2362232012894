import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../common/translation';
import Checkbox from '../../common/checkbox';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../common/table';
import { getAccountsRolesForUsername } from '../../../api/user';
import Loader from '../../common/loader';
import { Title } from '../../common/text';

const ExistingUser = props => {
    const { t, username, onChecked, checked = false } = props;
    const [existingUsers, setExistingUsers] = useState([]);
    const [loader, setLoader] = useState();

    useEffect(() => {
        setLoader(true);
        getAccountsRolesForUsername(username)
            .then(u => {
                setExistingUsers(u);
                setLoader(false);
            })
            .catch(err => {
                console.error('existingUser response is throwing ', err);
            });
    }, []);

    return (
        <div className="existingUser loadable">
            {loader && <Loader/>}
            <Checkbox checked={checked} onChange={onChecked} value="CONFIRM_ADD_EXISTING_USER" />
            <Title value={t('EXISTING_USERS')}/>
            {
                existingUsers && !isEmpty(existingUsers) && (
                    <Table className={'striped-horizontally'}>
                        <Thead>
                            <Tr>
                                <Th>{t('NAME')}</Th>
                                <Th>{t('USERNAME')}</Th>
                                <Th>{t('ACCOUNT')}</Th>
                                <Th>{t('USER_ROLE')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                existingUsers.map(user => {
                                    const { name, username, role: { id: roleId, description: userRole }, account: { id: accountId, name: accountName } } = user;

                                    return (
                                        <Tr key={`${roleId}-${accountId}`}>
                                            <Td>{name}</Td>
                                            <Td>{username}</Td>
                                            <Td>{t(accountName)}</Td>
                                            <Td>{t(userRole)}</Td>
                                        </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                )
            }
        </div>
    );
};

export default withTranslation()(ExistingUser);
