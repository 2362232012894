import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { defer } from 'rxjs';
import { Title } from '../../../../common/text';
import TextField from '../../../../common/textField';
import Button from '../../../../common/button';
import { withTranslation } from '../../../../common/translation';
import InfoBox from '../../../../common/infoBox';
import { getVpGroupRules, saveVpGroupRule, deleteVpGroupRule } from '../../../../../api/vp';
import { EditIcon, DeleteIcon } from '../../../../common/icon';
import { PRIMARY, SECONDARY } from '../../../../../constant/color';
import { EMPTY_STRING } from '../../../../../constant/text';
import ConfirmBox from '../../../../common/confirmBox';
import { warningMessage } from '../../../../../api/util';
import { useStore } from '../../../../../store';
import { SET_MESSAGE, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../../../constant/action';
import Loader from '../../../../common/loader';
import { useMount } from '../../../../common/useMount';

const GroupRule = props => {
    const { t, vpTypeId } = props;
    const mounted = useMount();
    const [groupRules, setGroupRules] = useState([]);
    const [groupRule, setGroupRule] = useState({ vptypesId: vpTypeId });
    const { id, description = EMPTY_STRING } = groupRule;
    const [addDisabled, setAddDisabled] = useState(true);
    const [loader, setLoader] = useState(false);
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    const fetchGroupRules = async vpTypeId => {
        setLoader(true);
        const grList = await getVpGroupRules(vpTypeId);
        setLoader(false);
        if (grList.error) {
            setMessage(grList.error);
        } else {
            setGroupRules(grList);
        }
    };

    useEffect(() => {
        const subscription = defer(() => fetchGroupRules(vpTypeId)).subscribe(() => setGroupRule({ vptypesId: vpTypeId }));

        return () => subscription.unsubscribe();
    }, [vpTypeId]);

    const setGroupRuleDescription = description => {
        if (isEmpty(description)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setGroupRule({ ...groupRule, description });
    };

    const editGroupRule = async gr => {
        setAddDisabled(true);
        setGroupRule(gr);
    };

    const deleteGroupRule = gr => {
        openPopUp({ component: <ConfirmBox value="REMOVE_GROUPRULE_CONFIRM" onConfirm={() => removeGroupRule(gr)} /> });
    };

    const removeGroupRule = async gr => {
        const { id, vptypesId } = gr;
        clearMessage();
        setLoader(true);
        const savedEss = await deleteVpGroupRule(id);
        if (mounted.current) {
            setLoader(false);
            if (savedEss.error) {
                setMessage(savedEss.error);
            } else {
                fetchGroupRules(vptypesId);
            }
        }
    };

    const saveGroupRule = async gr => {
        const { description } = gr;
        if (isEmpty(description)) {
            setMessage(warningMessage(['REQUIRED_FIELD']));
        } else {
            clearMessage();
            setLoader(true);
            const savedEss = await saveVpGroupRule(gr);
            if (mounted.current) {
                setLoader(false);
                if (savedEss.error) {
                    setMessage(savedEss.error);
                } else {
                    onCancel();
                    fetchGroupRules(vpTypeId);
                }
            }
        }
    };

    const onCancel = () => {
        setAddDisabled(true);
        setGroupRule({});
    };

    return (
        <div className="section separateLines">
            <Title value={t('EDIT_GROUP_RULE')} />
            <InfoBox>{t('VP_GROUPRULES_INFO')}</InfoBox>
            {loader && <Loader />}
            <div className="section inline">
                <TextField
                    placeholder="GROUP_RULE"
                    value={description}
                    onChange={setGroupRuleDescription}
                />
                <Button
                    btnType={PRIMARY}
                    value={t(id ? 'UPDATE' : 'ADD')}
                    onClick={() => saveGroupRule(groupRule)}
                    disabled={addDisabled}
                />
                {id && (
                    <Button
                        btnType={SECONDARY}
                        value={t('CANCEL')}
                        onClick={onCancel}
                    />
                )}
            </div>
            {
                groupRules.map(item => {
                    const { id, description } = item;

                    return (
                        <div key={id} className="inline">
                            <div className="essCardTitle inline">
                                <div className="leftSpacingInMiddleOfLine">{description}</div>
                            </div>
                            <div className="buttonField-right">
                                <EditIcon className="clickable" onClick={() => editGroupRule(item)} />
                                <DeleteIcon className="clickable" onClick={() => deleteGroupRule(item)} />
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default withTranslation()(GroupRule);
