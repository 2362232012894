import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as RettsLogoComponent } from '@internal/assets/icons/RETTS_negativ.svg';
import { ReactComponent as PredicareLogoComponent } from '@internal/assets/icons/logo_predicare_white.svg';
import { EMPTY_STRING, MAX_NOTIFICATION_COUNT_DISPLAY, PLUS, XML, PDF } from '../../constant/text';
import {
    CLOSE_ICON, MENU_ICON, INFO_ICON, TRANSLATE_ICON, XML_IMAGE, PDF_IMAGE,
    UP_ICON, DOWN_ICON, RIGHT_ICON, LEFT_ICON, SEARCH_ICON, NOTIFICATION_ICON, OVERVIEW_ICON,
    MANAGE_VERSIONS_ICON, USERS_ICON, EXPORT_ICON, IMPORT_ICON, MARK_AS_READ_ICON, READ_ICON, DEFAULT_SVG_SIZE,
    EDIT_ICON, DELETE_ICON, FLAG_ICON, USER_PROFILE_ICON, LICENSE_ICON, ADD_NODE_ICON, RIGHT_ARROW_ICON, ADD_ICON
} from '../../constant/icon';
// eslint-disable-next-line import/no-cycle
import ToolTip from './toolTip';

export const getImage = (src, alt) => <img src={src} alt={alt} />;

const getSvg = props => {
    const { d1 = EMPTY_STRING, d2 = 'M0 0h24v24H0z', d3, viewBox = DEFAULT_SVG_SIZE, width = DEFAULT_SVG_SIZE, toolTip = EMPTY_STRING, badge = null, fillClassName = 'none', className = EMPTY_STRING, onClick = () => { }, icon = null, disabled = false } = props;
    let svgIcon;
    if (icon) {
        svgIcon = icon;
    } else {
        svgIcon = (
            <svg height={width} className={fillClassName} viewBox={`0 0 ${viewBox} ${viewBox}`} width={width}>
                <path d={d1} />
                <path d={d2} fill="none" />
                {d3 && <path d={d3} />}
            </svg>
        );
    }

    const disabledClass = disabled ? 'disabledBtn' : EMPTY_STRING;

    return (
        <div className={`icon ${className} ${disabledClass}`} onClick={disabled ? () => { } : () => onClick()}>
            {isEmpty(toolTip) && svgIcon}
            {!isEmpty(toolTip) && <ToolTip icon={svgIcon} toolTipText={toolTip} />}
            {badge}
        </div>
    );
};

export const MenuIcon = props => getSvg({ d1: MENU_ICON, ...props });

export const InfoIcon = props => getSvg({ d1: INFO_ICON, ...props });

export const FlagIcon = props => getSvg({ d1: FLAG_ICON, ...props });

export const UserProfileIcon = props => getSvg({ d1: USER_PROFILE_ICON, ...props });

export const LicenseIcon = props => getSvg({ d1: LICENSE_ICON, ...props });

export const CloseIcon = props => getSvg({ d1: CLOSE_ICON, ...props });

export const UpArrowIcon = props => getSvg({ d1: UP_ICON, ...props });

export const DownArrowIcon = props => getSvg({ d1: DOWN_ICON, ...props });

export const RightArrowIcon = props => getSvg({ d1: RIGHT_ICON, ...props });

export const RightLineArrowIcon = props => getSvg({ d1: RIGHT_ARROW_ICON, ...props });

export const LeftArrowIcon = props => getSvg({ d1: LEFT_ICON, ...props });

export const AddIcon = props => getSvg({ d1: ADD_ICON, ...props });

export const Badge = props => {
    const { value } = props;
    if (!isEmpty(value)) {
        return <span className="badge">{value}</span>;
    }

    return <span />;
};

export const NotificationIcon = props => {
    const { count = 0 } = props;
    let badgeText = EMPTY_STRING;
    if (count > MAX_NOTIFICATION_COUNT_DISPLAY) {
        badgeText = `${MAX_NOTIFICATION_COUNT_DISPLAY}${PLUS}`;
    } else if (count > 0) {
        badgeText = `${count}`;
    }

    return getSvg({ d1: NOTIFICATION_ICON, badge: <Badge value={badgeText} />, toolTip: 'MESSAGES', ...props });
};

export const XmlIcon = () => getImage(XML_IMAGE, XML);

export const PdfIcon = () => getImage(PDF_IMAGE, PDF);

export const ListIcon = props => {
    const svgIcon = (
        <svg
            height="24"
            viewBox="0 0 30 30"
            width="24"
            fill="#FFFFFF"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px">
            <g data-name="Layer 2">
                <path d="M26.5,30.5H5.5V5.5h6v2h9v-2h6Zm-20-1h19V6.5h-4v2h-11v-2h-4Z" /><path
                    d="M21.5,8.5h-11v-5h3a2.5,2.5,0,0,1,4.9,0h3Zm-10-1h9v-3h-3V4a1.5,1.5,0,0,0-3,0v.5h-3Z" />
                <rect x="8.5" y="12.13" width="6.5" height="1" />
                <rect x="17" y="12.13" width="6.5" height="1" />
                <rect x="8.5" y="16.44" width="6.5" height="1" />
                <rect x="17" y="16.44" width="6.5" height="1" />
                <rect x="8.5" y="20.75" width="6.5" height="1" />
                <rect x="17" y="20.75" width="6.5" height="1" />
                <rect x="8.5" y="25.06" width="6.5" height="1" />
                <rect x="17" y="25.06" width="6.5" height="1" /></g>
        </svg>
    );

    return getSvg({ icon: svgIcon, ...props });
};

export const SearchIcon = props => getSvg({ d1: SEARCH_ICON, ...props });

export const PredicareLogo = () => (
    <div className="predicareLogo">
        <PredicareLogoComponent />
    </div>
    );

export const RettsLogo = () => (
    <div className="rettsLogo">
        <a href={'/'}>
            <RettsLogoComponent />
        </a>
    </div>
    );

export const OverviewIcon = props => getSvg({ d1: OVERVIEW_ICON, ...props });

export const ManageVersionIcon = props => getSvg({ d1: MANAGE_VERSIONS_ICON, ...props });

export const TranslateIcon = props => getSvg({ d1: TRANSLATE_ICON, ...props });

export const UsersIcon = props => getSvg({ d1: USERS_ICON, ...props });

export const ExportIcon = props => getSvg({ d1: EXPORT_ICON, ...props });

export const ImportIcon = props => getSvg({ d1: IMPORT_ICON, ...props });

export const MarkAsReadIcon = props => getSvg({ d1: MARK_AS_READ_ICON, d2: 'M0 0h24v24H0V0zm0 0h24v24H0V0z', ...props });

export const ReadIcon = props => getSvg({ d1: READ_ICON, ...props });

export const AddNodeIcon = props => {
    const { className = EMPTY_STRING } = props;

    return getSvg({ d1: ADD_NODE_ICON, className: `clickable ${className}`, ...props });
};

export const EditIcon = props => {
    const { className = EMPTY_STRING } = props;

    return getSvg({ d1: EDIT_ICON, className: `clickable ${className}`, ...props });
};

export const DeleteIcon = props => {
    const { className = EMPTY_STRING } = props;

    return getSvg({ d1: DELETE_ICON, className: `clickable ${className}`, ...props });
};
