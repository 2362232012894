import React, { useState, useEffect, useCallback } from 'react';
import { defer } from 'rxjs';
import PopUpCloseIcon from '../../common/popUpCloseIcon';
import { withTranslation } from '../../common/translation';
import { saveLicense, getLicensesForAccount } from '../../../api/user';
import Loader from '../../common/loader';
import { LabelText } from '../../common/text';
import { SET_MESSAGE, CLOSE_POP_UP, SET_LICENSES, CLEAR_MESSAGE } from '../../../constant/action';
import { useStore } from '../../../store';
import InfoBox from '../../common/infoBox';
import { errorMessage, successMessage } from '../../../api/util';
import LicenseExtender from './licenseExtender';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import Checkbox from '../../common/checkbox';
import { useMount } from '../../common/useMount';

const EditLicense = props => {
    const { t, data } = props;
    const [loader, setLoader] = useState(false);
    const mounted = useMount();
    const [license, setLicense] = useState(data);
    const { paid, validTo, licenseType: { type: licenseTypeName }, boughtByAccountName, usedByAccountName } = license;
    const [disabled, setDisabled] = useState(false);
    const { dispatch, state: { accounts: { selectedAccountNode } } } = useStore();
    const { id: accountId } = selectedAccountNode;
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const setLicenses = useCallback(payload => dispatch({ type: SET_LICENSES, payload }), [dispatch]);

    useEffect(() => {
        const subscription = defer(() => { }).subscribe(() => setLicense(data));

        return () => subscription.unsubscribe();
    }, []);

    const onPaidChecked = checked => {
        setDisabled(false);
        setLicense({ ...license, paid: checked });
    };

    const onYearSelect = year => {
        setDisabled(false);
        setLicense({ ...license, validTo: year });
    };

    const updateLicense = async () => {
        setDisabled(true);
        setLoader(true);
        clearMessage();
        const saved = await saveLicense(license);
        if (mounted.current) {
            setLoader(false);
            if (saved.error) {
                setMessage(saved.error);
            } else if (!saved) {
                setMessage(errorMessage(['UPDATE_LICENSE_FAILED']));
            } else {
                setLoader(true);
                const licenses = await getLicensesForAccount(accountId);
                if (mounted.current) {
                    setLoader(false);
                    if (licenses.error) {
                        setMessage(licenses.error);
                    } else {
                        setLicenses(licenses);
                        setMessage(successMessage(['UPDATE_LICENSE_SUCCESS']));
                    }
                }
                closePopUp();
            }
        }
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader} />
            <div className="popUpBody">
                {loader && <Loader />}
                <LabelText label={t('TYPE_OF_LICENSE')} text={licenseTypeName} />
                <LabelText label={t('OWNED_BY')} text={boughtByAccountName} />
                <LabelText label={t('USED_BY')} text={usedByAccountName} />
                <Checkbox checked={paid} onChange={onPaidChecked} value={'LICENSE_IS_PAID'} />
                <InfoBox>{`${t('VALID_THRU_INFO')}`}</InfoBox>
                <LicenseExtender selectedYear={validTo} onYearSelect={onYearSelect} />
                <div className="buttonField-right">
                    <Button value={t('SAVE')} btnType={PRIMARY} disabled={disabled} onClick={() => updateLicense()} />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(EditLicense);
