import isEmpty from 'lodash/isEmpty';
import { SET_VP_EDIT, SET_VP_TYPES, SET_SELECTED_MANAGE_VERSION, SET_VS_PACKAGES } from '../constant/action';
import { LOCAL_VP_EDIT } from '../constant/text';
import { setLocal } from '../api/localStorageUtil';

export const vpAction = {
    [SET_VP_TYPES]: (state, payload = []) => {
        if (!state.vp.vpTypes) {
            state.vp.vpTypes = [];
        }

        if (isEmpty(payload)) {
            return {
                vp: {
                    ...state.vp
                }
            };
        }

        payload = payload.map(({ id, type }) => ({ label: type, value: id }));

        return {
            vp: {
                ...state.vp,
                vpTypes: payload
            }
        };
    },
    [SET_VS_PACKAGES]: (state, payload) => ({
            manageVersions: { ...state.manageVersions, vp: payload }
        }),
    [SET_SELECTED_MANAGE_VERSION]: (state, payload = []) => ({
            manageVersions: {
                ...state.manageVersions,
                editVersion: {
                    ...state.manageVersions.editVersion,
                    selectedVersion: payload.selectedVersion,
                    selectedVp: payload.selectedVp
                }
            }
        }),
    [SET_VP_EDIT]: (state, payload) => {
        setLocal(LOCAL_VP_EDIT, payload, true);

        return {
            manageVersions: {
                ...state.manageVersions,
                vpEdit: payload
            }
        };
    },
};
