import React, { useCallback } from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';
import { useStore } from '../../../store';
import SearchForm from './searchForm';
import Card from '../../common/card';
import EssCard from './Ess/EssCard';
import VpCard from './vpCard';
import DefinitionCard from './definitionCard';
import ConfirmBox from '../../common/confirmBox';
import { ADD_SEARCH_CARD, REMOVE_SEARCH_CARD, OPEN_POP_UP, SET_MESSAGE } from '../../../constant/action';
import { DEFAULT_CARD_COUNT, VP, DEFINITION, MAX_CARDS_TO_DISPLAY, ESS } from '../../../constant/text';
import { getEssSearchById } from '../../../api/ess';
import { isPredicare, getAssignedProduct } from '../../util';
import { useMount } from '../../common/useMount';
import InfoBox from '../../common/infoBox';
import { EssCards } from '../../../types/EssCards';
import { VPCards } from '../../../types/VPCards';
import { DefinitionCards } from '../../../types/DefinitionCards';

const Search = props => {
    const { t } = props;
    const { dispatch, state: { card: { searchCards = [] }, popup: { show: popupVisible = false } } }:
        { dispatch: any, state: { popup: { show: boolean }, card: { searchCards: EssCards[] | VPCards[] | DefinitionCards[] }}} = useStore();
    const mounted = useMount();
    const addSearchCard = useCallback(payload => dispatch({ type: ADD_SEARCH_CARD, payload }), [dispatch]);
    const removeSearchCard = useCallback(payload => dispatch({ type: REMOVE_SEARCH_CARD, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const product = getAssignedProduct();

    const getCardComponent = data => {
        switch (data.cardType) {
            case VP:
                return <VpCard data={data} onClose={id => removeSearchCard({ id, key: `${id}_search_cards` })} />;
            case DEFINITION:
                return <DefinitionCard data={data} onClose={id => removeSearchCard({ id, key: `${id}_search_cards` })} />;
            default:
                return (
                    <EssCard
                        data={data}
                        onClose={id => removeSearchCard({ id, key: `${id}_search_cards` })}
                        addEssIdToSearchCard={addEssIdToSearchCard} />
);
        }
    };

    const addEssIdToSearchCard = async (essId: string) => {
        const ess = await getEssSearchById(essId);

        if (mounted.current) {
            if (ess.error) {
                setMessage(ess.error);
            } else {
                ess.cardType = ESS;
                addToSearchCards(ess);
            }
        }
    };

    const addToSearchCards = obj => {
        if (obj.id) {
            if (searchCards.length < MAX_CARDS_TO_DISPLAY) {
                addSearchCard(obj);
            } else {
                openPopUp({
                    component: <ConfirmBox
                        value="CLOSE_CHOICE_DIALOG"
                        chooseCardToCloseButton={true}
                        data={obj}
                    />
                });
            }
        }
    };

    return (
        <div className="search">
            {
                !product.noProduct && !isEmpty(product) && (
                <>
                    <SearchForm
                        onData={addToSearchCards}
                        showProductSelector={isPredicare()}
                        showSearchBy={true}
                        essTypeId={product.typeId!}
                        version={product.version!}
                        languageId={product.language.languageId!}
                        />
                    <div className="grid searchCardsGrid">
                        {
                                map(searchCards, data => (
                                    <Card
                                        key={Math.random()}
                                        count={(searchCards.length < DEFAULT_CARD_COUNT) ? searchCards.length : DEFAULT_CARD_COUNT}
                                        component={() => getCardComponent(data)}
                                        />
                                    ))
                            }
                    </div>
                </>
                  )}
            {
                product.noProduct && (

                <InfoBox title={t('NO_AVAILABLE_VERSIONS')} />

                )
            }
            {
               isEmpty(product) && !popupVisible && (

               <InfoBox title={t('REFRESH_PAGE')} />

                )
            }

        </div>
    );
};

export default withTranslation()(Search);
