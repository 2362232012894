import React, { useCallback, useState, useEffect } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import Dropdown from '../common/dropdown';
import { Title, FieldErrorText } from '../common/text';
import { getAccountUsers, getUserStartPage, isValidLicensedUser } from '../util';
import { SIZE, EMPTY_STRING, ERROR } from '../../constant/text';
import { PRIMARY } from '../../constant/color';
import {
    SET_MESSAGE,
    CLOSE_POP_UP,
} from '../../constant/action';
import { useStore } from '../../store';
import { withTranslation } from '../common/translation';
import Loader from '../common/loader';
import Button from '../common/button';
import PopUpCloseIcon from '../common/popUpCloseIcon';
import { getAccountUserId } from '../../api/util';
import { useLoginManager } from '../../UseLoginManager';

const AccountUserChanger = props => {
    const { t } = props;
    const [id, setId] = useState(getAccountUserId());
    const [loader, setLoader] = useState();
    const [disabled, setDisabled] = useState(false);
    const [dropdownError, setDropdownError] = useState(EMPTY_STRING);
    // eslint-disable-next-line no-unused-vars
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const history = useHistory();

    const { changeAccount } = useLoginManager();

    useEffect(() => {
        const subscription = defer(() => { }).subscribe(() => setId(getAccountUserId()));

        return () => subscription.unsubscribe();
    }, [setId]);

    const getAccountUsersForDropdown = () => {
        const accountUsers = getAccountUsers();
        const accountUsersForDropdown = [];
        if (accountUsers && !isEmpty(accountUsers)) {
            accountUsers.forEach(accountUser => {
                const { accountName, userrole, id: userId } = accountUser;
                accountUsersForDropdown.push({ label: `${t(accountName)} (${t(userrole.description)})`, value: userId });
            });
        }

        return accountUsersForDropdown;
    };

    const onChangeUserId = val => {
        setId(val);
        if (isValidLicensedUser(val)) {
            setDisabled(false);
            setDropdownError(EMPTY_STRING);
        } else {
            setDisabled(true);
            setDropdownError('LOGIN_INVALID_LICENSE');
        }
    };

    const onSubmit = () => {
        setLoader(true);
        setDisabled(true);
        changeAccount(id, e => {
            setMessage(e);
            setLoader(false);
            setDisabled(false);
        }, () => {
            setLoader(false);
            setDisabled(false);
            closePopUp();
            history.push(getUserStartPage());
        });
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader}/>
            <div className="popUpBody">
                <Title value={t('CHANGE_ACCOUNT')} size={SIZE.L}/>
                {loader && <Loader/>}
                <Dropdown
                    options={getAccountUsersForDropdown()}
                    value={(id && !isEmpty(id)) ? id : getAccountUserId()}
                    className="changeAccount"
                    keyPrefix="changeAccount"
                    onChange={onChangeUserId}
                />
                <FieldErrorText value={t(dropdownError)} type={ERROR} />
                <div className="buttonField-center">
                    <Button
                        value={t('OK')}
                        onClick={onSubmit}
                        btnType={PRIMARY}
                        disabled={disabled}
                    />
                    <Button
                        value={t('CANCEL')}
                        onClick={() => { closePopUp(); }}
                        btnType={PRIMARY}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(AccountUserChanger);
