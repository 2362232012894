import React, { useEffect, useCallback, useState } from 'react';
import { defer } from 'rxjs';
import filter from 'lodash/filter';
import { withTranslation } from '../../../../common/translation';
import PopUpCloseIcon from '../../../../common/popUpCloseIcon';
import Button from '../../../../common/button';
import Dropdown from '../../../../common/dropdown';
import TextField from '../../../../common/textField';
import CheckBox from '../../../../common/checkbox';
import RadioButtons from '../../../../common/radioButtons';
import ConfirmBox from '../../../../common/confirmBox';
import AddNewProduct from './addnewproduct';
import AddNewLanguage from './addnewlanguage';
import { AddIcon } from '../../../../common/icon';
import { getInitiateNewVersion, createNewVersion } from '../../../../../api/ess';
import { useStore } from '../../../../../store';
import { SET_MESSAGE, INITIATE_NEW_VERSION, CLOSE_POP_UP, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../../../constant/action';
import { useMount } from '../../../../common/useMount';

const CreateVersions = ({ fetchPkgs, handleCreateVersionPopup, t }) => {
    const {
        state: {
            manageVersions: { initVersions }
        },
        dispatch } = useStore();
    const mounted = useMount();

    const [data, setData] = useState({
        baseVp: false,
        basedOn: false,
        typeId: 1,
        languageId: 1,
        version: ''
    });
    const [vpBasedOn, setVpBasedOn] = useState('true');

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setNewVersionData = useCallback(payload => dispatch({ type: INITIATE_NEW_VERSION, payload }), [dispatch]);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    const fetchInitiate = () => {
        clearMessage();
        getInitiateNewVersion().then(response => {
            if (mounted.current) {
                if (response.error) {
                    setMessage(response.error);
                } else {
                    setNewVersionData(response);
                }
            }
        });
    };

    useEffect(() => {
        const subscription = defer(() => fetchInitiate()).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const handleData = (key, val) => {
        setData({ ...data, [key]: val });
    };

    const handleVersionCreation = async () => {
        const version = await createNewVersion(data);
        if (mounted.current && version) {
            fetchPkgs();
        }
    };

    const handleConfimBox = () => {
        const language = filter(initVersions.languages, item => item.value === data.languageId)[0].label;
        openPopUp({
            component: <ConfirmBox
                value={'CREATE_VERSION_MODAL'}
                version={data.version}
                language={language}
                onConfirm={handleVersionCreation} />
        });
    };

    const handleAddNewProduct = () => {
        openPopUp({
            component: <AddNewProduct handleCreateVersionPopup={handleCreateVersionPopup}/>
        });
    };

    const handleAddNewLanguage = () => {
        openPopUp({
            component: <AddNewLanguage handleCreateVersionPopup={handleCreateVersionPopup}/>
        });
    };

    return (
        <div className="ongoingVersion card">
            <div className="ongoingtab">
                {t('CREATE_NEW_VERSION')}
                <PopUpCloseIcon />
            </div>
            <div className="grid">
                <div className="col_2">{t('PRODUCTS')}</div>
                <div className="col_2">
                    <Dropdown
                        options={initVersions.types}
                        value={data.typeId}
                        keyPrefix="productList"
                        onChange={e => handleData('typeId', e)}
                    />
                    <AddIcon onClick={handleAddNewProduct}/>
                </div>
                <div className="col_2">{t('VERSION_NAME')}</div>
                <div className="col_2">
                    <TextField value={data.version} onChange={e => handleData('version', e)} />
                </div>
                <div className="col_2">{t('LANGUAGE')}</div>
                <div className="col_2">
                    <Dropdown
                        options={initVersions.languages}
                        value={data.languageId}
                        keyPrefix="productList"
                        onChange={e => handleData('languageId', e)}
                    />
                    <AddIcon onClick={handleAddNewLanguage}/>
                </div>
            </div>
            <CheckBox value="BASE_ON_EXISTING_VERSION" checked={data.basedOn} onChange={e => handleData('basedOn', e)} />
            {data.basedOn && (
                <div className="grid">
                    <div className="col_2">{t('PRODUCTS')}</div>
                    <div className="col_2">
                        <Dropdown
                            options={initVersions.types}
                            keyPrefix="productList"
                        />
                    </div>
                    <div className="col_2">{t('VERSION_NAME')}</div>
                    <div className="col_2">
                        <TextField />
                    </div>
                    <div className="col_2">{t('LANGUAGE')}</div>
                    <div className="col_2">
                        <Dropdown
                            options={initVersions.languages}
                            keyPrefix="productList"
                        />
                    </div>
                    <div className="horizontalDivider" />
                    {t('VITAL_SIGN')}:
                    <RadioButtons
                        name={'VP'}
                        value={vpBasedOn}
                        options={[
                            { label: 'EMPTY_VERSION', value: 'false' },
                            { label: 'BASE_ON_EXISTING_VERSION', value: 'true' }]}
                        onItemClick={setVpBasedOn}
                    />
                </div>
            )}
            <div className="grid">
                <div className="col_2" ><Button onClick={closePopUp} value={t('CANCEL')} /></div>
                <div className="col_2" ><Button onClick={handleConfimBox} disabled={!data.version.length} value={t('CREATE_VERSION')} /></div>
            </div>
        </div>
    );
};

export default withTranslation()(CreateVersions);
