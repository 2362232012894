import React, { useEffect, useState } from 'react';
import { defer } from 'rxjs';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { withTranslation } from '../../../../common/translation';
import { getVpPackageById } from '../../../../../api/vp';

const VitalSign = props => {
    const { vpPackageId, setNewVpTypeId, isCurrentDetailUpdated, currentDetail } = props;
    const [currentVpType, setCurrentVpType] = useState({});

    const fetchVpPackage = async () => {
        const data = await getVpPackageById(vpPackageId);
        setCurrentVpType(filter(data.vpheaders, item => item.vptypes === data.lastSavedVpheadersType)[0]);
        setNewVpTypeId(data.lastSavedVpheadersType);
    };

    useEffect(() => {
        let subscription;
        if (!isCurrentDetailUpdated) {
            subscription = defer(() => fetchVpPackage()).subscribe();
        } else {
            subscription = defer(() => { }).subscribe(setCurrentVpType(currentDetail));
        }

        return () => subscription.unsubscribe();
    }, [currentDetail]);

    return (
        <div>
            {
                currentVpType && sortBy(currentVpType.measurevalues, 'sortnumber').map(item => (
                    <div key={item.id} className="card">
                        {`${item.description} (${item.min}${(item.unit && item.unit !== null) ? `/${item.unit}` : ''} - ${item.max}${(item.unit && item.unit !== null) ? `/${item.unit}` : ''}) [${item.datatype}]`}
                    </div>
                    ))
            }
        </div>
    );
};

export default withTranslation()(VitalSign);
