import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../common/translation';
import { Title } from '../../common/text';
import { SIZE, EMPTY_STRING } from '../../../constant/text';
import { SET_MESSAGE, OPEN_POP_UP, SET_USERS, CLEAR_MESSAGE } from '../../../constant/action';
import { Table, Thead, Tbody, Th, Tr, Td } from '../../common/table';
import { getUsersForAccount, deleteUser } from '../../../api/user';
import { useStore } from '../../../store';
import { ReadIcon, CloseIcon, EditIcon, DeleteIcon } from '../../common/icon';
import Loader from '../../common/loader';
import EditUser from './editUser';
import AddUser from './addUser';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import { errorMessage } from '../../../api/util';
import { useMount } from '../../common/useMount';
import ConfirmBox from '../../common/confirmBox';

const Users = props => {
    const { t, node = {} } = props;
    const mounted = useMount();
    const { id: accountId } = node;
    const [loader, setLoader] = useState(false);
    const { dispatch, state: { users = [] } } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setUsers = useCallback(payload => dispatch({ type: SET_USERS, payload }), [dispatch]);

    const fetchUsers = async () => {
        setLoader(true);
        clearMessage();
        const users = await getUsersForAccount(accountId);
        if (mounted.current) {
            setLoader(false);
            if (users.error) {
                setMessage(users.error);
            } else {
                setUsers(users);
            }
        }
    };

    useEffect(() => {
        const subscription = defer(() => fetchUsers()).subscribe();

        return () => subscription.unsubscribe();
    }, [accountId]);

    const addUser = () => {
        openPopUp({ component: <AddUser node={node} /> });
    };

    const editUser = user => {
        openPopUp({ component: <EditUser data={user} /> });
    };

    const removeUser = async user => {
        setLoader(true);
        clearMessage();
        const deleted = await deleteUser({ id: user.id, accountId, role: { id: user.role.id } });
        if (mounted.current) {
            setLoader(false);
            if (deleted) {
                await fetchUsers();
            } else {
                setMessage(errorMessage(['BAD_REQUEST']));
            }
        }
    };

    const handleConfirmDelete = user => {
        openPopUp({
            component: <ConfirmBox
                value={'REMOVE_USER_CONFIRM'}
                onConfirm={async () => removeUser(user)} />
        });
    };

    return (
        <div className="users loadable">
            <Title value={t('USERS')} size={SIZE.L} />
            {loader && <Loader />}
            <Button btnType={PRIMARY} value={t('ADD_USER')} onClick={addUser} />
            {
                isEmpty(users) && <div className="notFound">{t('NO_USERS_FOUND_FOR_ACCOUNT')}</div>
            }
            {
                !isEmpty(users) && (
                    <Table className={'striped-horizontally'}>
                        <Thead>
                            <Tr>
                                <Th>{t('NAME')}</Th>
                                <Th>{t('USERNAME')}</Th>
                                <Th>{t('USER_ROLE')}</Th>
                                <Th>{t('ACTIVE')}</Th>
                                <Th>{t('LAST_LOGIN')}</Th>
                                <Th>{EMPTY_STRING}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                users.map(user => {
                                    const { id, name, username, role: { description: userRole }, active = false, lastLoginTime } = user;

                                    return (
                                        <Tr key={`${id}-${userRole}-${active}`}>
                                            <Td>{name}</Td>
                                            <Td>{username}</Td>
                                            <Td>{t(userRole)}</Td>
                                            <Td>{active ? <ReadIcon toolTip={t('ACTIVE')} /> : <CloseIcon toolTip={t('NOT_ACTIVE')} />}</Td>
                                            <Td>{lastLoginTime !== null ? lastLoginTime : EMPTY_STRING}</Td>
                                            <Td>
                                                <div className="icons">
                                                    <EditIcon toolTip={t('EDIT')} onClick={() => editUser(user)} />
                                                    <DeleteIcon toolTip={t('DELETE')} onClick={() => handleConfirmDelete(user)} />
                                                </div>
                                            </Td>
                                        </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                )
            }
        </div>
    );
};

export default withTranslation()(Users);
