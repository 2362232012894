import React, { useEffect, useCallback, useState } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../common/translation';
import { Title } from '../../common/text';
import { isPredicareRegion, isPredicare, isLocalAdmin, isCentralAdmin, convertObjectsToDropdownList, getAccountId } from '../../util';
import { useStore } from '../../../store';
import { SET_MESSAGE, SET_SELECTED_ACCOUNT_NODE, OPEN_POP_UP, SET_ACCOUNTS, CLEAR_MESSAGE } from '../../../constant/action';
import { getAccounts, getTreeChildAccounts } from '../../../api/user';
import Dropdown from '../../common/dropdown';
import { SIZE, DROPDOWN_SELECT_TEXT_VALUE, TREE_NODE_TYPE_NORMAL, TREE_NODE_TYPE_DELETE } from '../../../constant/text';
import Tree from './tree';
import Loader from '../../common/loader';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import SaveAccount from './saveAccount';
import { useMount } from '../../common/useMount';

const Accounts = props => {
    const { t } = props;
    const mounted = useMount();
    const [loader, setLoader] = useState(false);
    const { dispatch, state: { accounts = {} } } = useStore();
    const { regions = [], rootId = DROPDOWN_SELECT_TEXT_VALUE, nodes = {} } = accounts;
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setSelectedAccountNode = useCallback(payload => dispatch({ type: SET_SELECTED_ACCOUNT_NODE, payload }), [dispatch]);
    const setAccounts = useCallback(payload => dispatch({ type: SET_ACCOUNTS, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    useEffect(() => {
        const fetchAccount = async () => {
            clearData();
            if (isPredicare()) {
                setLoader(true);
                clearMessage();
                const regs = await getAccounts();
                if (mounted.current) {
                    setLoader(false);
                    if (regs.error) {
                        setMessage(regs.error);
                    } else {
                        const accs = {
                            regions: regs,
                            nodes: {}
                        };
                        if (rootId) {
                            accs.rootId = rootId;
                        }
                        if (rootId && rootId !== DROPDOWN_SELECT_TEXT_VALUE) {
                            const childAccs = await getTreeChildAccs(rootId);
                            if (childAccs) {
                                accs.nodes = childAccs;
                            }
                        }
                        if (mounted.current) {
                            setAccounts(accs);
                        }
                    }
                }
            } else if (isLocalAdmin() || isCentralAdmin()) {
                setLoader(true);
                clearMessage();
                const rootId = getAccountId();
                const childAccs = await getTreeChildAccs(rootId);
                if (mounted.current) {
                    setLoader(false);
                    if (childAccs.error) {
                        setMessage(childAccs.error);
                    } else {
                        setAccounts({ nodes: childAccs, rootId });
                        const node = childAccs[rootId];
                        setSelectedAccountNode(node);
                    }
                }
            }
        };
        const subscription = defer(() => fetchAccount()).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const clearData = () => {
        setSelectedAccountNode({});
        setAccounts({ nodes: {} });
    };

    const getTreeChildAccs = async accId => {
        setLoader(true);
        clearMessage();
        const childAccs = await getTreeChildAccounts(accId);
        if (mounted.current) {
            setLoader(false);
            if (childAccs.error) {
                setMessage(childAccs.error);

                return false;
            }
        }

        return childAccs;
    };

    const onRegionChange = async rootId => {
        setAccounts({ rootId });
        clearData();
        if (rootId !== DROPDOWN_SELECT_TEXT_VALUE) {
            setLoader(true);
            const childAccs = await getTreeChildAccs(rootId);
            if (mounted.current) {
                setLoader(false);
                if (childAccs) {
                    setAccounts({ nodes: childAccs });
                    const node = childAccs[rootId];
                    setSelectedAccountNode(node);
                }
            }
        }
    };

    const onNodeSelect = node => {
        setSelectedAccountNode(node);
    };

    const addAccount = async () => {
        openPopUp({ component: <SaveAccount title="ADD_CUSTOMER" /> });
    };

    return (
        <div className="accounts loadable">
            <Title value={t('ACCOUNTS')} size={SIZE.L} />
            {loader && <Loader />}
            {isPredicare() && <Button btnType={PRIMARY} value={t('ADD_CUSTOMER')} onClick={addAccount} />}
            <div className="region">
                {
                    !isEmpty(regions) && isPredicare() && (
                        <Dropdown
                            keyPrefix="customer"
                            size={SIZE.M}
                            options={convertObjectsToDropdownList(regions, 'id', 'name')}
                            onChange={onRegionChange}
                            value={rootId}
                            selectLabel="SELECT_CUSTOMER"
                        />
                    )
                }
            </div>
            <div className="accountsTree">
                {
                    !isEmpty(nodes) && !isPredicareRegion(nodes[rootId]) && (
                        <div className="organizationTree">
                            <Tree nodes={nodes} onNodeSelect={onNodeSelect} type={isPredicare() ? TREE_NODE_TYPE_DELETE : TREE_NODE_TYPE_NORMAL} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default withTranslation()(Accounts);
