import React, { useCallback } from 'react';
import PopUpCloseIcon from '../../../common/popUpCloseIcon';
import { Title } from '../../../common/text';
import Button from '../../../common/button';
import { withTranslation } from '../../../common/translation';
import { CLOSE_POP_UP } from '../../../../constant/action';
import { useStore } from '../../../../store';

const ChangeEssVersionPopUp = props => {
    const { t, essitem, updateVersion } = props;
    const { dispatch } = useStore();
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);

    const onUpdateVersion = () => {
        updateVersion(essitem.id);
        closePopUp();
    };

    return (
        <div className="popUpComponent">
            <PopUpCloseIcon/>
            <div className="popUpBody">
                <Title value={t('SET_ACTIVE_VERSION', { version: essitem.version.toString() })}/>
                <div className="buttonField-right">
                    <Button
                        value={t('CANCEL')}
                        onClick={closePopUp}
                        btnType={'btn-secondary'}
                        disabled={false}
                    />
                    <Button
                        value={t('OK')}
                        onClick={onUpdateVersion}
                        btnType={'btn-primary'}
                        disabled={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(ChangeEssVersionPopUp);
