import React from 'react';
import { TextValue } from './text';
import { withTranslation } from './translation';
import { EMPTY_STRING, CHECKBOX_TICK } from '../../constant/text';

const Checkbox = props => {
    const { t, checked = false, onChange, value = EMPTY_STRING, disabled = false, additionalStyle = {}} = props;

    return (
        <div className="radioField" style={additionalStyle}>
            <div className={`checkbox ${disabled} ${checked ? 'checked' : 'notChecked'}`} onClick={disabled ? () => {} : () => onChange(!checked)}>{checked ? CHECKBOX_TICK : EMPTY_STRING}</div>
            <TextValue value={t(value)}/>
        </div>

    );
};

export default withTranslation()(Checkbox);
