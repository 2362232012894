const BASE_URL = process.env.REACT_APP_API_BASEURL;

// ess
const ESS = `${BASE_URL}/ess`;
export const GET_ESS_LIST = `${ESS}/list`;
export const GET_ESS_DATA_BY_SEARCHPHRASE = `${ESS}/searchpackage`;
export const GET_ESS_SEARCH_DATA_BY_ID = `${ESS}/id`;
export const GET_ESS_CLASSIFICATION = `${ESS}/[ess_package_id]/essclassification`;
export const GET_FIRST_ESS_NUMBR_DETAIL_IN_PACKAGE = `${ESS}/firstess`;
export const GET_ESS_NUMBER_DETAIL_BY_PACKAGE_AND_NUMBER = `${ESS}/essnumber`;
export const GET_ADJACENT_ESS_NUMBER_IDS_BY_PACKAGE_AND_ESS_NUMBER_ID = `${ESS}/adjacentesses`;
export const SAVE_ESS = `${ESS}/saveitem`;
export const GET_ESS_CODE_TYPES = `${ESS}/codetypes`;
export const GET_DEFAULT_PROCESS_ACTIONS = `${ESS}/processactions`;

// vp
const VP = `${BASE_URL}/vp`;
export const GET_VP_PACKAGE_BY_ID = `${VP}/package`;
export const GET_VP_TYPES = `${VP}/types`;
export const GET_VP_TYPES_BY_VP_PACKAGE_ID = `${VP}/vpypes`;
export const GET_VP_BY_VP_TYPE_ID = `${VP}/vpheaderbytype`;
export const GET_VS_PACKAGES = `${VP}/lists`;
export const GET_VP_PACKAGE_BY_VERSION_LANGUAGE = `${VP}/search`;
export const GET_VP_LANGUAGES_FOR_VERSION = `${VP}/languages`;
export const GET_ALL_VERSIONS = `${VP}/versions`;
export const GET_ADJACENT_VP_TYPE_IDS_BY_VP_PACKAGE_AND_VP_TYPE_DETAIL_ID = `${VP}/adjacentvpheaders`;
export const GET_VP_TYPE_DETAIL_BY_ID = `${VP}/headerid`;
export const GET_VP_SEARCH_PHRASES = `${VP}/searchphrases`;
export const SAVE_VP_SEARCH_PHRASE = `${VP}/savesearchphrase`;
export const DELETE_VP_SEARCH_PHRASE = `${VP}/deletesearchphrase`;
export const GET_VP_GROUP_RULES = `${VP}/grouprules`;
export const SAVE_VP_GROUP_RULE = `${VP}/savegrouprule`;
export const DELETE_VP_GROUP_RULE = `${VP}/deletegrouprule`;
export const SAVE_VP_TEXT_RULE = `${VP}/savenominal`;
export const GET_VP_TEXT_RULES = `${VP}/nominals`;
export const DELETE_VP_TEXT_RULE = `${VP}/deletenominal`;
export const SAVE_VP_ARGUMENT = `${VP}/saveargument`;
export const GET_VP_ARGUMENTS = `${VP}/arguments`;
export const DELETE_VP_ARGUMENT = `${VP}/deleteargument`;

// definition
const DEFINITION = `${BASE_URL}/worddefinition`;
export const GET_DEFINITION_LIST_BY_ID = `${DEFINITION}/list`;

// journal
const JOURNAL = `${BASE_URL}/journal`;
export const GET_JOURNALS_FROM_PACKAGEID = `${JOURNAL}/getfrompackage`;
export const GET_JOURNALAUDIT_FROM_PACKAGEID = `${JOURNAL}/getfrompackageaudit`;

// package
const PACKAGE = `${BASE_URL}/package`;
export const DELETE_ESS_PACKAGE = `${PACKAGE}/deletepackage`;
export const CREATE_LOCAL_ESS_PACKAGE = `${PACKAGE}/createlocalpackage`;
export const GET_DEFAULT_PRODUCTS_FOR_USER = `${PACKAGE}/getdefault`;
export const GET_PRODUCTS_FOR_ACCOUNT = `${PACKAGE}/types`;
export const GET_ESS_VERSIONS_FOR_ESS_TYPE_ID = `${PACKAGE}/versions`;
export const GET_ESS_LANGUAGES_FOR_ESS_TYPE_ID_AND_ESS_VERSION = `${PACKAGE}/languages`;
export const GET_ESS_PACKAGE_BY_TYPE_VERSION_LANGUAGE = `${PACKAGE}/search`;
export const SET_DEFAULT_LOCAL_PACKAGE = `${PACKAGE}/setdefaultlocal`;
export const GET_ESS_PACKAGES = `${PACKAGE}/lists`;
export const GET_ESS_PUBLISHED_LOCAL = `${PACKAGE}/listslocal`;
export const GET_ESS_ONGOING_LOCAL = `${PACKAGE}/notreadylocal`;
export const INIT_NEW_VERSION = `${PACKAGE}/initnew`;
export const CREATE_NEW_VERSION = `${PACKAGE}/createnew`;
export const PUBLISH_ONGOING_VERSION = `${PACKAGE}/publishpackage`;
export const ADD_PACKAGE_TYPE = `${PACKAGE}/addpackagetype`;
export const ADD_PACKAGE_LANGUAGE = `${PACKAGE}/addpackagelanguage`;

// biframework user
const USER = `${BASE_URL}/biframework/user`;
export const CHANGE_ACCOUNT_USER = `${USER}/changeaccount`;
export const CHANGE_PASSWORD = `${USER}/changepassword`;
export const CHANGE_PASSWORD_BY_OTP = `${USER}/newpwonreset`;
export const SEND_OTC = `${USER}/resetpassword`;
export const CREATE_USER = `${USER}/create`;
export const SEARCH_USER = `${USER}/search`;
export const GET_ELEARNING_LINK = `${USER}/getrettselearningautologin`;
export const GET_USERS_FOR_ACCOUNT = `${USER}/list`;
export const SAVE_USER = `${USER}/savewithurl`;
export const DELETE_USER = `${USER}/delete`;
export const GET_ACCOUNTS_ROLES_FOR_USERNAME = `${USER}/username`;
export const SET_LANGUAGE = `${USER}/setlanguage`;

// biframework language
const LANGUAGE = `${BASE_URL}/biframework/language`;
export const GET_LANGUAGES = `${LANGUAGE}/alllanguages`;

// biframework message
const MESSAGE = `${BASE_URL}/biframework/message`;
export const GET_NOTIFICATIONS = `${MESSAGE}/list`;
export const NOTIFICATION_READ = `${MESSAGE}/messageread`;
export const SET_NOTIFICATION_MESSAGE = `${MESSAGE}/createmessage`;

// biframework account
const ACCOUNT = `${BASE_URL}/biframework/account`;
export const GET_CATEGORY_TYPES = `${ACCOUNT}/categorytypes`;
export const GET_USER_EMAILS = `${ACCOUNT}/getalluseremails`;
export const GET_ADMIN_EMAILS = `${ACCOUNT}/getadminemails`;
export const GET_ALL_ADDRESSES = `${ACCOUNT}/getalladdresses`;
export const GET_ACCOUNTS = `${ACCOUNT}/list`;
export const GET_ACCOUNT = `${ACCOUNT}/id`;
export const GET_ACCOUNT_TYPES = `${ACCOUNT}/accounttypes`;
export const SAVE_ACCOUNT = `${ACCOUNT}/save`;
export const DELETE_ACCOUNT = `${ACCOUNT}/delete`;
export const GET_CHILD_ACCOUNTS = `${ACCOUNT}/childlist`;
export const GET_TREE_CHILD_ACCOUNTS = `${ACCOUNT}/treelist`;

// biframework license
const LICENSE = `${BASE_URL}/biframework/license`;
export const GET_LICENSES_FOR_ACCOUNT = `${LICENSE}/list`;
export const DELETE_LICENSE = `${LICENSE}/delete`;
export const SAVE_LICENSE = `${LICENSE}/save`;
export const GET_LICENSE_TYPES = `${LICENSE}/licensetypes`;

// biframework xml
const BIFRAMEWORK_XML = `${BASE_URL}/biframework/xml`;
export const GET_SYSTEM_LANGUAGE_XML = `${BIFRAMEWORK_XML}/downloadlanguagetexts`;

// biframework file
const BIFRAMEWORK_FILE = `${BASE_URL}/biframework/file`;
export const DOWNLOAD_JOURNALS_BY_ID = `${BIFRAMEWORK_FILE}/download`;

// overview
const OVERVIEW = `${BASE_URL}/overview`;
export const GET_OVERVIEW = `${OVERVIEW}`;
export const GET_DEPARTMENT_USER = `${OVERVIEW}/getdepartmentuser`;

// pdf
const PDF = `${BASE_URL}/pdf`;
export const GET_ESS_PDF_COMPPACK = `${PDF}/comppack`;
export const GET_ESS_CHANGELOG_PDF = `${PDF}/comppackprevious`;
export const GET_ESS_PDF = `${PDF}/package`;
export const GET_VP_PDF = `${PDF}/vitalsigns`;
export const GET_DEFINITION_PDF = `${PDF}/worddefinitions`;
export const GET_ESS_INTERVAL_PDF = `${PDF}/essinterval`;
export const GET_ESS_AUDIT = `${PDF}/essaudit`;
export const GET_VP_AUDIT = `${PDF}/vpaudit`;
export const GET_DEFINITIONLIST_AUDIT = `${PDF}/definitionlistaudit`;
export const GET_ESSLOCALCHANGES_AUDIT = `${PDF}/localesschangesaudit`;

// xml
const XML = `${BASE_URL}/xml`;
export const GET_ESS_XML = `${XML}/download`;
export const GET_VP_XML = `${XML}/downloadvitalsignspackage`;
export const GET_VP_FORMAT2_XML = `${XML}/downloadvitalsignsarchetype`;
export const GET_DEFINITION_XML = `${XML}/downloadworddefinitions`;
export const GET_SCHEMA_XSD = `${XML}/schema`;
