import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { defer } from 'rxjs';
import { Title } from '../../../../common/text';
import TextField from '../../../../common/textField';
import Button from '../../../../common/button';
import { withTranslation } from '../../../../common/translation';
import InfoBox from '../../../../common/infoBox';
import { getArguments, saveArgument, deleteArgument } from '../../../../../api/vp';
import { getEssCodeTypes } from '../../../../../api/ess';
import { EditIcon, DeleteIcon } from '../../../../common/icon';
import { PRIMARY, SECONDARY } from '../../../../../constant/color';
import { EMPTY_STRING, DROPDOWN_SELECT_TEXT_VALUE } from '../../../../../constant/text';
import ConfirmBox from '../../../../common/confirmBox';
import { warningMessage } from '../../../../../api/util';
import { convertObjectsToDropdownList } from '../../../../util';
import { useStore } from '../../../../../store';
import { SET_MESSAGE, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../../../constant/action';
import Loader from '../../../../common/loader';
import Dropdown from '../../../../common/dropdown';
import { useMount } from '../../../../common/useMount';

const VpArgument = props => {
    const { t } = props;
    const [essCodeTypes, setEssCodeTypes] = useState([]);
    const mounted = useMount();
    const [vpArguments, setVpArguments] = useState([]);
    const [vpArgument, setVpArgument] = useState({ essCode: { codeType: Number(DROPDOWN_SELECT_TEXT_VALUE) } });
    const { id, description = EMPTY_STRING, essCode = {} } = vpArgument;
    const { codeType: essCodeTypeId, code: essCodeNumber } = essCode !== null ? essCode : {};
    const [addDisabled, setAddDisabled] = useState(true);
    const [loader, setLoader] = useState(false);
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    const fetchVpArguments = async () => {
        setLoader(true);
        const argList = await getArguments();
        setLoader(false);
        if (argList.error) {
            setMessage(argList.error);
        } else {
            setVpArguments(argList);
        }
    };

    useEffect(() => {
        const subscription = defer(() => {
            fetchVpArguments();
            getEssCodeTypes().then(data => {
                setEssCodeTypes(data);
            });
        }).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const setVpArgumentDescription = description => {
        clearMessage();
        if (isEmpty(description)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setVpArgument({ ...vpArgument, description });
    };

    const editVpArgument = async arg => {
        clearMessage();
        setAddDisabled(true);
        setVpArgument(arg);
    };

    const deleteVpArgument = argId => {
        openPopUp({ component: <ConfirmBox value="REMOVE_ARGUMENT_CONFIRM" onConfirm={() => removeVpArgument(argId)} /> });
    };

    const removeVpArgument = async argId => {
        clearMessage();
        setLoader(true);
        const savedEss = await deleteArgument(argId);
        if (mounted.current) {
            setLoader(false);
            if (savedEss.error) {
                setMessage(savedEss.error);
            } else {
                fetchVpArguments();
            }
        }
    };

    const saveVpArgument = async arg => {
        const { description, essCode } = arg;
        clearMessage();
        if (isEmpty(description) || isEmpty(essCode) || essCode.codeType === Number(DROPDOWN_SELECT_TEXT_VALUE) || isEmpty(essCode.code)) {
            setMessage(warningMessage(['REQUIRED_FIELD']));
        } else {
            setLoader(true);
            const savedEss = await saveArgument(arg);
            if (mounted.current) {
                setLoader(false);
                if (savedEss.error) {
                    setMessage(savedEss.error);
                } else {
                    onCancel();
                    fetchVpArguments();
                }
            }
        }
    };

    const onCancel = () => {
        clearMessage();
        setAddDisabled(true);
        setVpArgument({ essCode: { codeType: Number(DROPDOWN_SELECT_TEXT_VALUE) } });
    };

    const onChangeEssCodeType = codeTypeId => {
        clearMessage();
        if (codeTypeId === Number(DROPDOWN_SELECT_TEXT_VALUE)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setVpArgument({ ...vpArgument, essCode: { ...vpArgument.essCode, codeType: Number(codeTypeId) } });
    };

    const onChangeEssCodeNumber = codeNumber => {
        clearMessage();
        if (isEmpty(codeNumber)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setVpArgument({ ...vpArgument, essCode: { ...vpArgument.essCode, code: codeNumber } });
    };

    return (
        <div className="section separateLines">
            <Title value={t('EDIT_ARGUMENT')} />
            <InfoBox>{t('VP_ARGUMENTS_INFO')}</InfoBox>
            {loader && <Loader />}
            <div className="section inline">
                <TextField
                    placeholder="ARGUMENT_VALUE"
                    value={description}
                    onChange={setVpArgumentDescription}
                />
                <Dropdown
                    options={convertObjectsToDropdownList(essCodeTypes, 'id', 'name')}
                    value={essCodeTypeId}
                    onChange={onChangeEssCodeType}
                    keyPrefix="codeType"
                    selectLabel="SELECT_CODE_TYPE"
                />
                <TextField
                    placeholder="CODE"
                    value={essCodeNumber}
                    onChange={onChangeEssCodeNumber}
                />
                <Button
                    btnType={PRIMARY}
                    value={t(id ? 'UPDATE' : 'ADD')}
                    onClick={() => saveVpArgument(vpArgument)}
                    disabled={addDisabled}
                />
                {id && (
                    <Button
                        btnType={SECONDARY}
                        value={t('CANCEL')}
                        onClick={onCancel}
                    />
                )}
            </div>
            {
                vpArguments.map(item => {
                    const { id, description } = item;

                    return (
                        <div key={id} className="inline">
                            <div className="essCardTitle inline">
                                <div className="leftSpacingInMiddleOfLine">{description}</div>
                            </div>
                            <div className="buttonField-right">
                                <EditIcon className="clickable" onClick={() => editVpArgument(item)} />
                                <DeleteIcon className="clickable" onClick={() => deleteVpArgument(id)} />
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default withTranslation()(VpArgument);
