import { COLOR } from './color';

export const THEME1 = 'LIGHT_THEME';
export const THEME2 = 'DARK_THEME';
export const DEFAULT_THEME = THEME2;
export const THEMES = {
    [THEME1]: {
        accordionTitleBackground: COLOR.DARK_CYAN,
        accordionTitleText: COLOR.WHITE,
        primaryContentText: COLOR.LIGHT_THEME_TEXT_COLOR,
        secondaryContentText: COLOR.LIGHT_THEME_TEXT_COLOR,
        contentBackground: COLOR.WHITE,
        headerText: COLOR.LIGHT_THEME_TEXT_COLOR,
        headerBackground: COLOR.SKY_BLUE,
        headerBorder: COLOR.GREY_4,
        badgeText: COLOR.LIGHT_THEME_TEXT_COLOR,
        badgeBackground: COLOR.WARNING_BACKGROUND,
        footerText: COLOR.LIGHT_THEME_TEXT_COLOR,
        footerBackground: COLOR.SKY_BLUE,
        fieldBorder: COLOR.SKY_BLUE,
        activeFieldBorder: COLOR.DARK_SKY_BLUE,
        fieldText: COLOR.LIGHT_THEME_TEXT_COLOR,
        dropdownBackground: COLOR.WHITE,
        fieldBackground: COLOR.WHITE,
        tableHeaderColor: COLOR.DARK_3,
        tableBorder: COLOR.SKY_BLUE,
        tableTextColor: COLOR.BLACK,
        tableOddBackgroundColor: COLOR.LIGHTGREY,
        tableEvenBackgroundColor: COLOR.GREY_5,
        checkboxTick: COLOR.SKY_BLUE,
        checkboxBorder: COLOR.SKY_BLUE,
        checkboxBackground: COLOR.DARK_2,
        closeButton: COLOR.WARNING,
        linkTextColor: COLOR.DARK_SKY_BLUE,
        linkUnderlineColor: COLOR.BLUE,
        linkHoverTextColor: COLOR.BRIGHT_LINK_BLUE,
        headerLinkTextColor: COLOR.BLUE,
        headerLinkUnderlineColor: COLOR.BLUE,
        menuBackground: COLOR.WHITE,
        menuText: COLOR.LIGHT_THEME_TEXT_COLOR,
        menuBorder: COLOR.SKY_BLUE,
        popupText: COLOR.LIGHT_THEME_TEXT_COLOR,
        popupBackground: COLOR.LIGHT_SKY_BLUE,
        cardBorder: COLOR.LIGHTGREY,
        cardBackground: COLOR.LIGHT_SKY_BLUE,
        cardSectionTitleBackground: COLOR.DARK_4,
        cardSectionTitleText: COLOR.WHITE,
        cardSectionBorder: COLOR.LIGHT_THEME_TEXT_COLOR,
        cardSectionContent: COLOR.WHITE,
        cardSectionContentText: COLOR.GREY_4,
        cardVersionText: COLOR.GREY_4,
        shadow: COLOR.GREY,
        successText: COLOR.SUCCESS,
        successBackground: COLOR.SUCCESS_BACKGROUND,
        errorText: COLOR.ERROR,
        errorBackground: COLOR.ERROR_BACKGROUND,
        warningText: COLOR.WARNING,
        warningBackground: COLOR.WARNING_BACKGROUND,
        infoText: COLOR.INFO,
        info_layer: COLOR.DARK_1,
        info_layer_border: COLOR.DARK_0,
        infoBackground: COLOR.INFO_BACKGROUND,
        defaultButtonBorder: COLOR.BLACK,
        defaultButtonText: COLOR.LIGHT_THEME_TEXT_COLOR,
        defaultButtonBackground: COLOR.GREY,
        primaryButtonBorder: COLOR.BLACK,
        primaryButtonText: COLOR.WHITE,
        primaryButtonBackground: COLOR.SKY_BLUE,
        secondaryButtonBorder: COLOR.BLACK,
        secondaryButtonText: COLOR.LIGHT_THEME_TEXT_COLOR,
        secondaryButtonBackground: COLOR.WHITE,
        buttonFocus: COLOR.GREY_4,
        closeIcon: COLOR.LIGHT_THEME_TEXT_COLOR,
        messageCloseIcon: COLOR.LIGHT_THEME_TEXT_COLOR,
        scrollThumb: COLOR.GREY,
        scrollThumbHover: COLOR.LIGHTGREY,
        sideBarBG: COLOR.LIGHT_SKY_BLUE,
        treeNodeBorder: COLOR.SKY_BLUE,
        redIcon: COLOR.ERROR,
        greenIcon: COLOR.SUCCESS,
        yellowIcon: COLOR.WARNING_BACKGROUND,
        menuIcon: COLOR.ORANGE_2,
        toolTipText: COLOR.INFO,
        toolTipBackground: COLOR.INFO_BACKGROUND,
        loginContentBackground: COLOR.LIGHT_SKY_BLUE,
        loginBorder: COLOR.DARK_3,
        generalProcessAction: COLOR.GREY_4,
        warningButton: COLOR.ORANGE_2,
        stripeDark: COLOR.DARK_3,
        stripeLight: COLOR.DARK_4,
        prevPageClickIcon: COLOR.WHITE,
        arrowIcon: COLOR.WHITE
    },
    [THEME2]: {
        accordionTitleBackground: COLOR.DARK_CYAN,
        accordionTitleText: COLOR.WHITE,
        primaryContentText: COLOR.WHITE,
        secondaryContentText: COLOR.GREY_5,
        contentBackground: COLOR.DARK_2,
        secondaryContentBackground: COLOR.DARK_4,
        headerText: COLOR.WHITE,
        headerBackground: COLOR.DARK_1,
        headerBorder: COLOR.DARK_1,
        badgeText: COLOR.BLACK,
        badgeBackground: COLOR.WARNING_BACKGROUND,
        footerText: COLOR.WHITE,
        footerBackground: COLOR.DARK_2,
        fieldBorder: COLOR.GREY_1,
        activeFieldBorder: COLOR.DARK_SKY_BLUE,
        fieldText: COLOR.WHITE,
        dropdownBackground: COLOR.DARK_4,
        fieldBackground: COLOR.DARK_4,
        tableHeaderColor: COLOR.DARK_4,
        tableBorder: COLOR.DARK_1,
        tableTextColor: COLOR.BLACK,
        tableOddBackgroundColor: COLOR.LIGHTGREY,
        tableEvenBackgroundColor: COLOR.GREY_5,
        checkboxTick: COLOR.WHITE,
        checkboxBorder: COLOR.DARK_SKY_BLUE,
        checkboxBackground: COLOR.DARK_2,
        closeButton: COLOR.WARNING,
        linkTextColor: COLOR.DARK_SKY_BLUE,
        linkUnderlineColor: COLOR.GREY_5,
        linkHoverTextColor: COLOR.BRIGHT_LINK_BLUE,
        headerLinkTextColor: COLOR.GREY_5,
        headerLinkUnderlineColor: COLOR.BLUE,
        menuBackground: COLOR.BLACK,
        menuText: COLOR.WHITE,
        menuBorder: COLOR.SKY_BLUE,
        popupText: COLOR.WHITE,
        popupBackground: COLOR.DARK_2,
        cardBorder: COLOR.DARK_3,
        cardBackground: COLOR.DARK_3,
        cardSectionTitleBackground: COLOR.DARK_4,
        cardSectionTitleText: COLOR.WHITE,
        cardSectionBorder: COLOR.DARK_4,
        cardSectionContent: COLOR.WHITE,
        cardSectionContentText: COLOR.DARK_0,
        cardVersionText: COLOR.GREY_4,
        shadow: COLOR.GREY,
        successText: COLOR.SUCCESS,
        successBackground: COLOR.SUCCESS_BACKGROUND,
        errorText: COLOR.ERROR,
        errorBackground: COLOR.ERROR_BACKGROUND,
        warningText: COLOR.WARNING,
        warningBackground: COLOR.WARNING_BACKGROUND,
        infoText: COLOR.WHITE,
        info_layer: COLOR.DARK_1,
        info_layer_border: COLOR.DARK_0,
        infoBackground: COLOR.DARK_6,
        defaultButtonBorder: COLOR.BLACK,
        defaultButtonText: COLOR.WHITE,
        defaultButtonBackground: COLOR.DARK_5,
        primaryButtonBorder: COLOR.BLACK,
        primaryButtonText: COLOR.WHITE,
        primaryButtonBackground: COLOR.BLUE,
        secondaryButtonBorder: COLOR.BLACK,
        secondaryButtonText: COLOR.BLACK,
        secondaryButtonBackground: COLOR.DARK_2,
        buttonFocus: COLOR.WHITE,
        closeIcon: COLOR.WHITE,
        messageCloseIcon: COLOR.BLACK,
        scrollThumb: COLOR.GREY_4,
        scrollThumbHover: COLOR.GREY_3,
        sideBarBG: COLOR.DARK_3,
        treeNodeBorder: COLOR.SKY_BLUE,
        redIcon: COLOR.ERROR,
        greenIcon: COLOR.SUCCESS_BACKGROUND,
        yellowIcon: COLOR.WARNING_BACKGROUND,
        menuIcon: COLOR.ORANGE_2,
        toolTipText: COLOR.WHITE,
        toolTipBackground: COLOR.DARK_4,
        loginContentBackground: COLOR.DARK_3,
        loginBorder: COLOR.DARK_3,
        generalProcessAction: COLOR.GREY_4,
        warningButton: COLOR.ORANGE_2,
        stripeDark: COLOR.DARK_1,
        stripeLight: COLOR.DARK_2,
        prevPageClickIcon: COLOR.WHITE,
        arrowIcon: COLOR.WHITE
    }
};
