/* eslint-disable no-return-await */
import React, { useCallback, useState } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';
import uuid from 'react-uuid';
import { withTranslation } from '../../../common/translation';
import Button from '../../../common/button';
import Dropdown from '../../../common/dropdown';
import ConfirmBox from '../../../common/confirmBox';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../../common/table';
import {
    removeEssPackages,
    getEssPdfCompPack,
    createLocalEssPackageById
} from '../../../../api/ess';
import { useStore } from '../../../../store';
import {
    SET_ESS_EDIT,
    OPEN_POP_UP,
    SET_SELECTED_MANAGE_VERSION,
    SET_MESSAGE, CLEAR_MESSAGE,
} from '../../../../constant/action';
import { MANAGE_VERSIONS_ESSEDIT_PAGE, PUBLISH_ESS } from '../../../../constant/route';
import { ONGOING, EDIT, ESS, LOCAL_LANGUAGE_CODE } from '../../../../constant/text';
import { getVpPackageById } from '../../../../api/vp';
import { isPredicare } from '../../../util';
import { PRIMARY } from '../../../../constant/color';
import { useMount } from '../../../common/useMount';
import { getFromLocal } from '../../../../api/localStorageUtil';
import Loader from '../../../common/loader';

const EssList = props => {
    const { t, ongoingEssList, newEssList, refreshPkgs } = props;
    const {
        state: {
            languageCode: localLangCode = getFromLocal(LOCAL_LANGUAGE_CODE),
        },
        dispatch,
    } = useStore();
    const mounted = useMount();

    const [loader, setLoader] = useState(false);
    const [goToEditPage, setGoToEditPage] = useState(false);
    const [goToPublishForm, setGoToPublishForm] = useState(false);
    const setEssEdit = useCallback(payload => dispatch({ type: SET_ESS_EDIT, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setSelectedVersionData = useCallback(data => dispatch({
        type: SET_SELECTED_MANAGE_VERSION,
        payload: data
    }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    const onChangeLog = async (newPkgId, id, language) => {
        const data = await getEssPdfCompPack(newPkgId, id, language);
        if (data.error) {
            setMessage(data.error);
        } else if (mounted.current) {
            window.open(data);
        }
    };

    const handleConfirmBox = id => {
        openPopUp({ component: <ConfirmBox value={'REMOVE_ESS_CONFIRM'} onConfirm={() => removePackage(id)}/> });
    };

    const removePackage = async id => {
        const data = await removeEssPackages(id);
        if (mounted.current && data) {
            refreshPkgs();
        }
    };

    const editEss = async ess => {
        const { typeId, version, language: { languageId }, id: essPackageId } = ess;
        setEssEdit({
            searchBy: ESS,
            publicationStatus: ONGOING,
            typeId,
            version,
            languageId,
            essPackageId,
            editType: EDIT,
        });
        setGoToEditPage(true);
    };

    const handlePublish = async item => {
        const data = await getVpPackageById(item.vppackageId);
        if (data) {
            setSelectedVersionData({
                selectedVersion: item,
                selectedVp: data,
            });
            setGoToPublishForm(true);
        }
    };

    const getPackagesByLocalLanguage = packages => {
        if (!isEmpty(packages)) {
            return packages.filter(newPackage => newPackage.language.languageCode === localLangCode);
        }

        return [];
    };

    const downloadableEssPackage = getPackagesByLocalLanguage(newEssList);

    const createLocalEssPackage = async essPackageId => {
        setLoader(true);
        clearMessage();
        const localEssPkg = await createLocalEssPackageById(essPackageId);

        if (mounted.current) {
            setLoader(false);
            if (localEssPkg.error) {
                setMessage(localEssPkg.error);
            } else {
                editEss(localEssPkg);
            }
        }
    };

    const downloadEssPackage = async (essPackageId, essVersion) => {
        openPopUp({
            component: (
                <ConfirmBox
                    value={t('VERSION_WILL_BE_MOVED_TO_PROGRESS', { version: essVersion.toString() })}
                    onConfirm={() => createLocalEssPackage(essPackageId)}
                />
            ),
        });
    };

    return (
        <div className="card">
            <div className="title">{isPredicare() ? t('MAKE_NEW_VERSION') : t('LOCAL_VERSIONS_INFO')}</div>
            <Table className={'striped-horizontally'}>
                <Thead>
                    <Tr>
                        <Th>{t('VERSION_NAME')}</Th>
                        <Th/>
                    </Tr>
                </Thead>
                <Tbody>
                    {map(ongoingEssList, item => {
                        const changeLogOptions = map(remove(filter(ongoingEssList, {
                            type: item.type,
                            languageName: item.languageName,
                        }), data => data.version !== item.version), mapItem => ({
                            label: mapItem.version,
                            value: mapItem.id,
                        }));

                        return (
                            <Tr key={uuid()}>
                                <Td>{`${item.type} ${item.version}, ${item.languageName}`}</Td>
                                <Td>
                                    <Button btnType={PRIMARY} value={t('EDIT')} onClick={() => editEss(item)}/>
                                    <Button
                                        value={t('PUBLISH')}
                                        onClick={async () => await handlePublish(item)}
                                        btnType={PRIMARY}/>
                                    <Button value={t('REMOVE_ALL_CHANGES')} onClick={() => handleConfirmBox(item.id)}/>
                                    {isPredicare() && t('CHANGELOG')}
                                    {isPredicare() && (
                                        <Dropdown
                                            options={[...changeLogOptions, {
                                                label: 'None',
                                                value: '',
                                            }]}
                                            value={item.basedOn ? item.basedOn.id : 'None'}
                                            className="changelog"
                                            keyPrefix="changelog"
                                            onChange={e => onChangeLog(e, item.id, item.language.languageCode)}
                                        />
                                    )}
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <div className={'downloadableEssPackagesTable'}>
                <div className="title">{t('AVAILABLE_FOR_REGISTERING')}</div>
                {isEmpty(downloadableEssPackage)
                && <div className="blurredText">{t('WARNING_NO_PACKAGES_IN_LANG')}</div>}
                {!isEmpty(downloadableEssPackage) && (
                    <Table className={'striped-horizontally'}>
                        <Thead>
                            <Tr>
                                <Th>{t('VERSION_NAME')}</Th>
                                <Th/>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {map(downloadableEssPackage, item => (
                                <Tr key={item.id}>
                                    <Td>{`${item.type} ${item.version}, ${item.languageName}`}</Td>
                                    <Td>
                                        <div className="publishBtn">
                                            {loader && <Loader/>}
                                            <Button
                                                btnType={PRIMARY}
                                                value={t('DOWNLOAD')}
                                                onClick={() => downloadEssPackage(item.id, item.version)}
                                                />
                                        </div>
                                    </Td>
                                </Tr>
                                ))}
                        </Tbody>
                    </Table>
                )
                }
            </div>
            {goToEditPage && <Redirect to={MANAGE_VERSIONS_ESSEDIT_PAGE} />}
            {goToPublishForm && <Redirect to={PUBLISH_ESS} />}
        </div>
    );
};

export default withTranslation()(EssList);
