import React, { useCallback, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useStore } from '../../store';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../constant/action';
import { PUBLICATION_STATUSES, PUBLISHED, EMPTY_STRING } from '../../constant/text';
import { convertObjectsToDropdownList, convertStringsToDropdownList, removeDuplicatesFromArray, getExistsOrNewFirst } from '../util';
import Dropdown from './dropdown';
import Loader from './loader';
import { getProducts, getEssVersionsForEssTypeId, getEssLanguagesForEssTypeIdAndEssVersion, getEssPackageByTypeVersionLanguage } from '../../api/ess';
import { getVpLanguagesForVersion, getAllVersionsByPublicationStatus, getVpPackageByVersionLanguage } from '../../api/vp';
import { useMount } from './useMount';

const ProductSelector = props => {
    const { name = EMPTY_STRING, setEssPackage, publicationStatus = PUBLISHED, showPublicationStatus = false, essTypeId: pdtTypeId = 0, allVersions = false,
        version: pdtVersion, languageId: pdtLanguageId } = props;
    const mounted = useMount();
    const { dispatch, state: { essTypes = [] } } = useStore();
    const [types, setTypes] = useState(essTypes);
    const [published, setPublished] = useState(publicationStatus);
    const [essTypeId, setEssTypeId] = useState(pdtTypeId);
    const [essVersion, setEssVersion] = useState(pdtVersion);
    const [essLanguageId, setEssLanguageId] = useState(pdtLanguageId);
    const [essVersions, setEssVersions] = useState([]);
    const [essLanguages, setEssLanguages] = useState([]);
    const [loader, setLoader] = useState(false);

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        onPublishStatusChange(published);
    }, [name, allVersions, published]);

    const fetchEssPackage = async (published, version, langId, typeId) => {
        setLoader(true);
        clearMessage();
        if (typeId && version && langId) {
            const packageResponse = await getEssPackageByTypeVersionLanguage(typeId, version, langId);
            if (mounted.current) {
                if (packageResponse.error) {
                    setMessage(packageResponse.error);
                } else {
                    packageResponse.selectedPublicationStatus = published;
                    packageResponse.selectedAllVersions = allVersions;
                    setEssPackage(packageResponse);
                }
            }
        } else if (version && langId) {
            const vpPackageId = await getVpPackageByVersionLanguage(version, langId);
            if (mounted.current) {
                if (vpPackageId.error) {
                    setMessage(vpPackageId.error);
                } else {
                    setEssPackage({ vppackageId: vpPackageId, id: null, selectedPublicationStatus: published, selectedAllVersions: allVersions, typeId, version, language: { languageId: langId } });
                }
            }
        }
        if (mounted.current) {
            setLoader(false);
        }
    };

    const fetchLanguages = async (version, published, typeId) => {
        setLoader(true);
        clearMessage();
        let langs;
        if (typeId && version) {
            langs = await getEssLanguagesForEssTypeIdAndEssVersion(typeId, version, published);
        } else {
            langs = await getVpLanguagesForVersion(version, published);
        }
        if (mounted.current) {
            setLoader(false);
            if (langs.error) {
                setMessage(langs.error);
            } else {
                setEssLanguages(langs);
                const langId = getExistsOrNewFirst(langs, essLanguageId);
                setEssLanguageId(langId);
                if (langId) {
                    fetchEssPackage(published, version, langId, typeId);
                }
            }
        }
    };

    const fetchEssVersions = async (published, typeId) => {
        if (mounted.current) {
            setLoader(true);
            clearMessage();
        }
        let versions;
        if (typeId) {
            versions = await getEssVersionsForEssTypeId(typeId, published);
        } else {
            versions = await getAllVersionsByPublicationStatus(published);
        }
        if (mounted.current) {
            setLoader(false);
            if (versions.error) {
                setMessage(versions.error);
            } else {
                setEssVersions(versions);
                const version = getExistsOrNewFirst(versions, essVersion);
                setEssVersion(version);
                if (version && typeId) {
                    fetchLanguages(version, published, typeId);
                } else {
                    fetchLanguages(version, published);
                }
            }
        }
    };

    const fetchEssTypes = async published => {
        if (mounted.current) {
            setLoader(true);
            clearMessage();
        }
        const types = await getProducts(published);
        if (mounted.current) {
            setLoader(false);
            if (types.error) {
                setMessage(types.error);
            } else {
                setTypes(types);
                const typeId = getExistsOrNewFirst(types, essTypeId);
                setEssTypeId(typeId);
                if (typeId) {
                    fetchEssVersions(published, typeId);
                }
            }
        }
    };

    const onEssLanguageChange = langId => {
        setEssLanguageId(langId);
        fetchEssPackage(published, essVersion, langId, essTypeId);
    };

    const onEssVersionChange = version => {
        setEssVersion(version);
        if (allVersions) {
            fetchLanguages(version, published);
        } else {
            fetchLanguages(version, published, essTypeId);
        }
    };

    const onEssTypeChange = typeId => {
        setEssTypeId(typeId);
        fetchEssVersions(published, typeId);
    };

    const onPublishStatusChange = pub => {
        setPublished(pub);
        if (allVersions) {
            fetchEssVersions(pub);
        } else {
            fetchEssTypes(pub);
        }
    };

    return (
        <>
            <div className="productSelector loadable">
                {showPublicationStatus && (
                    <Dropdown
                        options={PUBLICATION_STATUSES}
                        value={published}
                        onChange={onPublishStatusChange}
                        keyPrefix="publicationStatus"
                    />
                )}
                {!isEmpty(types) && !allVersions && Object.keys(types).length > 1 && (
                    <Dropdown
                        options={convertObjectsToDropdownList(types, 'id', 'type')}
                        value={essTypeId}
                        onChange={onEssTypeChange}
                        keyPrefix="essTypes"
                    />
                )}
                {!isEmpty(essVersions) && essVersions.length > 1 && (
                    <Dropdown
                        options={convertStringsToDropdownList(removeDuplicatesFromArray(essVersions))}
                        value={essVersion}
                        onChange={onEssVersionChange}
                        keyPrefix="essVersions"
                    />
                )}
                {!isEmpty(essLanguages) && essLanguages.length > 1 && (
                    <Dropdown
                        options={convertObjectsToDropdownList(removeDuplicatesFromArray(essLanguages), 'id', 'languageName')}
                        value={essLanguageId}
                        onChange={onEssLanguageChange}
                        keyPrefix="essLanguages"
                    />
                )}
            </div>
            {loader && <Loader />}
        </>
    );
};

export default ProductSelector;
