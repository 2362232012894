import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { SIZE, EMPTY_STRING } from '../../constant/text';

const { L, M } = SIZE;

export const Label = props => {
    const { value = EMPTY_STRING, size = M, className = EMPTY_STRING, required = false } = props;

    if (!isEmpty(value)) {
        return <div className={`label ${size} ${className}`}>{`${value} ${required ? '*' : EMPTY_STRING}`}</div>;
    }

    return null;
};

export const Link = props => {
    const { value, size = M, className = EMPTY_STRING, onClick = () => { } } = props;

    return (
        <div className={`link ${size} ${className}`} onClick={onClick}>
            {value}
        </div>
    );
};

export const Title = props => {
    const { value, size = L, className = EMPTY_STRING, align = 'left' } = props;

    return (<div className={`title ${size} ${className} align-${align}`}>{value}</div>);
};

export const TextValue = props => {
    const { value, size = M, className = EMPTY_STRING } = props;

    return (
        <div className={`textValue ${size} ${className}`}>{value}</div>
    );
};

export const FieldErrorText = props => {
    const { value, size = M, type = EMPTY_STRING } = props;

    if (!isEmpty(value)) {
        return (
            <div className={`fieldInfo ${size} ${type}`}>{value}</div>
        );
    }

    return null;
};

export const LabelText = props => {
    const { label = EMPTY_STRING, text = EMPTY_STRING, className = EMPTY_STRING } = props;

    let colon;
    if (label.endsWith(':')) {
        colon = false;
    } else {
        colon = true;
    }

    return (
        <div className={`labelAndText ${className}`}>
            <Label value={`${label}${colon ? EMPTY_STRING : ':'}`} />
            <TextValue value={text} />
        </div>
    );
};
