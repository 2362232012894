export const ALL_LANGUAGE_LIST = [
    {
    code: 'be',
    language: 'Беларуская / Belarusian'
    },
    {
    code: 'bg',
    language: 'български / Bulgarian'
    },
    {
    code: 'cs',
    language: 'Čeština / Czech'
    },
    {
    code: 'da',
    language: 'Dansk / Danish'
    },
    {
    code: 'de',
    language: 'Deutsch / German'
    },
    {
    code: 'el',
    language: 'ελληνικά / Greek'
    },
    {
    code: 'en',
    language: 'English'
    },
    {
    code: 'es',
    language: 'Español / Spanish'
    },
    {
    code: 'et',
    language: 'Eesti / Estonian'
    },
    {
    code: 'fi',
    language: 'Suomi / Finnish'
    },
    {
    code: 'fr',
    language: 'Français / French'
    },
    {
    code: 'ga',
    language: 'Gaeilge / Irish'
    },
    {
    code: 'hi',
    language: 'हिन्दी / Hindi'
    },
    {
    code: 'hr',
    language: 'Hrvatski / Croatian'
    },
    {
    code: 'hu',
    language: 'Magyar / Hungarian'
    },
    {
    code: 'in',
    language: 'Bahasa indonesia / Indonesian'
    },
    {
    code: 'is',
    language: 'Íslenska / Icelandic'
    },
    {
    code: 'it',
    language: 'Italiano / Italian'
    },
    {
    code: 'ja',
    language: '日本語 / Japanese'
    },
    {
    code: 'ko',
    language: '한국어 / Korean'
    },
    {
    code: 'lt',
    language: 'Lietuvių / Lithuanian'
    },
    {
    code: 'lv',
    language: 'Latviešu / Latvian'
    },
    {
    code: 'mk',
    language: 'македонски / Macedonian'
    },
    {
    code: 'ms',
    language: 'Bahasa melayu / Malay'
    },
    {
    code: 'mt',
    language: 'Malti / Maltese'
    },
    {
    code: 'ne',
    language: 'नेपाली / Nepali'
    },
    {
    code: 'nl',
    language: 'Nederlands / Dutch'
    },
    {
    code: 'no',
    language: 'Norsk / Norwegian'
    },
    {
    code: 'pl',
    language: 'Polski / Polish'
    },
    {
    code: 'pt',
    language: 'Português / Portuguese'
    },
    {
    code: 'ro',
    language: 'Română / Romanian'
    },
    {
    code: 'ru',
    language: 'Русский / Russian'
    },
    {
    code: 'sk',
    language: 'Slovenčina / Slovak'
    },
    {
    code: 'sl',
    language: 'Slovenščina / Slovenian'
    },
    {
    code: 'sq',
    language: 'Shqipe / Albanian'
    },
    {
    code: 'sr',
    language: 'српски / Serbian'
    },
    {
    code: 'sv',
    language: 'Svenska / Swedish'
    },
    {
    code: 'th',
    language: 'ไทย / Thai'
    },
    {
    code: 'tr',
    language: 'Türkçe / Turkish'
    },
    {
    code: 'uk',
    language: 'Українська / Ukrainian'
    },
    {
    code: 'vi',
    language: 'Tiếng việt / Vietnamese'
    },
    {
    code: 'zh',
    language: '中文 / Chinese'
    }];
