import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { authenticate, changeAccountUser } from './api/user';
import { getDefaultProductsForUser } from './api/ess';
import { configureAppWithUserInfo, getAccountUserId, getUser, logout as utilLogout, redirectToLogin } from './api/util';
import { parseJwt } from './api/localStorageUtil';
import { SET_PRODUCT } from './constant/action';
import { useStore } from './store';
import { debugLog } from './util';

const loginManagerContext = createContext<LoginManagerType | null>(null);

export const ProvideLoginManager = ({ children }) => {
    const auth = useProvideLoginManager();

    return (
        <loginManagerContext.Provider value={auth}>
            {children}
        </loginManagerContext.Provider>
    );
};

const getStoredUserInformation = () => {
    const userFromLocalStorage = getUser();
    debugLog('User from local storage', userFromLocalStorage);

    return { user: userFromLocalStorage };
};

export const useLoginManager = () => useContext(loginManagerContext);

export type LoginErrorMessage = {
    show: Boolean,
    value: string[],
    status: string
};

type OnErrorCallback = (errorMessage: LoginErrorMessage) => void
type OnSuccessCallback = () => void

export type LoginManagerType = {
    user: any;
    changeAccount: (accountUserId: string, onError: OnErrorCallback, onSuccess: OnSuccessCallback) => void;
    loginDepartmentUser: (username: string, passwordHash: string, onError: OnErrorCallback, onSuccess: OnSuccessCallback) => void;
    refreshExistingLogin: (onError: OnErrorCallback, onSuccess: OnSuccessCallback) => void;
    login: (username: string, password: string, passwordHash: string, onError: OnErrorCallback, onSuccess: OnSuccessCallback) => void;
    getTokenTimeLeft: () => number,
    logout: () => void
}

const useProvideLoginManager = () : LoginManagerType => {
    // Local states which are returned from this function, and
    // which can be used in components by destructuring `useLoginManager()`.
    const [user, setUser] = useState<any>();
    const [timer, setTimer] = useState<any>();
    const { dispatch } = useStore();
    const setProduct = useCallback(payload => dispatch({ type: SET_PRODUCT, payload }), [dispatch]);
    const getTokenTimeLeft = () => {
        const { exp } = parseJwt();
        const nowInstant = new Date().getTime() / 1000;

        return exp - nowInstant;
    };

    useEffect(() => {
        const storedInfo = getStoredUserInformation();
        setUser(storedInfo.user);
    }, []);

    const configureOnLogin = response => {
        setUser(response);
        configureAppWithUserInfo(response);

        const timeLeftMs = getTokenTimeLeft() * 1000;

        if (timeLeftMs) {
            if (timer) {
                debugLog('clear old timer', timeLeftMs);
                clearTimeout(timer);
            }
            debugLog('start timer ', timeLeftMs);
            setTimer(setTimeout(() => {
                debugLog('timer timed out, logging out.');
                logout();
                redirectToLogin();
            }, timeLeftMs));
        }
    };

    const handleLogin = (methodPromise, onError, onSuccess = () => {}) => {
        const loginPromise = methodPromise.then(loginData => {
            if (loginData.error) {
                onError(loginData.error);

                return null;
            }
            configureOnLogin(loginData.response);

            return getDefaultProductsForUser();
        });
        if (loginPromise) {
            loginPromise.then(defaultProducts => {
                debugLog('In promise, defaultProducts: ', defaultProducts);
                if (defaultProducts.error) {
                    onError(defaultProducts.error);
                } else {
                    onSuccess();
                    setProduct(defaultProducts[0] ?? { noProduct: true });
                }
            })
            .catch(e => {
                debugLog('Login exception', e);
            });
    }
    };

    const login = (username, password, onError, onSuccess) => {
        debugLog('loginManager.login');
        handleLogin(authenticate(username, password, undefined), onError, onSuccess);
    };

    const loginDepartmentUser = (username, passwordHash, onError, onSuccess) => {
        debugLog('loginManager.loginDepartmentUser');
        handleLogin(authenticate(username, undefined, passwordHash), onError, onSuccess);
    };

    const changeAccount = (accountUserId, onError, onSuccess) => {
        debugLog('loginManager.changeAccount');
        setProduct(undefined);
        handleLogin(changeAccountUser(accountUserId), onError, onSuccess);
    };

    const refreshExistingLogin = (onError, onSuccess) => {
        debugLog('loginManager.refreshExistingLogin');
        changeAccount(getAccountUserId(), onError, onSuccess);
    };

    const logout = () => {
        setUser({});
        utilLogout();
    };

    return {
        user,
        login,
        loginDepartmentUser,
        refreshExistingLogin,
        changeAccount,
        getTokenTimeLeft,
        logout
    };
};
