import React, { useState, useCallback, useEffect } from 'react';
import { defer } from 'rxjs';
import { withTranslation } from '../../common/translation';
import { getFromLocal } from '../../../api/localStorageUtil';
import ExportDownload from './exportDownload';
import Dropdown from '../../common/dropdown';
import { LOCAL_LANGUAGE_CODE, XML, XSD } from '../../../constant/text';
import { convertLanguagesToDropdownFormat, getLanguageNameByCode } from '../../util';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import { useStore } from '../../../store';
import { downloadSchemaAsXsd } from '../../../api/ess';
import { downloadSystemLanguageAsXml } from '../../../api/definitionList';
import { useMount } from '../../common/useMount';

const SystemLanguage = props => {
    const { t } = props;
    const { dispatch } = useStore();
    const [languageCode, setLanguageCode] = useState(getFromLocal(LOCAL_LANGUAGE_CODE));
    const [languageName, setLanguageName] = useState(getLanguageNameByCode(languageCode));
    const mounted = useMount();
    const [xmlLoader, setXmlLoader] = useState(false);
    const [schemaLoader, setSchemaLoader] = useState(false);

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const subscription = defer(() => { }).subscribe(() => {
            const langCode = getFromLocal(LOCAL_LANGUAGE_CODE);
            setLanguageCode(langCode);
            setLanguageName(getLanguageNameByCode(langCode));
        });

        return () => subscription.unsubscribe();
    }, []);

    const downloadXml = async () => {
        setXmlLoader(true);
        clearMessage();
        const obj = {
            languageCode
        };
        const response = await downloadSystemLanguageAsXml(obj, `${languageCode}.xml`);
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setXmlLoader(false);
        }
    };

    const downloadSchema = async () => {
        setSchemaLoader(true);
        clearMessage();
        const response = await downloadSchemaAsXsd('languageTextCollectionSchema.xsd');
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setSchemaLoader(false);
        }
    };

    const onlanguagechange = val => {
        setLanguageCode(val);
        setLanguageName(getLanguageNameByCode(val));
    };

    return (
        <div className="systemLanguage">
            <Dropdown
                options={convertLanguagesToDropdownFormat('languageCode')}
                value={languageCode}
                onChange={onlanguagechange}
                keyPrefix="definition"
            />
            <div className="exportDownloads">
                <ExportDownload iconType={XML} desc1={t('SYSTEM_LANGUAGE')} desc2={languageName} note={'DOWNLOAD_AS_XML'} onClick={downloadXml} loader={xmlLoader} />
                <ExportDownload iconType={XSD} note={'DOWNLOAD_XML_SCHEMA_SYSLANG'} onClick={downloadSchema} loader={schemaLoader} />
            </div>
        </div>
    );
};

export default withTranslation()(SystemLanguage);
