import {
    SET_LANGUAGES, OPEN_POP_UP, CLOSE_POP_UP, APPLY_THEME, CLEAR_MESSAGE, SET_ACCOUNT_TYPES,
    SET_MESSAGE, EXPANSION_PANEL, SET_LANGUAGE_CODE, SET_NOTIFICATIONS, SET_SELECTED_ACCOUNT_NODE,
    SET_ACCOUNTS, SET_CATEGORY_TYPES, SET_PREDICARE_REGION_EXISTS, SET_PATH_WITH_POP_UP
} from '../constant/action';
import { LOCAL_SELECTED_THEME, LOCAL_LANGUAGE_CODE, LOCAL_LANGUAGES } from '../constant/text';
import { setLocal } from '../api/localStorageUtil';
import { clearMessage } from '../api/util';

export const appAction = {
    [APPLY_THEME]: (state, payload) => {
        setLocal(LOCAL_SELECTED_THEME, payload);

        return { theme: payload };
    },
    [CLEAR_MESSAGE]: () => ({
            message: clearMessage()
        }),
    [SET_MESSAGE]: (state, payload) => ({
        message: payload,
    }),
    [EXPANSION_PANEL]: (state, payload) => ({
            expandable: {
                ...state.expandable,
                [payload.key]: payload.value
            }
        }),
    [OPEN_POP_UP]: (state, payload) => ({
            popup: {
                show: true,
                ...payload
            }
        }),
    [CLOSE_POP_UP]: () => ({
            popup: {
                show: false
            }
        }),
    [SET_PATH_WITH_POP_UP]: (state, payload) => ({
            path: payload.path,
            popup: {
                show: true,
                ...payload.popup
            }
        }),
    [SET_LANGUAGES]: (state, payload) => {
        setLocal(LOCAL_LANGUAGES, payload, true);

        return {
            languages: payload
        };
    },
    [SET_LANGUAGE_CODE]: (state, payload) => {
        setLocal(LOCAL_LANGUAGE_CODE, payload);

        return {
            languageCode: payload
        };
    },
    [SET_NOTIFICATIONS]: (state, payload) => ({
            notifications: payload
        }),
    [SET_SELECTED_ACCOUNT_NODE]: (state, payload) => ({
            accounts: {
                ...state.accounts,
                selectedAccountNode: payload
            }
        }),
    [SET_ACCOUNT_TYPES]: (state, payload) => ({
            accountTypes: payload
        }),
    [SET_CATEGORY_TYPES]: (state, payload) => ({
            categoryTypes: payload
        }),
    [SET_ACCOUNTS]: (state, payload) => ({
            accounts: {
                ...state.accounts,
                ...payload
            }
        }),
    [SET_PREDICARE_REGION_EXISTS]: (state, payload) => ({
            predicareRegionExists: payload
        }),
};
