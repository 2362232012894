import React from 'react';
import { withTranslation } from '../../../common/translation';

const ExternalLinks = ({ t, externalLinks }) => (
    <>
        {externalLinks
        && (
            <div className="section" id="retts-facts">
                <div className="sectionTitle">{t('LOCAL_LINKS')}</div>
                <div className="sectionContent">
                    {
                        externalLinks.map(externalLink => (
                            <a
                                href={externalLink.url}
                                style={{ display: 'block' }}
                                key={externalLink.id}
                                rel="noopener noreferrer"
                                target="_blank">
                                {externalLink.description}
                            </a>
                        ))
                    }
                </div>
            </div>
        )}
    </>
    );

export default withTranslation()(ExternalLinks);
