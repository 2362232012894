import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { Title, Link, TextValue } from '../../common/text';
import { withTranslation } from '../../common/translation';
import TextField from '../../common/textField';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import Loader from '../../common/loader';
import { useStore } from '../../../store';
import { EMPTY_STRING, SIZE } from '../../../constant/text';
import { LOGIN_PAGE, ROOT_PAGE } from '../../../constant/route';
import { SET_MESSAGE, CLEAR_MESSAGE, SET_USERNAME_AND_PASSWORD } from '../../../constant/action';
import Checkbox from '../../common/checkbox';
import { createUser } from '../../../api/user';
import { errorMessage, successMessage } from '../../../api/util';
import { getQueryParamsAsObject, notValidEmail } from '../../util';
import { useMount } from '../../common/useMount';
import { useLoginManager } from '../../../UseLoginManager';

const Register = props => {
    const { t, i18n } = props;
    const { dispatch } = useStore();
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const mounted = useMount();
    const [invitation, setInvitation] = useState(EMPTY_STRING);
    const [name, setName] = useState(EMPTY_STRING);
    const [nameError, setNameError] = useState(EMPTY_STRING);
    const [email, setEmail] = useState(EMPTY_STRING);
    const [emailError, setEmailError] = useState(EMPTY_STRING);
    const [password1, setPassword1] = useState(EMPTY_STRING);
    const [password1Error, setPassword1Error] = useState(EMPTY_STRING);
    const [password2, setPassword2] = useState(EMPTY_STRING);
    const [password2Error, setPassword2Error] = useState(EMPTY_STRING);
    const [checked, setChecked] = useState(EMPTY_STRING);
    const termsAndConditions = t('PRIVACY_CONSENT_LINK');
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setUsernameAndPassword = useCallback(payload => dispatch({ type: SET_USERNAME_AND_PASSWORD, payload }), [dispatch]);
    const history = useHistory();
    const { logout } = useLoginManager();

    const MAX_LENGTH = 1000;

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        const url = window.location.href;
        const queryParams = getQueryParamsAsObject(decodeURI(url));
        if (queryParams.username && queryParams.name && queryParams.invitation && queryParams.language) {
            logout();
            i18n.changeLanguage(queryParams.languageCode);
            setInvitation(queryParams.invitation);
            setName(queryParams.name ? queryParams.name.replace('+', ' ') : queryParams.name);
            setEmail(queryParams.username);
        } else {
            // Only registrations from invites are allowed
            history.push(ROOT_PAGE);
        }
    };

    const openPage = () => {
        window.open(termsAndConditions);
    };

    const enableButton = () => {
        if (checked) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const onChecked = val => {
        setChecked(val);
        if (val) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const onNameChange = val => {
        if (val.length <= MAX_LENGTH) {
            setName(val);
        }
        if (val.length < 1) {
            setDisabled(true);
        } else {
            enableButton();
        }
    };

    const onPassword1Change = val => {
        if (password1.length <= MAX_LENGTH) {
            setPassword1(val);
        }
    };

    const onPassword2Change = val => {
        if (password2.length <= MAX_LENGTH) {
            setPassword2(val);
        }
    };

    const isValid = () => {
        setNameError(EMPTY_STRING);
        setEmailError(EMPTY_STRING);
        setPassword1Error(EMPTY_STRING);
        setPassword2Error(EMPTY_STRING);

        if (isEmpty(name)) {
            setNameError('REQUIRED_FIELD');
        }
        const mess = notValidEmail(email);
        if (mess) {
            setEmailError(mess);

            return false;
        }
        if (password1.length < 6) {
            setPassword1Error('PASSWORD_MINLENGTH');

            return false;
        }
        if (password2 !== password1) {
            setPassword2Error('PASSWORDS_DONT_MATCH');

            return false;
        }

        return true;
    };

    const onCreateUser = async () => {
        if (isValid()) {
            setLoader(true);
            setDisabled(true);
            clearMessage();
            const createObj = {
                acceptsGDPR: checked,
                invitation,
                name,
                password: password1,
                username: email
            };
            const created = await createUser(createObj);
            if (mounted.current) {
                setDisabled(false);
                setLoader(false);
                if (created.error) {
                    setMessage(errorMessage([created.error.value[0].message]));
                    history.push(ROOT_PAGE);
                } else {
                    setMessage(successMessage(['USER_CREATED_SUCCESS']));
                    setUsernameAndPassword({ username: email, password: password1 });
                    history.push(LOGIN_PAGE);
                }
            }
        }
    };

    return (
        <div className="card loadable registerForm">
            <Title value={t('CREATE_USER')} />
            <TextField
                value={email}
                label={'EMAIL_ADDRESS'}
                disabled={true}
                errorMessage={t(emailError)}
            />
            <TextField
                placeholder={'NAME'}
                value={name}
                onChange={onNameChange}
                label={'NAME'}
                required={true}
                errorMessage={t(nameError)}
            />
            <TextField
                placeholder={'PASSWORD'}
                type="password"
                value={password1}
                onChange={onPassword1Change}
                label={'PASSWORD'}
                required={true}
                errorMessage={t(password1Error)}
            />
            <TextField
                placeholder={'PASSWORD'}
                type="password"
                value={password2}
                onChange={onPassword2Change}
                label={'CONFIRM_NEW_PASSWORD'}
                required={true}
                errorMessage={t(password2Error)}
            />
            <TextValue value={`${t('READ_THE_TERMS_AND_CONDITIONS')}:`} size={SIZE.M} className="privacyPolicy"/>
            <Link value={termsAndConditions} onClick={openPage} />
            <Checkbox checked={checked} onChange={onChecked} value="ACCEPT_GDPR" />
            {loader && <Loader />}
            <div className="buttonField-center">
                <Button
                    value={t('CREATE_USER')}
                    onClick={onCreateUser}
                    btnType={PRIMARY}
                    disabled={disabled}
                />
            </div>
        </div>

    );
};

export default withTranslation()(Register);
