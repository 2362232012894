import React, { useCallback } from 'react';
import { withTranslation } from './translation';
import { useStore } from '../../store';
import { CLOSE_POP_UP } from '../../constant/action';
import { CloseIcon } from './icon';
import { EMPTY_STRING } from '../../constant/text';

const PopUpCloseIcon = props => {
    const { t, blur = false } = props;
    const { dispatch } = useStore();
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);

    return (
        <div className="popUpCloseIcon">
            <CloseIcon
                className={`closeIcon-card ${blur ? 'blur' : EMPTY_STRING}`}
                onClick={!blur ? () => closePopUp() : () => {}}
                toolTip={t('CLOSE')}
            />
        </div>
    );
};

export default withTranslation()(PopUpCloseIcon);
