import React, { useCallback } from 'react';
import map from 'lodash/map';
import { useHistory } from 'react-router-dom';
import { withTranslation } from '../../../common/translation';
import { Link } from '../../../common/text';
import Loader from '../../../common/loader';
import { SET_VP_EDIT } from '../../../../constant/action';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../../common/table';
import { PUBLISHED, REVISE, VP } from '../../../../constant/text';
import { MANAGE_VERSIONS_VPEDIT_PAGE } from '../../../../constant/route';
import { useStore } from '../../../../store';
import { getLanguageIdByCode } from '../../../util';

const VpList = props => {
    const { t, loader, list } = props;
    const { dispatch } = useStore();
    const setVpEdit = useCallback(payload => dispatch({ type: SET_VP_EDIT, payload }), [dispatch]);
    const history = useHistory();

    const editVp = vp => {
        const { version, languageCode, id: vpPackageId } = vp;
        setVpEdit({
            searchBy: VP,
            publicationStatus: PUBLISHED,
            allVersions: true,
            version,
            languageId: getLanguageIdByCode(languageCode),
            vpPackageId,
            editType: REVISE
        });
        history.push(MANAGE_VERSIONS_VPEDIT_PAGE);
    };

    return (
        <div className="card loadable">
            <div className="title">{t('PUBLISHED_VP_VERSIONS')}</div>
            <Table className={'striped-horizontally'}>
                <Thead>
                    <Tr>
                        <Th>{t('VERSION_NAME')}</Th>
                        <Th>{t('LANGUAGE')}</Th>
                        <Th>{t('CREATION_DATE')}</Th>
                        <Th>{t('PUBLISHED')}</Th>
                        <Th>{t('APPROVED_BY')}</Th>
                        <Th>{t('REVISION_DATE')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {map(list, item => (
                        <Tr key={item.id}>
                            <Td><Link value={item.version} onClick={() => editVp(item)} /></Td>
                            <Td>{item.languageName}</Td>
                            <Td>{item.creationDate}</Td>
                            <Td>{item.releaseDate}</Td>
                            <Td>{item.releaseUserName}</Td>
                            <Td>{item.revisionDate}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            {loader && <Loader />}
        </div>
    );
};

export default withTranslation()(VpList);
