/* eslint-disable no-return-await */
import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import { Link } from 'react-router-dom';
import { withTranslation } from '../../common/translation';
import { getVpTypesByVpPackageId, getVpByVpTypeId } from '../../../api/vp';
import { SIZE, VP, MAX_CARDS_TO_DISPLAY } from '../../../constant/text';
import { Title } from '../../common/text';
import { useStore } from '../../../store';
import { SEARCH_PAGE } from '../../../constant/route';
import { ADD_SEARCH_CARD, OPEN_POP_UP, SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import ConfirmBox from '../../common/confirmBox';
import Loader from '../../common/loader';
import { useMount } from '../../common/useMount';

const VpList = props => {
    const { t, vpPackageId } = props;
    const mounted = useMount();
    const { dispatch, state: { card: { searchCards = [] } } } = useStore();
    const [vpTypeList, setVpTypeList] = useState([]);
    const [loader, setLoader] = useState(false);
    const addSearchCard = useCallback(payload => dispatch({ type: ADD_SEARCH_CARD, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    const fetchData = async () => {
        if (vpPackageId) {
            clearMessage();
            setLoader(true);
            const vpTypeList = await getVpTypesByVpPackageId(vpPackageId);
            if (mounted.current) {
                setLoader(false);
                if (vpTypeList.error) {
                    setMessage(vpTypeList.error);
                } else {
                    setVpTypeList(vpTypeList);
                }
            }
        }
    };

    useEffect(() => {
        const subscription = defer(() => fetchData()).subscribe();

        return () => subscription.unsubscribe();
    }, [vpPackageId]);

    const addToSearchCards = obj => {
        if (searchCards.length < MAX_CARDS_TO_DISPLAY) {
            addSearchCard(obj);
        } else {
            openPopUp({
                component: <ConfirmBox
                    value="CLOSE_CHOICE_DIALOG"
                    chooseCardToCloseButton={true}
                    data={obj}
                />
            });
        }
    };

    const loadVp = async vpTypeId => {
        clearMessage();
        setLoader(true);
        getVpByVpTypeId(vpPackageId, vpTypeId).then(data => {
            if (mounted.current) {
                setLoader(false);
            }

            if (data.error) {
                if (mounted.current) {
                    setMessage(data.error);
                }
            } else {
                data.cardType = VP;
                addToSearchCards(data);
            }
        });
    };

    return (
        <div className="vpList loadable">
            {loader && <Loader />}
            <Title size={SIZE.L} value={t('VITAL_PARAMETERS_SHORT')} />
            <div className="links">
                {
                    vpTypeList && vpTypeList.map(vpType => <Link className={'link'} to={SEARCH_PAGE} key={`vpType-${vpType.id}`} value={vpType.type} onClick={async () => await loadVp(vpType.id)}>{vpType.type}<br /></Link>)
                }
            </div>
        </div>
    );
};

export default withTranslation()(VpList);
