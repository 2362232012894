import React, { useCallback } from 'react';
import { useStore } from '../../store';
import { withTranslation } from './translation';
import { WARNING } from '../../constant/text';
import { CLEAR_MESSAGE } from '../../constant/action';
import { CloseIcon } from './icon';

const Message = props => {
    const { t } = props;
    const { dispatch, state: { message: { show = false, status = WARNING, value = [] } } } = useStore();

    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const fadeMessage = show === true ? 'fadeIn' : 'fadeOut';

    const setTimerOnMessage = () => {
        setTimeout(() => {
            clearMessage();
        }, 3000);
    };

    return (
        <div className={`globalMessage ${status} ${fadeMessage}`} >
            {show === true && setTimerOnMessage()}
            <CloseIcon className="closeIcon-message" onClick={() => clearMessage()} />
            {show && value.map(val => `${t(val)}`).join(' ')}
        </div>
    );
};

export default withTranslation()(Message);
