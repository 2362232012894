import React, { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import SearchForm from '../../../search/searchForm';
import EssCard from '../../../search/Ess/EssCard';
import { useStore } from '../../../../../store';
import { SET_MESSAGE, CLEAR_MESSAGE, SET_ESS_EDIT } from '../../../../../constant/action';
import { ESS, LOCAL_ESS_EDIT } from '../../../../../constant/text';
import { DEFAULT } from '../../../../../constant/color';
import Loader from '../../../../common/loader';
import { getFromLocal } from '../../../../../api/localStorageUtil';
import PrevNextButtons from '../prevNextButtons';
import { getEssSearchById, getEssNumberDetailByPackageAndNumber, getAdjacentEssNumberIdsByPackageAndEssNumberId } from '../../../../../api/ess';
import { useMount } from '../../../../common/useMount';

const SearchEss = () => {
    // eslint-disable-next-line no-unused-vars
    const { dispatch, state: { manageVersions: { essEdit = {} } } } = useStore();
    const { searchBy, publicationStatus, typeId, version, languageId, editType } = getFromLocal(LOCAL_ESS_EDIT, true);
    const [loader, setLoader] = useState(false);
    const mounted = useMount();
    const [adjacentDetail, setAdjacentDetail] = useState({ previousId: null, nextId: null });
    const { previousId, nextId, currentDetail = {} } = adjacentDetail;
    const { packageId } = currentDetail;

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setEssEdit = useCallback(payload => dispatch({ type: SET_ESS_EDIT, payload }), [dispatch]);

    const onSearchData = data => {
        const { packageId, id } = data || {};
        getAdjacentDetail(packageId, id, data);
    };

    const onEdit = data => {
        const { packageId, cardType, selectedPublicationStatus,
            selectedTypeId, selectedVersion, selectedLanguageId,
            number: essNumber } = data;
        setEssEdit({
            searchBy: cardType,
            publicationStatus: selectedPublicationStatus,
            typeId: selectedTypeId,
            version: selectedVersion,
            languageId: selectedLanguageId,
            essPackageId: packageId,
            essNumber,
            editType
        });
        const el = document.getElementById('editSection');
        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const loadEss = async essId => {
        setLoader(true);
        clearMessage();
        const ess = await getEssSearchById(essId);
        if (mounted.current) {
            setLoader(false);
            if (ess.error) {
                setMessage(ess.error);
            } else {
                ess.cardType = ESS;
                const { packageId, number } = ess;
                getEssNumberDetail(packageId, number, null);
            }
        }
    };

    const getEssNumberDetail = async (packageId, essNumber, essId) => {
        if (essId || essNumber) {
            setLoader(true);
            clearMessage();
            let essNumberDetail;
            if (essId) {
                essNumberDetail = await getEssSearchById(essId);
            } else {
                essNumberDetail = await getEssNumberDetailByPackageAndNumber(packageId, essNumber);
            }
            if (mounted.current) {
                setLoader(false);
                if (essNumberDetail.error) {
                    setMessage(essNumberDetail.error);
                } else {
                    const { id: essNumberId } = essNumberDetail;
                    getAdjacentDetail(packageId, essNumberId, essNumberDetail);
                }
            }
        }
    };

    const getAdjacentDetail = async (essPackageId, essId, essDetail) => {
        if (essPackageId && essId) {
            setLoader(true);
            const adjacentEssNumIds = await getAdjacentEssNumberIdsByPackageAndEssNumberId(essPackageId, essId);
            if (mounted.current) {
                const { previousEssId: previousId, nextEssId: nextId } = adjacentEssNumIds;
                setAdjacentDetail({ ...adjacentDetail, previousId, nextId, currentDetail: essDetail });
                setLoader(false);
            }
        }
    };

    return (
        <div className="searchFormInEssEdit loadable">
            <SearchForm
                onData={onSearchData}
                showSearchBy={false}
                showProductSelector={true}
                showPublicationStatus={true}
                publicationStatus={publicationStatus}
                searchBy={searchBy}
                essTypeId={typeId}
                version={version}
                languageId={languageId}
            />
            <div className="searchResultInEssEdit">
                {!isEmpty(currentDetail) && currentDetail.id && (
                    <div className="prevNextButtonContainer">
                        <div className="buttonField-left">
                            <PrevNextButtons btnType={DEFAULT} previousId={previousId} packageId={packageId} nextId={nextId} getDetailById={getEssNumberDetail} />
                        </div>
                    </div>
                )}
                {loader && <Loader />}
                {!isEmpty(currentDetail) && currentDetail.id && (
                    <div className={'card cardess'}>
                        <EssCard
                            data={currentDetail}
                            onClose={() => setAdjacentDetail({ ...adjacentDetail, currentDetail: {}, previousId: null, nextId: null })}
                            addEssIdToSearchCard={loadEss}
                            onEdit={() => onEdit(currentDetail)}
                            editable={false}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchEss;
