export const APPLY_THEME = 'applyTheme';
export const SHOW_DETAILED_ESS_RANGE = 'showDetailedEssRange';
export const ADD_SEARCH_CARD = 'setEssSearchList';
export const SET_VP_TYPES = 'setVpTypes';
export const SET_ESS_TYPES = 'setEssTypes';
export const SET_VERSIONS = 'setVersions';
export const REMOVE_SEARCH_CARD = 'removeEssSearchList';
export const SAVE_CARDS = 'saveCards';
export const CLEAR_MESSAGE = 'clearMessage';
export const SET_MESSAGE = 'setMessage';
export const EXPANSION_PANEL = 'expansionPanel';
export const OPEN_POP_UP = 'openPopUp';
export const CLOSE_POP_UP = 'closePopUp';
export const SET_PATH_WITH_POP_UP = 'setPathWithPopUp';
export const CHANGE_ACCOUNT_USER = 'setSelectedAccountUserId';
export const SET_LANGUAGES = 'setLanguages';
export const SET_LANGUAGE_CODE = 'setLanguageCode';
export const SET_PRODUCT = 'setProduct';
export const SET_NOTIFICATIONS = 'setNotifications';
export const SET_SELECTED_ACCOUNT_NODE = 'setSelectedAccountNode';
export const SET_ACCOUNTS = 'setAccounts';
export const SET_CATEGORY_TYPES = 'setCategoryTypes';
export const SET_ACCOUNT_TYPES = 'setAccountTypes';
export const SET_OVERVIEW = 'setOverview';
export const SET_USERS = 'setUsers';
export const SET_LICENSES = 'setLicenses';
export const SET_ESS_PACKAGES = 'setEssPackages';
export const SET_ESS_EDIT = 'setEssEdit';
export const SET_VP_EDIT = 'setVpEdit';
export const SET_VS_PACKAGES = 'setVsPackages';
export const SET_PREDICARE_REGION_EXISTS = 'setPredicareRegionExists';
export const SET_REGISTER_DATA = 'setRegisterData';
export const INITIATE_NEW_VERSION = 'getInititateNewVersion';
export const SET_SELECTED_MANAGE_VERSION = 'setSelectedManageVersion';
export const SET_PROCESS_ACTION_TYPE = 'setProcessActionType';
export const SET_USERNAME_AND_PASSWORD = 'setUserNameAndPassword';
