import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { useStore } from '../../../store';
import { withTranslation } from '../../common/translation';
import { getEssClassificationListFromApi } from '../../../api/ess';
import { Title, Link } from '../../common/text';
import { splitRange, scrollToEssRange } from '../../util';
import { ESS_CLASSIFY_RANGE_CAPACITY, SIZE, ESS_SCROLL_REF_PREFIX, ESS_NEXT_NUMBER_STEP } from '../../../constant/text';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import Loader from '../../common/loader';
import { useMount } from '../../common/useMount';

const EssClassifyList = props => {
    const { t, essPackageId } = props;
    const mounted = useMount();
    const [essClassificationList, setEssClassificationList] = useState([]);
    const [loader, setLoader] = useState(false);
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            if (essPackageId) {
                clearMessage();
                setLoader(true);
                const data = await getEssClassificationListFromApi(essPackageId);
                if (mounted.current) {
                    setLoader(false);
                    if (data.error) {
                        setMessage(data.error);
                    } else {
                        setEssClassificationList(data);
                    }
                }
            }
        };
        const subscription = defer(() => fetchData()).subscribe();

        return () => subscription.unsubscribe();
    }, [essPackageId]);

    return (
        <div className="essClassifyList loadable">
            {loader && <Loader />}
            <Title size={SIZE.L} value={t('ESS')} />
            <div>
                {
                    essClassificationList && !isEmpty(essClassificationList) && sortBy(essClassificationList, o => o.minRange).map(essClassifyObj => {
                        const subTitle = `${essClassifyObj.description} (${essClassifyObj.minRange}-${essClassifyObj.maxRange})`;

                        return (
                            <div key={essClassifyObj.id}>
                                {
                                    <Title size={SIZE.M} value={subTitle} />
                                }
                                {
                                    (
                                        <div className="detailedEssRanges">
                                            {
                                                splitRange(essClassifyObj.minRange, essClassifyObj.maxRange, ESS_CLASSIFY_RANGE_CAPACITY).map(range => (
                                                    <Link size={SIZE.M} key={range.min} value={`${range.min}-${range.max}`} onClick={() => scrollToEssRange(`${ESS_SCROLL_REF_PREFIX}${range.min}`, ESS_NEXT_NUMBER_STEP)} />
                                                    ))
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default withTranslation()(EssClassifyList);
