import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { defer } from 'rxjs';
import { Title } from '../../../../common/text';
import TextField from '../../../../common/textField';
import Button from '../../../../common/button';
import { withTranslation } from '../../../../common/translation';
import InfoBox from '../../../../common/infoBox';
import { getVpTextRules, saveVpTextRule, deleteVpTextRule } from '../../../../../api/vp';
import { getEssCodeTypes } from '../../../../../api/ess';
import { EditIcon, DeleteIcon } from '../../../../common/icon';
import { PRIMARY, SECONDARY } from '../../../../../constant/color';
import { EMPTY_STRING, DROPDOWN_SELECT_TEXT_VALUE } from '../../../../../constant/text';
import ConfirmBox from '../../../../common/confirmBox';
import { warningMessage } from '../../../../../api/util';
import { convertObjectsToDropdownList } from '../../../../util';
import { useStore } from '../../../../../store';
import { SET_MESSAGE, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../../../constant/action';
import Loader from '../../../../common/loader';
import Dropdown from '../../../../common/dropdown';
import { useMount } from '../../../../common/useMount';

const TextRule = props => {
    const { t } = props;
    const [essCodeTypes, setEssCodeTypes] = useState([]);
    const mounted = useMount();
    const [textRules, setTextRules] = useState([]);
    const [textRule, setTextRule] = useState({ essCode: { codeType: Number(DROPDOWN_SELECT_TEXT_VALUE) } });
    const { id, nominalDescription = EMPTY_STRING, essCode = {} } = textRule;
    const { codeType: essCodeTypeId, code: essCodeNumber } = essCode !== null ? essCode : {};
    const [addDisabled, setAddDisabled] = useState(true);
    const [loader, setLoader] = useState(false);
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    const fetchTextRules = async () => {
        setLoader(true);
        const trList = await getVpTextRules();
        setLoader(false);
        if (trList.error) {
            setMessage(trList.error);
        } else {
            setTextRules(trList);
        }
    };

    useEffect(() => {
        const subscription = defer(() => {
            fetchTextRules();
            getEssCodeTypes().then(data => {
                setEssCodeTypes(data);
            });
        }).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const setTextRuleDescription = nominalDescription => {
        clearMessage();
        if (isEmpty(nominalDescription)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setTextRule({ ...textRule, nominalDescription });
    };

    const editTextRule = async tr => {
        clearMessage();
        setAddDisabled(true);
        setTextRule(tr);
    };

    const deleteTextRule = trId => {
        openPopUp({ component: <ConfirmBox value="REMOVE_NOMINAL_CONFIRM" onConfirm={() => removeTextRule(trId)} /> });
    };

    const removeTextRule = async trId => {
        clearMessage();
        setLoader(true);
        const savedEss = await deleteVpTextRule(trId);
        if (mounted.current) {
            setLoader(false);
            if (savedEss.error) {
                setMessage(savedEss.error);
            } else {
                fetchTextRules();
            }
        }
    };

    const saveTextRule = async tr => {
        const { nominalDescription, essCode } = tr;
        clearMessage();
        if (isEmpty(nominalDescription) || isEmpty(essCode) || essCode.codeType === Number(DROPDOWN_SELECT_TEXT_VALUE) || isEmpty(essCode.code)) {
            setMessage(warningMessage(['REQUIRED_FIELD']));
        } else {
            setLoader(true);
            const savedEss = await saveVpTextRule(tr);
            if (mounted.current) {
                setLoader(false);
                if (savedEss.error) {
                    setMessage(savedEss.error);
                } else {
                    onCancel();
                    fetchTextRules();
                }
            }
        }
    };

    const onCancel = () => {
        clearMessage();
        setAddDisabled(true);
        setTextRule({ essCode: { codeType: Number(DROPDOWN_SELECT_TEXT_VALUE) } });
    };

    const onChangeEssCodeType = codeTypeId => {
        clearMessage();
        if (codeTypeId === Number(DROPDOWN_SELECT_TEXT_VALUE)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setTextRule({ ...textRule, essCode: { ...textRule.essCode, codeType: Number(codeTypeId) } });
    };

    const onChangeEssCodeNumber = codeNumber => {
        clearMessage();
        if (isEmpty(codeNumber)) {
            setAddDisabled(true);
        } else {
            setAddDisabled(false);
        }
        setTextRule({ ...textRule, essCode: { ...textRule.essCode, code: codeNumber } });
    };

    return (
        <div className="section separateLines">
            <Title value={t('EDIT_NOMINAL')} />
            <InfoBox>{t('NOMINAL_WILL_REFLECT_IN_ALL_AGE_GROUPS')}</InfoBox>
            {loader && <Loader />}
            <div className="section inline">
                <TextField
                    placeholder="NOMINAL_VALUE"
                    value={nominalDescription}
                    onChange={setTextRuleDescription}
                />
                <Dropdown
                    options={convertObjectsToDropdownList(essCodeTypes, 'id', 'name')}
                    value={essCodeTypeId}
                    onChange={onChangeEssCodeType}
                    keyPrefix="codeType"
                    selectLabel="SELECT_CODE_TYPE"
                />
                <TextField
                    placeholder="CODE"
                    value={essCodeNumber}
                    onChange={onChangeEssCodeNumber}
                />
                <Button
                    btnType={PRIMARY}
                    value={t(id ? 'UPDATE' : 'ADD')}
                    onClick={() => saveTextRule(textRule)}
                    disabled={addDisabled}
                />
                {id && (
                    <Button
                        btnType={SECONDARY}
                        value={t('CANCEL')}
                        onClick={onCancel}
                    />
                )}
            </div>
            {
                textRules.map(item => {
                    const { id, nominalDescription } = item;

                    return (
                        <div key={id} className="inline">
                            <div className="essCardTitle inline">
                                <div className="leftSpacingInMiddleOfLine">{nominalDescription}</div>
                            </div>
                            <div className="buttonField-right">
                                <EditIcon className="clickable" onClick={() => editTextRule(item)} />
                                <DeleteIcon className="clickable" onClick={() => deleteTextRule(id)} />
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default withTranslation()(TextRule);
