import {
    SHOW_DETAILED_ESS_RANGE,
    INITIATE_NEW_VERSION,
    SET_ESS_TYPES,
    SET_VERSIONS,
    SET_ESS_EDIT,
    SET_PRODUCT,
    SET_ESS_PACKAGES,
    SET_PROCESS_ACTION_TYPE,
} from '../constant/action';
import { setLocal } from '../api/localStorageUtil';
import { LOCAL_ESS_EDIT, LOCAL_SELECTED_PRODUCT } from '../constant/text';

export const essAction = {
    [SHOW_DETAILED_ESS_RANGE]: (state, payload = true) => ({
            ess: {
                showDetailedEssRanges: payload,
                essSearchRepeatedId: null,
                searchList: [],
            }
        }),
    [SET_PRODUCT]: (state, payload) => {
        setLocal(LOCAL_SELECTED_PRODUCT, payload, true);

        return {
            product: payload
        };
    },
    [SET_ESS_PACKAGES]: (state, payload) => ({
            manageVersions: { ...state.manageVersions, ess: payload }
        }),
    [SET_ESS_TYPES]: (state, payload) => ({
            essTypes: payload
        }),
    [SET_VERSIONS]: (state, payload) => ({
            versions: payload
        }),
    [SET_ESS_EDIT]: (state, payload) => {
        setLocal(LOCAL_ESS_EDIT, payload, true);

        return {
            manageVersions: {
                ...state.manageVersions,
                essEdit: payload
            }
        };
    },
    [INITIATE_NEW_VERSION]: (state, payload) => {
        const types = payload.types.map(item => ({ value: item.id, label: item.type }));
        const languages = payload.languages.map(item => ({ value: item.id, label: item.languageName }));

        return {
            manageVersions: {
                ...state.manageVersions,
                initVersions: {
                    ...state.manageVersions.initVersions,
                    types,
                    languages
                }
            }
        };
    },
    [SET_PROCESS_ACTION_TYPE]: (state, payload) => ({
            manageVersions: {
                ...state.manageVersions,
                isRETTSProcessActionType: payload
            }
        })
};
