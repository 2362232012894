import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import TextField from '../../common/textField';
import { Link } from '../../common/text';
import Button from '../../common/button';
import { withTranslation } from '../../common/translation';
import { PRIMARY } from '../../../constant/color';
import {
    OPEN_POP_UP,
    SET_MESSAGE, SET_USERNAME_AND_PASSWORD,
} from '../../../constant/action';
import { useStore } from '../../../store';
import PasswordResetter from './passwordResetter';
import { isLoggedIn, warningMessage } from '../../../api/util';
import { EMPTY_STRING, SIZE } from '../../../constant/text';
import { getAccountUsers, notValidEmail } from '../../util';
import Loader from '../../common/loader';
import { getFromLocal, removeFromLocal, setLocal } from '../../../api/localStorageUtil';
import Checkbox from '../../common/checkbox';
import { SEARCH_PAGE } from '../../../constant/route';
import { useLoginManager } from '../../../UseLoginManager';
import { debugLog } from '../../../util';
import AccountUserChanger from '../../header/accountUserChanger';

const Login = props => {
    const { XS } = SIZE;
    const { t } = props;
    const { dispatch, state: { loginId } } = useStore();
    const [loader, setLoader] = useState(false);
    const [usernameError, setUsernameError] = useState(EMPTY_STRING);
    const [passwordError, setPasswordError] = useState(EMPTY_STRING);
    const [rememberMe, setRememberMe] = useState(false);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setUsernameAndPassword = useCallback(payload => dispatch({ type: SET_USERNAME_AND_PASSWORD, payload }), [dispatch]);

    const { login, logout } = useLoginManager();
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('wasRedirectedToLogin')) {
            setMessage(warningMessage([t('SESSION_EXPIRED_WARNING')]));
            setTimeout(() => {
                localStorage.removeItem('wasRedirectedToLogin');
            }, 3000);
            logout();
        } else if (isLoggedIn()) {
            redirectLoggedInUserToSearchPage();
        }
    }, []);

    useEffect(() => {
        const usernameFromLocalStorage = getFromLocal('REMEMBER_ME_USERNAME', false);

        if (loginId.username === '' && usernameFromLocalStorage) {
            setUsernameAndPassword({ ...loginId, username: usernameFromLocalStorage });
            setRememberMe(true);
        }
    }, []);

    const onUsernameChange = email => {
        setUsernameAndPassword({ ...loginId, username: email });
    };

    const onPasswordChange = password => {
        setUsernameAndPassword({ ...loginId, password });
    };

    const isValid = () => {
        setUsernameError(EMPTY_STRING);
        setPasswordError(EMPTY_STRING);
        const mess = notValidEmail(loginId.username);
        if (mess) {
            setUsernameError(t(mess));

            return false;
        }

        if (isEmpty(loginId.password)) {
            setPasswordError(t('REQUIRED_FIELD'));

            return false;
        }

        return true;
    };

    const maybeUpdateRememberedUsername = username => {
        if (rememberMe) {
            setLocal('REMEMBER_ME_USERNAME', username, false);
        } else {
            removeFromLocal(['REMEMBER_ME_USERNAME']);
        }
    };

    const redirectLoggedInUserToSearchPage = () => {
        debugLog('redirect logged in user to search page');
        history.replace(SEARCH_PAGE);
    };

    const onLogin = () => {
        if (isValid()) {
            setLoader(true);
            maybeUpdateRememberedUsername(loginId.username);
            login(loginId.username, loginId.password, error => {
                setMessage(error);
                setLoader(false);
            }, () => {
                if (getAccountUsers().length > 1) {
                    openPopUp({ component: <AccountUserChanger /> });
                }
                redirectLoggedInUserToSearchPage();
                setLoader(false);
            });
        }
    };

    const onForgotPassword = () => {
        openPopUp({ component: <PasswordResetter /> });
    };

    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onLogin();
        }
    };

    return (
        <div className="login loadable">
            <div className="loginContainer">
                <div className="loginContent">
                    <TextField
                        dataAttribute="login_input_username"
                        type="email"
                        placeholder={'USERNAME'}
                        value={loginId.username}
                        onChange={onUsernameChange}
                        onKeyPress={e => onKeyPress(e)}
                        required={true}
                        errorMessage={usernameError}
                    />
                    <TextField
                        dataAttribute="login_input_password"
                        placeholder={'PASSWORD'}
                        value={loginId.password}
                        onChange={onPasswordChange}
                        type="password"
                        onKeyPress={e => onKeyPress(e)}
                        required={true}
                        errorMessage={passwordError}
                    />
                    <div className={'rememberMe'}>
                        <Checkbox checked={rememberMe} value={'REMEMBER_ME'} onChange={setRememberMe} />
                    </div>
                    {loader && <Loader />}
                    <Button
                        dataAttribute="login_button_submit"
                        btnType={PRIMARY}
                        type={'submit'}
                        value={`${t('LOGIN')}`}
                        onClick={() => onLogin()}
                        disabled={loader}
                    />
                    <Link value={t('FORGOT_PASSWORD')} onClick={onForgotPassword} size={XS} />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(Login);
