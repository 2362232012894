import { apiGet, apiFileDownload } from './request';
import { GET_DEFINITION_LIST_BY_ID, GET_DEFINITION_PDF, GET_DEFINITION_XML, GET_SYSTEM_LANGUAGE_XML, GET_DEFINITIONLIST_AUDIT } from '../constant/api';
import SessionStorageCache from '../sessionStorageCache';

export const getDefinitionListById = async languageId => SessionStorageCache.cacheOutput(`definitions_${languageId}`, 60, async () => apiGet(`${GET_DEFINITION_LIST_BY_ID}/${languageId}`));

export const getDefinitionListAudit = async (payload, fileName) => {
    const { languageCode, date, time } = payload;
    const data = await apiFileDownload(`${GET_DEFINITIONLIST_AUDIT}/${languageCode}/${date}/${time}`, fileName, 'NO_DEFINITIONLIST_HISTORY_FOUND');

    return data;
};

export const downloadDefinitionAsPdf = async (obj, fileName) => {
    const { languageCode, landscape = false } = obj;
    const data = await apiFileDownload(`${GET_DEFINITION_PDF}/${languageCode}/${landscape}`, fileName, 'NO_SUCH_DEFINITION');

    return data;
};

export const downloadDefinitionAsXml = async (obj, fileName) => {
    const { languageCode } = obj;
    const data = await apiFileDownload(`${GET_DEFINITION_XML}/${languageCode}`, fileName, 'NO_SUCH_DEFINITION');

    return data;
};

export const downloadSystemLanguageAsXml = async (obj, fileName) => {
    const { languageCode } = obj;
    const data = await apiFileDownload(`${GET_SYSTEM_LANGUAGE_XML}/${languageCode}`, fileName, 'NO_SUCH_DEFINITION');

    return data;
};
