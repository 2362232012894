import React from 'react';

const Loader = () => (
    <div className="loaderContainer">
        <div className="loader"><div /><div /><div /><div /><div /><div /><div /><div /></div>
        <div id="loaderBackgroundOverlay" className="backgroundOverlay" />
    </div>
    );

export default Loader;
