import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { withTranslation } from './component/common/translation';
import { THEMES, DEFAULT_THEME } from './constant/theme';
import { useStore } from './store';
import { GlobalStyles } from './style/theme';
import Header from './component/header';
import Routes from './component/routes';
import Footer from './component/footer';
import { getFromLocal } from './api/localStorageUtil';
import { LOCAL_SELECTED_THEME, EMPTY_STRING } from './constant/text';

const App = () => {
    const { state: { theme = EMPTY_STRING } } = useStore();

    const getTheme = () => {
        let themeName = !isEmpty(theme) ? theme : getFromLocal(LOCAL_SELECTED_THEME);
        themeName = !isEmpty(themeName) ? themeName : DEFAULT_THEME;

        return THEMES[themeName];
    };

    return (
        <ThemeProvider theme={getTheme()}>
            <BrowserRouter>
                <GlobalStyles />
                <Header />
                <Routes />
                <Footer />
            </BrowserRouter>
        </ThemeProvider>
    );
};
export default withTranslation()(App);
