import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import TextArea from '../../../../../common/textArea';
import Button from '../../../../../common/button';
import { PRIMARY, SECONDARY } from '../../../../../../constant/color';
import { saveEss } from '../../../../../../api/ess';
import ConfirmBox from '../../../../../common/confirmBox';
import { getInfoByTextType } from '../../../../../util';
import { EMPTY_STRING, SIZE } from '../../../../../../constant/text';
import { useMount } from '../../../../../common/useMount';
import { withTranslation } from '../../../../../common/translation';
import { Title } from '../../../../../common/text';
import CharacterButtons from '../../../../../common/characterButtons';
import Line from '../../../../../common/line';
import { ProcessActionsAndFactsEnum } from '../../../../../../types/ProcessActionAndFactsEnum';

const LocalGeneralProcessActions = props => {
    const { t, ess, setEss, clearMessage, setMessage, openPopUp, isRETTSProcessActionType } = props;
    const { id: essId, packageId, infos = [] } = ess;
    const mounted = useMount();
    const [localProcessAction, setLocalProcessAction] = useState(getInfoByTextType(infos, ProcessActionsAndFactsEnum.LOCAL_GENERAL_PROCESS_ACTIONS));
    const [rettsProcessAction, setRettsProcessAction] = useState(getInfoByTextType(infos, ProcessActionsAndFactsEnum.RETTS_GENERAL_PROCESS_ACTIONS));
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(isEmpty(localProcessAction));
    const [deleteDisabled, setDeleteDisabled] = useState(isEmpty(localProcessAction));
    const { text: additionalDescription = EMPTY_STRING } = localProcessAction;
    const disableStyleGray = { color: 'gray' };

    useEffect(() => {
        setLocalProcessAction(getInfoByTextType(infos, ProcessActionsAndFactsEnum.LOCAL_GENERAL_PROCESS_ACTIONS));
        setRettsProcessAction(getInfoByTextType(infos, ProcessActionsAndFactsEnum.RETTS_GENERAL_PROCESS_ACTIONS));
    }, [infos]);

    useEffect(() => {
        setDeleteDisabled(isEmpty(additionalDescription));
    });

    const save = async () => {
        const payload = {
            action: 'save',
            essId,
            info: localProcessAction
        };

        clearMessage();
        const savedEss = await saveEss(payload);
        if (mounted.current) {
            if (savedEss.error) {
                setMessage(savedEss.error);
            } else {
                setEss(packageId, essId);
            }
        }
    };

    const remove = async () => {
        const payload = {
            action: 'delete',
            essId,
            info: localProcessAction
        };
        clearMessage();
        setDeleteDisabled(true);
        const savedEss = await saveEss(payload);
        if (mounted.current) {
            if (savedEss.error) {
                setMessage(savedEss.error);
            } else {
                setEss(packageId, essId);
            }
        }
    };

    const handleOnClick = async typeOfClick => {
        switch (typeOfClick) {
            case 'save':
                save();
                setSaveButtonEnabled(true);
                break;
            case 'delete':
                openPopUp({ component: <ConfirmBox value="REMOVE_LOCAL_FACTS_CONFIRM" onConfirm={() => remove()} /> });
                break;
            default:
                setEss(packageId, essId);
                setSaveButtonEnabled(true);
        }
    };

    const handleOnchange = desc => {
        if (isEmpty(desc)) {
            setSaveButtonEnabled(true);
            setDeleteDisabled(true);
        } else {
            setSaveButtonEnabled(false);
            setDeleteDisabled(false);
        }
        setLocalProcessAction({ ...localProcessAction, text: desc, textType: ProcessActionsAndFactsEnum.LOCAL_GENERAL_PROCESS_ACTIONS });
    };

    if (isRETTSProcessActionType && rettsProcessAction.text === undefined) {
        return (
            <>
                <Title value={`${t('GENERAL_PROCESS_ACTIONS')}`} size={SIZE.M}/>
                <div>
                    <p className="title">{t('NO_CONTENT')}</p>
                </div>
            </>
        );
    }

    return (
        <>
            <Title value={`${t('GENERAL_PROCESS_ACTIONS')}`} size={SIZE.M}/>
            {rettsProcessAction.text !== undefined
                && (
                <>
                    <div style={isRETTSProcessActionType ? null : disableStyleGray}>
                        <div className="rettsProcessAction">
                            {rettsProcessAction.text}
                            {!isRETTSProcessActionType && (
                                <button
                                    type="button"
                                    className="button btn-default right"
                                    onClick={() => { handleOnchange(rettsProcessAction.text); }}>
                                    {t('COPY')}
                                </button>
                            )}
                        </div>
                    </div>
                    <Line />
                </>
                )
            }
            {!isRETTSProcessActionType
                && (
                    <div>
                        <div className="section">
                            <div className="displayFlex">
                                <TextArea
                                    id="generalProcessActions"
                                    onChange={handleOnchange}
                                    value={additionalDescription}
                                    placeholder={t('PROCESS_ACTION_DESCRIPTION')}
                                    rows="5"
                                    cols="200"/>
                                <div className="processActionsButtons">
                                    <Button
                                        btnType={PRIMARY}
                                        value={t('SAVE_TEXT')}
                                        onClick={() => {
                                            handleOnClick('save');
                                        }}
                                        disabled={saveButtonEnabled}/>
                                    <Button
                                        btnType={SECONDARY}
                                        value={saveButtonEnabled ? t('REMOVE_LOCAL_FACTS') : t('CANCEL')}
                                        onClick={() => {
                                            const typeOfClick = saveButtonEnabled ? 'delete' : 'cancel';
                                            handleOnClick(typeOfClick);
                                        }}
                                        disabled={deleteDisabled}/>
                                </div>
                            </div>
                            <CharacterButtons
                                returnCursorTo="generalProcessActions"
                                returnCallback={character => {
                                handleOnchange(additionalDescription + character);
                            }}/>
                        </div>
                    </div>
                )}
        </>
    );
};

export default withTranslation()(LocalGeneralProcessActions);
