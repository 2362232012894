import React from 'react';
import { withTranslation } from '../../../common/translation';
import { Title, LabelText } from '../../../common/text';

const PublishConfirmBox = props => {
    const { t, selectedVersion } = props;
    const { type, version, languageName } = selectedVersion;

    return (
        <div className="publishConfirmBox">
            <Title value={t('VERSION_TO_PUBLISH')} />
            <LabelText text={type} label={t('TYPE')} />
            <LabelText text={version} label={t('VERSION')} />
            <LabelText text={languageName} label={t('LANGUAGE')} />
        </div>
    );
};

export default withTranslation()(PublishConfirmBox);
