/* eslint-disable react/button-has-type */
import React from 'react';
import { EMPTY_STRING } from '../../constant/text';
import { DEFAULT } from '../../constant/color';

const Button = props => {
    const { type = 'button', btnType = DEFAULT, value = EMPTY_STRING, onClick = () => { }, disabled = false, otherClassName = '', dataAttribute } = props;
    const clsName = disabled ? `button ${btnType} disabledBtn` : `button ${btnType}`;

    return (
        <button
            data-test={dataAttribute}
            type={type}
            className={`${clsName} ${otherClassName}`}
            onClick={disabled ? () => { } : () => onClick()}>
            {value}
        </button>
    );
};

export default Button;
