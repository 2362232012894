import React from 'react';
import groupBy from 'lodash/groupBy';
import { withTranslation } from '../../../common/translation';
import EssTitle from './EssTitle';
import EssSearchPhrase from './EssSearchPhrase';
import EssPreAction from './EssPreAction';
import EssTriage from './EssTriage';
import EssProcessAction from './EssProcessAction';
import EssFact from './EssFact';
import ExternalLinks from './ExternalLinks';
import EssStatus from './EssStatus';
import Sticky from './Sticky';

const EssCard = props => {
  const {
    data,
    onClose,
    closable = true,
    editable = false,
    onEdit = () => {},
    addEssIdToSearchCard = () => {},
  } = props;
  const symptoms = groupBy(data.symptoms, 'essRisklevel');

  const [fullSticky, setFullSticky] = React.useState(false);

  return (
      <>
          <div className="essCard scrollable" data-test="essCard_div_cardContainer">
              <Sticky
                  isFullSticky={fullSticky}
                  sometimesStickyComponents={(
                      <EssSearchPhrase
                          data={data}
                          isFullSticky={fullSticky}
                          addEssIdToSearchCard={essId => {
                addEssIdToSearchCard(essId);
              }}
            />
          )}
                  alwaysStickyComponents={(
                      <>
                          <EssTitle
                              checked={fullSticky}
                              setStickyChange={() => setFullSticky(value => !value)}
                              closable={closable}
                              editable={editable}
                              onClose={onClose}
                              onEdit={onEdit}
                              data={data}
              />
                          {data.essStatus && <EssStatus essStatus={data.essStatus} isFullSticky={fullSticky} />}
                      </>
          )}
        />

              <EssPreAction data={data} isFullSticky={fullSticky} />
              <EssTriage symptoms={symptoms} />
              <EssProcessAction data={data} />
              <EssFact data={data} />
              <ExternalLinks externalLinks={data.externalLinks} />
          </div>
      </>
  );
};

export default withTranslation()(EssCard);
