/* text constants should come here */
// application level
export const APP_NAME = 'RETTS';
export const APP_DESCRIPTION = 'RAPID EMERGENCY TRIAGE AND TREATMENT SYSTEM';
export const PREDICARE = 'Predicare';
export const CHECKBOX_TICK = '✔';
export const RADIO_DOT = '☢';
export const TREE_NODE_TYPE_NORMAL = 'NORMAL_TYPE';
export const TREE_NODE_TYPE_LICENSE = 'CHECKBOX_TYPE';
export const TREE_NODE_TYPE_DELETE = 'EDIT_TYPE';
export const EMPTY_STRING = '';
export const ESS = 'ESS';
export const SP = 'SP';
export const VP = 'VP';
export const DEFINITION = 'DEFINITION';
export const DEFINITIONLIST = 'DEFINITIONLIST';
export const JOURNAL = 'JOURNAL';
export const ESSLOCALCHANGES = 'ESSLOCALCHANGES';
export const SVENSKA = 'Svenska';
export const SVENSKA_CODE = 'sv';
export const ENGLISH_CODE = 'en';
export const ESS_CLASSIFY_RANGE_CAPACITY = 15;
export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';
export const USER = 'USER';
export const ADMIN = 'ADMIN';
export const ESS_SCROLL_REF_PREFIX = 'ESS_';
export const ESS_NEXT_NUMBER_STEP = 1;
export const DROPDOWN_SELECT_TEXT_VALUE = '-1';
export const DEFAULT_CARD_COUNT = 2;
export const MAX_CARDS_TO_DISPLAY = 2;
export const MAX_NOTIFICATION_COUNT_DISPLAY = 20;
export const TREENODE_NAME_MAX_LENGTH = 30;
export const LICENSE_EXTENDER_YEAR_RANGE_DISPLAY = 5;
export const LICENSE_EXTENDER_START_YEAR_LENGTH_DISPLAY = 2;
export const DEFAULT_LICENSE_TYPE_ID = 1;
export const PREDICARE_ACCOUNT_TYPE_ID = 0;
export const CARE_UNIT_ACCOUNT_TYPE_ID = 3;
export const ALL_ACCOUNT_TYPE_ID = -1;
export const UNAUTHORIZED = '401';
export const NOT_FOUND = '404';
export const ISE = '500';
export const ERROR_RESPONSE_MESSAGE_PREFIX = 'Request failed with status code';
export const CANNOT_DOWNLOAD_FOR_THIS_INPUT = 'CANNOT_DOWNLOAD_FOR_THIS_INPUT';
export const LICENSE_EXPIRY_DATE_FORMAT = '-12-31 23:59';
export const QUERY_SPLITTER = '?';
export const QUERY_PARAMS_SPLITTER = '&';
export const PARAM_SPLITTER = '=';
export const PLUS = '+';
export const EXTRA_DOTS = '...';
export const QUERY_PARAM_USERNAME = 'username';
export const QUERY_PARAM_PASSWORDHASH = 'passwordhash';
export const QUERY_PARAM_NAME = 'name';
export const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
const RED = 'RED';
const ORANGE = 'ORANGE';
const YELLOW = 'YELLOW';
const GREEN = 'GREEN';
const BLUE = 'BLUE';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';
export const HORIZONTAL = 'horizontalDivider';
export const VERTICAL = 'verticalDivider';
export const GREEN_ICON = 'greenIcon';
export const RED_ICON = 'redIcon';
export const YELLOW_ICON = 'yellowIcon';
export const RED_FLAG_TOOLTIP = 'RED_FLAG_TOOLTIP';
export const GREEN_FLAG_TOOLTIP = 'GREEN_FLAG_TOOLTIP';
export const YELLOW_FLAG_TOOLTIP = 'YELLOW_FLAG_TOOLTIP';
export const SUCCESS_ADMIN_MSG = 'HAS_ADMIN_TOOLTIP';
export const FAILURE_ADMIN_MSG = 'HAS_NO_ADMIN_TOOLTIP';
export const SUCCESS_LICENSE_MSG = 'HAS_LICENSE_TOOLTIP';
export const FAILURE_LICENSE_MSG = 'HAS_NO_LICENSE_TOOLTIP';
export const WARNING_LICENSE_MSG = 'HAS_EXPIRING_LICENSE_TOOLTIP';
export const ALL = 'ALL';
export const PUBLISHED = true;
export const ONGOING = false;
export const VERSION_EXTENT = 'VERSION';
export const INTERVAL_EXTENT = 'INTERVAL';
export const SINGLE_ESS_EXTENT = 'SINGLE_ESS';
export const XML = 'XML';
export const PDF = 'PDF';
export const XSD = 'XSD';
export const XML_FORMAT1 = '1';
export const XML_FORMAT2 = '2';
export const REVISE = 'REVISE';
export const EDIT = 'EDIT';
// dropdown constants
export const XML_FORMATS = [
    { label: 'XML_FORMAT1', value: XML_FORMAT1 },
    { label: 'XML_FORMAT2', value: XML_FORMAT2 }
];

export const ESS_LIST_SORT_BY = [
    { label: 'ESS_NUMBER', value: ESS },
    { label: 'SEARCH_PHRASE', value: SP }
];

export const SEARCH_TYPES = [
    { label: 'ESS', value: ESS },
    { label: 'VITAL_SIGN', value: VP },
    { label: 'DEFINITION', value: DEFINITION }
];

export const HISTORY_DROPDOWN_LIST = [
    { label: 'ESS', value: ESS },
    { label: 'VITAL_SIGN', value: VP },
    { label: 'DEFINITION_LIST', value: DEFINITIONLIST },
    { label: 'LOCAL_ESS_CHANGES', value: ESSLOCALCHANGES }
];

export const EXTENTS = [
    { label: 'VERSION', value: VERSION_EXTENT },
    { label: 'INTERVAL', value: INTERVAL_EXTENT },
    { label: 'SINGLE_ESS', value: SINGLE_ESS_EXTENT }
];

export const PREDCARE_MAILBOX_CATEGORY = [
    { label: 'EMERGENCY_ROOM', value: 'Akutmottagning', id: 1 },
    { label: 'AMBULANCE', value: 'Ambulans', id: 2 },
    { label: 'SOS_ALARM', value: 'SOS Alarm', id: 3 },
];

export const PUBLICATION_STATUSES = [
    { label: 'HANDLE_EXISTING_VERSIONS', value: PUBLISHED },
    { label: 'HANDLE_NEW_VERSION', value: ONGOING }
];

export const RISK_LEVEL_VALUE = { 1: RED, 2: ORANGE, 3: YELLOW, 4: GREEN, 5: BLUE };
export const RISK_LEVEL_SYMPTOMS_TRANSLATION_KEYS = {
    RED: 'HEADING_RED_SYMPTOMS',
    ORANGE: 'HEADING_ORANGE_SYMPTOMS',
    YELLOW: 'HEADING_YELLOW_SYMPTOMS',
    GREEN: 'HEADING_GREEN_SYMPTOMS',
    BLUE: 'HEADING_BLUE_SYMPTOMS'
};

// local storage keys
export const LOCAL_STORAGE_SECRET_KEY = 'keyseclsamnbgajk';
export const LOCAL_ACCESS_TOKEN = 'accessToken';
export const LOCAL_LANGUAGE_CODE = 'i18nextLng';
export const LOCAL_LANGUAGES = 'languages';
export const LOCAL_SELECTED_THEME = 'theme';
export const LOCAL_ACCOUNT_USERS = 'accountUsers';
export const LOCAL_SELECTED_PRODUCT = 'product';
export const LOCAL_USER_TYPE = 'userType';
export const LOCAL_ESS_EDIT = 'essEdit';
export const LOCAL_VP_EDIT = 'vpEdit';
// api header keys
export const API_HEADER_JWT = 'jwt';
// sizes
export const SIZE = {
    XXL: 'xxl',
    XL: 'xl',
    L: 'l',
    M: 'm',
    S: 's',
    XS: 'xs',
    XXS: 'xxs'
};
