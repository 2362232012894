import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { TranslationProvider } from './component/common/translation';
import i18n from './i18n';
import { StoreProvider } from './store';
import App from './app';
import Loader from './component/common/loader';
import * as serviceWorker from './serviceWorker';
import { ProvideLoginManager } from './UseLoginManager';

window.apiBaseUrl = process.env.REACT_APP_RETTSLIB_BASEURL;

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        <TranslationProvider i18n={i18n}>
            <StoreProvider>
                <ProvideLoginManager>
                    <App />
                </ProvideLoginManager>
            </StoreProvider>
        </TranslationProvider>
    </Suspense>,
    document.getElementById('root'),
);

serviceWorker.unregister();
