import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import LogRocket from 'logrocket';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { withTranslation } from '../../../common/translation';
import { Title } from '../../../common/text';
import Checkbox from '../../../common/checkbox';
import ConfirmBox from '../../../common/confirmBox';
import { errorMessage, successMessage } from '../../../../api/util';
import Button from '../../../common/button';
import { useStore } from '../../../../store';
import Loader from '../../../common/loader';
import {
    getJournalsFromVersiosnId,
    downloadEssAsPdf,
    publishVersion,
    setDefaultLocalPackage, getDefaultProductsForUser,
} from '../../../../api/ess';
import { downloadVpAsPdf } from '../../../../api/vp';
import {
    SET_MESSAGE, CLEAR_MESSAGE,
    OPEN_POP_UP, SET_PRODUCT,
} from '../../../../constant/action';
import { ExportIcon, LeftArrowIcon } from '../../../common/icon';
import { MANAGE_VERSIONS_PAGE } from '../../../../constant/route';
import PublishConfirmBox from './publishConfirmBox';
import { getDateTimeFormat } from '../../../util';
import { useMount } from '../../../common/useMount';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../common/table';

const PublishForm = props => {
    const {
        state: {
            manageVersions: { editVersion: { selectedVersion } }
        }, dispatch
    } = useStore();

    const mounted = useMount();
    const [value, setValue] = useState({ selectedDate: new Date(), journals: [], checked: false });
    const [redirectToPublishedView, setRedirectToPublishedView] = useState(false);
    const history = useHistory();

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setProduct = useCallback(payload => dispatch({ type: SET_PRODUCT, payload }), [dispatch]);

    const handleState = (val, key) => {
        setValue({ ...value, [key]: val });
    };

    const getJournals = () => {
        clearMessage();
        getJournalsFromVersiosnId(selectedVersion.id).then(response => {
            if (mounted.current) {
                if (response.error) {
                    setMessage(response.error);
                } else {
                    handleState(response, 'journals');
                }
            }
        });
    };

    useEffect(() => {
        const execute = () => {
            if (selectedVersion.id) {
                getJournals();
            } else {
                history.push(MANAGE_VERSIONS_PAGE);
            }
        };

        const subscription = defer(() => execute()).subscribe();

        return () => subscription.unsubscribe();
    }, [selectedVersion]);

    const { t } = props;
    const [loader, setLoader] = useState(false);

    const downloadEss = async () => {
        setLoader(true);
        clearMessage();
        const data = await downloadEssAsPdf({ packageId: selectedVersion.id }, 'EssVersion.pdf');
        if (mounted.current) {
            setLoader(false);
            if (data.error) {
                setMessage(data.error);
            }
        }
    };

    const downloadVp = async () => {
        setLoader(true);
        clearMessage();
        const data = await downloadVpAsPdf({ vpPackageId: selectedVersion.vppackageId }, 'VpPackage.pdf');
        if (mounted.current) {
            setLoader(false);
            if (data.error) {
                setMessage(data.error);
            }
        }
    };

    const showPopup = (value, selectedVersion) => {
        openPopUp({
            component: <ConfirmBox
                onConfirm={publishVersions}
                component={(
                    <PublishConfirmBox selectedVersion={selectedVersion} />
                )}
            />
        });
    };

    const publishVersions = async () => {
        clearMessage();
        setLoader(true);
        const releaseDate = getDateTimeFormat(new Date());
        try {
            const published = await publishVersion({
                id: selectedVersion.id,
                releaseDate
            });
            if (mounted.current) {
                setLoader(false);
                if (published.error) {
                    LogRocket.error('Error when trying to publish version', published.error);
                    setMessage(published.error);
                } else {
                    LogRocket.log('Published version successfully', { versionId: selectedVersion.id });
                    setMessage(successMessage(['PUBLISHED_SUCCESSFULLY']));
                    const localPackageResponse = await setDefaultLocalPackage(selectedVersion.id);
                    if (mounted.current) {
                        if (localPackageResponse.error) {
                            setMessage(localPackageResponse.error);
                        } else {
                            const defaultProducts = await getDefaultProductsForUser();
                            if (defaultProducts.error) {
                                LogRocket.error('Error when selecting default package for user', defaultProducts.error);
                                throw new Error();
                            } else {
                                setProduct(defaultProducts[0] ?? { noProduct: true });
                            }
                        }
                    }
                    setRedirectToPublishedView(true);
                }
            }
        } catch (error) {
            if (mounted.current) {
                LogRocket.error('Error when trying to publish version', error);
                setMessage(errorMessage(['GENERIC_ERROR']));
            }
        }
    };

    return (
        <div className="grid loadable publishForm">
            <Link to={MANAGE_VERSIONS_PAGE} className={'button btn-primary'}>
                <LeftArrowIcon className="prevPageClickIcon"/>
            </Link>
            {loader && <Loader />}
            <div className="col_1">
                <Title className="title" value={`${t('PUBLISHING')}: ${selectedVersion.type}, ${selectedVersion.version}, ${selectedVersion.languageName}`} />
            </div>

            <div className={'col_1'}>
                <h3>{t('REVIEW_DOCUMENTS')}: </h3>
            </div>

            <div className={'col_1'}>
                <Table className={'striped-horizontally'}>
                    <Thead>
                        <Tr>
                            <Th>{t('NAME')}</Th>
                            <Th />
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>{t('ESS_PACKAGE')}</Td>
                            <Td className={'clickable'} onClick={downloadEss}>
                                <div className={'download-link'}>
                                    <ExportIcon/>
                                    {t('EXPORT_CHOSEN_VERSION_LOCAL')}
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>{t('VITAL_SIGN')}</Td>
                            <Td className={'clickable'} onClick={downloadVp}>
                                <div className={'download-link'}>
                                    <ExportIcon/>
                                    {t('EXPORT_CHOSEN_VERSION_LOCAL')}
                                </div>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </div>

            <div className="col_1">
                <Checkbox value={'MATERIAL_APPROVED'} checked={value.checked} onChange={val => handleState(val, 'checked')} />
            </div>
            <Button value={t('PUBLISH_VERSION')} disabled={!value.checked} onClick={() => showPopup(value, selectedVersion)} />
            {redirectToPublishedView && <Redirect to={MANAGE_VERSIONS_PAGE} />}
        </div>

    );
};

export default withTranslation()(PublishForm);
