import React from 'react';
import { withTranslation } from '../../../common/translation';
import { WithAnnotations } from '../../../common/withAnnotations';

const EssFact = ({ data, t }) => {
    const visible = !data.replaceFacts;
    const predicareFacts = data.infos.filter(item => item.textType === 1);
    const localFacts = data.infos.filter(item => item.textType === 2);
    const essImages = data.essImages;

return (
    <>
        {visible && predicareFacts && predicareFacts.length > 0 && (
            <div className="section ie11-hack" id="retts-facts">
                <div className="sectionTitle">{t('FACTS_RETTS')}</div>
                <div className="sectionContent">
                    <div className="sectionContent-text-wrapper">
                        <WithAnnotations>
                            {predicareFacts.map(item => item.text)}
                        </WithAnnotations>
                        {essImages && essImages.map(imageObj => (
                            <div key={imageObj.id}>
                                <img src={`data:image/jpg;base64, ${imageObj.imageData}`} alt="" style={{ maxWidth: '100%' }}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )}
        {localFacts.length > 0 && (
            <div className="section" id="local-facts">
                <div className="sectionTitle">{t('LOCAL_FACTS')}</div>
                <div className="sectionContent">
                    <WithAnnotations>
                        {localFacts.map(item => item.text)}
                    </WithAnnotations>
                </div>
            </div>
        )}
    </>
);
};

export default withTranslation()(EssFact);
