import React, { useState, useCallback, useEffect } from 'react';
import { defer } from 'rxjs';
import { withTranslation } from '../../common/translation';
import ProductSelector from '../../common/productSelector';
import { getAssignedProduct, isPredicare } from '../../util';
import Dropdown from '../../common/dropdown';
import { XML_FORMATS, XML_FORMAT1, XML_FORMAT2, PDF, XML, XSD } from '../../../constant/text';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import Checkbox from '../../common/checkbox';
import ExportDownload from './exportDownload';
import { canDownloadPdf, canExportXml } from '../../../api/util';
import { useStore } from '../../../store';
import { downloadSchemaAsXsd } from '../../../api/ess';
import { downloadVpAsPdf, downloadVpAsXml, downloadVpFormat2AsXml } from '../../../api/vp';
import { useMount } from '../../common/useMount';

const VitalSign = props => {
    const { t } = props;
    const { dispatch } = useStore();
    const mounted = useMount();
    const [essPackage, setEssPackage] = useState(getAssignedProduct());
    const [format, setFormat] = useState(XML_FORMAT1);
    const [landscape, setLandscape] = useState(false);
    const [pdfLoader, setPdfLoader] = useState(false);
    const [xmlLoader, setXmlLoader] = useState(false);
    const [schemaLoader, setSchemaLoader] = useState(false);
    const { type, typeId, version, vppackageId: vpPackageId, language: { languageCode, languageId, languageName } } = essPackage;

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const subscription = defer(() => { }).subscribe(() => setEssPackage(getAssignedProduct()));

        return subscription.unsubscribe();
    }, [setEssPackage]);

    const downloadPdf = async () => {
        setPdfLoader(true);
        clearMessage();
        const obj = {
            vpPackageId,
            languageCode,
            landscape
        };
        const response = await downloadVpAsPdf(obj, 'vp.pdf');
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setPdfLoader(false);
        }
    };

    const downloadXml = async format => {
        setXmlLoader(true);
        clearMessage();
        const obj = {
            vpPackageId
        };
        let response;
        if (format === XML_FORMAT2) {
            response = await downloadVpFormat2AsXml(obj, 'vp.xml');
        } else {
            response = await downloadVpAsXml(obj, 'vp.xml');
        }
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setXmlLoader(false);
        }
    };

    const downloadSchema = async format => {
        setSchemaLoader(true);
        clearMessage();
        let response;
        if (format === XML_FORMAT2) {
            response = await downloadSchemaAsXsd('vitalSignsArchetypeSchema.xsd');
        } else {
            response = await downloadSchemaAsXsd('vitalSignsPackageSchema.xsd');
        }
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setSchemaLoader(false);
        }
    };

    const enablePdfDownload = (canDownloadPdf() || isPredicare());
    const enableXmlExport = (canExportXml() || isPredicare());

    return (
        <div className="vs">
            <ProductSelector
                showPublicationStatus={true}
                setEssPackage={setEssPackage}
                essTypeId={typeId}
                version={version}
                languageId={languageId}
            />
            <Dropdown
                options={XML_FORMATS}
                value={format}
                onChange={setFormat}
            />
            {format === XML_FORMAT1 && (
                <Checkbox
                    value="PDF_IN_LANDSCAPE"
                    onChange={setLandscape}
                    checked={landscape}
                />
            )}
            <div className="exportDownloads">
                {format === XML_FORMAT1 && <ExportDownload iconType={PDF} desc1={`${t('VITAL_PARAMETERS_SHORT')} ${type}`} desc2={`${version}, ${languageName}`} note={'DOWNLOAD_AS_PDF'} onClick={downloadPdf} loader={pdfLoader} disabled={!enablePdfDownload} />}
                <ExportDownload iconType={XML} desc1={`${t('VITAL_PARAMETERS_SHORT')} ${type}`} desc2={`${version}, ${languageName}`} note={'DOWNLOAD_AS_XML'} onClick={() => downloadXml(format)} loader={xmlLoader} disabled={!enableXmlExport} />
                {isPredicare() && <ExportDownload iconType={XSD} note={'DOWNLOAD_XML_SCHEMA_VP'} onClick={() => downloadSchema(format)} loader={schemaLoader} />}
            </div>
        </div>
    );
};

export default withTranslation()(VitalSign);
