import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { debugLog } from '../../util';
import { getQueryParamsAsObject } from '../util';
import { LOGIN_PAGE, SEARCH_PAGE } from '../../constant/route';
import { useLoginManager } from '../../UseLoginManager';
import { useStore } from '../../store';
import { SET_MESSAGE } from '../../constant/action';
import { errorMessage } from '../../api/util';

const AutoLoginPage = () => {
    const history = useHistory();

    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);

    const { loginDepartmentUser, logout } = useLoginManager();

    const redirectLoggedInUserToSearchPage = () => {
        history.push(SEARCH_PAGE);
    };

    useEffect(() => {
        const url = window.location.href;
        let queryParams;
        try {
            queryParams = getQueryParamsAsObject(decodeURI(url));
        } catch (e) {
            debugLog(e);
            setMessage(errorMessage(['Invalid query parameters.']));
            history.replace(LOGIN_PAGE);
        }
        if (!isEmpty(queryParams)) {
            if (queryParams.username && queryParams.passwordhash) {
                debugLog('Department user login');
                const {
                    username: userName,
                    passwordhash: passwordHash
                } = queryParams;
                if (isEmpty(userName) || isEmpty(passwordHash)) {
                    debugLog('Empty username or passwordhash in department user link.');

                    return;
                }
                loginDepartmentUser(userName, passwordHash, error => {
                    setMessage(error);
                    logout();
                    history.push(LOGIN_PAGE);
                }, redirectLoggedInUserToSearchPage);
            } else {
                setMessage(errorMessage(['Missing correct query parameters.']));
                history.replace(LOGIN_PAGE);
            }
        } else {
            debugLog('Matched / without parameters');
            history.replace(LOGIN_PAGE);
        }

    }, []);

    return (
        <>
        </>
    );
};

export default AutoLoginPage;
