import React from 'react';
import { Route } from 'react-router-dom';
import { isLoggedIn } from '../../api/util';
import RedirectToLogin from './redirectToLogin';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (isLoggedIn() ? <Component {...props} /> : <RedirectToLogin />)}
        />
    );

export default PrivateRoute;
