import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import { withTranslation } from '../../../common/translation';
import { getEssPackages, getLocalOngoingEssPackages } from '../../../../api/ess';
import { getVsPackages } from '../../../../api/vp';
import { SET_MESSAGE, SET_ESS_PACKAGES, SET_VS_PACKAGES, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../../constant/action';
import EssList from './essList';
import VpList from './vpList';
import JournalList from './journalList';
import { useStore } from '../../../../store';
import Button from '../../../common/button';
import CreateVersions from './createversions';
import Loader from '../../../common/loader';
import { isPredicare } from '../../../util';
import { PRIMARY } from '../../../../constant/color';
import { useMount } from '../../../common/useMount';
import { getOverviewDetails } from '../../../../api/user';

const OnGoingVersions = props => {
    const {
        state: {
            manageVersions: { ess, vp }
        },
        dispatch } = useStore();

    const { t } = props;

    const [loader, setLoader] = useState(true);
    const [newEssPackages, setNewEssPackages] = useState([]);
    const mounted = useMount();

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setEssPackages = useCallback(payload => dispatch({ type: SET_ESS_PACKAGES, payload }), [dispatch]);
    const setVsPackages = useCallback(payload => dispatch({ type: SET_VS_PACKAGES, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    const fetchPkgs = () => {
        clearMessage();
        setLoader(true);
        if (isPredicare()) {
            getEssPackages().then(response => {
                if (mounted.current) {
                    setLoader(false);
                    if (response.error) {
                        setMessage(response.error);
                    } else {
                        setEssPackages(response);
                        setLoader(true);
                        getVsPackages().then(res => {
                            if (mounted.current) {
                                setLoader(false);
                                if (res.error) {
                                    setMessage(res.error);
                                } else {
                                    setVsPackages(res);
                                }
                            }
                        });
                    }
                }
            });
        } else {
            getLocalOngoingEssPackages().then(response => {
                if (mounted.current) {
                    setLoader(false);
                    if (response.error) {
                        setMessage(response.error);
                    } else {
                        setEssPackages({ notReady: response });
                    }
                }
            });
        }

        getOverviewDetails().then(response => {
            if (mounted.current) {
                if (response.error) {
                    setMessage(response.error);
                } else {
                    setNewEssPackages(response.newPackages);
                }
            }
        });
    };

    useEffect(() => {
        const subscription = defer(() => fetchPkgs()).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const handleCreateVersionPopup = () => {
        openPopUp({ component: <CreateVersions handleCreateVersionPopup={handleCreateVersionPopup} fetchPkgs={fetchPkgs} /> });
    };

    return (
        <div className="ongoingVersion loadable tabPanel">
            <div className="ongoingtab">
                {isPredicare() && <Button btnType={PRIMARY} onClick={handleCreateVersionPopup} value={t('CREATE_VERSION')} />}
            </div>
            {loader && <Loader />}
            <EssList refreshPkgs={fetchPkgs} ongoingEssList={reverse(sortBy(ess.notReady, item => item.creationDate))} newEssList={newEssPackages}/>
            {isPredicare() && <VpList list={reverse(sortBy(vp.notReady, item => item.creationDate))} />}
            {isPredicare() && <JournalList list={reverse(sortBy(ess.notReady, item => item.creationDate))} />}
        </div >
    );
};

export default withTranslation()(OnGoingVersions);
