import React, { useState, useCallback, useEffect } from 'react';
import validator from 'validator';
import LogRocket from 'logrocket';
import { v4 as uuidv4 } from 'uuid';
import PopUpCloseIcon from '../../common/popUpCloseIcon';
import Loader from '../../common/loader';
import { withTranslation } from '../../common/translation';
import { Title, Label } from '../../common/text';
import TextField from '../../common/textField';
import { useStore } from '../../../store';
import Button from '../../common/button';
import { saveUser, getUsersForAccount, getDepartmentUser } from '../../../api/user';
import { PRIMARY } from '../../../constant/color';
import { CLOSE_POP_UP, SET_USERS, SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import Dropdown from '../../common/dropdown';
import { getRolesDropdownListForNode } from '../../util';
import { successMessage, errorMessage } from '../../../api/util';
import ExistingUser from './existingUser';
import { useMount } from '../../common/useMount';
import { DEPARTMENT_USER_ROLE_ID } from '../../../constant/route';
import { QUERY_SPLITTER } from '../../../constant/text';

const AddUser = props => {
    const { t, node } = props;
    const { dispatch, state: { users = [] } } = useStore();
    const { name: accountName, id: accountId } = node;
    const mounted = useMount();
    const [showExistingUsers, setShowExistingUsers] = useState(false);
    const [overrideUser, setOverrideUser] = useState(false);
    const [roles, setRoles] = useState([]);
    const [loader, setLoader] = useState();
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [showCredentials, setShowCredentials] = useState(true);
    const [showLoginLink, setShowLoginLink] = useState(false);

    const [autoLoginLink, setAutoLoginLink] = useState();

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [roleId, setRoleId] = useState();
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const setUsers = useCallback(payload => dispatch({ type: SET_USERS, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        setRoles(getRolesDropdownListForNode(node, users));
    }, [node, users]);

    useEffect(() => {
        validateRequiredFields();
    }, [name, email, roleId]);

    const onRoleChange = roleId => {
        setShowCredentials(true);
        setRoleId(roleId);
        if (roleId === DEPARTMENT_USER_ROLE_ID.toString()) {
            setShowCredentials(false);
            setShowExistingUsers(false);
            setName(node.name);
            setEmail(node.name);
            setPassword(uuidv4());
        } else if (name === node.name) {
            setName('');
            setEmail('');
        }
    };

    const handleErrorResponse = response => {
        if (response.error.value[0] === 'USERNAME_EXISTS' && roleId !== DEPARTMENT_USER_ROLE_ID.toString()) {
            setShowExistingUsers(true);
        } else {
            setMessage(response.error);
        }
    };

    const onsubmit = async () => {
        setSaveButtonDisabled(true);
        clearMessage();
        const user = {
            name: validator.trim(name),
            username: email,
            password,
            role: {
                id: roleId ? roleId : roles[0].value
            },
            active: true,
            accountId,
            adminAccountType: null,
            url: window.location.href
        };
        setLoader(true);
        const res = await saveUser(user, overrideUser);
        if (res.error) {
            setLoader(false);
            LogRocket.error('Error adding new user', res.error);
            handleErrorResponse(res);
        } else {
            const usersResponse = await getUsersForAccount(accountId);
            if (mounted.current) {
                setLoader(false);
                if (usersResponse.error) {
                    setMessage(usersResponse.error);
                } else {
                    setUsers(usersResponse);
                    if (res === true) {
                        LogRocket.info('Adding user successfully');
                        if (roleId === DEPARTMENT_USER_ROLE_ID.toString()) {
                            const deptUserDetail = await getDepartmentUser(accountId);
                            if (deptUserDetail.error) {
                                setMessage(deptUserDetail.error);
                            } else {
                                setShowLoginLink(true);
                                const queryParams = deptUserDetail.autoLoginLink.split(QUERY_SPLITTER)[1];
                                const autoLoginLink = `${window.location.origin}${QUERY_SPLITTER}${queryParams}`;
                                setAutoLoginLink(encodeURI(autoLoginLink));
                                setMessage(successMessage(['USER_ADDED']));
                            }
                        } else if (roleId !== DEPARTMENT_USER_ROLE_ID.toString() && !overrideUser) {
                            setMessage(successMessage(['USER_INVITATION_WAS_SENT']));
                            closePopUp();
                        } else {
                            setMessage(successMessage(['USER_ADDED']));
                            closePopUp();
                        }
                    } else {
                        LogRocket.info('Trying to add user with role that exists');
                        setMessage(errorMessage(['USER_ROLE_ACCOUNT_EXISTS']));
                    }
                }
            }
        }
    };

    const validateRequiredFields = () => {
        setSaveButtonDisabled(true);
        if (roleId !== '' && roleId !== undefined
            && name && name.length > 0 && !validator.isEmpty(name, { ignore_whitespace: true })
            && email && email.length > 0) {
            if (roleId !== DEPARTMENT_USER_ROLE_ID.toString()) {
                if (validator.isEmail(email)) {
                    setSaveButtonDisabled(false);
                }
            } else {
                setSaveButtonDisabled(false);
            }
        }
    };

    const handleChange = (value, e) => {
        switch (e.currentTarget.name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(validator.escape(validator.trim(value)));
                break;
            default:
        }
    };

    const onCheckExistingUser = checked => {
        setSaveButtonDisabled(!checked);
        setOverrideUser(checked);
    };

    const copyLink = () => {
        document.getElementById('link-input').select();
        document.execCommand('copy');
    };

    return (
        <div className="popUpComponent loadable scrollable">
            <PopUpCloseIcon blur={loader}/>
            <div className="popUpBody">
                {
                    !showLoginLink
                    && (
                        <>
                            <Title value={t('ADD_USER')}/>
                            <Label value={t(accountName)}/>
                            <br/>
                            {loader && <Loader/>}
                            <div className="labelAndField">
                                <Label value={`${t('USER_ROLE')}`}/>
                                <Dropdown
                                    optionsTitle={t('CHOOSE')}
                                    options={roles}
                                    onChange={onRoleChange}
                                    value={roleId}
                                />
                            </div>
                            {showCredentials && (
                                <>
                                    <TextField
                                        name={'name'}
                                        placeholder={'NAME'}
                                        value={name}
                                        onChange={((value, e) => {
                                            handleChange(value, e);
                                        })}
                                        label={'NAME'}
                                    />
                                    <TextField
                                        name={'email'}
                                        type="email"
                                        placeholder={'USERNAME'}
                                        value={email}
                                        onChange={(value, e) => {
                                            handleChange(value, e);
                                        }}
                                        label={'USERNAME'}
                                    />
                                </>
                            )
                            }
                            <div className="buttonField-right">
                                <Button btnType={PRIMARY} value={t('SAVE')} onClick={onsubmit} disabled={saveButtonDisabled}/>
                                <Button
                                    btnType={PRIMARY}
                                    value={t('CANCEL')}
                                    onClick={() => {
                                        closePopUp();
                                    }}
                                    disabled={false}/>
                            </div>
                            {
                                showExistingUsers
                                && <ExistingUser username={email} onChecked={onCheckExistingUser} checked={overrideUser}/>
                            }
                        </>
                    )
                }
                {showLoginLink && (
                    <>
                        <TextField
                            id={'link-input'}
                            placeholder={'AUTOLOGIN_LINK'}
                            type="text"
                            value={autoLoginLink}
                            label={'AUTOLOGIN_LINK'}
                        />
                        <Button btnType={PRIMARY} value={t('COPY_LINK')} onClick={copyLink} disabled={false}/>
                    </>
                )
                }
            </div>
        </div>
    );
};

export default withTranslation()(AddUser);
