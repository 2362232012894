import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useStore } from '../../store';
import { OPEN_POP_UP, CLOSE_POP_UP } from '../../constant/action';
import { getUserStartPage, getSideBarList, isAdminRoute, getAdminStartPage } from '../util';
import AccountUserChanger from './accountUserChanger';
import LanguageChanger from './languageChanger';
import PasswordChanger from './passwordChanger';
import { withTranslation } from '../common/translation';
import { CloseIcon } from '../common/icon';
import { isLoggedIn, getRoleId } from '../../api/util';
import { ROLES, DEFAULT_USER_ROLE_ID, DEPARTMENT_USER_ROLE_ID } from '../../constant/route';
import { ADMIN, USER, EMPTY_STRING } from '../../constant/text';
import { getElearningLink } from '../../api/user';
import { useLoginManager } from '../../UseLoginManager';

const SideBar = props => {
    const { t, setSideNavOpen, sideNavOpen = false } = props;
    const { logout } = useLoginManager();
    const { dispatch } = useStore();
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const [eLearningLink, setElearningLink] = useState(null);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (eLearningLink === null && sideNavOpen === true) {
                const response = await getElearningLink();
                setElearningLink(response.autoLoginLink ?? response.baseUrl);
            }
        })();
    }, [sideNavOpen]);

    const SETTINGS_MENU = {
        CHANGE_LANGUAGE: () => openPopUp({ component: <LanguageChanger /> }),
        CHANGE_ACCOUNT: () => openPopUp({ component: <AccountUserChanger /> }),
        CHANGE_PASSWORD: () => openPopUp({ component: <PasswordChanger /> })
    };

    let settings = getSideBarList(SETTINGS_MENU);

    if (!isLoggedIn()) {
        const generalSettings = ['CHANGE_LANGUAGE'];
        settings = settings.filter(item => generalSettings.includes(item.label));
    }

    if (getRoleId() === DEPARTMENT_USER_ROLE_ID) {
        settings = settings.filter(item => !['CHANGE_PASSWORD'].includes(item.label));
    }

    const onItemClick = item => {
        SETTINGS_MENU[item]();
        setSideNavOpen(false);
    };

    const onLogout = () => {
        logout();
        closePopUp();
        setSideNavOpen(false);
        history.push('/');
    };

    const toggleView = () => {
        if (isAdminRoute()) {
            history.push(getUserStartPage());
        } else {
            history.push(getAdminStartPage());
        }
        setSideNavOpen(false);
    };

    return (
        <div className={`sidenav ${sideNavOpen ? 'sidenavOpen' : EMPTY_STRING}`} id="sidenav">
            <CloseIcon className="closeIcon-menu" onClick={() => setSideNavOpen(false)} />
            {
                settings.map(item => <div className="sidenavItem" key={item.value} onClick={() => onItemClick(item.value)}>{t(item.label)}</div>)
            }
            {
                isLoggedIn() && isAdminRoute() && ROLES[getRoleId()].adminView && (
                    <div className="sidenavItem not-available-for-small-screens">
                        <Link to={ROLES[DEFAULT_USER_ROLE_ID].homePage} onClick={() => toggleView()} className={'link'}>
                            {t('TYPE_OF_VIEW', { typeOfView: (isAdminRoute() ? t(USER) : t(ADMIN)) })}
                        </Link>
                    </div>
                )
            }

            {
                isLoggedIn() && !isAdminRoute() && ROLES[getRoleId()].adminView && (
                    <div className="sidenavItem not-available-for-small-screens">
                        <Link to={ROLES[getRoleId()].homePage} onClick={() => toggleView()} className={'link'}>
                            {t('TYPE_OF_VIEW', { typeOfView: (isAdminRoute() ? t(USER) : t(ADMIN)) })}
                        </Link>
                    </div>
                )
            }
            {isLoggedIn() && (
                <div className="sidenavItem">
                    <a
                        className="link"
                        href={eLearningLink}
                        style={{ display: 'block' }}
                        rel="noopener noreferrer"
                        target="_blank">
                        {t('OPEN_E_LEARNING')}
                    </a>
                </div>
            )}
            {
                isLoggedIn() && <div className="logout" onClick={() => onLogout()}>{t('LOGOUT')}</div>
            }
        </div>
    );
};

export default React.memo(withTranslation()(SideBar));
