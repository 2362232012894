import React from 'react';
import { SIZE, EMPTY_STRING } from '../../constant/text';

const TextField = props => {
    const { onChange, id, value, placeholder = EMPTY_STRING, rows = '4', cols = '50', size = SIZE.M, className = EMPTY_STRING } = props;

    return (
        <textarea
            id={id}
            className={`textField ${size} ${className}`}
            value={value}
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            onChange={e => onChange(e.target.value)}
            autoComplete="off"/>
    );
};

export default TextField;
