import React from 'react';
import { isEmpty } from 'lodash';
import { withTranslation } from '../../common/translation';
import { Title } from '../../common/text';
import Accordion from '../../common/accordion';
import Ess from './ess';
import VitalSign from './vitalSign';
import DefinitionList from './definitionList';
import SystemLanguage from './systemLanguage';
import { getAssignedProduct, isPredicare } from '../../util';
import InfoBox from '../../common/infoBox';

const Export = props => {
    const { t } = props;
    const assignedProduct = getAssignedProduct();

    return (
        <div className="export">
            {
                isEmpty(assignedProduct) && (
                    <div className="card">
                        <div className="historyForm">
                            <InfoBox title={t('REFRESH_PAGE')} />
                        </div>
                    </div>
                )
            }
            {
                assignedProduct.noProduct && (
                    <div className="card">
                        <div className="historyForm">
                            <InfoBox title={t('NO_AVAILABLE_VERSIONS')} />
                        </div>
                    </div>
                )
            }
            {
                !assignedProduct.noProduct && !isEmpty(assignedProduct) && (
                    <>
                        <Title value={`${t('EXPORT')}`} />
                        <Accordion title="ESS" component={Ess} expandedByDefault={true} />
                        <Accordion title="VITAL_SIGNS" component={VitalSign} />
                        <Accordion title="DEFINITION_LIST" component={DefinitionList} />
                        {isPredicare() && <Accordion title="SYSTEM_LANGUAGE" component={SystemLanguage} />}
                    </>
                )
            }
        </div>
    );
};

export default withTranslation()(Export);
