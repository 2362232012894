const moment = require('moment');

const cacheOutput = async (cacheKey, cacheTimeInMinutes, dataFetcher) => {
    let cachedItem = sessionStorage.getItem(cacheKey);

    if (cachedItem) {
        return JSON.parse(cachedItem).data;
    }

    cachedItem = {
        data: await dataFetcher(),
        expiry: moment().add(cacheTimeInMinutes, 'minutes')
    };

    sessionStorage.setItem(cacheKey, JSON.stringify(cachedItem));

    return cachedItem.data;
};

const SessionStorageCache = {
    cacheOutput
};

export default SessionStorageCache;
