import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import { getEssPackages, getLocalPublishedEssPackages } from '../../../../api/ess';
import { getVsPackages } from '../../../../api/vp';
import { SET_MESSAGE, SET_ESS_PACKAGES, SET_VS_PACKAGES, CLEAR_MESSAGE } from '../../../../constant/action';
import EssList from './essList';
import VpList from './vpList';
import JournalList from './journalList';
import { useStore } from '../../../../store';
import { isPredicare } from '../../../util';
import Loader from '../../../common/loader';
import { useMount } from '../../../common/useMount';

const PublishedVersions = () => {
    const {
        state: {
            manageVersions: { ess, vp }
        },
        dispatch } = useStore();
    const [loader, setLoader] = useState(false);
    const mounted = useMount();

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const setEssPackages = useCallback(payload => dispatch({ type: SET_ESS_PACKAGES, payload }), [dispatch]);
    const setVsPackages = useCallback(payload => dispatch({ type: SET_VS_PACKAGES, payload }), [dispatch]);

    const getPackages = () => {
        if (isPredicare()) {
            setLoader(true);
            getEssPackages().then(response => {
                if (mounted.current) {
                    setLoader(false);
                    if (response.error) {
                        setMessage(response.error);
                    } else {
                        setEssPackages(response);
                        setLoader(true);
                        getVsPackages().then(res => {
                            if (mounted.current) {
                                setLoader(false);
                                if (res.error) {
                                    setMessage(res.error);
                                } else {
                                    setVsPackages(res);
                                }
                            }
                        });
                    }
                }
            });
        } else {
            setLoader(true);
            getLocalPublishedEssPackages().then(response => {
                if (mounted.current) {
                    setLoader(false);
                    if (response.error) {
                        setMessage(response.error);
                    } else {
                        setEssPackages(response);
                    }
                }
            });
        }
    };

    useEffect(() => {
        clearMessage();
        const subscription = defer(() => getPackages()).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className="loadable tabPanel">
            {loader && <Loader />}
            {ess.published && <EssList list={reverse(sortBy(ess.published, item => item.releaseDate))} updateEssListCallback={getPackages} />}
            {isPredicare() && <VpList list={reverse(sortBy(vp.published, item => item.releaseDate))} />}
            {isPredicare() && <JournalList list={reverse(sortBy(ess.published, item => item.releaseDate))} />}
        </div>
    );
};

export default PublishedVersions;
