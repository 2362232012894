import React, { useState } from 'react';
import uuid from 'react-uuid';
import OnGoingVersions from './ongoingversions/index';
import PublishedVersions from './publishedversions/index';
import History from './history/index';
import { withTranslation } from '../../common/translation';
import { MANAGE_VERSION_SUB_MENU_LIST } from '../../../constant/route';
import ToolTip from '../../common/toolTip';

const ManageVersions = props => {
    const { t } = props;
    const [active, setActive] = useState(MANAGE_VERSION_SUB_MENU_LIST[0].icon);

    return (
        <div className="handlVersion">
            <div className="tabs">
                {
                    MANAGE_VERSION_SUB_MENU_LIST.map(subMenu => {
                        const { toolTip, icon: subMenuName } = subMenu;

                        return (
                            <span key={uuid()} onClick={() => setActive(subMenuName)}>
                                <ToolTip toolTipText={t(toolTip)} value={t(subMenuName)} className={active === subMenuName ? 'tab activeTab' : 'tab'} />
                            </span>
                        );
                    })
                }
            </div>
            <div className="tabPanels">
                {active === MANAGE_VERSION_SUB_MENU_LIST[0].icon && <PublishedVersions className="tabPanel"/>}
                {active === MANAGE_VERSION_SUB_MENU_LIST[1].icon && <OnGoingVersions className="tabPanel"/>}
                {active === MANAGE_VERSION_SUB_MENU_LIST[2].icon && <History className="tabPanel"/>}
            </div>
        </div>
    );
};

export default withTranslation()(ManageVersions);
