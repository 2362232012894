import React from 'react';
import { InfoIcon } from './icon';
import { EMPTY_STRING, INFO } from '../../constant/text';

const InfoBox = props => {
    const { title = EMPTY_STRING, type = INFO, children = children } = props;
    const infoClass = title === EMPTY_STRING ? 'infoTextWithNoTitle' : EMPTY_STRING;

    return (
        <div className={`card ${type}`}>
            <div className="infoTitle title">
                <div>{title}</div>
                <InfoIcon className={type} />
            </div>
            <div className={infoClass}>
                {children}
            </div>
        </div>
    );
};

export default InfoBox;
