import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import uuid from 'react-uuid';
import Dropdown from '../../../../../common/dropdown';
import { convertObjToDropdownList } from '../../../../../util';
import { RISK_LEVEL_VALUE } from '../../../../../../constant/text';
import TextArea from '../../../../../common/textArea';
import Button from '../../../../../common/button';
import { PRIMARY, SECONDARY } from '../../../../../../constant/color';
import { withTranslation } from '../../../../../common/translation';
import Line from '../../../../../common/line';
import { DeleteIcon, EditIcon, RightLineArrowIcon } from '../../../../../common/icon';
import { saveEss } from '../../../../../../api/ess';
import ConfirmBox from '../../../../../common/confirmBox';
import { useMount } from '../../../../../common/useMount';
import CharacterButtons from '../../../../../common/characterButtons';
import { WithAnnotations } from '../../../../../common/withAnnotations';
import RadioButtons from '../../../../../common/radioButtons';
import InfoPopupBox from '../../../../../common/InfoPopupBox';

const LocalSpecificProcessActions = props => {
    const { t, ess, setEss, clearMessage, setMessage, openPopUp, defaultProcessActions, setProcessActionTypeIsRETTS, isRETTSProcessActionType } = props;
    const { id: essId, processActions = [], packageId, isLocalProcessActions: hasLocalProcessActions = false } = ess;
    const mounted = useMount();
    const [risklevel, setRisklevel] = useState(1);
    const [description, setDescription] = useState('');
    const [processBtnDisabled, setProcessBtnDisabled] = useState(true);
    const [editId, setEditId] = useState(null);
    const disableStyleGray = { color: 'gray' };
    const disableStyleOpacity = { opacity: 0.5 };

    useEffect(() => {
        description && description !== '' ? setProcessBtnDisabled(false) : setProcessBtnDisabled(true);
    }, [description, risklevel]);

    const editProcessAction = async id => {
        setEditId(id);
        const process = processActions.find(pa => pa.id === id);
        if (process) {
            setDescription(process.description);
            setRisklevel(process.risklevel);
        }
    };

    const openDeletePrompt = id => {
        openPopUp({ component: <ConfirmBox value="REMOVE_PROACTION_CONFIRM" onConfirm={() => saveProcessAction('delete', id)} /> });
    };

    const handleCancel = () => {
        setEditId(null);
        setDescription('');
    };

    const saveProcessAction = async (action, id) => {
        clearMessage();
        const payload = { action, essId };
        if (action === 'save') {
            payload.processAction = editId ? { id: editId, description, risklevel } : { description, risklevel };
        }
        if (action === 'delete') {
            payload.processAction = { id };
        }
        const savedEss = await saveEss(payload);

        if (mounted.current) {
            if (savedEss.error) {
                setMessage(savedEss.error);
            } else {
                setEditId(null);
                setDescription('');
                setEss(packageId, essId);
            }
        }
    };

    const handleModifyBtnClick = processAction => {
        setRisklevel(processAction.risklevel);
        setDescription(`${processAction.description} `);
        document.querySelector('#specificProcessActions').focus();
    };

    const handleProcessActionTypeChange = () => {
        if (hasLocalProcessActions) {
            openPopUp({ component: <InfoPopupBox value="WARN_MESSAGE_DELETE_LOCAL"/> });
        } else {
            setProcessActionTypeIsRETTS(!isRETTSProcessActionType);
        }
    };

    const defaultProcessActionsView = () => (
        <>
            <div>
                <div className="title" style={isRETTSProcessActionType ? null : disableStyleGray}>{t('RETTS_PROCESS_ACTIONS')}</div>
                <div className="section separateLines">
                    {defaultProcessActions && defaultProcessActions.sort((a, b) => (a.risklevel - b.risklevel)).map(processAction => (
                        <div key={uuid()} className="inline stripe">
                            <div
                                className="essCardTitle inline"
                                style={isRETTSProcessActionType ? null : disableStyleGray}>
                                <span style={isRETTSProcessActionType ? null : disableStyleOpacity}>
                                    <RightLineArrowIcon className={`riskLevel-icon riskLevel-icon-${processAction.risklevel}`} />
                                </span>
                                <div className="leftSpacingInMiddleOfLine" >{<WithAnnotations>{processAction.description}</WithAnnotations>}</div>
                            </div>
                            {!isRETTSProcessActionType
                                && (
                                <div className="buttonField-right">
                                    <button type="button" className="button btn-default" onClick={() => { handleModifyBtnClick(processAction); }}>
                                        {t('COPY')}
                                    </button>
                                </div>
)
                            }
                        </div>
                        ))}
                </div>
            </div>
            <Line />
        </>
        );

    return (
        <>
            <Line />
            <RadioButtons
                options={[
                    { label: t('RETTS_PROCESS_ACTIONS'), value: true },
                    { label: t('LOCAL_PROCESS_ACTIONS'), value: false }
                ]}
                name="processActionType"
                onItemClick={handleProcessActionTypeChange}
                value={isRETTSProcessActionType}
            />
            <br/>
            {defaultProcessActionsView()}
            <br/>
            {!isRETTSProcessActionType
                && (
                <>
                    <div className="title">
                        {hasLocalProcessActions ? t('EDIT_LOCAL_PROCESS_ACTIONS') : t('ADD_LOCAL_PROCESS_ACTIONS')}
                    </div>
                    <div className="section processActions">
                        <Dropdown
                            options={convertObjToDropdownList(RISK_LEVEL_VALUE)}
                            value={risklevel}
                            onChange={risklevelKey => setRisklevel(Number(risklevelKey))}
                    />
                        <div>
                            <TextArea
                                id="specificProcessActions"
                                className="processActionInput"
                                placeholder={t('PROCESS_ACTION')}
                                value={description}
                                onChange={value => setDescription(value)}
                                outerClassName="processActionsDescription"
                                rows="1"
                        />
                            <CharacterButtons returnCursorTo="specificProcessActions" returnCallback={character => { setDescription(description + character); }} />
                        </div>
                        <div className="processActionsButtons">
                            {editId && (
                            <Button
                                btnType={SECONDARY}
                                value={t('CANCEL')}
                                onClick={handleCancel}
                            />
                        )}
                            <Button
                                btnType={PRIMARY}
                                value={t(editId ? 'UPDATE' : 'ADD')}
                                onClick={() => saveProcessAction('save')}
                                disabled={processBtnDisabled}
                        />
                        </div>
                    </div>

                    <div className="section separateLines">
                        {hasLocalProcessActions && !isEmpty(processActions) && processActions.map(pa => {
                        const { id, description, risklevel } = pa;

                        return (
                            <div key={id} className="inline stripe" >
                                <div className="essCardTitle inline">
                                    <RightLineArrowIcon className={`riskLevel-icon riskLevel-icon-${risklevel}`} />
                                    <div className="leftSpacingInMiddleOfLine">{description}</div>
                                </div>
                                <div className="buttonField-right">
                                    <button type="button" className="button btn-default" onClick={() => editProcessAction(id)}>
                                        {<EditIcon className="clickable" />}
                                    </button>
                                    <button type="button" className="button button-secondary" onClick={() => openDeletePrompt(id)}>
                                        {<DeleteIcon className="clickable" />}
                                    </button>
                                </div>
                            </div>
                        );
                        })
                }
                        <Line />
                    </div>
                </>
                )
            }
        </>
    );
};

export default withTranslation()(LocalSpecificProcessActions);
