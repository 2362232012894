import React from 'react';
import { Redirect } from 'react-router-dom';
import { withTranslation } from './translation';

const SetLanguageAndRedirect = props => {
    const { i18n, language, path } = props;

    i18n.changeLanguage(language);

    return (
        <Redirect to={path}/>
    );
};

export default withTranslation()(SetLanguageAndRedirect);
