import { apiGet, apiPost, apiFileDownload, apiFileDownloadPost } from './request';
import {
    GET_VP_TYPES_BY_VP_PACKAGE_ID, GET_VP_TYPES, GET_VP_BY_VP_TYPE_ID, GET_VP_PDF, GET_VP_XML, GET_VP_FORMAT2_XML, GET_VS_PACKAGES, GET_VP_GROUP_RULES,
    GET_VP_LANGUAGES_FOR_VERSION, GET_VP_PACKAGE_BY_VERSION_LANGUAGE, GET_VP_PACKAGE_BY_ID, GET_VP_AUDIT, GET_ALL_VERSIONS, DELETE_VP_SEARCH_PHRASE,
    GET_ADJACENT_VP_TYPE_IDS_BY_VP_PACKAGE_AND_VP_TYPE_DETAIL_ID, GET_VP_TYPE_DETAIL_BY_ID, GET_VP_SEARCH_PHRASES, SAVE_VP_SEARCH_PHRASE, DELETE_VP_GROUP_RULE,
    SAVE_VP_GROUP_RULE, SAVE_VP_TEXT_RULE, GET_VP_TEXT_RULES, DELETE_VP_TEXT_RULE,
    SAVE_VP_ARGUMENT, GET_VP_ARGUMENTS, DELETE_VP_ARGUMENT
} from '../constant/api';
import { SVENSKA_CODE, EMPTY_STRING } from '../constant/text';

export const getVpTypesByVpPackageId = async vpPackageId => {
    const data = await apiGet(`${GET_VP_TYPES_BY_VP_PACKAGE_ID}/${vpPackageId}`);

    return data;
};

export const getVpTypeDetailById = async vpTypeDetailId => {
    const data = await apiGet(`${GET_VP_TYPE_DETAIL_BY_ID}/${vpTypeDetailId}`);

    return data;
};

export const getAdjacentVpTypeIdsByPackageAndVpTypeDetailId = async (vpPackageId, vpTypeDetailId) => {
    const data = await apiGet(`${GET_ADJACENT_VP_TYPE_IDS_BY_VP_PACKAGE_AND_VP_TYPE_DETAIL_ID}/${vpPackageId}/${vpTypeDetailId}`);

    return data;
};

export const getAllVersions = async () => {
    const data = await apiGet(`${GET_ALL_VERSIONS}`);

    return data;
};

export const getAllVersionsByPublicationStatus = async published => {
    const data = await apiGet(`${GET_ALL_VERSIONS}?published=${published}`);

    return data;
};

export const getVpAudit = async (payload, fileName) => {
    const data = await apiFileDownloadPost(`${GET_VP_AUDIT}`, payload, fileName, 'NO_VP_HISTORY_FOUND');

    return data;
};

export const getVpTypes = async () => {
    const data = await apiGet(GET_VP_TYPES);

    return data;
};

export const getVpPackageById = async vpPackageId => {
    const data = await apiGet(`${GET_VP_PACKAGE_BY_ID}/${vpPackageId}`);

    return data;
};

export const getVpByVpTypeId = async (vpPackageId, vpTypeId) => {
    const data = await apiGet(`${GET_VP_BY_VP_TYPE_ID}/${vpPackageId}/${vpTypeId}`);

    return data;
};

export const downloadVpAsPdf = async (obj, fileName) => {
    const { vpPackageId, languageCode = SVENSKA_CODE, landscape = false } = obj;
    const data = await apiFileDownload(`${GET_VP_PDF}/${vpPackageId}/${languageCode}/${landscape}`, fileName, 'NO_SUCH_VP');

    return data;
};

export const downloadVpAsXml = async (obj, fileName) => {
    const { vpPackageId } = obj;
    const data = await apiFileDownload(`${GET_VP_XML}/${vpPackageId}`, fileName, 'NO_SUCH_VP');

    return data;
};

export const downloadVpFormat2AsXml = async (obj, fileName) => {
    const { vpPackageId } = obj;
    const data = await apiFileDownload(`${GET_VP_FORMAT2_XML}/${vpPackageId}`, fileName, 'NO_SUCH_VP');

    return data;
};

export const getVsPackages = async () => {
    const data = await apiGet(`${GET_VS_PACKAGES}`);

    return data;
};

export const getVpLanguagesForVersion = async (version, published = EMPTY_STRING) => {
    const data = await apiGet(`${GET_VP_LANGUAGES_FOR_VERSION}/${version}?published=${published}`);

    return data;
};

export const getVpPackageByVersionLanguage = async (version, languageId) => {
    const data = await apiGet(`${GET_VP_PACKAGE_BY_VERSION_LANGUAGE}/${version}/${languageId}`);

    return data;
};

export const getVpSearchPhrases = async vpPackageId => {
    const data = await apiGet(`${GET_VP_SEARCH_PHRASES}/${vpPackageId}`);

    return data;
};

export const saveVpSearchPhrase = async searchPhraseObj => {
    const data = await apiPost(`${SAVE_VP_SEARCH_PHRASE}`, searchPhraseObj);

    return data;
};

export const deleteVpSearchPhrase = async searchPhraseId => {
    const data = await apiPost(`${DELETE_VP_SEARCH_PHRASE}`, { id: searchPhraseId });

    return data;
};

export const getVpGroupRules = async vpTypesId => {
    const data = await apiGet(`${GET_VP_GROUP_RULES}/${vpTypesId}`);

    return data;
};
export const saveVpGroupRule = async payload => {
    const data = await apiPost(`${SAVE_VP_GROUP_RULE}`, payload);

    return data;
};

export const deleteVpGroupRule = async groupRuleId => {
    const data = await apiPost(`${DELETE_VP_GROUP_RULE}`, { id: groupRuleId });

    return data;
};

export const getVpTextRules = async () => {
    const data = await apiGet(`${GET_VP_TEXT_RULES}`);

    return data;
};
export const saveVpTextRule = async payload => {
    const data = await apiPost(`${SAVE_VP_TEXT_RULE}`, payload);

    return data;
};

export const deleteVpTextRule = async textRuleId => {
    const data = await apiPost(`${DELETE_VP_TEXT_RULE}`, { id: textRuleId });

    return data;
};

export const getArguments = async () => {
    const data = await apiGet(`${GET_VP_ARGUMENTS}`);

    return data;
};
export const saveArgument = async payload => {
    const data = await apiPost(`${SAVE_VP_ARGUMENT}`, payload);

    return data;
};

export const deleteArgument = async argId => {
    const data = await apiPost(`${DELETE_VP_ARGUMENT}`, { id: argId });

    return data;
};
