import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import remove from 'lodash/remove';
import omit from 'lodash/omit';
import { ADD_SEARCH_CARD, REMOVE_SEARCH_CARD, SAVE_CARDS } from '../constant/action';
import { warningMessage } from '../api/util';

export const cardAction = {
    [SAVE_CARDS]: (state, payload) => {
        const { data, replace } = payload;
        const cards = state.card.searchCards;

        if (some(cards, card => card.id === data.id)) {
            return {
                card: {
                    ...state.card
                },
                message: warningMessage([data.cardType, 'IS_ALREADY_OPEN'])
            };
        }

        if (cards[0].id === replace) {
            cards.shift();
            cards.unshift(data);
        } else if (cards[1].id === replace) {
            cards.pop();
            cards.push(data);
        }

        return {
            card: {
                searchCards: cards
            }
        };
    },
    [ADD_SEARCH_CARD]: (state, payload = {}) => {
        const cards = state.card.searchCards;
        if (isEmpty(payload)) {
            return {
                card: {
                    ...state.card
                }
            };
        }

        if (some(cards, card => card.id === payload.id)) {
            return {
                card: {
                    ...state.card
                },
                message: warningMessage([payload.cardType, 'IS_ALREADY_OPEN'])
            };
        }

        return {
            card: {
                ...state.card,
                searchCards: [...state.card.searchCards, payload]
            }
        };
    },
    [REMOVE_SEARCH_CARD]: (state, payload) => {
        const cards = state.card.searchCards;
        const expandable = state.expandable;
        remove(cards, card => card.id === payload.id);

        return {
            card: {
                ...state.card,
                searchCards: cards
            },
            expandable: omit(expandable, payload.key),
        };
    }
};
