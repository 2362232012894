import React, { useCallback, useEffect } from 'react';
import { defer } from 'rxjs';
import { Title } from '../common/text';
import { withTranslation } from '../common/translation';
import Dropdown from '../common/dropdown';
import { useStore } from '../../store';
import { SIZE } from '../../constant/text';
import { CLOSE_POP_UP, SET_LANGUAGE_CODE, SET_LANGUAGES, SET_MESSAGE, CLEAR_MESSAGE } from '../../constant/action';
import { convertLanguagesToDropdownFormat, getLanguageIdByCode, getLanguageCodeById } from '../util';
import PopUpCloseIcon from '../common/popUpCloseIcon';
import { getLanguages } from '../../api/app';
import { setLanguage } from '../../api/user';
import { isLoggedIn } from '../../api/util';

const LanguageChanger = props => {
    const { t, i18n } = props;
    const { dispatch } = useStore();
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const setLanguageCode = useCallback(payload => dispatch({ type: SET_LANGUAGE_CODE, payload }), [dispatch]);
    const setLanguages = useCallback(payload => dispatch({ type: SET_LANGUAGES, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const subscription = defer(() => getLanguages()).subscribe(langs => {
            clearMessage();
            if (langs.error) {
                setMessage(langs.error);
            } else {
                langs = langs.filter(x => x.languageCode !== 'th');
                langs = langs.filter(x => x.languageCode !== 'nl');
                langs = langs.filter(x => x.languageCode !== 'zh');

                setLanguages(langs);
            }
        });

        return () => subscription.unsubscribe();
    }, []);

    const onLanguageChange = async langId => {
        const langCode = getLanguageCodeById(langId);
        setLanguageCode(langCode);
        i18n.changeLanguage(langCode);
        if (isLoggedIn()) {
            await setLanguage(langId);
        }
        closePopUp();
    };

    return (
        <div className="popUpComponent">
            <PopUpCloseIcon />
            <div className="popUpBody">
                <Title value={t('CHANGE_LANGUAGE')} size={SIZE.L} />
                <Dropdown
                    options={convertLanguagesToDropdownFormat('languageId')}
                    value={getLanguageIdByCode()}
                    className="changeAccount"
                    keyPrefix="changeAccount"
                    onChange={onLanguageChange}
                />
            </div>
        </div>
    );
};

export default React.memo(withTranslation()(LanguageChanger));
