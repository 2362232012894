import React, { useState, useEffect } from 'react';
import { defer } from 'rxjs';
import { SIZE } from '../../../constant/text';
import { Title } from '../../common/text';
import { withTranslation } from '../../common/translation';
import Loader from '../../common/loader';
import LabelTextTable from '../../common/labelTextTable';
import { getAccount } from '../../../api/user';
import { useStore } from '../../../store';

const AccountInfo = props => {
    const { t, node = {} } = props;
    const { state: { accounts = {} } } = useStore();
    const [accountInfo, setAccountInfo] = useState(node);
    const { name, accountType = {}, categoryType = {}, responsibleName, responsiblePhone, responsibleEmail, visitingAddress = {}, invoiceAddress = {}, canExportXml = false } = accountInfo;
    const { type: accountTypeName } = accountType || {};
    const { type: categoryTypeName } = categoryType || {};
    const { name: visitingName, address1: visitingAddress1, address2: visitingAddress2, postcode: visitingPostcode, city: visitingCity, state: visitingState, country: visitingCountry } = visitingAddress || {};
    const { name: invoiceName, address1: invoiceAddress1, address2: invoiceAddress2, postcode: invoicePostcode, city: invoiceCity, state: invoiceState, country: invoiceCountry } = invoiceAddress || {};
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const subscription = defer(() => {
            setLoader(true);
            getAccount(node.id);
        }).subscribe(accInfo => {
            setLoader(false);
            setAccountInfo(accInfo);
        });

        return () => subscription.unsubscribe();
    }, [accounts]);

    return (
        <div className="accountInfo loadable">
            <Title value={t('INFORMATION')} size={SIZE.L} />
            <LabelTextTable
                labelTexts={[
                    { label: 'NAME', text: name },
                    { label: 'TYPE_OF_ACCOUNT', text: accountTypeName },
                    { label: 'TYPE_OF_CATEGORY', text: categoryTypeName },
                    { label: 'RESPONSIBLE_NAME', text: responsibleName },
                    { label: 'RESPONSIBLE_PHONE', text: responsiblePhone },
                    { label: 'RESPONSIBLE_EMAIL', text: responsibleEmail },
                    { label: 'ACCOUNT_SETTINGS_EXPORT_XML', text: canExportXml ? 'YES' : 'NO' }
                ]}
                prefix="accInfo"
            />
            {loader && <Loader />}
            {visitingAddress && (
                <div className="visitingAddress">
                    <Title value={t('VISITING_ADDRESS')} size={SIZE.L} />
                    <LabelTextTable
                        labelTexts={[
                            { label: 'NAME', text: visitingName },
                            { label: 'ADDRESS_1', text: visitingAddress1 },
                            { label: 'ADDRESS_2', text: visitingAddress2 },
                            { label: 'POSTCODE', text: visitingPostcode },
                            { label: 'CITY', text: visitingCity },
                            { label: 'STATE', text: visitingState },
                            { label: 'COUNTRY', text: visitingCountry }
                        ]}
                        prefix="visiting"
                    />
                </div>
            )}
            {invoiceAddress && (
                <div className="invoiceAddress">
                    <Title value={t('INVOICE_ADDRESS')} size={SIZE.L} />
                    <LabelTextTable
                        labelTexts={[
                            { label: 'NAME', text: invoiceName },
                            { label: 'ADDRESS_1', text: invoiceAddress1 },
                            { label: 'ADDRESS_2', text: invoiceAddress2 },
                            { label: 'POSTCODE', text: invoicePostcode },
                            { label: 'CITY', text: invoiceCity },
                            { label: 'STATE', text: invoiceState },
                            { label: 'COUNTRY', text: invoiceCountry }
                        ]}
                        prefix="invoice"
                    />
                </div>
            )}
        </div>
    );
};

export default withTranslation()(AccountInfo);
