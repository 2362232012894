import React, { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from '../../../common/translation';
import InfoBox from '../../../common/infoBox';
import { useStore } from '../../../../store';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../../constant/action';
import { SIZE, HISTORY_DROPDOWN_LIST, ESS, PDF, VP, DEFINITIONLIST, ESSLOCALCHANGES, LOCAL_LANGUAGE_CODE } from '../../../../constant/text';
import SearchForm from '../../search/searchForm';
import Checkbox from '../../../common/checkbox';
import ExportDownload from '../../export/exportDownload';
import { getEssAudit, getEssLocalChangesAudit } from '../../../../api/ess';
import { getVpAudit } from '../../../../api/vp';
import { getDefinitionListAudit } from '../../../../api/definitionList';
import { getFromLocal } from '../../../../api/localStorageUtil';
import { getDateTimeFormat, getDateFormat, getTimeFormat, getAssignedProduct, isPredicare } from '../../../util';
import { canDownloadPdf, warningMessage } from '../../../../api/util';
import { useMount } from '../../../common/useMount';
import { Label } from '../../../common/text';

const history = {
    [ESS]: {
        note: 'GET_HISTORY_ESS',
        api: getEssAudit,
        fileName: 'ESS-audit.pdf'
    },
    [VP]: {
        note: 'GET_HISTORY_VS',
        api: getVpAudit,
        fileName: 'Vitalsigns-audit.pdf'
    },
    [DEFINITIONLIST]: {
        note: 'GET_HISTORY_DEFINITION_LIST',
        api: getDefinitionListAudit,
        fileName: 'Worddefinitions-audit.pdf'
    },
    [ESSLOCALCHANGES]: {
        note: 'GET_HISTORY_LOCAL_ESS_CHANGES',
        api: getEssLocalChangesAudit,
        fileName: 'ESSlocalchanges-audit.pdf'
    }
};

const History = props => {
    const { t } = props;
    const { dispatch } = useStore();
    const [loader, setLoader] = useState(false);
    const mounted = useMount();
    const [localAdjustments, setLocalAdjustments] = useState(false);
    const [date, setDate] = useState(new Date());
    const [data, setData] = useState({});
    const { cardType = ESS } = data;

    const assignedProduct = getAssignedProduct();
    const { version, language } = assignedProduct;

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    const onData = filterData => {
        setData(filterData);
    };

    const downloadPdf = async obj => {
        const { cardType, data, date, localAdjustments } = obj;
        clearMessage();
        setLoader(true);
        let payload = {};
        switch (cardType) {
            case ESS:
                if (data.id) {
                    payload = {
                        date: getDateTimeFormat(date),
                        id: data.id,
                        lang: getFromLocal(LOCAL_LANGUAGE_CODE),
                        localAdjustments,
                    };
                }
                break;

            case VP:
                if (data.id) {
                    payload = {
                        date: getDateTimeFormat(date),
                        id: data.id,
                        lang: getFromLocal(LOCAL_LANGUAGE_CODE)
                    };
                }
                break;

            case DEFINITIONLIST:
                if (date && language) {
                    payload = {
                        date: getDateFormat(date),
                        time: getTimeFormat(date),
                        languageCode: language.languageCode
                    };
                }
                break;

            case ESSLOCALCHANGES:
                if (data.id) {
                    payload = {
                        date: getDateTimeFormat(date),
                        packageId: data.id,
                        lang: getFromLocal(LOCAL_LANGUAGE_CODE),
                        fromEssNumber: 1,
                        toEssNumber: 100000
                    };
                }
                break;

            default:
                payload = {};
                break;
        }
        if (!isEmpty(payload)) {
            const historyResponse = await history[cardType].api(payload, history[cardType].fileName);
            if (mounted.current) {
                if (historyResponse.error) {
                    setMessage(historyResponse.error);
                }
            }
        } else {
            setMessage(warningMessage(['GENERIC_ERROR']));
        }
        setLoader(false);
    };

    const showDownloadButton = () => {
        let result = false;
        const version = t('VERSION').toLowerCase();
        switch (cardType) {
            case ESS:
                if (data.id) {
                    result = true;
                    history[cardType].note = `${t('GET_HISTORY_FOR')} ${cardType} ${data.number}, ${version} ${data.packageVersion}`;
                }
                break;

            case VP:
                if (data.id) {
                    result = true;
                    history[cardType].note = `${t('GET_HISTORY_FOR')} ${cardType} ${t(data.vptypeName)}, ${version} ${data.version}`;
                }
                break;

            case DEFINITIONLIST:
                result = date ? true : false;
                break;

            case ESSLOCALCHANGES:
                result = data.id ? true : false;
                history[cardType].note = `${t('GET_HISTORY_LOCAL_ESS_CHANGES')}, ${version} ${data.version}`;
                break;
            default:
                result = false;
                break;
        }

        return result;
    };

    const enablePdfDownload = (canDownloadPdf() || isPredicare());

    return (
        <div className="history tabPanel">
            {
                isEmpty(assignedProduct) && (
                    <div className="card">
                        <div className="historyForm">
                            <InfoBox title={t('REFRESH_PAGE')} />
                        </div>
                    </div>
                )
            }
            {
                assignedProduct.noProduct && (
                    <div className="card">
                        <div className="historyForm">
                            <InfoBox title={t('NO_AVAILABLE_VERSIONS')} />
                        </div>
                    </div>
                )
            }
            {
                !assignedProduct.noProduct && !isEmpty(assignedProduct) && (
                    <div className="card">
                        <InfoBox>{t('VERSION_AUDIT_INFO')}</InfoBox>
                        <div className="historyForm">
                            <SearchForm
                                onData={onData}
                                showSearchBy={true}
                                searchByOptions={HISTORY_DROPDOWN_LIST}
                                showProductSelector={true}
                                version={version}
                                languageId={language.languageId}
                            />
                            <div className="date">
                                <Label size={SIZE.M} value={`${t('DATE')}:`}/>
                                <DatePicker
                                    className="datePicker"
                                    selected={date}
                                    onChange={d => setDate(d)}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                />
                            </div>
                            {cardType === ESS && (
                                <Checkbox
                                    checked={localAdjustments}
                                    onChange={setLocalAdjustments}
                                    value={'INCLUDE_LOCAL_ADJUSTMENTS'}
                                />
                            )}
                            <div className="center">
                                {!isEmpty(data) && showDownloadButton() && (
                                    <ExportDownload
                                        iconType={PDF}
                                        note={history[cardType].note}
                                        onClick={() => downloadPdf({ cardType, data, date, localAdjustments })}
                                        loader={loader}
                                        disabled={!enablePdfDownload}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default withTranslation()(History);
