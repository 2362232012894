import { appAction } from './app';
import { essAction } from './ess';
import { vpAction } from './vp';
import { userAction } from './user';
import { cardAction } from './card';
import { WARNING, EMPTY_STRING } from '../constant/text';

const initialStates = {
    notifications: [],
    message: {
        show: false,
        status: WARNING,
        value: EMPTY_STRING
    },
    popup: {
        show: false
    },
    accountUserId: null,
    accountUsers: [],
    expandable: {},
    // Only contains user-data
    user: {},
    ess: {
        showDetailedEssRange: true,
    },
    vp: {
        vpTypes: []
    },
    card: {
        searchCards: []
    },
    product: {},
    essTypes: [],
    versions: [],
    languages: [],
    overview: {},
    manageVersions: {
        ess: {},
        vp: {},
        essEdit: {},
        initVersions: {
            types: [{ label: 'None', value: '' }],
            languages: [{ label: 'None', value: '' }]
        },
        editVersion: {
            selectedVersion: {},
            selectedVp: {}
        },
        isRETTSProcessActionType: true
    },
    loginId: { username: '', password: '' },
};

const actions = {
    ...appAction,
    ...userAction,
    ...essAction,
    ...vpAction,
    ...cardAction
};

export { initialStates, actions };
