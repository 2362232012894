import { predicareAuthManager } from '@predicare/retts-lib';
import isEmpty from 'lodash/isEmpty';
import { errorMessage, getDataInTreeStructure } from './util';
import { apiFileDownload, apiGet, apiPost, unauthorizedApiGet, unauthorizedApiPost } from './request';
import {
    CHANGE_ACCOUNT_USER,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_BY_OTP,
    CREATE_USER,
    DELETE_ACCOUNT,
    DELETE_LICENSE,
    DELETE_USER,
    GET_ACCOUNT,
    GET_ACCOUNTS,
    GET_ACCOUNTS_ROLES_FOR_USERNAME,
    GET_ADMIN_EMAILS,
    GET_ALL_ADDRESSES,
    GET_CHILD_ACCOUNTS,
    GET_DEPARTMENT_USER,
    GET_ELEARNING_LINK,
    GET_LICENSE_TYPES,
    GET_LICENSES_FOR_ACCOUNT,
    GET_OVERVIEW,
    GET_TREE_CHILD_ACCOUNTS,
    GET_USER_EMAILS,
    GET_USERS_FOR_ACCOUNT,
    SAVE_ACCOUNT,
    SAVE_LICENSE,
    SAVE_USER,
    SEARCH_USER,
    SEND_OTC,
    SET_LANGUAGE,
    SET_NOTIFICATION_MESSAGE,
} from '../constant/api';
import { EMPTY_STRING } from '../constant/text';

export const authenticate = (username, password, passwordHash = null) => predicareAuthManager.login(username, password, passwordHash)
        .then(response => {
            if (response && response.jwt !== undefined && response.jwt !== null) {
                return {
                    response
                };
            }
            if (response.errorCode && response.errorCode === 1) {
                return ({
                    user: {},
                    error: errorMessage(['LOGIN_INVALID_LICENSE'])
                });
            }

            return ({
                user: {},
                error: errorMessage(['LOGIN_FAIL'])
            });
        }).catch(() => ({
                user: {},
                error: errorMessage(['GENERIC_ERROR'])
            }));

export const changeAccountUser = async userId => {
    const response = await apiPost(CHANGE_ACCOUNT_USER, { id: userId });
    if (response && response.errorCode === 1) {
        return ({
            error: errorMessage(['LOGIN_INVALID_LICENSE'])
        });
    }
    if (response && response.errorCode === 2) {
        return ({
            error: errorMessage(['LOGIN_FAIL'])
        });
    }

    return { response };
};

export const changePassword = (password, newPassword, email = EMPTY_STRING) => {
    if (isEmpty(email)) {
        return apiPost(CHANGE_PASSWORD, { password, newPassword });
    }

    return unauthorizedApiPost(CHANGE_PASSWORD_BY_OTP, { password, newPassword, newUsername: email });
};

export const createUser = async payload => {
    const data = await unauthorizedApiPost(CREATE_USER, payload);

    return data;
};

export const sendOtc = email => unauthorizedApiPost(SEND_OTC, { username: email });

export const getAccount = async accountId => {
    const data = await apiGet(`${GET_ACCOUNT}/${accountId}`);

    return data;
};

export const getChildAccounts = async accountId => {
    const data = await apiGet(`${GET_CHILD_ACCOUNTS}/${accountId}`);

    return data;
};

export const getTreeChildAccounts = async accountId => {
    const data = await apiGet(`${GET_TREE_CHILD_ACCOUNTS}/${accountId}`);
    if (!data.error) {
        return getDataInTreeStructure(data, accountId);
    }

    return data;
};

export const saveAccount = async account => {
    const data = await apiPost(`${SAVE_ACCOUNT}`, account);

    return data;
};

export const deleteAccount = async nodeId => {
    const data = await apiPost(`${DELETE_ACCOUNT}`, { id: nodeId });

    return data;
};

export const getAccounts = async () => {
    const data = await apiGet(`${GET_ACCOUNTS}`);

    return data;
};

export const getUsersForAccount = async accountId => {
    const data = await apiGet(`${GET_USERS_FOR_ACCOUNT}/${accountId}`);

    return data;
};

export const getOverviewDetails = async () => {
    const data = await apiGet(`${GET_OVERVIEW}`);

    return data;
};

export const setNotificationMessage = async values => {
    const data = await apiPost(`${SET_NOTIFICATION_MESSAGE}`, values);

    return data;
};

export const saveUser = async (user, override) => {
    const data = await apiPost(`${SAVE_USER}/${override}`, user);

    return data;
};

export const getDepartmentUser = async accountId => {
    const data = await apiGet(`${GET_DEPARTMENT_USER}/${accountId}`);

    return data;
};

export const deleteUser = async user => {
    const data = await apiPost(`${DELETE_USER}`, user);

    return data;
};

export const getAccountsRolesForUsername = async username => {
    const data = await apiGet(`${GET_ACCOUNTS_ROLES_FOR_USERNAME}/${username}`);

    return data;
};

export const getLicensesForAccount = async accountId => {
    const data = await apiGet(`${GET_LICENSES_FOR_ACCOUNT}/${accountId}`);

    return data;
};

export const getLicenseTypes = async () => {
    const data = await apiGet(`${GET_LICENSE_TYPES}`);

    return data;
};

export const deleteLicense = async licenseId => {
    const data = await apiPost(`${DELETE_LICENSE}`, { id: licenseId });

    return data;
};

export const saveLicense = async license => {
    const data = await apiPost(`${SAVE_LICENSE}`, license);

    return data;
};

export const downloadUserEmails = async fileName => {
    const data = await apiFileDownload(`${GET_USER_EMAILS}`, fileName);

    return data;
};

export const downloadAdminEmails = async fileName => {
    const data = await apiFileDownload(`${GET_ADMIN_EMAILS}`, fileName);

    return data;
};

export const downloadAllAddresses = async fileName => {
    const data = await apiFileDownload(`${GET_ALL_ADDRESSES}`, fileName);

    return data;
};

export const getSearchUsers = async () => {
    const data = await apiGet(`${SEARCH_USER}`);

    return data;
};

export const setLanguage = async languageId => {
    const data = await apiPost(SET_LANGUAGE, { languageId });

    return data;
};

export const getElearningLink = async () => unauthorizedApiGet(GET_ELEARNING_LINK);
