import React from 'react';
import { withTranslation } from './translation';
import { EMPTY_STRING } from '../../constant/text';

const RadioButtons = props => {
    const { t, options = [], name = EMPTY_STRING, className = EMPTY_STRING, onItemClick, value = EMPTY_STRING } = props;

    return (
        <ul className={`radioButtons ${className}`}>
            {
                options.map(item => (
                    <li className="radioField" key={item.label}>
                        <input className="radioButton clickable" type="radio" name={name} value={item.value} checked={item.value === value} onChange={() => onItemClick(item.value)} />
                        <span className="radioLabel">{t(item.label)}</span>
                    </li>
                    ))
            }
        </ul>
    );
};

export default withTranslation()(RadioButtons);
