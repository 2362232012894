/* eslint-disable no-return-await */
import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import { Link } from 'react-router-dom';
import { withTranslation } from '../../common/translation';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../common/table';
import Dropdown from '../../common/dropdown';
import { Label } from '../../common/text';
import { getEssListFromApi, getEssSearchById } from '../../../api/ess';
import { ESS, ESS_LIST_SORT_BY, SIZE, ESS_SCROLL_REF_PREFIX, SP, MAX_CARDS_TO_DISPLAY } from '../../../constant/text';
import { useStore } from '../../../store';
import { OPEN_POP_UP, SET_MESSAGE, SHOW_DETAILED_ESS_RANGE, ADD_SEARCH_CARD, CLEAR_MESSAGE } from '../../../constant/action';
import { ROLES, DEFAULT_USER_ROLE_ID } from '../../../constant/route';
import ConfirmBox from '../../common/confirmBox';
import Loader from '../../common/loader';
import { useMount } from '../../common/useMount';

const EssSpList = props => {
    const { t, essPackageId } = props;
    const mounted = useMount();
    const { dispatch, state: { card: { searchCards = [] } } } = useStore();
    const [essList, setEssList] = useState([]);
    const [loader, setLoader] = useState(false);
    const showDetailedEssRange = useCallback(data => dispatch({ type: SHOW_DETAILED_ESS_RANGE, payload: data }), [dispatch]);
    const addSearchCard = useCallback(payload => dispatch({ type: ADD_SEARCH_CARD, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    const fetchData = async fieldName => {
        if (essPackageId) {
            clearMessage();
            setLoader(true);
            const essList = await getEssListFromApi(essPackageId, fieldName);
            if (mounted.current) {
                setLoader(false);
                if (essList.error) {
                    setMessage(essList.error);
                } else {
                    setEssList(essList);
                }
            }
        }
    };

    useEffect(() => {
        const subscription = defer(() => {
            fetchData(ESS);
        }).subscribe(() => showDetailedEssRange(true));

        return () => subscription.unsubscribe();
    }, [essPackageId]);

    const orderBy = fieldName => {
        if (fieldName === ESS) {
            fetchData(ESS);
            showDetailedEssRange(true);
        } else {
            fetchData(SP);
            showDetailedEssRange(false);
        }
    };

    const addToSearchCards = obj => {
        if (searchCards.length < MAX_CARDS_TO_DISPLAY) {
            addSearchCard(obj);
        } else {
            openPopUp({
                component: <ConfirmBox
                    value="CLOSE_CHOICE_DIALOG"
                    chooseCardToCloseButton={true}
                    data={obj}
                />
            });
        }
    };

    const loadEss = async essNumber => {
        clearMessage();
        setLoader(true);
        getEssSearchById(essNumber).then(data => {
            if (mounted.current) {
                setLoader(false);
            }

            if (data.error) {
                if (mounted.current) {
                    setMessage(data.error);
                }
            } else {
                data.cardType = ESS;
                addToSearchCards(data);
            }
        });
    };

    return (
        <div className="essSpList loadable">
            {loader && <Loader />}
            <div className="essSortByFilter">
                <Label size={SIZE.S} value={t('SORT_BY')} />
                <Dropdown
                    size={SIZE.S}
                    options={ESS_LIST_SORT_BY}
                    onChange={val => orderBy(val)} />
            </div>
            <Table className={'striped-horizontally'}>
                <Thead>
                    <Tr>
                        <Th>{t('ESS_NUMBER')}</Th>
                        <Th>{t('SEARCH_PHRASE')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        essList && essList.map(ess => (
                            <Tr id={`${ESS_SCROLL_REF_PREFIX}${ess.number}`} key={`${ess.number}${Math.random()}`}>
                                <Td><Link className={'link'} to={ROLES[DEFAULT_USER_ROLE_ID].homePage} value={ess.number} onClick={async () => await loadEss(ess.id)}>{ess.number}</Link></Td>
                                <Td>
                                    {
                                            ess.searchPhrases.map(item => <Link className={'link'} to={ROLES[DEFAULT_USER_ROLE_ID].homePage} value={item.searchPhrase} key={item.searchPhrase} onClick={async () => await loadEss(ess.id)}>{item.searchPhrase}<br /></Link>)
                                        }
                                </Td>
                            </Tr>
                            ))
                    }
                </Tbody>
            </Table>
        </div>
    );
};

export default withTranslation()(EssSpList);
