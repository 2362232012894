import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { LOGIN_PAGE } from '../../constant/route';
import { isLoggedIn } from '../../api/util';
import { getUserStartPage } from '../util';
import { debugLog } from '../../util';
import { PRIMARY } from '../../constant/color';
import { withTranslation } from '../common/translation';
import Button from '../common/button';
import { Title } from '../common/text';

const NoSuchPage = props => {
    const { t } = props;
    const history = useHistory();

    useEffect(() => {

    });

    const navigateHome = () => {
        if (isLoggedIn()) {
            debugLog('NoSuchPage: Navigate to user start page');
            history.push(getUserStartPage());
        } else {
            debugLog('NoSuchPage: Navigate to login page');
            history.push(LOGIN_PAGE);
        }
    }

    return (
        <div className={'noPageHere'}>
            <Title value={'No such page'} align={'center'}/>
            <Button
                value={t('BACK')}
                btnType={PRIMARY}
                onClick={navigateHome}
            />
        </div>
    );
};

export default withTranslation()(NoSuchPage);
