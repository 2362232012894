import jwt from 'jwt-decode';
import CryptoJS from 'crypto-js';
import isEmpty from 'lodash/isEmpty';
import { EMPTY_STRING, LOCAL_ACCESS_TOKEN, LOCAL_STORAGE_SECRET_KEY } from '../constant/text';
import { debugLog } from '../util';

export const encryptMessage = message => {
    const encryptedMessage = CryptoJS.AES.encrypt(message, LOCAL_STORAGE_SECRET_KEY);

    return encryptedMessage.toString();
};

export const decryptMessage = message => {
    const decryptedBytes = CryptoJS.AES.decrypt(message, LOCAL_STORAGE_SECRET_KEY);
    const decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);

    return decryptedMessage;
};

export const setLocal = (key, value, securedMode = false) => {
    debugLog('setLocal: key: ', key, ' value: ', value);
    if (value === null || value === 'null' || value === undefined || value === 'undefined') {
        value = EMPTY_STRING;
    }
    if (typeof value === 'object' || typeof value === 'number' || Array.isArray(value) || typeof value === 'boolean') {
        value = JSON.stringify(value);
    }
    if (securedMode) {
        value = encryptMessage(value);
    }
    localStorage.setItem(key, value);
};

export const getFromLocal = (key, securedMode = false) => {
    let value = localStorage.getItem(key);
    if (value) {
        if (securedMode) {
            value = decryptMessage(value);
        }
        if (value === null || value === 'null' || value === undefined || value === 'undefined') {
            return EMPTY_STRING;
        }
        if (value[0] === '{' || value[0] === '[' || value === 'true' || value === 'false') {
            value = JSON.parse(value);
        }

        return value;
    }

    return EMPTY_STRING;
};

export const removeFromLocal = (keys = []) => {
    keys.forEach(key => {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
    });
};

export const clearLocal = () => {
    localStorage.clear();
};

export const parseJwt = () => {
    const token = getFromLocal(LOCAL_ACCESS_TOKEN);
    if (isEmpty(token)) {
        return {};
    }

    return jwt(token.trim());
};
