import React, { useEffect, useState, useCallback } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import compact from 'lodash/compact';
import find from 'lodash/find';
import LogRocket from 'logrocket';
import { useStore } from '../../../store';
import Dropdown from '../../common/dropdown';
import Loader from '../../common/loader';
import AutoComplete from '../../common/autoComplete';
import ProductSelector from '../../common/productSelector';
import {
    SEARCH_TYPES, ESS, VP, DEFINITION, SIZE, EMPTY_STRING, DEFINITIONLIST,
    DROPDOWN_SELECT_TEXT_VALUE,
    PUBLISHED,
    JOURNAL, ESSLOCALCHANGES
} from '../../../constant/text';
import { SET_VP_TYPES } from '../../../constant/action';
import { getEssSearchBySearchPharse, getEssSearchById } from '../../../api/ess';
import { getVpTypesByVpPackageId, getVpByVpTypeId } from '../../../api/vp';
import { getDefinitionListById } from '../../../api/definitionList';
import { getAssignedProduct, convertObjectsToDropdownList } from '../../util';
import { useMount } from '../../common/useMount';

const SearchForm = (props: any) => {
    const { onData, searchBy = ESS, showSearchBy = false, searchByOptions = SEARCH_TYPES, showProductSelector = false,
        showPublicationStatus = false, publicationStatus = PUBLISHED, essTypeId, allVersions = false,
        version, languageId } = props;

    const mounted = useMount();
    const [loader, setLoader] = useState(false);
    const [searchType, setSearchType] = useState();
    const [lang, setLang] = useState();
    const [searchValue, setSearchValue] = useState(EMPTY_STRING);
    const [searchResult, setSearchResult] = useState([]);
    const [definitionValue, setDefinitionValue] = useState(EMPTY_STRING);
    const [definitionResult, setDefinitionResult] = useState([]);
    const [langDefinition, setLangDefinition] = useState([]);
    const [vpType, setVpType] = useState(DROPDOWN_SELECT_TEXT_VALUE);
    const [essPackage, setEssPackage]: any = useState(getAssignedProduct());
    const { id: essPackageId, vppackageId: vpPackageId, typeId: selectedTypeId = 0, version: selectedVersion, selectedPublicationStatus, selectedAllVersions }: any = essPackage || {};
    let selectedLanguageId = languageId;

    if (!isEmpty(essPackage) && !essPackage.noProduct) {
        selectedLanguageId = essPackage.language.languageId;
    }

    const { dispatch, state: { vp: { vpTypes = [] } } } = useStore();

    const setVpTypes = useCallback(payload => dispatch({ type: SET_VP_TYPES, payload }), [dispatch]);

    useEffect(() => {
        setLang(languageId);
    }, [languageId]);

    useEffect(() => {
        const subscription = defer(() => manageSearchTypes(searchBy)).subscribe();

        return () => subscription.unsubscribe();
    }, [searchBy, showSearchBy]);

    useEffect(() => {
        setEssPackage(getAssignedProduct());
    }, [version]);

    useEffect(() => {
        const subscription = defer(() => fetchVpTypesByVpPackageId(vpPackageId)).subscribe();

        return () => subscription.unsubscribe();
    }, [vpPackageId]);

    const dataOutput = data => {
        onData({ ...data, cardType: searchType, selectedPublicationStatus, selectedTypeId, selectedVersion, selectedLanguageId, selectedAllVersions });
    };

    const fetchDefinitionsForLanguage = async language => {
        setLoader(true);
        const definitionList = await getDefinitionListById(language);
        if (mounted.current) {
            setLoader(false);

            if (!definitionList.error) {
                setLangDefinition(definitionList);
            }
        }
    };

    const fetchVpTypesByVpPackageId = async vpPackageId => {
        setLoader(true);
        const vptyps = await getVpTypesByVpPackageId(vpPackageId);
        if (mounted.current) {
            setLoader(false);

            if (!vptyps.error) {
                setVpTypes(vptyps);
            }
        }
    };

    const manageSearchTypes = async searchType => {
        if (mounted.current) {
            setSearchType(searchType);
            setVpType(DROPDOWN_SELECT_TEXT_VALUE);
            if (searchType === DEFINITION) {
                fetchDefinitionsForLanguage(lang);
                showSearchBy && onData({ cardType: DEFINITION });
            } else if (searchType === VP) {
                showSearchBy && onData({ cardType: VP });
            } else if (searchType === DEFINITIONLIST) {
                showSearchBy && onData({ cardType: DEFINITIONLIST });
            } else if (searchType === JOURNAL) {
                showSearchBy && onData({ cardType: JOURNAL });
            } else if (searchType === ESS) {
                showSearchBy && onData({ cardType: ESS });
            } else if (searchType === ESSLOCALCHANGES) {
                showSearchBy && onData({ cardType: ESSLOCALCHANGES });
            }
        }
    };

    const manageAutoSearch = async val => {
        LogRocket.log('ESS search phrase', { searchPhrase: val });
        setSearchValue(val);
        if (essPackageId && val) {
            setLoader(true);
            const data = await getEssSearchBySearchPharse(essPackageId, val);
            if (mounted.current) {
                setLoader(false);
                if (data.error) {
                    setSearchResult([]);
                } else {
                    setSearchResult(data.map((item, i) => ({ label: item.searchPhrase, value: item.id, key: i })));
                }
            }
        } else {
            setSearchResult([]);
        }
    };

    const manageDefinition = async val => {
        setDefinitionValue(val);
        const pattern = new RegExp(`${val.toLowerCase()}`);
        // @ts-ignore
        const data = compact(map(langDefinition, item => (item.key.toLowerCase()).match(pattern) && item));
        // @ts-ignore
        setDefinitionResult(map(data, item => ({ value: item.id, label: item.key, key: item.id })));
    };

    const manageAutoComplete = async val => {
        LogRocket.log('Selected ESS id', { id: val });
        setSearchResult([]);
        setLoader(true);
        const data = await getEssSearchById(val);

        if (mounted.current) {
            setLoader(false);
            if (data.error) {
                LogRocket.error('ESS search error', { error: data.error });
            } else {
                data.cardType = ESS;
                dataOutput(data);
                setSearchValue(EMPTY_STRING);
                LogRocket.log('ESS search result', { cardType: data.cardType, id: data.id, essNumber: data.number, packageId: data.packageId, version: data.packageVersion });
            }
        }
    };

    const manageVpTypeChange = async (val, vpPackageId) => {
        setLoader(true);
        const data = await getVpByVpTypeId(vpPackageId, val);
        if (mounted.current) {
            setLoader(false);
            if (!data.error) {
                data.cardType = VP;
                dataOutput(data);
            }
        }
    };

    const manageAutoDefinitionComplete = async val => {
        setDefinitionValue(EMPTY_STRING);
        setDefinitionResult([]);
        // @ts-ignore
        const data = find(langDefinition, item => item.id === val);
        // @ts-ignore
        data.cardType = DEFINITION;
        dataOutput(data);
    };

    const onSetEssPackage = data => {
        setEssPackage(data);
        if (searchType === JOURNAL) {
            data.cardType = JOURNAL;
            dataOutput(data);
        } else if (searchType === ESSLOCALCHANGES) {
            data.cardType = ESSLOCALCHANGES;
            dataOutput(data);
        }
    };

    return (
        <>
            <div className="searchForm loadable">
                {showSearchBy && (
                    <Dropdown
                        dataAttribute="searchForm_select_search"
                        size={SIZE.M}
                        onChange={manageSearchTypes}
                        value={searchType}
                        options={searchByOptions}
                    />
                )}
                {showProductSelector && (searchType === ESS || searchType === VP || searchType === JOURNAL || searchType === ESSLOCALCHANGES) && (
                    <ProductSelector
                        name={searchType}
                        setEssPackage={onSetEssPackage}
                        publicationStatus={publicationStatus}
                        showPublicationStatus={showPublicationStatus}
                        essTypeId={essTypeId}
                        allVersions={allVersions}
                        version={version}
                        languageId={languageId}
                    />
                )}
                {searchType === ESS && (
                    <AutoComplete
                        dataAttribute="searchForm_input_search"
                        size={SIZE.M}
                        data={searchResult}
                        onChange={manageAutoSearch}
                        label="SEARCH_PLACEHOLDER"
                        value={searchValue}
                        id="Search ESS for user"
                        onAutocomplete={manageAutoComplete}
                        filterType={() => { }}
                    />
                )}
                {searchType === VP && vpTypes && !isEmpty(vpTypes) && (
                    <Dropdown
                        keyPrefix="vptype"
                        size={SIZE.M}
                        onChange={val => manageVpTypeChange(val, vpPackageId)}
                        value={vpType}
                        options={convertObjectsToDropdownList(vpTypes, 'id', 'type')}
                        selectLabel="SELECT_VP"
                        sortReverse={true}
                    />
                )}
                {searchType === DEFINITION && (
                    <AutoComplete
                        size={SIZE.M}
                        data={definitionResult}
                        onChange={manageDefinition}
                        label="DEFINITION_TERM"
                        value={definitionValue}
                        id="Definition terms"
                        onAutocomplete={manageAutoDefinitionComplete}
                        filterType={() => { }}
                    />
                )}
            </div>
            {loader && <Loader />}
        </>
    );
};

export default SearchForm;
