import { apiGet, apiPost, apiDelete, apiFileDownload, apiFileDownloadPost } from './request';
import {
    GET_ESS_LIST,
    GET_ESS_DATA_BY_SEARCHPHRASE,
    GET_ESS_SEARCH_DATA_BY_ID,
    GET_ESS_CLASSIFICATION,
    DELETE_ESS_PACKAGE,
    GET_ESS_PDF,
    GET_ESS_XML,
    GET_SCHEMA_XSD,
    GET_ESS_INTERVAL_PDF,
    INIT_NEW_VERSION,
    CREATE_NEW_VERSION,
    CREATE_LOCAL_ESS_PACKAGE,
    GET_FIRST_ESS_NUMBR_DETAIL_IN_PACKAGE,
    GET_ADJACENT_ESS_NUMBER_IDS_BY_PACKAGE_AND_ESS_NUMBER_ID,
    GET_ESS_NUMBER_DETAIL_BY_PACKAGE_AND_NUMBER,
    GET_ESS_CHANGELOG_PDF,
    GET_ESS_PDF_COMPPACK,
    GET_ESS_PACKAGES,
    GET_ESS_ONGOING_LOCAL,
    GET_ESS_PUBLISHED_LOCAL,
    GET_ESS_VERSIONS_FOR_ESS_TYPE_ID,
    GET_ESS_LANGUAGES_FOR_ESS_TYPE_ID_AND_ESS_VERSION,
    GET_ESS_PACKAGE_BY_TYPE_VERSION_LANGUAGE,
    GET_DEFAULT_PRODUCTS_FOR_USER,
    SAVE_ESS,
    GET_JOURNALAUDIT_FROM_PACKAGEID,
    SET_DEFAULT_LOCAL_PACKAGE,
    GET_PRODUCTS_FOR_ACCOUNT,
    GET_JOURNALS_FROM_PACKAGEID,
    GET_ESS_AUDIT,
    GET_ESSLOCALCHANGES_AUDIT,
    DOWNLOAD_JOURNALS_BY_ID,
    PUBLISH_ONGOING_VERSION,
    ADD_PACKAGE_TYPE,
    ADD_PACKAGE_LANGUAGE,
    GET_ESS_CODE_TYPES,
    GET_DEFAULT_PROCESS_ACTIONS,
} from '../constant/api';
import { getFromLocal } from './localStorageUtil';
import { LOCAL_LANGUAGE_CODE, ALL, SVENSKA_CODE } from '../constant/text';

export const getEssCodeTypes = async () => {
    const data = await apiGet(`${GET_ESS_CODE_TYPES}`);

    return data;
};

export const getEssListFromApi = async (essPackageId, orderBy) => {
    const data = await apiGet(`${GET_ESS_LIST}/${essPackageId}?orderBy=${orderBy}`);

    return data;
};

export const getEssAudit = async (payload, fileName) => {
    const data = await apiFileDownloadPost(`${GET_ESS_AUDIT}`, payload, fileName, 'DOWNLOAD_FAILED');

    return data;
};

export const getEssLocalChangesAudit = async (payload, fileName) => {
    const data = await apiFileDownloadPost(`${GET_ESSLOCALCHANGES_AUDIT}`, payload, fileName, 'DOWNLOAD_FAILED');

    return data;
};

export const saveEss = async payload => {
    const data = await apiPost(`${SAVE_ESS}`, payload);

    return data;
};

export const getEssSearchBySearchPharse = async (essPackageId, searchPhrase) => {
    const data = await apiGet(`${GET_ESS_DATA_BY_SEARCHPHRASE}/${essPackageId}/${searchPhrase}`);

    return data;
};

export const getEssSearchById = async essHeaderId => {
    const data = await apiGet(`${GET_ESS_SEARCH_DATA_BY_ID}/${essHeaderId}`);

    return data;
};

export const createLocalEssPackageById = async essPackageId => {
    const data = await apiPost(`${CREATE_LOCAL_ESS_PACKAGE}`, { id: essPackageId });

    return data;
};

export const getFirstEssNumberDetail = async essPackageId => {
    const data = await apiGet(`${GET_FIRST_ESS_NUMBR_DETAIL_IN_PACKAGE}/${essPackageId}`);

    return data;
};

export const getAdjacentEssNumberIdsByPackageAndEssNumberId = async (essPackageId, essNumberId) => {
    const data = await apiGet(`${GET_ADJACENT_ESS_NUMBER_IDS_BY_PACKAGE_AND_ESS_NUMBER_ID}/${essPackageId}/${essNumberId}`);

    return data;
};

export const getEssNumberDetailByPackageAndNumber = async (essPackageId, essNumber) => {
    const data = await apiGet(`${GET_ESS_NUMBER_DETAIL_BY_PACKAGE_AND_NUMBER}/${essPackageId}/${essNumber}`);

    return data;
};

export const getDefaultProcessActions = async essHeaderId => apiGet(`${GET_DEFAULT_PROCESS_ACTIONS}/${essHeaderId}`);

export const downloadEssChangeLogAsPdf = async (essPackageId, fileName) => {
    const data = await apiFileDownload(`${GET_ESS_CHANGELOG_PDF}/${essPackageId}/${getFromLocal(LOCAL_LANGUAGE_CODE)}`, fileName, 'DOWNLOAD_FAILED');

    return data;
};

export const downloadEssAsPdf = async (obj, fileName) => {
    const { packageId, localAdjustments = true, languageCode = SVENSKA_CODE, landscape = false, compressed = false, alphaIndex = false, numIndex = false } = obj;
    const data = await apiFileDownload(`${GET_ESS_PDF}/${packageId}/${localAdjustments}/${languageCode}/${landscape}/${compressed}/${alphaIndex}/${numIndex}`, fileName, 'DOWNLOAD_FAILED');

    return data;
};

export const downloadEssIntervalAsPdf = async (obj, fileName) => {
    const { packageId, firstEss, lastEss, localAdjustments = true, languageCode, landscape = false, compressed = false, alphaIndex = false, numIndex = false } = obj;
    const data = await apiFileDownload(`${GET_ESS_INTERVAL_PDF}/${packageId}/${firstEss}/${lastEss}/${localAdjustments}/${languageCode}/${landscape}/${compressed}/${alphaIndex}/${numIndex}`, fileName, 'DOWNLOAD_FAILED');

    return data;
};

export const downloadEssAsXml = async (obj, fileName) => {
    const { packageId, localAdjustments = true } = obj;
    const data = await apiFileDownload(`${GET_ESS_XML}/${packageId}/${localAdjustments}`, fileName, 'DOWNLOAD_FAILED');

    return data;
};

export const downloadSchemaAsXsd = async fileName => {
    const data = await apiFileDownload(`${GET_SCHEMA_XSD}/${fileName}`, fileName);

    return data;
};

export const getEssClassificationListFromApi = async essPackageId => {
    const data = await apiGet(`${GET_ESS_CLASSIFICATION.replace('[ess_package_id]', essPackageId)}`);

    return data;
};

export const removeEssPackages = async essPackageId => {
    const data = await apiDelete(`${DELETE_ESS_PACKAGE}/${essPackageId}`);

    return data;
};

export const getInitiateNewVersion = async () => {
    const data = await apiGet(`${INIT_NEW_VERSION}`);

    return data;
};

export const createNewVersion = async newData => {
    const data = await apiPost(`${CREATE_NEW_VERSION}`, newData);

    return data;
};

export const getEssPackages = async () => {
    const data = await apiGet(`${GET_ESS_PACKAGES}`);

    return data;
};

export const getJournalsFromVersiosnId = async id => {
    const data = await apiGet(`${GET_JOURNALS_FROM_PACKAGEID}/${id}`);

    return data;
};

export const getJournalAudit = async (payload, fileName) => {
    const { id: essPackageId, date, time } = payload;
    const data = await apiGet(`${GET_JOURNALAUDIT_FROM_PACKAGEID}/${essPackageId}/${date}/${time}`, fileName, 'NO_JOURNAL_HISTORY_FOUND');

    return data;
};

export const getLocalPublishedEssPackages = async () => {
    const data = await apiGet(`${GET_ESS_PUBLISHED_LOCAL}`);

    return data;
};

export const getLocalOngoingEssPackages = async () => {
    const data = await apiGet(`${GET_ESS_ONGOING_LOCAL}`);

    return data;
};
export const downloadJournalAsPdf = async (obj, fileName) => {
    const { packageId } = obj;
    const data = await apiFileDownload(`${DOWNLOAD_JOURNALS_BY_ID}/${packageId}`, fileName, 'NO_SUCH_JOURNAL');

    return data;
};

export const getEssPdfCompPack = async (newPkgId, id, languageCode) => {
    const data = await apiGet(`${GET_ESS_PDF_COMPPACK}/${id}/${newPkgId}/${languageCode}`);

    return data;
};

export const getEssVersionsForEssTypeId = async (essTypeId, pub) => {
    let url = `${GET_ESS_VERSIONS_FOR_ESS_TYPE_ID}/${essTypeId}`;
    if (pub !== ALL) {
        url += `?published=${pub}`;
    }
    const data = await apiGet(url);

    return data;
};

export const getEssLanguagesForEssTypeIdAndEssVersion = async (essTypeId, essVersion, pub) => {
    let url = `${GET_ESS_LANGUAGES_FOR_ESS_TYPE_ID_AND_ESS_VERSION}/${essTypeId}/${essVersion}`;
    if (pub !== ALL) {
        url += `?published=${pub}`;
    }
    const data = await apiGet(url);

    return data;
};

export const getEssPackageByTypeVersionLanguage = async (essTypeId, essVersion, essLanguageId) => {
    const data = await apiGet(`${GET_ESS_PACKAGE_BY_TYPE_VERSION_LANGUAGE}/${essTypeId}/${essVersion}/${essLanguageId}`);

    return data;
};

export const getProducts = async pub => {
    let url = `${GET_PRODUCTS_FOR_ACCOUNT}`;
    if (pub !== ALL) {
        url += `?published=${pub}`;
    }
    const data = await apiGet(url);

    return data;
};

export const getAssignableProductsForAccount = async accountId => {
    const data = await apiGet(`${GET_PRODUCTS_FOR_ACCOUNT}/${accountId}?filter=AVAILABLE`);

    return data;
};

export const getProductsForAccount = async accountId => {
    const data = await apiGet(`${GET_PRODUCTS_FOR_ACCOUNT}/${accountId}`);

    return data;
};

export const deleteAvailableProduct = async (accountId, product) => {
    const data = await apiDelete(`${GET_PRODUCTS_FOR_ACCOUNT}/${accountId}`, product);

    return data;
};

export const assignAssignableProduct = async (accountId, product) => {
    const data = await apiPost(`${GET_PRODUCTS_FOR_ACCOUNT}/${accountId}`, product);

    return data;
};

export const setDefaultLocalPackage = async id => {
    const data = await apiPost(`${SET_DEFAULT_LOCAL_PACKAGE}/${id}`);

    return data;
};

export const getDefaultProductsForUser = async () => {
    const data = await apiGet(`${GET_DEFAULT_PRODUCTS_FOR_USER}`);

    return data;
};

export const publishVersion = async newData => {
    const data = await apiPost(`${PUBLISH_ONGOING_VERSION}`, newData);

    return data;
};

export const addNewPackageType = async newData => {
    const data = await apiPost(`${ADD_PACKAGE_TYPE}`, newData);

    return data;
};

export const addNewLanguageType = async newData => {
    const data = await apiPost(`${ADD_PACKAGE_LANGUAGE}`, newData);

    return data;
};
