import React from 'react';
import { XmlIcon, PdfIcon } from '../../common/icon';
import { withTranslation } from '../../common/translation';
import { XML, PDF, XSD, EMPTY_STRING } from '../../../constant/text';
import Loader from '../../common/loader';

const ExportDownload = props => {
    const { t, iconType = PDF, desc1, desc2, note = EMPTY_STRING, onClick = () => { }, loader = false, disabled = false } = props;

    const getIcon = iconType => {
        switch (iconType) {
            case PDF:
                return <PdfIcon />;

            case XML:
                return <XmlIcon />;

            case XSD:
                return <XmlIcon />;

            default:
                return null;
        }
    };

    const classes = disabled ? 'exportDownload loadable' : 'exportDownload clickable loadable';

    return (
        <div className={classes} onClick={() => (disabled ? {} : onClick())}>
            <div className="icon">{getIcon(iconType)}</div>
            {!disabled && (
                <>
                    <div>
                        {desc1 && desc2 && <div className="productVersion">{`${t(desc1.trim())}, ${desc2.trim()}`}</div>}
                        <br/>
                        <div className="note">{t(note)}</div>
                    </div>
                    <div>
                        {loader && <Loader />}
                    </div>
                </>
              )}
            {disabled && (
                <div>
                    <p><i>{t('EXPORTS_DISABLED_INFORMATION')}</i></p>
                </div>
            )}
        </div>
    );
};

export default withTranslation()(ExportDownload);
