import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from './translation';
import { EMPTY_STRING } from '../../constant/text';

const LabelTextTable = props => {
    const { t, labelTexts = [], prefix = EMPTY_STRING } = props;

    return (
        <table className="labelTextTable">
            <thead />
            <tbody>
                {
                    !isEmpty(labelTexts) && labelTexts.map(labelText => {
                        const { label = EMPTY_STRING, text = EMPTY_STRING } = labelText;

                        return (
                            <tr key={`${prefix}-${label}`} className="labelTextRow">
                                <td className="tableLeftLabel">{t(label)}</td>
                                <td className="tableRightText">{t(text)}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
};

export default withTranslation()(LabelTextTable);
