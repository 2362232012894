export const COLOR = {
    BLACK: '#000000',
    WHITE: '#ffffff',
    DARK_0: '#2b2b2b',
    DARK_1: '#283742',
    DARK_2: '#30404D',
    DARK_3: '#3A4B59',
    DARK_4: '#586f82',
    DARK_5: '#137cbd',
    DARK_6: '#2c4f69',
    GREY_1: '#3e3f3e',
    GREY_2: '#4a4b4a',
    GREY_3: '#565756',
    GREY_4: '#828282',
    GREY_5: '#e4e4e4',
    LIGHTGREY: '#d0d0d0',
    ORANGE: '#ff9933',
    SKY_BLUE: '#00ccff',
    BLUE: '#3e9dcc',
    SUCCESS: '#00720d',
    SUCCESS_BACKGROUND: '#beebc0',
    ERROR: '#f81504',
    ERROR_BACKGROUND: '#f7c4c0',
    WARNING: '#bc7308',
    WARNING_BACKGROUND: '#f7cb8d',
    INFO: '#048bb4',
    INFO_BACKGROUND: '#caffff',
    DARK_SKY_BLUE: '#607d8b',
    LIGHT_SKY_BLUE: '#e5eaea',
    DARK_CYAN: '#008b8b',
    LIGHT_THEME_TEXT_COLOR: '#292929',
    BRIGHT_LINK_BLUE: '#5bcaff',
    ORANGE_2: '#FF9800',

};

export const DEFAULT = 'btn-default';
export const PRIMARY = 'btn-primary';
export const SECONDARY = 'btn-secondary';
