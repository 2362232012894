import React, { useState, useEffect, useCallback } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import PopUpCloseIcon from '../../common/popUpCloseIcon';
import { withTranslation } from '../../common/translation';
import { Title, Label } from '../../common/text';
import Dropdown from '../../common/dropdown';
import Loader from '../../common/loader';
import { getLicenseTypes, getTreeChildAccounts, deleteLicense, saveLicense, getLicensesForAccount } from '../../../api/user';
import { useStore } from '../../../store';
import { SET_MESSAGE, CLOSE_POP_UP, SET_LICENSES, CLEAR_MESSAGE } from '../../../constant/action';
import { convertObjectsToDropdownList } from '../../util';
import { errorMessage, successMessage } from '../../../api/util';
import InfoBox from '../../common/infoBox';
import Tree from './tree';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import { DEFAULT_LICENSE_TYPE_ID, TREE_NODE_TYPE_LICENSE } from '../../../constant/text';
import { useMount } from '../../common/useMount';

const AssignLicense = props => {
    const { t, node } = props;
    const mounted = useMount();
    const [loader, setLoader] = useState(false);
    const [licenseTypeId, setLicenseTypeId] = useState(DEFAULT_LICENSE_TYPE_ID);
    const [licenseTypes, setLicenseTypes] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [nodes, setNodes] = useState({});
    const [saveNodes, setSaveNodes] = useState({});
    const [deleteNodes, setDeleteNodes] = useState({});
    const { name: accountName, id: accountId } = node;
    const { dispatch, state: { licenses = [] } } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const closePopUp = useCallback(payload => dispatch({ type: CLOSE_POP_UP, payload }), [dispatch]);
    const setLicenses = useCallback(payload => dispatch({ type: SET_LICENSES, payload }), [dispatch]);

    useEffect(() => {
        const fetchLicenseTypes = async () => {
            setLoader(true);
            clearMessage();
            const licenseTypeList = await getLicenseTypes();
            if (mounted.current) {
                setLoader(false);
                if (licenseTypeList.error) {
                    setMessage(licenseTypeList.error);
                } else {
                    setLicenseTypes(licenseTypeList);
                    setLoader(true);
                    const childAccs = await getTreeChildAccounts(accountId);
                    if (mounted.current) {
                        setLoader(false);
                        if (childAccs.error) {
                            setMessage(childAccs.error);
                        } else {
                            setNodes(childAccs);
                        }
                    }
                }
            }
        };
        const subscription = defer(() => fetchLicenseTypes()).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const onLicenseTypeChange = licenseTypeId => {
        setDisabled(false);
        setLicenseTypeId(licenseTypeId);
    };

    const getValidMonths = () => {
        if (isEmpty(licenseTypes)) {
            return 0;
        }

        return licenseTypes.filter(licenseType => licenseType.id === licenseTypeId)[0].availableMonthsAfterInvalid;
    };

    const getAlreadyExistingLicenseId = node => {
        const { accountType: { id: accountTypeId }, id: accountId } = node;
        const filteredLicenses = licenses.filter(license => {
            const { licenseType: { id: itemLicenseTypeId }, accountType: { id: itemAccountTypeId }, boughtByAccountId, usedByAccountId } = license;

            return (
                licenseTypeId === itemLicenseTypeId
                && accountTypeId === itemAccountTypeId
                && accountId === boughtByAccountId
                && accountId === usedByAccountId
            );
        });

        if (filteredLicenses.length > 0) {
            return filteredLicenses[0].id;
        }

        return false;
    };

    const updateNode = node => {
        const { id: nodeId } = node;
        setDisabled(false);
        const licenseId = getAlreadyExistingLicenseId(node);
        if (licenseId) {
            node.licenseId = licenseId;
        }
        nodes[nodeId] = node;
        if (node.hasLicense) {
            delete deleteNodes[nodeId];
            setSaveNodes({ ...saveNodes, [nodeId]: node });
        } else if (!licenseId && !node.hasLicense) {
            delete saveNodes[nodeId];
            setDeleteNodes({ ...deleteNodes, [nodeId]: node });
        } else {
            delete saveNodes[nodeId];
            setDeleteNodes({ ...deleteNodes, [nodeId]: node });
        }
        setNodes({ ...nodes, [nodeId]: node });
    };

    const onNodeSelect = node => {
        node.hasLicense = !node.hasLicense;
        updateNode(node);
    };

    const setSelectedYear = (node, year) => {
        node.validTo = year;
        updateNode(node);
    };

    const assignLicense = async node => {
        const { accountType: { id: accountTypeId }, id: nodeId, validTo } = node;
        const license = {
            licenseType: { id: licenseTypeId },
            accountType: { id: accountTypeId },
            boughtByAccountId: nodeId,
            usedByAccountId: nodeId,
            validTo,
            paid: true
        };
        if (node.licenseId) {
            license.id = node.licenseId;
        }
        clearMessage();
        const saved = await saveLicense(license);
        if (mounted.current) {
            if (saved.error) {
                setMessage(saved.error);
            }
        }
    };

    const removeLicense = async node => {
        if (node.licenseId) {
            clearMessage();
            const saved = await deleteLicense(node.licenseId);
            if (mounted.current) {
                if (saved.error) {
                    setMessage(saved.error);
                }
            }
        }
    };

    const onSaveLicenses = () => {
        setDisabled(true);
        setLoader(true);
        const promises = [];
        if (!isEmpty(saveNodes)) {
            Object.keys(saveNodes).forEach(key => {
                promises.push(assignLicense(saveNodes[key]));
            });
        }
        if (!isEmpty(deleteNodes)) {
            Object.keys(deleteNodes).forEach(key => {
                promises.push(removeLicense(deleteNodes[key]));
            });
        }
        Promise.all(promises).then(async () => {
            if (mounted.current) {
                setLoader(true);
                clearMessage();
                const licenses = await getLicensesForAccount(accountId);
                if (mounted.current) {
                    setLoader(false);
                    if (licenses.error) {
                        setMessage(licenses.error);
                    } else {
                        setLicenses(licenses);
                        setMessage(successMessage(['ASSIGN_LICENSE_SUCCESS']));
                    }
                }
                closePopUp();
            }
        }).catch(() => {
            if (mounted.current) {
                setMessage(errorMessage(['ASSIGN_LICENSE_FAILED']));
                setLoader(false);
                setDisabled(false);
            }
        });
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader} />
            <div className="popUpBody">
                <Title value={t(accountName)} />
                {loader && <Loader />}
                <div className="labelAndField">
                    <Label value={`${t('TYPE_OF_LICENSE')}:`} />
                    <Dropdown
                        options={convertObjectsToDropdownList(licenseTypes, 'id', 'type')}
                        onChange={onLicenseTypeChange}
                        value={licenseTypeId}
                    />
                </div>
                <InfoBox>{`${t('LICENSE_EXPIRES_INFO')} ${getValidMonths()} ${t('MONTHS')}`}</InfoBox>
                {
                    !isEmpty(nodes) && (
                        <div className="organizationTree">
                            <Tree nodes={nodes} onNodeSelect={onNodeSelect} type={TREE_NODE_TYPE_LICENSE} setSelectedYear={setSelectedYear} />
                        </div>
                    )
                }
                <div className="buttonField-right">
                    <Button btnType={PRIMARY} value={t('SAVE')} onClick={onSaveLicenses} disabled={disabled} />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(AssignLicense);
