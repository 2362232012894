import React, { useState, useCallback, useEffect } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import PopUpCloseIcon from '../../common/popUpCloseIcon';
import Loader from '../../common/loader';
import { withTranslation } from '../../common/translation';
import { Title } from '../../common/text';
import Dropdown from '../../common/dropdown';
import TextField from '../../common/textField';
import { getOverviewRolesList, getFilteredSearchUsers } from '../../util';
import { getSearchUsers } from '../../../api/user';
import { Table, Thead, Tbody, Th, Tr, Td } from '../../common/table';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import { DROPDOWN_SELECT_TEXT_VALUE, EMPTY_STRING } from '../../../constant/text';
import { useStore } from '../../../store';
import { useMount } from '../../common/useMount';

const SearchUser = props => {
    const { t } = props;
    const [loader, setLoader] = useState();
    const mounted = useMount();
    const [selectedRoleId, setSelectedRoleId] = useState(DROPDOWN_SELECT_TEXT_VALUE);
    const [searchWord, setSearchWord] = useState(EMPTY_STRING);
    const [searchUsers, setSearchUsers] = useState([]);
    const [filteredSearchUsers, setFilteredSearchUsers] = useState([]);
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const fetchAllUsers = async () => {
            setLoader(true);
            clearMessage();
            const searchUsers = await getSearchUsers();
            if (mounted.current) {
                setLoader(false);
                if (searchUsers.error) {
                    setMessage(searchUsers.error);
                } else {
                    setSearchUsers(searchUsers);
                }
            }
        };
        const subscription = defer(() => fetchAllUsers()).subscribe();

        return () => subscription.unsubscribe();
    }, []);

    const onRoleChange = val => {
        setSelectedRoleId(val);
        setFilteredSearchUsers(getFilteredSearchUsers(searchUsers, searchWord, val));
    };

    const onSearchWordChange = val => {
        setSearchWord(val);
        setFilteredSearchUsers(getFilteredSearchUsers(searchUsers, val, selectedRoleId));
    };

    return (
        <div className="popUpComponent loadable">
            <PopUpCloseIcon blur={loader} />
            <div className="popUpBody">
                <Title value={t('SEARCH_FOR_USER')} />
                {loader && <Loader />}
                <div className="searchFormInline">
                    <TextField
                        value={searchWord}
                        onChange={onSearchWordChange}
                        placeholder={'SEARCH_FOR_USER'}
                    />
                    <Dropdown
                        options={getOverviewRolesList()}
                        onChange={onRoleChange}
                        value={selectedRoleId}
                        selectLabel="ALL_ROLES"
                        keyPrefix
                    />
                </div>
                {
                    !isEmpty(filteredSearchUsers) && (
                        <Table className={'striped-horizontally'}>
                            <Thead>
                                <Tr>
                                    <Th>{t('USER')}</Th>
                                    <Th>{t('ACCOUNT')}</Th>
                                    <Th>{t('ROLE')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    filteredSearchUsers.map(searchUser => {
                                        const { name, username, account: { name: accountName }, role: { description: roleName } } = searchUser;

                                        return (
                                            <Tr key={`${name}-${username}-${accountName}-${roleName}`}>
                                                <Td>
                                                    <div className="searchedName">{t(name)}</div>
                                                    <div className="searchedUsername">{t(username)}</div>
                                                </Td>
                                                <Td>{t(accountName)}</Td>
                                                <Td>{t(roleName)}</Td>
                                            </Tr>
                                        );
                                    })
                                }
                            </Tbody>
                        </Table>
                    )
                }
            </div>
        </div>
    );
};

export default withTranslation()(SearchUser);
