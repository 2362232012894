import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation } from '../common/translation';
import { getMenuList } from '../util';
import { MenuIcon } from '../common/icon';
import { HeaderContainer } from '../../style/theme';
import SideBar from './sideBar';
import '../../style/index.scss';
import { isLoggedIn } from '../../api/util';
import AppLogo from './appLogo';
import { EMPTY_STRING } from '../../constant/text';
import UserDetails from './userDetails';
import { useLoginManager } from '../../UseLoginManager';

const Header = props => {
    const { t } = props;
    const { user } = useLoginManager();
    const { pathname } = useLocation();
    const [sideNavOpen, setSideNavOpen] = useState(false);
    const isActive = paths => paths.includes(pathname);

    const onOutsideClick = outsideId => {
        if (outsideId === 'sideBarBackgroundOverlay') {
            setSideNavOpen(false);
        }
    };

    return (
        <HeaderContainer className={isLoggedIn() ? 'header' : 'header logoutHeader'}>
            <UserDetails
                username={user ? user.username : ''}
                rolename={user ? t(user.roleName) : ''}
                accountname={user ? user.accountName : ''}
            />
            <AppLogo/>
            <div className="iconContainer">
                {isLoggedIn() && getMenuList().map(menuIcon => {
                    const { icon: Icon, paths, toolTip } = menuIcon;

                    return (
                        <Link to={paths[0]} key={paths[0]}>
                            <Icon
                                toolTip={t(toolTip)}
                                className={`headerIcon menuIcon ${isActive(paths) ? 'active' : EMPTY_STRING}`}
                            />
                        </Link>
                    );
                })}
                <MenuIcon onClick={() => setSideNavOpen(true)} className="headerIcon menuIcon"/>
            </div>
            {sideNavOpen
                && (
                    <>
                        <SideBar setSideNavOpen={setSideNavOpen} sideNavOpen={sideNavOpen}/>
                        <div
                            className="backgroundOverlay"
                            id="sideBarBackgroundOverlay"
                            onClick={e => onOutsideClick(e.target.id)}/>
                    </>
                )
            }
        </HeaderContainer>
    );
};

export default withTranslation()(Header);
