import React, { useState, useCallback, useEffect } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../../../common/translation';
import { Title } from '../../../../common/text';
import { SIZE, EMPTY_STRING } from '../../../../../constant/text';
import Line from '../../../../common/line';
import RadioButtons from '../../../../common/radioButtons';
import { getInfoByTextType } from '../../../../util';
import TextArea from '../../../../common/textArea';
import Button from '../../../../common/button';
import ConfirmBox from '../../../../common/confirmBox';
import { PRIMARY, SECONDARY } from '../../../../../constant/color';
import { SET_MESSAGE, OPEN_POP_UP, CLEAR_MESSAGE } from '../../../../../constant/action';
import { saveEss } from '../../../../../api/ess';
import { useStore } from '../../../../../store';
import Loader from '../../../../common/loader';
import { useMount } from '../../../../common/useMount';
import CharacterButtons from '../../../../common/characterButtons';
import { ProcessActionsAndFactsEnum } from '../../../../../types/ProcessActionAndFactsEnum';

const LocalFactBox = props => {
    const { t, ess, setEss } = props;
    const { id: essId, packageId, infos = [], replaceFacts: passedReplaceFacts = false } = ess;
    const [replaceFacts, setReplaceFacts] = useState(passedReplaceFacts);
    const [info, setInfo] = useState(getInfoByTextType(infos, ProcessActionsAndFactsEnum.LOCAL_FACTBOX));
    const { text: additionalDescription = EMPTY_STRING } = info;
    const [loader, setLoader] = useState(false);
    const mounted = useMount();
    const [saveDisabled, setSaveDisabled] = useState(isEmpty(additionalDescription));
    const [deleteDisabled, setDeleteDisabled] = useState(isEmpty(additionalDescription));
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);
    const openPopUp = useCallback(payload => dispatch({ type: OPEN_POP_UP, payload }), [dispatch]);

    useEffect(() => {
        const subscription = defer(() => setInfo(getInfoByTextType(infos, ProcessActionsAndFactsEnum.LOCAL_FACTBOX))).subscribe();

        return () => subscription.unsubscribe();
    }, [infos]);

    useEffect(() => {
        const subscription = defer(() => setReplaceFacts(passedReplaceFacts)).subscribe();

        return () => subscription.unsubscribe();
    }, [passedReplaceFacts]);

    useEffect(() => {
        const subscription = defer(() => setDeleteDisabled(isEmpty(additionalDescription))).subscribe();

        return () => subscription.unsubscribe();
    });

    const setAdditionalDescription = desc => {
        if (isEmpty(desc)) {
            setSaveDisabled(true);
            setDeleteDisabled(true);
        } else {
            setSaveDisabled(false);
            setDeleteDisabled(false);
        }
        setInfo({ ...info, text: desc, textType: ProcessActionsAndFactsEnum.LOCAL_FACTBOX });
    };

    const saveInfo = async (information, essId, replaceFacts) => {
        if (!isEmpty(information)) {
            const payload = {
                action: 'save',
                essId,
                info: information,
                replaceFacts
            };
            clearMessage();
            setLoader(true);
            setSaveDisabled(true);
            const savedEss = await saveEss(payload);
            if (mounted.current) {
                setLoader(false);
                if (savedEss.error) {
                    setMessage(savedEss.error);
                    setSaveDisabled(false);
                } else {
                    setEss(packageId, essId);
                }
            }
        }
    };

    const removeInfo = async (information, essId) => {
        if (!isEmpty(information)) {
            const payload = {
                action: 'delete',
                essId,
                info: information
            };
            clearMessage();
            setLoader(true);
            setDeleteDisabled(true);
            const savedEss = await saveEss(payload);
            if (mounted.current) {
                setLoader(false);
                if (savedEss.error) {
                    setMessage(savedEss.error);
                    setDeleteDisabled(false);
                } else {
                    setEss(packageId, essId);
                }
            }
        }
    };

    const deleteInfo = (information, essId) => {
        openPopUp({ component: <ConfirmBox value="REMOVE_LOCAL_FACTS_CONFIRM" onConfirm={() => removeInfo(information, essId)} /> });
    };

    const onChangeReplaceFacts = replaceFacts => {
        setReplaceFacts(replaceFacts);
        setSaveDisabled(false);
    };

    return (
        <div className="card loadable">
            {loader && <Loader />}
            <div className="essCardTitle">
                <Title value={t('LOCAL_FACTS')} size={SIZE.M} className="essTitle" />
            </div>
            <Line />
            <div className="section">
                <TextArea
                    id="localFacts"
                    onChange={setAdditionalDescription}
                    value={additionalDescription}
                    rows="5"
                    cols="200"
                />
                <CharacterButtons returnCursorTo="localFacts" returnCallback={character => { setAdditionalDescription(additionalDescription + character); }} />
                <RadioButtons
                    options={[
                        { label: t('REPLACE_FACTS'), value: true },
                        { label: t('COMPLEMENT_FACTS'), value: false }
                    ]}
                    name="replacefacts"
                    onItemClick={onChangeReplaceFacts}
                    value={replaceFacts}
                />
            </div>
            <div className="section buttonField-right">
                <Button
                    btnType={PRIMARY}
                    value={t('SAVE_TEXT')}
                    onClick={() => saveInfo(info, essId, replaceFacts)}
                    disabled={saveDisabled}
                />
                <Button
                    btnType={SECONDARY}
                    value={t('REMOVE_LOCAL_FACTS')}
                    onClick={() => deleteInfo(info, essId)}
                    disabled={deleteDisabled}
                />
            </div>
        </div>
    );
};

export default withTranslation()(LocalFactBox);
