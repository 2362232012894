import React from 'react';
import map from 'lodash/map';
import { withTranslation } from '../../../common/translation';
import { WithAnnotations } from '../../../common/withAnnotations';

const EssPreAction = ({ data, t, isFullSticky }) => {
    const id = isFullSticky ? 'processActionsSticky' : '';
  const preActionsSection = () => {
    if (data.preActions && data.preActions.length) {
      return (
          <div id={id} className="section">
              <div className="sectionTitle">{t('PREVENTIVE_ACTION')}</div>
              <div className="sectionContent">
                  {map(data.preActions, (item, i) => (
                      <div key={i}>
                          <WithAnnotations>{item.description}</WithAnnotations>
                      </div>
            ))}
              </div>
          </div>
      );
    }

    return null;
  };

  return preActionsSection();
};

export default withTranslation()(EssPreAction);
