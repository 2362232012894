import React from 'react';
import { Title } from '../../../common/text';
import { SIZE } from '../../../../constant/text';
import { CloseIcon, EditIcon } from '../../../common/icon';
import { withTranslation } from '../../../common/translation';

const EssTitle = ({
  closable = true,
  editable,
  onClose,
  onEdit,
  data,
  t,
  setStickyChange,
  checked,
}) => (
    <>
        <div className="essCardTitle" id="essTitle">
            <Title
                value={`${data.number}. ${data.packageType}`}
                size={SIZE.M}
                className="essTitle"
      />
            <section className="displayFlex">
                {closable && (
                <div className="essTitleCheckbox">
                    <label
                        className="noWrap"
                        htmlFor="sticky-checkbox"
          >
                        {t('STICKY_ESSHEADER')}
                    </label>
                    <input
                        name="sticky-checkbox"
                        id="sticky-checkbox"
                        type="checkbox"
                        checked={checked}
                        onChange={setStickyChange}
          />
                </div>
)}

                <Title
                    value={`${t('VERSION')}: ${data.packageVersion}`}
                    size={SIZE.XS}
                    className="essTitle versionText"
        />

                {editable && (
                <EditIcon
                    className="editIcon-card"
                    toolTip={t('EDIT_ESS')}
                    onClick={onEdit}
          />
        )}
                {closable && (
                <CloseIcon
                    toolTip={t('CLOSE_ESS')}
                    className="closeIcon-card"
                    onClick={() => onClose(data.id)}
          />
        )}
            </section>
        </div>
    </>
);

export default withTranslation()(EssTitle);
