import React from 'react';
import _ from 'lodash';
import { withTranslation } from '../../../common/translation';
import { WithAnnotations } from '../../../common/withAnnotations';

const EssProcessAction = ({ data, t }) => {
    const arrayIndex = data.isLocalProcessActions ? 4 : 3;
    const generalProcessActions = data.infos.filter(item => item.textType === arrayIndex);

    const processActionSection = () => {
            const colorLevels = [
                { level: '1', description: 'RED' },
                { level: '2', description: 'ORANGE' },
                { level: '3', description: 'YELLOW' },
                { level: '4', description: 'GREEN' },
                { level: '5', description: 'BLUE' }];
            const groupedProcessActions = _.chain(data.processActions)
                                    .groupBy('risklevel')
                                    .map((value, key) => ({
                                        risklevel: key,
                                        processActions: value
                                    }))
                                    .value();
            const processActionView = groupedProcessActions.map((riskGroup, i) => (
                <div key={i} className={`processAction riskLevel-border-${riskGroup.risklevel}`}>
                    <h3 className="processActionTitle">{t(colorLevels.find(c => c.level === riskGroup.risklevel).description)}</h3>
                    {riskGroup.processActions.map((item, j) => (
                        <div key={i + j}>
                            <WithAnnotations>{item.description}</WithAnnotations>
                        </div>
                    ))}

                </div>
            ));

            return (
                <div className="section">
                    <div className="sectionTitle">{t('PROCESS_ACTIONS')}</div>
                    <div className="sectionContent">
                        {processActionView}
                        {generalProcessActions.length !== 0
                        && (
                            <div className="processAction generalProcessAction" >
                                <h3 className="processActionTitle">{t('GENERAL_PROCESS_ACTIONS')}</h3>
                                <WithAnnotations>{generalProcessActions.map(item => item.text)}</WithAnnotations>
                                </div>
                        )}
                    </div>
                </div>
            );
    };

    return processActionSection();
};

export default withTranslation()(EssProcessAction);
