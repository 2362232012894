import React, { useState, useEffect } from 'react';
import { withTranslation } from '../../../../common/translation';
import { useStore } from '../../../../../store';
import Button from '../../../../common/button';
import SearchPhrase from './searchPhrase';
import GroupRule from './groupRule';
import TextRule from './textRule';
import VitalSign from './vitalSign';
import Argument from './argument';
import { EMPTY_STRING } from '../../../../../constant/text';

const EditOptions = props => {
    const {
        state: {
            // eslint-disable-next-line no-unused-vars
            manageVersions: { editVersion: { selectedVersion, selectedVp } }
        },
    } = useStore();
    const [tab, setTab] = useState('EDIT_VP');

    const { t, vpPackageId, setNewVpTypeId, currentDetail, isCurrentDetailUpdated, vpTypeId, setShowPrevNext = () => { } } = props;

    useEffect(() => {
        if (tab === 'EDIT_VP' || tab === 'EDIT_GROUP_RULE') {
            setShowPrevNext(true);
        } else {
            setShowPrevNext(false);
        }
    }, [tab]);

    return (
        <div className="editOptions">
            <div className="grid">
                <Button value={t('EDIT_VP')} otherClassName={`col_5 ${tab === 'EDIT_VP' ? 'activeOutline' : EMPTY_STRING}`} onClick={() => setTab('EDIT_VP')} />
                <Button value={t('EDIT_SEARCH_PHRASE')} otherClassName={`col_5 ${tab === 'EDIT_SEARCH_PHRASE' ? 'activeOutline' : EMPTY_STRING}`} onClick={() => setTab('EDIT_SEARCH_PHRASE')} />
                <Button value={t('EDIT_GROUP_RULE')} otherClassName={`col_5 ${tab === 'EDIT_GROUP_RULE' ? 'activeOutline' : EMPTY_STRING}`} onClick={() => setTab('EDIT_GROUP_RULE')} />
                <Button value={t('EDIT_NOMINAL')} otherClassName={`col_5 ${tab === 'EDIT_NOMINAL' ? 'activeOutline' : EMPTY_STRING}`} onClick={() => setTab('EDIT_NOMINAL')} />
                <Button value={t('EDIT_ARGUMENT')} otherClassName={`col_5 ${tab === 'EDIT_ARGUMENT' ? 'activeOutline' : EMPTY_STRING}`} onClick={() => setTab('EDIT_ARGUMENT')} />
            </div>
            <div className="edit">
                {tab === 'EDIT_VP' && <VitalSign vpPackageId={vpPackageId} currentDetail={currentDetail} isCurrentDetailUpdated={isCurrentDetailUpdated} setNewVpTypeId={setNewVpTypeId} />}
                {tab === 'EDIT_SEARCH_PHRASE' && <SearchPhrase vpPackageId={vpPackageId} />}
                {tab === 'EDIT_GROUP_RULE' && <GroupRule vpTypeId={vpTypeId} />}
                {tab === 'EDIT_NOMINAL' && <TextRule />}
                {tab === 'EDIT_ARGUMENT' && <Argument />}
            </div>
        </div>
    );
};

export default withTranslation()(EditOptions);
