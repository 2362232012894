import React from 'react';
import Button from '../../../common/button';
import { PRIMARY } from '../../../../constant/color';
import { withTranslation } from '../../../common/translation';

const PrevNextButtons = props => {
    const { previousId, packageId, nextId, btnType = PRIMARY, getDetailById, t } = props;

    return (
        <div className="buttonField-left">
            <Button btnType={btnType} value={t('PREVIOUS')} onClick={() => getDetailById(packageId, null, previousId)} disabled={!previousId} />
            <Button btnType={btnType} value={t('NEXT')} onClick={() => getDetailById(packageId, null, nextId)} disabled={!nextId} />
        </div>
    );
};

export default withTranslation()(PrevNextButtons);
