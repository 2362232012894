import React, { useEffect, useCallback, useState } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { withTranslation } from '../../../../common/translation';
import { Title } from '../../../../common/text';
import { LOCAL_VP_EDIT, REVISE, EDIT, EMPTY_STRING, SIZE, ERROR } from '../../../../../constant/text';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../../../constant/action';
import { MANAGE_VERSIONS_PAGE } from '../../../../../constant/route';
import { getAdjacentVpTypeIdsByPackageAndVpTypeDetailId, getVpTypeDetailById, getVpByVpTypeId } from '../../../../../api/vp';
import Loader from '../../../../common/loader';
import PrevNextButtons from '../prevNextButtons';
import EditOptions from './editOptions';
import InfoBox from '../../../../common/infoBox';
import { useStore } from '../../../../../store';
import { getFromLocal } from '../../../../../api/localStorageUtil';
import { PRIMARY } from '../../../../../constant/color';
import { LeftArrowIcon } from '../../../../common/icon';
import SearchVp from './searchVp';
import { useMount } from '../../../../common/useMount';

const VpEdit = props => {
    const { t } = props;
    const mounted = useMount();
    const [showPrevNext, setShowPrevNext] = useState(true);
    const { dispatch, state: { manageVersions: { vpEdit = {} } } } = useStore();
    const { editType = EDIT, vpPackageId, vpTypeId = 1 } = getFromLocal(LOCAL_VP_EDIT, true);
    const [loader, setLoader] = useState(false);
    const [newVpTypeId, setNewVpTypeId] = useState(vpTypeId);
    const [adjacentDetail, setAdjacentDetail] = useState({ previousId: null, nextId: null });
    const { previousId, nextId, currentDetail = {} } = adjacentDetail;
    const {
        vppackageId: packageId,
        vptypeName: packageType = EMPTY_STRING,
        version: packageVersion = EMPTY_STRING,
        languageName: packageLanguage = EMPTY_STRING,
        lastSavedDate = EMPTY_STRING,
        vptypes: vptypeId = vpTypeId
    } = currentDetail;

    const [isCurrentDetailUpdated, setCurrentDetailUpdated] = useState(false);
    const history = useHistory();

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const fetchDetail = () => {
            if (vpPackageId) {
                getVpTypeDetail(vpPackageId, newVpTypeId, null);
            }
        };

        const subscription = defer(() => fetchDetail()).subscribe();

        return () => subscription.unsubscribe();
    }, [vpEdit, newVpTypeId]);

    const getAdjacentDetail = async (vpPackageId, vpTypeDetailId, vpTypeDetail) => {
        if (vpPackageId && vpTypeDetailId) {
            setLoader(true);
            const adjacentVpTypeIds = await getAdjacentVpTypeIdsByPackageAndVpTypeDetailId(vpPackageId, vpTypeDetailId);
            if (mounted.current) {
                const { previousHeaderId: previousId, nextHeaderId: nextId } = adjacentVpTypeIds;
                setAdjacentDetail({ ...adjacentDetail, previousId, nextId, currentDetail: vpTypeDetail });
                setLoader(false);
            }
        }
    };

    const getVpTypeDetail = async (vpPackageId, vpTypeId, vpTypeDetailId) => {
        if (vpTypeDetailId || vpTypeId) {
            setLoader(true);
            clearMessage();
            let vpTypeDetail;
            if (vpTypeDetailId) {
                vpTypeDetail = await getVpTypeDetailById(vpTypeDetailId);
            } else {
                vpTypeDetail = await getVpByVpTypeId(vpPackageId, vpTypeId);
            }
            if (mounted.current) {
                setLoader(false);
                if (vpTypeDetail.error) {
                    setMessage(vpTypeDetail.error);
                } else {
                    const { id: vpTypeDetId } = vpTypeDetail;
                    getAdjacentDetail(vpPackageId, vpTypeDetId, vpTypeDetail);
                }
            }
        }
    };

    const setNewVpTypeIdValue = val => {
        setNewVpTypeId(val);
        setCurrentDetailUpdated(true);
    };

    return (
        <div className="editContainer">
            <LeftArrowIcon className="prevPageClickIcon" onClick={() => history.push(MANAGE_VERSIONS_PAGE)} />
            <div className="editor">
                <SearchVp />
                {isEmpty(currentDetail) && <div>{t('NO_VP_FOUND_FOR_EDITING')}</div>}
                {
                    !isEmpty(currentDetail) && (
                        <div id="editSection" className="editSection loadable">
                            <Title value={editType === REVISE ? t('REVISE_VP') : t('EDIT_VP')} size={SIZE.L} />
                            {editType === REVISE && <InfoBox info={t('REVISION_WARNING_TEXT')} type={ERROR} />}
                            <InfoBox title={t('YOU_ARE_WORKING_IN')}>
                                {`${packageVersion}, ${packageLanguage}`}

                                <div>
                                    <div className="infoTitle title">
                                        <div>{t('CURRENTLY_SHOWING')}</div>
                                    </div>
                                    <div>
                                        ${t(packageType)}
                                    </div>
                                </div>

                                <div>
                                    <div className="infoTitle title">
                                        <div>{t('LAST_SAVED')}</div>
                                    </div>
                                    <div>
                                        ${lastSavedDate}
                                    </div>
                                </div>
                            </InfoBox>
                            {loader && <Loader />}
                            {showPrevNext && <PrevNextButtons btnType={PRIMARY} previousId={previousId} packageId={packageId} nextId={nextId} getDetailById={getVpTypeDetail} />}
                            {showPrevNext && <Title value={t(packageType)} />}
                            <EditOptions
                                vpPackageId={vpPackageId}
                                vpTypeId={vptypeId}
                                setShowPrevNext={setShowPrevNext}
                                setNewVpTypeId={setNewVpTypeIdValue}
                                isCurrentDetailUpdated={isCurrentDetailUpdated}
                                currentDetail={currentDetail}
                            />
                            {loader && <Loader />}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default withTranslation()(VpEdit);
