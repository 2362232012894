import React, { useState, useCallback, useEffect } from 'react';
import { defer } from 'rxjs';
import { getFromLocal } from '../../../api/localStorageUtil';
import { LOCAL_LANGUAGE_CODE, PDF, XML, XSD } from '../../../constant/text';
import { convertLanguagesToDropdownFormat, isPredicare, getLanguageNameByCode } from '../../util';
import Dropdown from '../../common/dropdown';
import Checkbox from '../../common/checkbox';
import ExportDownload from './exportDownload';
import { canDownloadPdf, canExportXml } from '../../../api/util';
import { downloadDefinitionAsPdf, downloadDefinitionAsXml } from '../../../api/definitionList';
import { downloadSchemaAsXsd } from '../../../api/ess';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import { useStore } from '../../../store';
import { useMount } from '../../common/useMount';

const DefinitionList = () => {
    const { dispatch } = useStore();
    const [languageCode, setLanguageCode] = useState(getFromLocal(LOCAL_LANGUAGE_CODE));
    const [languageName, setLanguageName] = useState(getLanguageNameByCode(languageCode));
    const mounted = useMount();
    const [landscape, setLandscape] = useState(false);
    const [pdfLoader, setPdfLoader] = useState(false);
    const [xmlLoader, setXmlLoader] = useState(false);
    const [schemaLoader, setSchemaLoader] = useState(false);

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    useEffect(() => {
        const subscription = defer(() => { }).subscribe(() => {
            const langCode = getFromLocal(LOCAL_LANGUAGE_CODE);
            setLanguageCode(langCode);
            setLanguageName(getLanguageNameByCode(langCode));
        });

        return () => subscription.unsubscribe();
    }, []);

    const downloadPdf = async () => {
        setPdfLoader(true);
        clearMessage();
        const obj = {
            languageCode,
            landscape
        };
        const response = await downloadDefinitionAsPdf(obj, 'definitions.pdf');
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setPdfLoader(false);
        }
    };

    const downloadXml = async () => {
        setXmlLoader(true);
        clearMessage();
        const obj = {
            languageCode
        };
        const response = await downloadDefinitionAsXml(obj, 'definitions.xml');
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setXmlLoader(false);
        }
    };

    const downloadSchema = async () => {
        setSchemaLoader(true);
        clearMessage();
        const response = await downloadSchemaAsXsd('wordDefinitionListSchema.xsd');
        if (mounted.current) {
            if (response.error) {
                setMessage(response.error);
            }
            setSchemaLoader(false);
        }
    };

    const onlanguagechange = val => {
        setLanguageCode(val);
        setLanguageName(getLanguageNameByCode(val));
    };

    const enablePdfDownload = (canDownloadPdf() || isPredicare());
    const enableXmlExport = (canExportXml() || isPredicare());

    return (
        <div className="definitionList">
            <Dropdown
                options={convertLanguagesToDropdownFormat('languageCode')}
                value={languageCode}
                onChange={onlanguagechange}
                keyPrefix="definition"
            />
            <Checkbox
                value="PDF_IN_LANDSCAPE"
                onChange={setLandscape}
                checked={landscape}
            />
            <div className="exportDownloads">
                <ExportDownload iconType={PDF} desc1={'DEFINITION_LIST'} desc2={languageName} note={'DOWNLOAD_AS_PDF'} onClick={downloadPdf} loader={pdfLoader} disabled={!enablePdfDownload} />
                <ExportDownload iconType={XML} desc1={'DEFINITION_LIST'} desc2={languageName} note={'DOWNLOAD_AS_XML'} onClick={downloadXml} loader={xmlLoader} disabled={!enableXmlExport} />
                {isPredicare() && <ExportDownload iconType={XSD} note={'DOWNLOAD_XML_SCHEMA_DEFINITION'} onClick={downloadSchema} loader={schemaLoader} />}
            </div>
        </div>
    );
};

export default DefinitionList;
