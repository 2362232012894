import React, { useEffect, useCallback, useState } from 'react';
import { defer } from 'rxjs';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from '../../common/translation';
import { Title } from '../../common/text';
import Loader from '../../common/loader';
import Button from '../../common/button';
import { PRIMARY } from '../../../constant/color';
import Dropdown from '../../common/dropdown';
import { DeleteIcon } from '../../common/icon';
import { useStore } from '../../../store';
import { SIZE, EMPTY_STRING } from '../../../constant/text';
import { SET_MESSAGE, CLEAR_MESSAGE } from '../../../constant/action';
import { getAssignableProductsForAccount, getProductsForAccount, deleteAvailableProduct, assignAssignableProduct } from '../../../api/ess';
import { convertObjectsToDropdownList, isPredicare } from '../../util';
import { useMount } from '../../common/useMount';

const Products = props => {
    const { t, node = {} } = props;
    const { id: nodeId } = node || {};
    const [loader, setLoader] = useState(false);
    const mounted = useMount();
    const [disabled, setDisabled] = useState(false);
    const [assignableProducts, setAssignableProducts] = useState([]);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [selectedAssignableProductId, setSelectedAssignableProductId] = useState();
    const { dispatch } = useStore();
    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);
    const clearMessage = useCallback(payload => dispatch({ type: CLEAR_MESSAGE, payload }), [dispatch]);

    const fetchProducts = async () => {
        setLoader(true);
        clearMessage();
        const results = await Promise.all([getProductsForAccount(nodeId), getAssignableProductsForAccount(nodeId)]);
        if (mounted.current) {
            setLoader(false);
            const availablePdts = results[0];
            const assignablePdts = results[1];
            if (availablePdts.error) {
                setMessage(availablePdts.error);
            } else if (assignablePdts.error) {
                setMessage(assignablePdts.error);
            } else {
                setAvailableProducts(availablePdts);
                setAssignableProducts(assignablePdts);
                setSelectedAssignableProductId(assignablePdts[0].id);
            }
        }
    };

    useEffect(() => {
        const subscription = defer(() => fetchProducts()).subscribe();

        return () => subscription.unsubscribe();
    }, [nodeId]);

    const assignProduct = async () => {
        setDisabled(true);
        clearMessage();
        const product = assignableProducts.filter(item => Number(item.id) === Number(selectedAssignableProductId))[0];
        const assigned = await assignAssignableProduct(nodeId, product);
        if (mounted.current) {
            setDisabled(false);
            if (assigned && assigned.error) {
                setMessage(assigned.error);
            } else {
                fetchProducts();
            }
        }
    };

    const removeAvailableProduct = async product => {
        clearMessage();
        const deleted = await deleteAvailableProduct(nodeId, product);
        if (mounted.current) {
            if (deleted && deleted.error) {
                setMessage(deleted.error);
            } else {
                fetchProducts();
            }
        }
    };

    return (
        <div className="products loadable">
            <Title value={t('PRODUCTS')} size={SIZE.L} />
            {loader && <Loader />}
            {!isEmpty(availableProducts) && (
                <div className="availableProducts">
                    <Title value={t('AVAILABLE_PRODUCTS')} size={SIZE.M} />
                    {
                        availableProducts.map(availableProduct => {
                            const { id, type } = availableProduct;

                            return (
                                <div key={id} className="availableProduct">
                                    <div className="availableProductName">{type}</div>
                                    {isPredicare() && <DeleteIcon onClick={() => removeAvailableProduct(availableProduct)} />}
                                </div>
                            );
                        })
                    }
                </div>
            )}
            {isEmpty(availableProducts) && <div className="notFound">{t('NO_PRODUCTS_FOUND_FOR_ACCOUNT')}{!isPredicare() ? `. ${t('CONTACT_PREDICARE')}` : EMPTY_STRING}</div>}
            {isPredicare() && !isEmpty(assignableProducts) && (
                <div className="assignProduct">
                    <Title value={t('ASSIGN_PRODUCT')} size={SIZE.M} />
                    <Dropdown
                        options={convertObjectsToDropdownList(assignableProducts, 'id', 'type')}
                        value={selectedAssignableProductId ? selectedAssignableProductId : assignableProducts[0].id}
                        onChange={setSelectedAssignableProductId}
                        keyPrefix="assignableProducts"
                    />
                    <Button btnType={PRIMARY} value={t('ASSIGN')} onClick={assignProduct} disabled={disabled} />
                </div>
            )}
        </div>
    );
};

export default withTranslation()(Products);
