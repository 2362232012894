const enableDebugLogs = process.env!.NODE_ENV === 'development';

let debugLogFunction;

if (enableDebugLogs) {
    debugLogFunction = console.log.bind(window.console);
} else {
    debugLogFunction = function () {};
}

export const debugLog = debugLogFunction;
