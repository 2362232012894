import React from 'react';
import { RettsLogo } from '../common/icon';

const AppLogo = () => (
    <div className="appLogo">
        <RettsLogo />
    </div>
    );

export default React.memo(AppLogo);
