import React, { useState, useCallback } from 'react';
import filter from 'lodash/filter';
import { CloseIcon } from '../../../../common/icon';
import { withTranslation } from '../../../../common/translation';
import Dropdown from '../../../../common/dropdown';
import Button from '../../../../common/button';
import { addNewLanguageType } from '../../../../../api/ess';
import { ALL_LANGUAGE_LIST } from '../../../../../constant/language';
import { useStore } from '../../../../../store';
import { SET_MESSAGE } from '../../../../../constant/action';

const AddNewLanguage = props => {
    const { t, handleCreateVersionPopup } = props;
    const [data, setData] = useState({ lang: {} });
    const { dispatch } = useStore();

    const setMessage = useCallback(payload => dispatch({ type: SET_MESSAGE, payload }), [dispatch]);

    const handleData = (key, val) => {
        const selected = filter(ALL_LANGUAGE_LIST, item => item.code === val);
        setData({ ...data, [key]: selected });
    };

    const addNewLanguage = async () => {
        const fetch = await addNewLanguageType(data.lang);
        if (fetch.error) {
            setMessage(fetch.error);
        }
        handleCreateVersionPopup();
    };

    return (
        <div className="popUpComponent">
            <CloseIcon className="popUpCloseIcon" onClick={handleCreateVersionPopup} />
            <div className="title">AddNewProduct</div>
            <div className="popUpBody">
                {t('SPECIFY_LANGUAGE')}
                <div className="horizontalDivider" />
                <div className="grid">
                    <div className="col_2">{t('AVAILABLE_TO_CATEGORY')}</div>
                    <div className="col_2">
                        <Dropdown
                            options={ALL_LANGUAGE_LIST.map(item => ({ label: item.language, value: item.code }))}
                            keyPrefix="langList"
                            onChange={e => handleData('lang', e)}
                        />
                    </div>
                </div>
                <div className="horizontalDivider" />
                <div className="grid">
                    <div className="col_2" ><Button onClick={handleCreateVersionPopup} value={t('CANCEL')} /></div>
                    <div className="col_2" ><Button onClick={addNewLanguage} value={t('ADD_LANGUAGE')} /></div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(AddNewLanguage);
